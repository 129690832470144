var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { sidebarCssClasses } from './Shared';
//import ClickOutHandler from 'react-onclickout';

import './Shared/element-closest';
import LayoutHelper from './Shared/layout/layout';

var propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  compact: PropTypes.bool,
  display: PropTypes.string,
  fixed: PropTypes.bool,
  minimized: PropTypes.bool,
  isOpen: PropTypes.bool,
  offCanvas: PropTypes.bool,
  staticContext: PropTypes.any,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};

var defaultProps = {
  tag: 'div',
  compact: false,
  display: '',
  fixed: false,
  minimized: false,
  isOpen: false,
  offCanvas: false
};

class DlvAppSidebar extends Component {


  constructor(props) {
    super(props);
  }

  componentDidMount = ()=> {
    this.displayBreakpoint(this.props.display);
    this.isCompact(this.props.compact);
    this.isFixed(this.props.fixed);
    this.isMinimized(this.props.minimized);
    this.isOffCanvas(this.props.offCanvas);
  };
  isCompact= (compact)=> {
    if (compact) {
      document.body.classList.add('sidebar-compact');
    }
  };

  isFixed= (fixed)=> {
    if (fixed) {
      document.body.classList.add('sidebar-fixed');
    }
  };

  isMinimized= (minimized) =>{
    LayoutHelper.sidebarToggle(minimized);
  };

  isOffCanvas= (offCanvas) => {
    if (offCanvas) {
      document.body.classList.add('sidebar-off-canvas');
    }
  };

  displayBreakpoint= (display) =>{
    var cssTemplate = 'sidebar-' + display + '-show';
    var _sidebarCssClasses$ = sidebarCssClasses[0],
      cssClass = _sidebarCssClasses$[0];

    if (display && sidebarCssClasses.indexOf(cssTemplate) > -1) {
      cssClass = cssTemplate;
    }
    document.body.classList.add(cssClass);
  };

  hideMobile= () =>{
    if (document.body.classList.contains('sidebar-show')) {
      document.body.classList.remove('sidebar-show');
    }
  };

  // onClickOut= (e) =>{
  //   if (typeof window !== 'undefined' && document.body.classList.contains('sidebar-show')) {
  //     if (!e.target.closest('[data-sidebar-toggler]')) {
  //       this.hideMobile();
  //     }
  //   }
  // };

  render =() =>{
    var _this2 = this;

    var _props = this.props,
      className = _props.className,
      children = _props.children,
      Tag = _props.tag,
      attributes = _objectWithoutProperties(_props, ['className', 'children', 'tag']);

    delete attributes.compact;
    delete attributes.display;
    delete attributes.fixed;
    delete attributes.minimized;
    delete attributes.offCanvas;
    delete attributes.isOpen;
    delete attributes.staticContext;

    var classes = classNames(className, 'sidebar');

    // sidebar-nav root
    // return React.createElement(
    //   ClickOutHandler,
    //   { onClickOut: function onClickOut(e) {
    //       _this2.onClickOut(e);
    //     } },
     return React.createElement(
        Tag,
        _extends({ className: classes }, attributes),
        children
      )
    // );
  };


}
DlvAppSidebar.defaultProps = defaultProps;

export default React.memo(DlvAppSidebar);
