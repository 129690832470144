/* tslint:disable */
/* eslint-disable */
/**
 * Mediasignaler API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdvancedMode
 */
export interface AdvancedMode {
    /**
     * 
     * @type {number}
     * @memberof AdvancedMode
     */
    'FrameNoiseReduceLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdvancedMode
     */
    'InterFrameNoiseReduceLevel'?: number;
}
/**
 * 
 * @export
 * @interface AdvancedModeOptions
 */
export interface AdvancedModeOptions {
    /**
     * 
     * @type {FrameNoiseReduceLevelOptions}
     * @memberof AdvancedModeOptions
     */
    'FrameNoiseReduceLevel'?: FrameNoiseReduceLevelOptions;
    /**
     * 
     * @type {InterFrameNoiseReduceLevelOptions}
     * @memberof AdvancedModeOptions
     */
    'InterFrameNoiseReduceLevel'?: InterFrameNoiseReduceLevelOptions;
}
/**
 * 
 * @export
 * @interface AiCamera
 */
export interface AiCamera {
    /**
     * 
     * @type {number}
     * @memberof AiCamera
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCamera
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCamera
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiCamera
     */
    'port'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCamera
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCamera
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCamera
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCamera
     */
    'dateFormat'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiCamera
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiCamera
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCamera
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCamera
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCamera
     */
    'status'?: AiCameraStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AiCamera
     */
    'aiCameraType'?: AiCameraAiCameraTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AiCamera
     */
    'aiModuleId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiCamera
     */
    'areaId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiCamera
     */
    'zoneId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AiCamera
     */
    'hasPtz'?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AiCamera
     */
    'properties'?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof AiCamera
     */
    'panOffset'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCamera
     */
    'managementMode'?: string;
}

export const AiCameraStatusEnum = {
    Online: 'ONLINE',
    Offline: 'OFFLINE',
    NotWorking: 'NOT_WORKING'
} as const;

export type AiCameraStatusEnum = typeof AiCameraStatusEnum[keyof typeof AiCameraStatusEnum];
export const AiCameraAiCameraTypeEnum = {
    NoAi: 'NO_AI',
    FaceRecognition: 'FACE_RECOGNITION'
} as const;

export type AiCameraAiCameraTypeEnum = typeof AiCameraAiCameraTypeEnum[keyof typeof AiCameraAiCameraTypeEnum];

/**
 * 
 * @export
 * @interface AiCameraEx
 */
export interface AiCameraEx {
    /**
     * 
     * @type {number}
     * @memberof AiCameraEx
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCameraEx
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraEx
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiCameraEx
     */
    'port'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCameraEx
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraEx
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraEx
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraEx
     */
    'dateFormat'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiCameraEx
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiCameraEx
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCameraEx
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraEx
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraEx
     */
    'status'?: AiCameraExStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AiCameraEx
     */
    'aiCameraType'?: AiCameraExAiCameraTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AiCameraEx
     */
    'aiModuleId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiCameraEx
     */
    'areaId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiCameraEx
     */
    'zoneId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AiCameraEx
     */
    'hasPtz'?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AiCameraEx
     */
    'properties'?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof AiCameraEx
     */
    'panOffset'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCameraEx
     */
    'managementMode'?: string;
    /**
     * 
     * @type {CameraPosition}
     * @memberof AiCameraEx
     */
    'position'?: CameraPosition;
    /**
     * 
     * @type {string}
     * @memberof AiCameraEx
     */
    'lastUpdate'?: string;
}

export const AiCameraExStatusEnum = {
    Online: 'ONLINE',
    Offline: 'OFFLINE',
    NotWorking: 'NOT_WORKING'
} as const;

export type AiCameraExStatusEnum = typeof AiCameraExStatusEnum[keyof typeof AiCameraExStatusEnum];
export const AiCameraExAiCameraTypeEnum = {
    NoAi: 'NO_AI',
    FaceRecognition: 'FACE_RECOGNITION'
} as const;

export type AiCameraExAiCameraTypeEnum = typeof AiCameraExAiCameraTypeEnum[keyof typeof AiCameraExAiCameraTypeEnum];

/**
 * 
 * @export
 * @interface AiCameraFilter
 */
export interface AiCameraFilter {
    /**
     * 
     * @type {Array<GenericFilter>}
     * @memberof AiCameraFilter
     */
    'filters'?: Array<GenericFilter>;
    /**
     * 
     * @type {string}
     * @memberof AiCameraFilter
     */
    'filterGroupOp'?: AiCameraFilterFilterGroupOpEnum;
}

export const AiCameraFilterFilterGroupOpEnum = {
    And: 'AND',
    Or: 'OR',
    AndNot: 'AND_NOT',
    OrNot: 'OR_NOT'
} as const;

export type AiCameraFilterFilterGroupOpEnum = typeof AiCameraFilterFilterGroupOpEnum[keyof typeof AiCameraFilterFilterGroupOpEnum];

/**
 * 
 * @export
 * @interface AiCameraImagingSettingsScheduleDTO
 */
export interface AiCameraImagingSettingsScheduleDTO {
    /**
     * 
     * @type {number}
     * @memberof AiCameraImagingSettingsScheduleDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCameraImagingSettingsScheduleDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiCameraImagingSettingsScheduleDTO
     */
    'aiCameraImagingSettingsId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AiCameraImagingSettingsScheduleDTO
     */
    'defaultSchedule'?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AiCameraImagingSettingsScheduleDTO
     */
    'properties'?: { [key: string]: object; };
    /**
     * 
     * @type {Array<AiCameraImagingSettingsScheduleIntervalDTO>}
     * @memberof AiCameraImagingSettingsScheduleDTO
     */
    'aiCameraImagingSettingsScheduleIntervalDTOS'?: Array<AiCameraImagingSettingsScheduleIntervalDTO>;
}
/**
 * 
 * @export
 * @interface AiCameraImagingSettingsScheduleDateDTO
 */
export interface AiCameraImagingSettingsScheduleDateDTO {
    /**
     * 
     * @type {number}
     * @memberof AiCameraImagingSettingsScheduleDateDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiCameraImagingSettingsScheduleDateDTO
     */
    'aiCameraImagingSettingsScheduleId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCameraImagingSettingsScheduleDateDTO
     */
    'aiCameraImagingSettingsScheduleName'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiCameraImagingSettingsScheduleDateDTO
     */
    'aiCameraImagingSettingsId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCameraImagingSettingsScheduleDateDTO
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface AiCameraImagingSettingsScheduleHistDTO
 */
export interface AiCameraImagingSettingsScheduleHistDTO {
    /**
     * 
     * @type {number}
     * @memberof AiCameraImagingSettingsScheduleHistDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiCameraImagingSettingsScheduleHistDTO
     */
    'aiCameraImagingSettingsId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCameraImagingSettingsScheduleHistDTO
     */
    'aiCameraImagingSettingsName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraImagingSettingsScheduleHistDTO
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraImagingSettingsScheduleHistDTO
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface AiCameraImagingSettingsScheduleIntervalDTO
 */
export interface AiCameraImagingSettingsScheduleIntervalDTO {
    /**
     * 
     * @type {number}
     * @memberof AiCameraImagingSettingsScheduleIntervalDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiCameraImagingSettingsScheduleIntervalDTO
     */
    'aiCameraImagingSettingsId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCameraImagingSettingsScheduleIntervalDTO
     */
    'aiCameraImagingSettingsName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraImagingSettingsScheduleIntervalDTO
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraImagingSettingsScheduleIntervalDTO
     */
    'endTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraImagingSettingsScheduleIntervalDTO
     */
    'specialStart'?: AiCameraImagingSettingsScheduleIntervalDTOSpecialStartEnum;
    /**
     * 
     * @type {string}
     * @memberof AiCameraImagingSettingsScheduleIntervalDTO
     */
    'specialEnd'?: AiCameraImagingSettingsScheduleIntervalDTOSpecialEndEnum;
}

export const AiCameraImagingSettingsScheduleIntervalDTOSpecialStartEnum = {
    Sunset: 'SUNSET',
    Sunrise: 'SUNRISE'
} as const;

export type AiCameraImagingSettingsScheduleIntervalDTOSpecialStartEnum = typeof AiCameraImagingSettingsScheduleIntervalDTOSpecialStartEnum[keyof typeof AiCameraImagingSettingsScheduleIntervalDTOSpecialStartEnum];
export const AiCameraImagingSettingsScheduleIntervalDTOSpecialEndEnum = {
    Sunset: 'SUNSET',
    Sunrise: 'SUNRISE'
} as const;

export type AiCameraImagingSettingsScheduleIntervalDTOSpecialEndEnum = typeof AiCameraImagingSettingsScheduleIntervalDTOSpecialEndEnum[keyof typeof AiCameraImagingSettingsScheduleIntervalDTOSpecialEndEnum];

/**
 * 
 * @export
 * @interface AiCameraLess
 */
export interface AiCameraLess {
    /**
     * 
     * @type {number}
     * @memberof AiCameraLess
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCameraLess
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraLess
     */
    'dateFormat'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiCameraLess
     */
    'aiCameraTypeId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AiCameraLess
     */
    'hasPtz'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AiCameraLess
     */
    'zoneId'?: number;
}
/**
 * 
 * @export
 * @interface AiCameraModule
 */
export interface AiCameraModule {
    /**
     * 
     * @type {number}
     * @memberof AiCameraModule
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiCameraModule
     */
    'encoderGpuId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiCameraModule
     */
    'decoderGpuId'?: number;
}
/**
 * 
 * @export
 * @interface AiCameraSpeedAdjustment
 */
export interface AiCameraSpeedAdjustment {
    /**
     * 
     * @type {number}
     * @memberof AiCameraSpeedAdjustment
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiCameraSpeedAdjustment
     */
    'elementTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCameraSpeedAdjustment
     */
    'ptzSpeedType'?: AiCameraSpeedAdjustmentPtzSpeedTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AiCameraSpeedAdjustment
     */
    'outputRealSpeed'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiCameraSpeedAdjustment
     */
    'inputCommandSpeed'?: number;
}

export const AiCameraSpeedAdjustmentPtzSpeedTypeEnum = {
    Pan: 'PAN',
    Tilt: 'TILT',
    Zoom: 'ZOOM'
} as const;

export type AiCameraSpeedAdjustmentPtzSpeedTypeEnum = typeof AiCameraSpeedAdjustmentPtzSpeedTypeEnum[keyof typeof AiCameraSpeedAdjustmentPtzSpeedTypeEnum];

/**
 * 
 * @export
 * @interface AiCameraUpsert
 */
export interface AiCameraUpsert {
    /**
     * 
     * @type {number}
     * @memberof AiCameraUpsert
     */
    'areaId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiCameraUpsert
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCameraUpsert
     */
    'status'?: AiCameraUpsertStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AiCameraUpsert
     */
    'camname'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraUpsert
     */
    'ipaddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiCameraUpsert
     */
    'onvifPort'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCameraUpsert
     */
    'onvifPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraUpsert
     */
    'onvifAuthType'?: AiCameraUpsertOnvifAuthTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AiCameraUpsert
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraUpsert
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraUpsert
     */
    'dateFormat'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiCameraUpsert
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiCameraUpsert
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCameraUpsert
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraUpsert
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraUpsert
     */
    'model'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCameraUpsert
     */
    'brand'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiCameraUpsert
     */
    'zoneId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCameraUpsert
     */
    'managementMode'?: AiCameraUpsertManagementModeEnum;
    /**
     * 
     * @type {number}
     * @memberof AiCameraUpsert
     */
    'maxRecordingDays'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiCameraUpsert
     */
    'aiCameraType'?: AiCameraUpsertAiCameraTypeEnum;
    /**
     * 
     * @type {VideoStreamUpsert}
     * @memberof AiCameraUpsert
     */
    'liveStream'?: VideoStreamUpsert;
    /**
     * 
     * @type {VideoStreamUpsert}
     * @memberof AiCameraUpsert
     */
    'recordStream'?: VideoStreamUpsert;
    /**
     * 
     * @type {VideoStreamUpsert}
     * @memberof AiCameraUpsert
     */
    'lowStream'?: VideoStreamUpsert;
    /**
     * 
     * @type {number}
     * @memberof AiCameraUpsert
     */
    'cameraManagerId'?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AiCameraUpsert
     */
    'properties'?: { [key: string]: object; };
    /**
     * 
     * @type {AiCameraModule}
     * @memberof AiCameraUpsert
     */
    'aiCameraModule'?: AiCameraModule;
}

export const AiCameraUpsertStatusEnum = {
    Online: 'ONLINE',
    Offline: 'OFFLINE',
    NotWorking: 'NOT_WORKING'
} as const;

export type AiCameraUpsertStatusEnum = typeof AiCameraUpsertStatusEnum[keyof typeof AiCameraUpsertStatusEnum];
export const AiCameraUpsertOnvifAuthTypeEnum = {
    SoapDigest: 'SOAP_DIGEST',
    HttpDigest: 'HTTP_DIGEST',
    HttpBasic: 'HTTP_BASIC'
} as const;

export type AiCameraUpsertOnvifAuthTypeEnum = typeof AiCameraUpsertOnvifAuthTypeEnum[keyof typeof AiCameraUpsertOnvifAuthTypeEnum];
export const AiCameraUpsertManagementModeEnum = {
    None: 'NONE',
    Onvif: 'ONVIF',
    ExternalManager: 'EXTERNAL_MANAGER',
    OnlyPing: 'ONLY_PING',
    Axis: 'AXIS',
    OnvifProxy: 'ONVIF_PROXY',
    Hikvision: 'HIKVISION'
} as const;

export type AiCameraUpsertManagementModeEnum = typeof AiCameraUpsertManagementModeEnum[keyof typeof AiCameraUpsertManagementModeEnum];
export const AiCameraUpsertAiCameraTypeEnum = {
    NoAi: 'NO_AI',
    FaceRecognition: 'FACE_RECOGNITION'
} as const;

export type AiCameraUpsertAiCameraTypeEnum = typeof AiCameraUpsertAiCameraTypeEnum[keyof typeof AiCameraUpsertAiCameraTypeEnum];

/**
 * 
 * @export
 * @interface AiModuleDTO
 */
export interface AiModuleDTO {
    /**
     * 
     * @type {number}
     * @memberof AiModuleDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiModuleDTO
     */
    'areaId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiModuleDTO
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiModuleDTO
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiModuleDTO
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiModuleDTO
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiModuleDTO
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiModuleDTO
     */
    'port'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiModuleDTO
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiModuleDTO
     */
    'password'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiModuleDTO
     */
    'sectionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiModuleDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiModuleDTO
     */
    'zoneId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiModuleDTO
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiModuleDTO
     */
    'model'?: string;
}
/**
 * 
 * @export
 * @interface ApiCameraStatus
 */
export interface ApiCameraStatus {
    /**
     * 
     * @type {string}
     * @memberof ApiCameraStatus
     */
    'status'?: ApiCameraStatusStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiCameraStatus
     */
    'cameraId'?: number;
}

export const ApiCameraStatusStatusEnum = {
    Online: 'ONLINE',
    Offline: 'OFFLINE',
    NotWorking: 'NOT_WORKING'
} as const;

export type ApiCameraStatusStatusEnum = typeof ApiCameraStatusStatusEnum[keyof typeof ApiCameraStatusStatusEnum];

/**
 * 
 * @export
 * @interface ApiError
 */
export interface ApiError {
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'errorCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'target'?: string;
}
/**
 * 
 * @export
 * @interface ApiErrorResponse
 */
export interface ApiErrorResponse {
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiErrorResponse
     */
    'errorList'?: Array<ApiError>;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiErrorResponse
     */
    'error'?: ApiError;
}
/**
 * 
 * @export
 * @interface ApiPreset
 */
export interface ApiPreset {
    /**
     * 
     * @type {string}
     * @memberof ApiPreset
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPreset
     */
    'pan'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPreset
     */
    'tilt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPreset
     */
    'zoom'?: number;
}
/**
 * 
 * @export
 * @interface ApiProfile
 */
export interface ApiProfile {
    /**
     * 
     * @type {number}
     * @memberof ApiProfile
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiProfile
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface BLC
 */
export interface BLC {
    /**
     * 
     * @type {boolean}
     * @memberof BLC
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BLC
     */
    'BLCMode'?: string;
}
/**
 * 
 * @export
 * @interface BLCModeOptions
 */
export interface BLCModeOptions {
    /**
     * 
     * @type {string}
     * @memberof BLCModeOptions
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof BLCModeOptions
     */
    'opt'?: string;
}
/**
 * 
 * @export
 * @interface BLCOptions
 */
export interface BLCOptions {
    /**
     * 
     * @type {EnabledOptions}
     * @memberof BLCOptions
     */
    'enabled'?: EnabledOptions;
    /**
     * 
     * @type {BLCModeOptions}
     * @memberof BLCOptions
     */
    'BLCMode'?: BLCModeOptions;
}
/**
 * 
 * @export
 * @interface BrightnessLevelOptions
 */
export interface BrightnessLevelOptions {
    /**
     * 
     * @type {number}
     * @memberof BrightnessLevelOptions
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrightnessLevelOptions
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrightnessLevelOptions
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface BrightnessLimitOptions
 */
export interface BrightnessLimitOptions {
    /**
     * 
     * @type {number}
     * @memberof BrightnessLimitOptions
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrightnessLimitOptions
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrightnessLimitOptions
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface CameraContinousMove
 */
export interface CameraContinousMove {
    /**
     * 
     * @type {CameraPosition}
     * @memberof CameraContinousMove
     */
    'cameraPosition'?: CameraPosition;
    /**
     * 
     * @type {number}
     * @memberof CameraContinousMove
     */
    'timeoutSecs'?: number;
}
/**
 * 
 * @export
 * @interface CameraMove
 */
export interface CameraMove {
    /**
     * 
     * @type {CameraPosition}
     * @memberof CameraMove
     */
    'cameraPosition'?: CameraPosition;
    /**
     * 
     * @type {number}
     * @memberof CameraMove
     */
    'speed'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CameraMove
     */
    'relative'?: boolean;
}
/**
 * 
 * @export
 * @interface CameraPosition
 */
export interface CameraPosition {
    /**
     * 
     * @type {number}
     * @memberof CameraPosition
     */
    'pan'?: number;
    /**
     * 
     * @type {number}
     * @memberof CameraPosition
     */
    'tilt'?: number;
    /**
     * 
     * @type {number}
     * @memberof CameraPosition
     */
    'zoom'?: number;
    /**
     * 
     * @type {number}
     * @memberof CameraPosition
     */
    'focus'?: number;
    /**
     * 
     * @type {number}
     * @memberof CameraPosition
     */
    'iris'?: number;
}
/**
 * 
 * @export
 * @interface CameraSequence
 */
export interface CameraSequence {
    /**
     * 
     * @type {number}
     * @memberof CameraSequence
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CameraSequence
     */
    'name'?: string;
    /**
     * 
     * @type {Array<CameraSequenceElement>}
     * @memberof CameraSequence
     */
    'cameraSequences'?: Array<CameraSequenceElement>;
}
/**
 * 
 * @export
 * @interface CameraSequenceElement
 */
export interface CameraSequenceElement {
    /**
     * 
     * @type {number}
     * @memberof CameraSequenceElement
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CameraSequenceElement
     */
    'index'?: number;
    /**
     * 
     * @type {number}
     * @memberof CameraSequenceElement
     */
    'cameraId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CameraSequenceElement
     */
    'cameraName'?: string;
    /**
     * 
     * @type {number}
     * @memberof CameraSequenceElement
     */
    'waitTime'?: number;
}
/**
 * 
 * @export
 * @interface CameraToken
 */
export interface CameraToken {
    /**
     * 
     * @type {string}
     * @memberof CameraToken
     */
    'token'?: string;
    /**
     * 
     * @type {number}
     * @memberof CameraToken
     */
    'cameraId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CameraToken
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CameraToken
     */
    'streamType'?: CameraTokenStreamTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CameraToken
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof CameraToken
     */
    'expirationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof CameraToken
     */
    'finishTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof CameraToken
     */
    'cameraTokenStatus'?: CameraTokenCameraTokenStatusEnum;
}

export const CameraTokenStreamTypeEnum = {
    Default: 'DEFAULT',
    Record: 'RECORD',
    Low: 'LOW',
    Detection: 'DETECTION',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type CameraTokenStreamTypeEnum = typeof CameraTokenStreamTypeEnum[keyof typeof CameraTokenStreamTypeEnum];
export const CameraTokenCameraTokenStatusEnum = {
    CameraTokenUnused: 'CAMERA_TOKEN_UNUSED',
    CameraTokenInUse: 'CAMERA_TOKEN_IN_USE',
    CameraTokenFinished: 'CAMERA_TOKEN_FINISHED',
    CameraTokenNotExists: 'CAMERA_TOKEN_NOT_EXISTS',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type CameraTokenCameraTokenStatusEnum = typeof CameraTokenCameraTokenStatusEnum[keyof typeof CameraTokenCameraTokenStatusEnum];

/**
 * 
 * @export
 * @interface CameraTourAiCameraPresetDTO
 */
export interface CameraTourAiCameraPresetDTO {
    /**
     * 
     * @type {number}
     * @memberof CameraTourAiCameraPresetDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CameraTourAiCameraPresetDTO
     */
    'index'?: number;
    /**
     * 
     * @type {string}
     * @memberof CameraTourAiCameraPresetDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof CameraTourAiCameraPresetDTO
     */
    'cameraTourId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CameraTourAiCameraPresetDTO
     */
    'aiCameraPresetId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CameraTourAiCameraPresetDTO
     */
    'waitTime'?: number;
}
/**
 * 
 * @export
 * @interface CameraTourDTO
 */
export interface CameraTourDTO {
    /**
     * 
     * @type {number}
     * @memberof CameraTourDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CameraTourDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof CameraTourDTO
     */
    'elementAiCameraId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CameraTourDTO
     */
    'repeat'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CameraTourDTO
     */
    'repeatTime'?: number;
    /**
     * 
     * @type {Array<CameraTourAiCameraPresetDTO>}
     * @memberof CameraTourDTO
     */
    'listCameraTourAiCameraPresetDTO'?: Array<CameraTourAiCameraPresetDTO>;
}
/**
 * 
 * @export
 * @interface CaptureMode
 */
export interface CaptureMode {
    /**
     * 
     * @type {string}
     * @memberof CaptureMode
     */
    'mode'?: string;
}
/**
 * 
 * @export
 * @interface Chart
 */
export interface Chart {
    /**
     * 
     * @type {Array<string>}
     * @memberof Chart
     */
    'labels'?: Array<string>;
    /**
     * 
     * @type {Array<Dataset>}
     * @memberof Chart
     */
    'datasets'?: Array<Dataset>;
}
/**
 * 
 * @export
 * @interface ChartWrapper
 */
export interface ChartWrapper {
    /**
     * 
     * @type {Chart}
     * @memberof ChartWrapper
     */
    'chart'?: Chart;
    /**
     * 
     * @type {string}
     * @memberof ChartWrapper
     */
    'type'?: ChartWrapperTypeEnum;
}

export const ChartWrapperTypeEnum = {
    Xlabel: 'XLABEL',
    Xylabel: 'XYLABEL'
} as const;

export type ChartWrapperTypeEnum = typeof ChartWrapperTypeEnum[keyof typeof ChartWrapperTypeEnum];

/**
 * 
 * @export
 * @interface Color
 */
export interface Color {
    /**
     * 
     * @type {number}
     * @memberof Color
     */
    'brightnessLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof Color
     */
    'contrastLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof Color
     */
    'saturationLevel'?: number;
}
/**
 * 
 * @export
 * @interface ColorOptions
 */
export interface ColorOptions {
    /**
     * 
     * @type {BrightnessLevelOptions}
     * @memberof ColorOptions
     */
    'brightnessLevel'?: BrightnessLevelOptions;
    /**
     * 
     * @type {ContrastLevelOptions}
     * @memberof ColorOptions
     */
    'contrastLevel'?: ContrastLevelOptions;
    /**
     * 
     * @type {SaturationLevelOptions}
     * @memberof ColorOptions
     */
    'saturationLevel'?: SaturationLevelOptions;
}
/**
 * 
 * @export
 * @interface ConnectionParams
 */
export interface ConnectionParams {
    /**
     * 
     * @type {string}
     * @memberof ConnectionParams
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionParams
     */
    'codecType'?: ConnectionParamsCodecTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ConnectionParams
     */
    'transportType'?: ConnectionParamsTransportTypeEnum;
}

export const ConnectionParamsCodecTypeEnum = {
    IgnoreCodec: 'IGNORE_CODEC',
    Raw: 'RAW',
    H264: 'H264',
    H265: 'H265',
    Vp8: 'VP8',
    Vp9: 'VP9',
    Mpeg2: 'MPEG2',
    Mpeg4: 'MPEG4'
} as const;

export type ConnectionParamsCodecTypeEnum = typeof ConnectionParamsCodecTypeEnum[keyof typeof ConnectionParamsCodecTypeEnum];
export const ConnectionParamsTransportTypeEnum = {
    Rtsp: 'RTSP',
    File: 'FILE',
    Rtp: 'RTP',
    RtspTcp: 'RTSP_TCP'
} as const;

export type ConnectionParamsTransportTypeEnum = typeof ConnectionParamsTransportTypeEnum[keyof typeof ConnectionParamsTransportTypeEnum];

/**
 * 
 * @export
 * @interface ContrastLevelOptions
 */
export interface ContrastLevelOptions {
    /**
     * 
     * @type {number}
     * @memberof ContrastLevelOptions
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContrastLevelOptions
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContrastLevelOptions
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface CreateDetectionInfoFromProtoRequest
 */
export interface CreateDetectionInfoFromProtoRequest {
    /**
     * 
     * @type {File}
     * @memberof CreateDetectionInfoFromProtoRequest
     */
    'file': File;
}
/**
 * 
 * @export
 * @interface CreateEvent
 */
export interface CreateEvent {
    /**
     * 
     * @type {number}
     * @memberof CreateEvent
     */
    'elementId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateEvent
     */
    'startDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEvent
     */
    'needRecording'?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof CreateEvent
     */
    'customParams'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof CreateEvent
     */
    'eventType'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateEvent
     */
    'eventTypeId'?: number;
}
/**
 * 
 * @export
 * @interface Crs
 */
export interface Crs {
    /**
     * 
     * @type {string}
     * @memberof Crs
     */
    'type'?: CrsTypeEnum;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Crs
     */
    'properties'?: { [key: string]: object; };
}

export const CrsTypeEnum = {
    Name: 'name',
    Link: 'link'
} as const;

export type CrsTypeEnum = typeof CrsTypeEnum[keyof typeof CrsTypeEnum];

/**
 * 
 * @export
 * @interface DSS
 */
export interface DSS {
    /**
     * 
     * @type {boolean}
     * @memberof DSS
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DSS
     */
    'DSSLevel'?: string;
}
/**
 * 
 * @export
 * @interface DSSLevelOptions
 */
export interface DSSLevelOptions {
    /**
     * 
     * @type {string}
     * @memberof DSSLevelOptions
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof DSSLevelOptions
     */
    'opt'?: string;
}
/**
 * 
 * @export
 * @interface DSSOptions
 */
export interface DSSOptions {
    /**
     * 
     * @type {EnabledOptions}
     * @memberof DSSOptions
     */
    'enabled'?: EnabledOptions;
    /**
     * 
     * @type {DSSLevelOptions}
     * @memberof DSSOptions
     */
    'DSSLevel'?: DSSLevelOptions;
}
/**
 * 
 * @export
 * @interface Dataset
 */
export interface Dataset {
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'label'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof Dataset
     */
    'data'?: Array<object>;
}
/**
 * 
 * @export
 * @interface Dehaze
 */
export interface Dehaze {
    /**
     * 
     * @type {string}
     * @memberof Dehaze
     */
    'DehazeMode'?: string;
}
/**
 * 
 * @export
 * @interface DehazeModeOptions
 */
export interface DehazeModeOptions {
    /**
     * 
     * @type {string}
     * @memberof DehazeModeOptions
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof DehazeModeOptions
     */
    'opt'?: string;
}
/**
 * 
 * @export
 * @interface DehazeOptions
 */
export interface DehazeOptions {
    /**
     * 
     * @type {DehazeModeOptions}
     * @memberof DehazeOptions
     */
    'DehazeMode'?: DehazeModeOptions;
}
/**
 * 
 * @export
 * @interface DetectionAreaDTO
 */
export interface DetectionAreaDTO {
    /**
     * 
     * @type {number}
     * @memberof DetectionAreaDTO
     */
    'id'?: number;
    /**
     * 
     * @type {Array<GeometryDTO>}
     * @memberof DetectionAreaDTO
     */
    'geometryList'?: Array<GeometryDTO>;
    /**
     * 
     * @type {string}
     * @memberof DetectionAreaDTO
     */
    'detectionAreaType'?: DetectionAreaDTODetectionAreaTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DetectionAreaDTO
     */
    'detectionDelayMsOn'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetectionAreaDTO
     */
    'detectionDelayMsOff'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetectionAreaDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetectionAreaDTO
     */
    'sectionId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DetectionAreaDTO
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof DetectionAreaDTO
     */
    'properties'?: { [key: string]: object; };
}

export const DetectionAreaDTODetectionAreaTypeEnum = {
    Count: 'COUNT',
    Tmc: 'TMC',
    Homography: 'HOMOGRAPHY',
    Roi: 'ROI',
    Queue: 'QUEUE',
    Lane: 'LANE',
    Forbidden: 'FORBIDDEN',
    Semaphore: 'SEMAPHORE',
    RedLightPhoto: 'RED_LIGHT_PHOTO',
    DetectionIgnore: 'DETECTION_IGNORE',
    TmcCount: 'TMC_COUNT',
    PlateRecognition: 'PLATE_RECOGNITION',
    FaceRecognition: 'FACE_RECOGNITION',
    Coil: 'COIL',
    CrossWalkPedestrianVehicle: 'CROSS_WALK_PEDESTRIAN_VEHICLE',
    TextDetection: 'TEXT_DETECTION'
} as const;

export type DetectionAreaDTODetectionAreaTypeEnum = typeof DetectionAreaDTODetectionAreaTypeEnum[keyof typeof DetectionAreaDTODetectionAreaTypeEnum];

/**
 * 
 * @export
 * @interface DetectionAreaLess
 */
export interface DetectionAreaLess {
    /**
     * 
     * @type {number}
     * @memberof DetectionAreaLess
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetectionAreaLess
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetectionAreaLess
     */
    'sectionId'?: number;
}
/**
 * 
 * @export
 * @interface DetectionAreaSyncDTO
 */
export interface DetectionAreaSyncDTO {
    /**
     * 
     * @type {string}
     * @memberof DetectionAreaSyncDTO
     */
    'cameraPresetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetectionAreaSyncDTO
     */
    'cameraName'?: string;
    /**
     * 
     * @type {Array<DetectionAreaDTO>}
     * @memberof DetectionAreaSyncDTO
     */
    'detectionAreaList'?: Array<DetectionAreaDTO>;
}
/**
 * 
 * @export
 * @interface DetectionAreaValidation
 */
export interface DetectionAreaValidation {
    /**
     * 
     * @type {number}
     * @memberof DetectionAreaValidation
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetectionAreaValidation
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DetectionAreaValidation
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface DetectionCameraScheduleDTO
 */
export interface DetectionCameraScheduleDTO {
    /**
     * 
     * @type {number}
     * @memberof DetectionCameraScheduleDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetectionCameraScheduleDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetectionCameraScheduleDTO
     */
    'elementAiCameraId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DetectionCameraScheduleDTO
     */
    'defaultSchedule'?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof DetectionCameraScheduleDTO
     */
    'properties'?: { [key: string]: object; };
    /**
     * 
     * @type {Array<DetectionCameraScheduleIntervalDTO>}
     * @memberof DetectionCameraScheduleDTO
     */
    'detectionCameraScheduleIntervalDTOS'?: Array<DetectionCameraScheduleIntervalDTO>;
}
/**
 * 
 * @export
 * @interface DetectionCameraScheduleDateDTO
 */
export interface DetectionCameraScheduleDateDTO {
    /**
     * 
     * @type {number}
     * @memberof DetectionCameraScheduleDateDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetectionCameraScheduleDateDTO
     */
    'detectionCameraScheduleId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetectionCameraScheduleDateDTO
     */
    'detectionCameraScheduleName'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetectionCameraScheduleDateDTO
     */
    'elementAiCameraId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetectionCameraScheduleDateDTO
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface DetectionCameraScheduleHistDTO
 */
export interface DetectionCameraScheduleHistDTO {
    /**
     * 
     * @type {number}
     * @memberof DetectionCameraScheduleHistDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetectionCameraScheduleHistDTO
     */
    'detectionInfoId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetectionCameraScheduleHistDTO
     */
    'detectionInfoName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetectionCameraScheduleHistDTO
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetectionCameraScheduleHistDTO
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface DetectionCameraScheduleIntervalDTO
 */
export interface DetectionCameraScheduleIntervalDTO {
    /**
     * 
     * @type {number}
     * @memberof DetectionCameraScheduleIntervalDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetectionCameraScheduleIntervalDTO
     */
    'detectionInfoId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetectionCameraScheduleIntervalDTO
     */
    'detectionInfoName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetectionCameraScheduleIntervalDTO
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetectionCameraScheduleIntervalDTO
     */
    'endTime'?: string;
}
/**
 * 
 * @export
 * @interface DetectionInfoDTO
 */
export interface DetectionInfoDTO {
    /**
     * 
     * @type {number}
     * @memberof DetectionInfoDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetectionInfoDTO
     */
    'name'?: string;
    /**
     * 
     * @type {Preset}
     * @memberof DetectionInfoDTO
     */
    'preset'?: Preset;
    /**
     * 
     * @type {boolean}
     * @memberof DetectionInfoDTO
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DetectionInfoDTO
     */
    'timeToPreset'?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof DetectionInfoDTO
     */
    'properties'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof DetectionInfoDTO
     */
    'streamType'?: DetectionInfoDTOStreamTypeEnum;
    /**
     * 
     * @type {Array<DetectionAreaDTO>}
     * @memberof DetectionInfoDTO
     */
    'detectionAreaList'?: Array<DetectionAreaDTO>;
    /**
     * 
     * @type {Array<DetectionInfoRoiDTO>}
     * @memberof DetectionInfoDTO
     */
    'detectionInfoRoiList'?: Array<DetectionInfoRoiDTO>;
}

export const DetectionInfoDTOStreamTypeEnum = {
    Default: 'DEFAULT',
    Record: 'RECORD',
    Low: 'LOW'
} as const;

export type DetectionInfoDTOStreamTypeEnum = typeof DetectionInfoDTOStreamTypeEnum[keyof typeof DetectionInfoDTOStreamTypeEnum];

/**
 * 
 * @export
 * @interface DetectionInfoFilter
 */
export interface DetectionInfoFilter {
    /**
     * 
     * @type {Array<GenericFilter>}
     * @memberof DetectionInfoFilter
     */
    'filters'?: Array<GenericFilter>;
    /**
     * 
     * @type {string}
     * @memberof DetectionInfoFilter
     */
    'filterGroupOp'?: DetectionInfoFilterFilterGroupOpEnum;
}

export const DetectionInfoFilterFilterGroupOpEnum = {
    And: 'AND',
    Or: 'OR',
    AndNot: 'AND_NOT',
    OrNot: 'OR_NOT'
} as const;

export type DetectionInfoFilterFilterGroupOpEnum = typeof DetectionInfoFilterFilterGroupOpEnum[keyof typeof DetectionInfoFilterFilterGroupOpEnum];

/**
 * 
 * @export
 * @interface DetectionInfoRoiDTO
 */
export interface DetectionInfoRoiDTO {
    /**
     * 
     * @type {number}
     * @memberof DetectionInfoRoiDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetectionInfoRoiDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetectionInfoRoiDTO
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetectionInfoRoiDTO
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetectionInfoRoiDTO
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetectionInfoRoiDTO
     */
    'height'?: number;
    /**
     * 
     * @type {Array<DetectorRoiDTO>}
     * @memberof DetectionInfoRoiDTO
     */
    'detectorRoiList'?: Array<DetectorRoiDTO>;
}
/**
 * 
 * @export
 * @interface DetectorDTO
 */
export interface DetectorDTO {
    /**
     * 
     * @type {number}
     * @memberof DetectorDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetectorDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetectorDTO
     */
    'engineFile'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetectorDTO
     */
    'trackerParams'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetectorDTO
     */
    'batchSize'?: number;
    /**
     * 
     * @type {ObjectClassificationDTO}
     * @memberof DetectorDTO
     */
    'objectClassification'?: ObjectClassificationDTO;
    /**
     * 
     * @type {Array<number>}
     * @memberof DetectorDTO
     */
    'parentObjectIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DetectorDTO
     */
    'gpus'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof DetectorDTO
     */
    'inferenceType'?: DetectorDTOInferenceTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DetectorDTO
     */
    'netWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetectorDTO
     */
    'netHeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetectorDTO
     */
    'detectionAlgorithm'?: DetectorDTODetectionAlgorithmEnum;
    /**
     * 
     * @type {number}
     * @memberof DetectorDTO
     */
    'maxWaitUntilForceBatch'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetectorDTO
     */
    'parentDetectorId'?: number;
}

export const DetectorDTOInferenceTypeEnum = {
    Fp32: 'FP32',
    Fp16: 'FP16',
    Int8: 'INT8'
} as const;

export type DetectorDTOInferenceTypeEnum = typeof DetectorDTOInferenceTypeEnum[keyof typeof DetectorDTOInferenceTypeEnum];
export const DetectorDTODetectionAlgorithmEnum = {
    Detection: 'OBJECT_DETECTION',
    Classification: 'OBJECT_CLASSIFICATION'
} as const;

export type DetectorDTODetectionAlgorithmEnum = typeof DetectorDTODetectionAlgorithmEnum[keyof typeof DetectorDTODetectionAlgorithmEnum];

/**
 * 
 * @export
 * @interface DetectorRoiDTO
 */
export interface DetectorRoiDTO {
    /**
     * 
     * @type {number}
     * @memberof DetectorRoiDTO
     */
    'id'?: number;
    /**
     * 
     * @type {DetectorDTO}
     * @memberof DetectorRoiDTO
     */
    'detector'?: DetectorDTO;
    /**
     * 
     * @type {number}
     * @memberof DetectorRoiDTO
     */
    'detectorGroupId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetectorRoiDTO
     */
    'frameSkip'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetectorRoiDTO
     */
    'threshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetectorRoiDTO
     */
    'drawType'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DetectorRoiDTO
     */
    'gpuList'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DvrLicenseData
 */
export interface DvrLicenseData {
    /**
     * 
     * @type {number}
     * @memberof DvrLicenseData
     */
    'viewCameraNum'?: number;
    /**
     * 
     * @type {number}
     * @memberof DvrLicenseData
     */
    'recordCameraNum'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DvrLicenseData
     */
    'h265Support'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DvrLicenseData
     */
    'videowallSupport'?: boolean;
}
/**
 * 
 * @export
 * @interface EFlip
 */
export interface EFlip {
    /**
     * 
     * @type {string}
     * @memberof EFlip
     */
    'mode': EFlipModeEnum;
    /**
     * 
     * @type {Array<object>}
     * @memberof EFlip
     */
    'any'?: Array<object>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EFlip
     */
    'otherAttributes'?: { [key: string]: string; };
}

export const EFlipModeEnum = {
    Off: 'OFF',
    On: 'ON',
    Extended: 'EXTENDED'
} as const;

export type EFlipModeEnum = typeof EFlipModeEnum[keyof typeof EFlipModeEnum];

/**
 * 
 * @export
 * @interface EIS
 */
export interface EIS {
    /**
     * 
     * @type {boolean}
     * @memberof EIS
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface EISOptions
 */
export interface EISOptions {
    /**
     * 
     * @type {EnabledOptions}
     * @memberof EISOptions
     */
    'enabled'?: EnabledOptions;
}
/**
 * 
 * @export
 * @interface EnabledOptions
 */
export interface EnabledOptions {
    /**
     * 
     * @type {string}
     * @memberof EnabledOptions
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnabledOptions
     */
    'opt'?: string;
}
/**
 * 
 * @export
 * @interface ExcelSyncTask
 */
export interface ExcelSyncTask {
    /**
     * 
     * @type {string}
     * @memberof ExcelSyncTask
     */
    'hash'?: string;
    /**
     * 
     * @type {Array<IExcelSync>}
     * @memberof ExcelSyncTask
     */
    'excelSyncList'?: Array<IExcelSync>;
}
/**
 * 
 * @export
 * @interface Exposure
 */
export interface Exposure {
    /**
     * 
     * @type {string}
     * @memberof Exposure
     */
    'ExposureType'?: string;
    /**
     * 
     * @type {OverexposeSuppress}
     * @memberof Exposure
     */
    'OverexposeSuppress'?: OverexposeSuppress;
    /**
     * 
     * @type {number}
     * @memberof Exposure
     */
    'exposureLevel'?: number;
}
/**
 * 
 * @export
 * @interface ExposureLevelOptions
 */
export interface ExposureLevelOptions {
    /**
     * 
     * @type {number}
     * @memberof ExposureLevelOptions
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExposureLevelOptions
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExposureLevelOptions
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface ExposureOptions
 */
export interface ExposureOptions {
    /**
     * 
     * @type {ExposureTypeOptions}
     * @memberof ExposureOptions
     */
    'ExposureType'?: ExposureTypeOptions;
    /**
     * 
     * @type {OverexposeSuppressOptions}
     * @memberof ExposureOptions
     */
    'OverexposeSuppress'?: OverexposeSuppressOptions;
    /**
     * 
     * @type {ExposureLevelOptions}
     * @memberof ExposureOptions
     */
    'exposureLevel'?: ExposureLevelOptions;
}
/**
 * 
 * @export
 * @interface ExposureTypeOptions
 */
export interface ExposureTypeOptions {
    /**
     * 
     * @type {string}
     * @memberof ExposureTypeOptions
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExposureTypeOptions
     */
    'opt'?: string;
}
/**
 * 
 * @export
 * @interface Feature
 */
export interface Feature {
    /**
     * 
     * @type {Crs}
     * @memberof Feature
     */
    'crs'?: Crs;
    /**
     * 
     * @type {Array<number>}
     * @memberof Feature
     */
    'bbox'?: Array<number>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Feature
     */
    'properties'?: { [key: string]: object; };
    /**
     * 
     * @type {FeatureGeometry}
     * @memberof Feature
     */
    'geometry'?: FeatureGeometry;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface FeatureCollection
 */
export interface FeatureCollection {
    /**
     * 
     * @type {Crs}
     * @memberof FeatureCollection
     */
    'crs'?: Crs;
    /**
     * 
     * @type {Array<number>}
     * @memberof FeatureCollection
     */
    'bbox'?: Array<number>;
    /**
     * 
     * @type {Array<Feature>}
     * @memberof FeatureCollection
     */
    'features'?: Array<Feature>;
}
/**
 * @type FeatureGeometry
 * @export
 */
export type FeatureGeometry = Feature | FeatureCollection | GeometryCollection | LineString | MultiLineString | MultiPoint | MultiPolygon | Point | Polygon;

/**
 * 
 * @export
 * @interface FloatRange
 */
export interface FloatRange {
    /**
     * 
     * @type {number}
     * @memberof FloatRange
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof FloatRange
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface FocusConfiguration
 */
export interface FocusConfiguration {
    /**
     * 
     * @type {string}
     * @memberof FocusConfiguration
     */
    'focusStyle'?: string;
    /**
     * 
     * @type {number}
     * @memberof FocusConfiguration
     */
    'focusLimited'?: number;
}
/**
 * 
 * @export
 * @interface FocusConfigurationOptions
 */
export interface FocusConfigurationOptions {
    /**
     * 
     * @type {FocusStyleOptions}
     * @memberof FocusConfigurationOptions
     */
    'focusStyle'?: FocusStyleOptions;
    /**
     * 
     * @type {FocusLimitedOptions}
     * @memberof FocusConfigurationOptions
     */
    'focusLimited'?: FocusLimitedOptions;
}
/**
 * 
 * @export
 * @interface FocusLimitedOptions
 */
export interface FocusLimitedOptions {
    /**
     * 
     * @type {string}
     * @memberof FocusLimitedOptions
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof FocusLimitedOptions
     */
    'opt'?: string;
}
/**
 * 
 * @export
 * @interface FocusStyleOptions
 */
export interface FocusStyleOptions {
    /**
     * 
     * @type {string}
     * @memberof FocusStyleOptions
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof FocusStyleOptions
     */
    'opt'?: string;
}
/**
 * 
 * @export
 * @interface FrameNoiseReduceLevelOptions
 */
export interface FrameNoiseReduceLevelOptions {
    /**
     * 
     * @type {number}
     * @memberof FrameNoiseReduceLevelOptions
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrameNoiseReduceLevelOptions
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrameNoiseReduceLevelOptions
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface Gain
 */
export interface Gain {
    /**
     * 
     * @type {number}
     * @memberof Gain
     */
    'GainLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof Gain
     */
    'GainLimit'?: number;
}
/**
 * 
 * @export
 * @interface GainLevelOptions
 */
export interface GainLevelOptions {
    /**
     * 
     * @type {string}
     * @memberof GainLevelOptions
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof GainLevelOptions
     */
    'opt'?: string;
}
/**
 * 
 * @export
 * @interface GainLimitOptions
 */
export interface GainLimitOptions {
    /**
     * 
     * @type {string}
     * @memberof GainLimitOptions
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof GainLimitOptions
     */
    'opt'?: string;
}
/**
 * 
 * @export
 * @interface GainOptions
 */
export interface GainOptions {
    /**
     * 
     * @type {GainLevelOptions}
     * @memberof GainOptions
     */
    'GainLevel'?: GainLevelOptions;
    /**
     * 
     * @type {GainLimitOptions}
     * @memberof GainOptions
     */
    'GainLimit'?: GainLimitOptions;
}
/**
 * 
 * @export
 * @interface GeneralLevelOptions
 */
export interface GeneralLevelOptions {
    /**
     * 
     * @type {number}
     * @memberof GeneralLevelOptions
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralLevelOptions
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralLevelOptions
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface GeneralMode
 */
export interface GeneralMode {
    /**
     * 
     * @type {number}
     * @memberof GeneralMode
     */
    'generalLevel'?: number;
}
/**
 * 
 * @export
 * @interface GeneralModeOptions
 */
export interface GeneralModeOptions {
    /**
     * 
     * @type {GeneralLevelOptions}
     * @memberof GeneralModeOptions
     */
    'generalLevel'?: GeneralLevelOptions;
}
/**
 * 
 * @export
 * @interface GenericFilter
 */
export interface GenericFilter {
    /**
     * 
     * @type {string}
     * @memberof GenericFilter
     */
    'field'?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericFilter
     */
    'filterOp'?: GenericFilterFilterOpEnum;
    /**
     * 
     * @type {string}
     * @memberof GenericFilter
     */
    'value'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GenericFilter
     */
    'empty'?: boolean;
}

export const GenericFilterFilterOpEnum = {
    Eq: 'EQ',
    Contains: 'CONTAINS',
    Neq: 'NEQ',
    Ge: 'GE',
    Le: 'LE',
    Gt: 'GT',
    Lt: 'LT',
    EqNull: 'EQ_NULL'
} as const;

export type GenericFilterFilterOpEnum = typeof GenericFilterFilterOpEnum[keyof typeof GenericFilterFilterOpEnum];

/**
 * 
 * @export
 * @interface GeoJsonObject
 */
export interface GeoJsonObject {
    /**
     * 
     * @type {Crs}
     * @memberof GeoJsonObject
     */
    'crs'?: Crs;
    /**
     * 
     * @type {Array<number>}
     * @memberof GeoJsonObject
     */
    'bbox'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof GeoJsonObject
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface GeometryCollection
 */
export interface GeometryCollection extends GeoJsonObject {
    /**
     * 
     * @type {Array<GeoJsonObject>}
     * @memberof GeometryCollection
     */
    'geometries'?: Array<GeoJsonObject>;
}
/**
 * 
 * @export
 * @interface GeometryCollectionAllOf
 */
export interface GeometryCollectionAllOf {
    /**
     * 
     * @type {Array<GeoJsonObject>}
     * @memberof GeometryCollectionAllOf
     */
    'geometries'?: Array<GeoJsonObject>;
}
/**
 * 
 * @export
 * @interface GeometryDTO
 */
export interface GeometryDTO {
    /**
     * 
     * @type {number}
     * @memberof GeometryDTO
     */
    'index'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeometryDTO
     */
    'geometryType'?: GeometryDTOGeometryTypeEnum;
    /**
     * 
     * @type {Array<GeometryPointDTO>}
     * @memberof GeometryDTO
     */
    'coordinates'?: Array<GeometryPointDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeometryDTO
     */
    'detectionLocations'?: Array<GeometryDTODetectionLocationsEnum>;
    /**
     * 
     * @type {string}
     * @memberof GeometryDTO
     */
    'detectionAreaFunction'?: GeometryDTODetectionAreaFunctionEnum;
}

export const GeometryDTOGeometryTypeEnum = {
    Point: 'POINT',
    Line: 'LINE',
    Rectangle: 'RECTANGLE',
    Polygon: 'POLYGON'
} as const;

export type GeometryDTOGeometryTypeEnum = typeof GeometryDTOGeometryTypeEnum[keyof typeof GeometryDTOGeometryTypeEnum];
export const GeometryDTODetectionLocationsEnum = {
    Top: 'TOP',
    TopLeft: 'TOP_LEFT',
    TopRight: 'TOP_RIGHT',
    Middle: 'MIDDLE',
    MiddleLeft: 'MIDDLE_LEFT',
    MiddleRight: 'MIDDLE_RIGHT',
    Bottom: 'BOTTOM',
    BottomLeft: 'BOTTOM_LEFT',
    BottomRight: 'BOTTOM_RIGHT'
} as const;

export type GeometryDTODetectionLocationsEnum = typeof GeometryDTODetectionLocationsEnum[keyof typeof GeometryDTODetectionLocationsEnum];
export const GeometryDTODetectionAreaFunctionEnum = {
    Default: 'DEFAULT',
    Direction: 'DIRECTION'
} as const;

export type GeometryDTODetectionAreaFunctionEnum = typeof GeometryDTODetectionAreaFunctionEnum[keyof typeof GeometryDTODetectionAreaFunctionEnum];

/**
 * 
 * @export
 * @interface GeometryPointDTO
 */
export interface GeometryPointDTO {
    /**
     * 
     * @type {number}
     * @memberof GeometryPointDTO
     */
    'index'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeometryPointDTO
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeometryPointDTO
     */
    'y'?: number;
}
/**
 * @type GetImagingOptionsOfElementId200Response
 * @export
 */
export type GetImagingOptionsOfElementId200Response = HikvisionImagingOptions | OnvifImagingOptions;

/**
 * @type GetJoysticks1200Response
 * @export
 */
export type GetJoysticks1200Response = Array<Joystick> | Joystick;

/**
 * 
 * @export
 * @interface HLC
 */
export interface HLC {
    /**
     * 
     * @type {boolean}
     * @memberof HLC
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof HLC
     */
    'HLCLevel'?: number;
}
/**
 * 
 * @export
 * @interface HLCOptions
 */
export interface HLCOptions {
    /**
     * 
     * @type {EnabledOptions}
     * @memberof HLCOptions
     */
    'enabled'?: EnabledOptions;
}
/**
 * 
 * @export
 * @interface HikvisionImagingOptions
 */
export interface HikvisionImagingOptions extends ImagingOptions {
    /**
     * 
     * @type {boolean}
     * @memberof HikvisionImagingOptions
     */
    'supportLaserSpotManual'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof HikvisionImagingOptions
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HikvisionImagingOptions
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof HikvisionImagingOptions
     */
    'videoInputID'?: number;
    /**
     * 
     * @type {WDROptions}
     * @memberof HikvisionImagingOptions
     */
    'WDR'?: WDROptions;
    /**
     * 
     * @type {BLCOptions}
     * @memberof HikvisionImagingOptions
     */
    'BLC'?: BLCOptions;
    /**
     * 
     * @type {IrcutFilterOptions}
     * @memberof HikvisionImagingOptions
     */
    'IrcutFilter'?: IrcutFilterOptions;
    /**
     * 
     * @type {WhiteBalanceOptions}
     * @memberof HikvisionImagingOptions
     */
    'WhiteBalance'?: WhiteBalanceOptions;
    /**
     * 
     * @type {ExposureOptions}
     * @memberof HikvisionImagingOptions
     */
    'Exposure'?: ExposureOptions;
    /**
     * 
     * @type {IrisOptions}
     * @memberof HikvisionImagingOptions
     */
    'Iris'?: IrisOptions;
    /**
     * 
     * @type {SharpnessOptions}
     * @memberof HikvisionImagingOptions
     */
    'Sharpness'?: SharpnessOptions;
    /**
     * 
     * @type {ShutterOptions}
     * @memberof HikvisionImagingOptions
     */
    'Shutter'?: ShutterOptions;
    /**
     * 
     * @type {GainOptions}
     * @memberof HikvisionImagingOptions
     */
    'Gain'?: GainOptions;
    /**
     * 
     * @type {ColorOptions}
     * @memberof HikvisionImagingOptions
     */
    'Color'?: ColorOptions;
    /**
     * 
     * @type {NoiseReduceOptions}
     * @memberof HikvisionImagingOptions
     */
    'NoiseReduce'?: NoiseReduceOptions;
    /**
     * 
     * @type {PTZOptions}
     * @memberof HikvisionImagingOptions
     */
    'PTZ'?: PTZOptions;
    /**
     * 
     * @type {DehazeOptions}
     * @memberof HikvisionImagingOptions
     */
    'Dehaze'?: DehazeOptions;
    /**
     * 
     * @type {HLCOptions}
     * @memberof HikvisionImagingOptions
     */
    'HLC'?: HLCOptions;
    /**
     * 
     * @type {FocusConfigurationOptions}
     * @memberof HikvisionImagingOptions
     */
    'FocusConfiguration'?: FocusConfigurationOptions;
    /**
     * 
     * @type {EISOptions}
     * @memberof HikvisionImagingOptions
     */
    'EIS'?: EISOptions;
    /**
     * 
     * @type {DSSOptions}
     * @memberof HikvisionImagingOptions
     */
    'DSS'?: DSSOptions;
    /**
     * 
     * @type {IrLightOptions}
     * @memberof HikvisionImagingOptions
     */
    'IrLight'?: IrLightOptions;
    /**
     * 
     * @type {ImageFreezeOptions}
     * @memberof HikvisionImagingOptions
     */
    'ImageFreeze'?: ImageFreezeOptions;
    /**
     * 
     * @type {boolean}
     * @memberof HikvisionImagingOptions
     */
    'isSupportLaserSpotManual'?: boolean;
}
/**
 * 
 * @export
 * @interface HikvisionImagingOptionsAllOf
 */
export interface HikvisionImagingOptionsAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'supportLaserSpotManual'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'videoInputID'?: number;
    /**
     * 
     * @type {WDROptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'WDR'?: WDROptions;
    /**
     * 
     * @type {BLCOptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'BLC'?: BLCOptions;
    /**
     * 
     * @type {IrcutFilterOptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'IrcutFilter'?: IrcutFilterOptions;
    /**
     * 
     * @type {WhiteBalanceOptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'WhiteBalance'?: WhiteBalanceOptions;
    /**
     * 
     * @type {ExposureOptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'Exposure'?: ExposureOptions;
    /**
     * 
     * @type {IrisOptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'Iris'?: IrisOptions;
    /**
     * 
     * @type {SharpnessOptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'Sharpness'?: SharpnessOptions;
    /**
     * 
     * @type {ShutterOptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'Shutter'?: ShutterOptions;
    /**
     * 
     * @type {GainOptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'Gain'?: GainOptions;
    /**
     * 
     * @type {ColorOptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'Color'?: ColorOptions;
    /**
     * 
     * @type {NoiseReduceOptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'NoiseReduce'?: NoiseReduceOptions;
    /**
     * 
     * @type {PTZOptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'PTZ'?: PTZOptions;
    /**
     * 
     * @type {DehazeOptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'Dehaze'?: DehazeOptions;
    /**
     * 
     * @type {HLCOptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'HLC'?: HLCOptions;
    /**
     * 
     * @type {FocusConfigurationOptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'FocusConfiguration'?: FocusConfigurationOptions;
    /**
     * 
     * @type {EISOptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'EIS'?: EISOptions;
    /**
     * 
     * @type {DSSOptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'DSS'?: DSSOptions;
    /**
     * 
     * @type {IrLightOptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'IrLight'?: IrLightOptions;
    /**
     * 
     * @type {ImageFreezeOptions}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'ImageFreeze'?: ImageFreezeOptions;
    /**
     * 
     * @type {boolean}
     * @memberof HikvisionImagingOptionsAllOf
     */
    'isSupportLaserSpotManual'?: boolean;
}
/**
 * 
 * @export
 * @interface HikvisionImagingSettings
 */
export interface HikvisionImagingSettings extends ImagingSettings {
    /**
     * 
     * @type {number}
     * @memberof HikvisionImagingSettings
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HikvisionImagingSettings
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof HikvisionImagingSettings
     */
    'videoInputID'?: number;
    /**
     * 
     * @type {ImageFlip}
     * @memberof HikvisionImagingSettings
     */
    'ImageFlip'?: ImageFlip;
    /**
     * 
     * @type {IrcutFilter}
     * @memberof HikvisionImagingSettings
     */
    'IrcutFilter'?: IrcutFilter;
    /**
     * 
     * @type {Exposure}
     * @memberof HikvisionImagingSettings
     */
    'Exposure'?: Exposure;
    /**
     * 
     * @type {PowerLineFrequency}
     * @memberof HikvisionImagingSettings
     */
    'powerLineFrequency'?: PowerLineFrequency;
    /**
     * 
     * @type {PTZ}
     * @memberof HikvisionImagingSettings
     */
    'PTZ'?: PTZ;
    /**
     * 
     * @type {FocusConfiguration}
     * @memberof HikvisionImagingSettings
     */
    'FocusConfiguration'?: FocusConfiguration;
    /**
     * 
     * @type {LensInitialization}
     * @memberof HikvisionImagingSettings
     */
    'LensInitialization'?: LensInitialization;
    /**
     * 
     * @type {DSS}
     * @memberof HikvisionImagingSettings
     */
    'DSS'?: DSS;
    /**
     * 
     * @type {IrLight}
     * @memberof HikvisionImagingSettings
     */
    'IrLight'?: IrLight;
    /**
     * 
     * @type {ZoomLimit}
     * @memberof HikvisionImagingSettings
     */
    'ZoomLimit'?: ZoomLimit;
    /**
     * 
     * @type {Iris}
     * @memberof HikvisionImagingSettings
     */
    'Iris'?: Iris;
    /**
     * 
     * @type {CaptureMode}
     * @memberof HikvisionImagingSettings
     */
    'CaptureMode'?: CaptureMode;
    /**
     * 
     * @type {ImageFreeze}
     * @memberof HikvisionImagingSettings
     */
    'ImageFreeze'?: ImageFreeze;
    /**
     * 
     * @type {ProportionalPan}
     * @memberof HikvisionImagingSettings
     */
    'proportionalpan'?: ProportionalPan;
    /**
     * 
     * @type {WDR}
     * @memberof HikvisionImagingSettings
     */
    'WDR'?: WDR;
    /**
     * 
     * @type {BLC}
     * @memberof HikvisionImagingSettings
     */
    'BLC'?: BLC;
    /**
     * 
     * @type {NoiseReduce}
     * @memberof HikvisionImagingSettings
     */
    'NoiseReduce'?: NoiseReduce;
    /**
     * 
     * @type {WhiteBalance}
     * @memberof HikvisionImagingSettings
     */
    'WhiteBalance'?: WhiteBalance;
    /**
     * 
     * @type {Sharpness}
     * @memberof HikvisionImagingSettings
     */
    'Sharpness'?: Sharpness;
    /**
     * 
     * @type {Gain}
     * @memberof HikvisionImagingSettings
     */
    'Gain'?: Gain;
    /**
     * 
     * @type {Shutter}
     * @memberof HikvisionImagingSettings
     */
    'Shutter'?: Shutter;
    /**
     * 
     * @type {Color}
     * @memberof HikvisionImagingSettings
     */
    'Color'?: Color;
    /**
     * 
     * @type {Dehaze}
     * @memberof HikvisionImagingSettings
     */
    'Dehaze'?: Dehaze;
    /**
     * 
     * @type {HLC}
     * @memberof HikvisionImagingSettings
     */
    'HLC'?: HLC;
    /**
     * 
     * @type {EIS}
     * @memberof HikvisionImagingSettings
     */
    'EIS'?: EIS;
}
/**
 * 
 * @export
 * @interface HikvisionImagingSettingsAllOf
 */
export interface HikvisionImagingSettingsAllOf {
    /**
     * 
     * @type {number}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'videoInputID'?: number;
    /**
     * 
     * @type {ImageFlip}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'ImageFlip'?: ImageFlip;
    /**
     * 
     * @type {IrcutFilter}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'IrcutFilter'?: IrcutFilter;
    /**
     * 
     * @type {Exposure}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'Exposure'?: Exposure;
    /**
     * 
     * @type {PowerLineFrequency}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'powerLineFrequency'?: PowerLineFrequency;
    /**
     * 
     * @type {PTZ}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'PTZ'?: PTZ;
    /**
     * 
     * @type {FocusConfiguration}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'FocusConfiguration'?: FocusConfiguration;
    /**
     * 
     * @type {LensInitialization}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'LensInitialization'?: LensInitialization;
    /**
     * 
     * @type {DSS}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'DSS'?: DSS;
    /**
     * 
     * @type {IrLight}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'IrLight'?: IrLight;
    /**
     * 
     * @type {ZoomLimit}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'ZoomLimit'?: ZoomLimit;
    /**
     * 
     * @type {Iris}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'Iris'?: Iris;
    /**
     * 
     * @type {CaptureMode}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'CaptureMode'?: CaptureMode;
    /**
     * 
     * @type {ImageFreeze}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'ImageFreeze'?: ImageFreeze;
    /**
     * 
     * @type {ProportionalPan}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'proportionalpan'?: ProportionalPan;
    /**
     * 
     * @type {WDR}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'WDR'?: WDR;
    /**
     * 
     * @type {BLC}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'BLC'?: BLC;
    /**
     * 
     * @type {NoiseReduce}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'NoiseReduce'?: NoiseReduce;
    /**
     * 
     * @type {WhiteBalance}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'WhiteBalance'?: WhiteBalance;
    /**
     * 
     * @type {Sharpness}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'Sharpness'?: Sharpness;
    /**
     * 
     * @type {Gain}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'Gain'?: Gain;
    /**
     * 
     * @type {Shutter}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'Shutter'?: Shutter;
    /**
     * 
     * @type {Color}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'Color'?: Color;
    /**
     * 
     * @type {Dehaze}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'Dehaze'?: Dehaze;
    /**
     * 
     * @type {HLC}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'HLC'?: HLC;
    /**
     * 
     * @type {EIS}
     * @memberof HikvisionImagingSettingsAllOf
     */
    'EIS'?: EIS;
}
/**
 * 
 * @export
 * @interface IExcelSync
 */
export interface IExcelSync {
    /**
     * 
     * @type {string}
     * @memberof IExcelSync
     */
    'excelSyncErrorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof IExcelSync
     */
    'excelSyncOperation'?: IExcelSyncExcelSyncOperationEnum;
}

export const IExcelSyncExcelSyncOperationEnum = {
    Insert: 'INSERT',
    Update: 'UPDATE',
    Delete: 'DELETE',
    Error: 'ERROR',
    None: 'NONE'
} as const;

export type IExcelSyncExcelSyncOperationEnum = typeof IExcelSyncExcelSyncOperationEnum[keyof typeof IExcelSyncExcelSyncOperationEnum];

/**
 * 
 * @export
 * @interface ImageFlip
 */
export interface ImageFlip {
    /**
     * 
     * @type {boolean}
     * @memberof ImageFlip
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface ImageFreeze
 */
export interface ImageFreeze {
    /**
     * 
     * @type {boolean}
     * @memberof ImageFreeze
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface ImageFreezeOptions
 */
export interface ImageFreezeOptions {
    /**
     * 
     * @type {EnabledOptions}
     * @memberof ImageFreezeOptions
     */
    'enabled'?: EnabledOptions;
}
/**
 * 
 * @export
 * @interface ImagingOptions
 */
export interface ImagingOptions {
    /**
     * 
     * @type {string}
     * @memberof ImagingOptions
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface ImagingSettings
 */
export interface ImagingSettings {
    /**
     * 
     * @type {string}
     * @memberof ImagingSettings
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface ImagingSettingsDTO
 */
export interface ImagingSettingsDTO {
    /**
     * 
     * @type {number}
     * @memberof ImagingSettingsDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImagingSettingsDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ImagingSettingsDTO
     */
    'cameraId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImagingSettingsDTO
     */
    'serverDate'?: string;
    /**
     * 
     * @type {SetCurrentImagingSettingsOfElementIdRequest}
     * @memberof ImagingSettingsDTO
     */
    'imagingSettings'?: SetCurrentImagingSettingsOfElementIdRequest;
}
/**
 * 
 * @export
 * @interface InterFrameNoiseReduceLevelOptions
 */
export interface InterFrameNoiseReduceLevelOptions {
    /**
     * 
     * @type {string}
     * @memberof InterFrameNoiseReduceLevelOptions
     */
    'value'?: string;
    /**
     * 
     * @type {number}
     * @memberof InterFrameNoiseReduceLevelOptions
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof InterFrameNoiseReduceLevelOptions
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface IrLight
 */
export interface IrLight {
    /**
     * 
     * @type {string}
     * @memberof IrLight
     */
    'mode'?: string;
    /**
     * 
     * @type {number}
     * @memberof IrLight
     */
    'brightnessLimit'?: number;
}
/**
 * 
 * @export
 * @interface IrLightOptions
 */
export interface IrLightOptions {
    /**
     * 
     * @type {ModeOptions}
     * @memberof IrLightOptions
     */
    'mode'?: ModeOptions;
    /**
     * 
     * @type {BrightnessLimitOptions}
     * @memberof IrLightOptions
     */
    'brightnessLimit'?: BrightnessLimitOptions;
}
/**
 * 
 * @export
 * @interface IrcutFilter
 */
export interface IrcutFilter {
    /**
     * 
     * @type {string}
     * @memberof IrcutFilter
     */
    'IrcutFilterType'?: string;
    /**
     * 
     * @type {string}
     * @memberof IrcutFilter
     */
    'nightToDayFilterLevel'?: string;
    /**
     * 
     * @type {Schedule}
     * @memberof IrcutFilter
     */
    'Schedule'?: Schedule;
}
/**
 * 
 * @export
 * @interface IrcutFilterOptions
 */
export interface IrcutFilterOptions {
    /**
     * 
     * @type {IrcutFilterTypeOptions}
     * @memberof IrcutFilterOptions
     */
    'IrcutFilterType'?: IrcutFilterTypeOptions;
    /**
     * 
     * @type {NightToDayFilterLevelOptions}
     * @memberof IrcutFilterOptions
     */
    'nightToDayFilterLevel'?: NightToDayFilterLevelOptions;
    /**
     * 
     * @type {ScheduleOptions}
     * @memberof IrcutFilterOptions
     */
    'Schedule'?: ScheduleOptions;
}
/**
 * 
 * @export
 * @interface IrcutFilterTypeOptions
 */
export interface IrcutFilterTypeOptions {
    /**
     * 
     * @type {string}
     * @memberof IrcutFilterTypeOptions
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof IrcutFilterTypeOptions
     */
    'opt'?: string;
}
/**
 * 
 * @export
 * @interface Iris
 */
export interface Iris {
    /**
     * 
     * @type {number}
     * @memberof Iris
     */
    'IrisLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof Iris
     */
    'maxIrisLevelLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof Iris
     */
    'minIrisLevelLimit'?: number;
}
/**
 * 
 * @export
 * @interface IrisLevelOptions
 */
export interface IrisLevelOptions {
    /**
     * 
     * @type {string}
     * @memberof IrisLevelOptions
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof IrisLevelOptions
     */
    'opt'?: string;
}
/**
 * 
 * @export
 * @interface IrisOptions
 */
export interface IrisOptions {
    /**
     * 
     * @type {IrisLevelOptions}
     * @memberof IrisOptions
     */
    'IrisLevel'?: IrisLevelOptions;
    /**
     * 
     * @type {MaxIrisLevelLimitOptions}
     * @memberof IrisOptions
     */
    'maxIrisLevelLimit'?: MaxIrisLevelLimitOptions;
    /**
     * 
     * @type {MinIrisLevelLimitOptions}
     * @memberof IrisOptions
     */
    'minIrisLevelLimit'?: MinIrisLevelLimitOptions;
}
/**
 * 
 * @export
 * @interface Joystick
 */
export interface Joystick {
    /**
     * 
     * @type {number}
     * @memberof Joystick
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Joystick
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Joystick
     */
    'uuid'?: string;
    /**
     * 
     * @type {Array<JoystickMapping>}
     * @memberof Joystick
     */
    'joystickMappingList'?: Array<JoystickMapping>;
}
/**
 * 
 * @export
 * @interface JoystickMapping
 */
export interface JoystickMapping {
    /**
     * 
     * @type {number}
     * @memberof JoystickMapping
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof JoystickMapping
     */
    'actionType'?: string;
    /**
     * 
     * @type {number}
     * @memberof JoystickMapping
     */
    'button'?: number;
}
/**
 * 
 * @export
 * @interface Layout
 */
export interface Layout {
    /**
     * 
     * @type {number}
     * @memberof Layout
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Layout
     */
    'name'?: string;
    /**
     * 
     * @type {Array<LayoutBlock>}
     * @memberof Layout
     */
    'layoutBlockList'?: Array<LayoutBlock>;
    /**
     * 
     * @type {number}
     * @memberof Layout
     */
    'layoutScenarioId'?: number;
}
/**
 * 
 * @export
 * @interface LayoutBlock
 */
export interface LayoutBlock {
    /**
     * 
     * @type {number}
     * @memberof LayoutBlock
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LayoutBlock
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof LayoutBlock
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof LayoutBlock
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof LayoutBlock
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof LayoutBlock
     */
    'layoutBlockType'?: LayoutBlockLayoutBlockTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LayoutBlock
     */
    'layoutSize'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LayoutBlock
     */
    'properties'?: { [key: string]: object; };
}

export const LayoutBlockLayoutBlockTypeEnum = {
    Camera: 'CAMERA',
    Graph: 'GRAPH',
    Webpage: 'WEBPAGE',
    CameraSequence: 'CAMERA_SEQUENCE'
} as const;

export type LayoutBlockLayoutBlockTypeEnum = typeof LayoutBlockLayoutBlockTypeEnum[keyof typeof LayoutBlockLayoutBlockTypeEnum];

/**
 * 
 * @export
 * @interface LayoutGroup
 */
export interface LayoutGroup {
    /**
     * 
     * @type {number}
     * @memberof LayoutGroup
     */
    'id'?: number;
    /**
     * 
     * @type {Set<VideowallScreen>}
     * @memberof LayoutGroup
     */
    'videowallScreens'?: Set<VideowallScreen>;
    /**
     * 
     * @type {Layout}
     * @memberof LayoutGroup
     */
    'layout'?: Layout;
}
/**
 * 
 * @export
 * @interface LayoutScenario
 */
export interface LayoutScenario {
    /**
     * 
     * @type {number}
     * @memberof LayoutScenario
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LayoutScenario
     */
    'layoutId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LayoutScenario
     */
    'name'?: string;
    /**
     * 
     * @type {Set<LayoutScenarioBlock>}
     * @memberof LayoutScenario
     */
    'layoutScenarioBlocks'?: Set<LayoutScenarioBlock>;
}
/**
 * 
 * @export
 * @interface LayoutScenarioBlock
 */
export interface LayoutScenarioBlock {
    /**
     * 
     * @type {number}
     * @memberof LayoutScenarioBlock
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LayoutScenarioBlock
     */
    'layoutBlockId'?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LayoutScenarioBlock
     */
    'properties'?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface LensInitialization
 */
export interface LensInitialization {
    /**
     * 
     * @type {boolean}
     * @memberof LensInitialization
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface LineString
 */
export interface LineString extends GeoJsonObject {
    /**
     * 
     * @type {Array<LngLatAlt>}
     * @memberof LineString
     */
    'coordinates'?: Array<LngLatAlt>;
}
/**
 * 
 * @export
 * @interface LineStringAllOf
 */
export interface LineStringAllOf {
    /**
     * 
     * @type {Array<LngLatAlt>}
     * @memberof LineStringAllOf
     */
    'coordinates'?: Array<LngLatAlt>;
}
/**
 * 
 * @export
 * @interface LngLatAlt
 */
export interface LngLatAlt {
    /**
     * 
     * @type {number}
     * @memberof LngLatAlt
     */
    'longitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof LngLatAlt
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof LngLatAlt
     */
    'altitude'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof LngLatAlt
     */
    'additionalElements'?: Array<number>;
}
/**
 * 
 * @export
 * @interface MaxIrisLevelLimitOptions
 */
export interface MaxIrisLevelLimitOptions {
    /**
     * 
     * @type {number}
     * @memberof MaxIrisLevelLimitOptions
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaxIrisLevelLimitOptions
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaxIrisLevelLimitOptions
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface MaxShutterLevelLimitOptions
 */
export interface MaxShutterLevelLimitOptions {
    /**
     * 
     * @type {string}
     * @memberof MaxShutterLevelLimitOptions
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaxShutterLevelLimitOptions
     */
    'opt'?: string;
}
/**
 * 
 * @export
 * @interface MinIrisLevelLimitOptions
 */
export interface MinIrisLevelLimitOptions {
    /**
     * 
     * @type {number}
     * @memberof MinIrisLevelLimitOptions
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof MinIrisLevelLimitOptions
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof MinIrisLevelLimitOptions
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface MinShutterLevelLimitOptions
 */
export interface MinShutterLevelLimitOptions {
    /**
     * 
     * @type {string}
     * @memberof MinShutterLevelLimitOptions
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof MinShutterLevelLimitOptions
     */
    'opt'?: string;
}
/**
 * 
 * @export
 * @interface ModeOptions
 */
export interface ModeOptions {
    /**
     * 
     * @type {string}
     * @memberof ModeOptions
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModeOptions
     */
    'opt'?: string;
}
/**
 * 
 * @export
 * @interface MultiLayout
 */
export interface MultiLayout {
    /**
     * 
     * @type {number}
     * @memberof MultiLayout
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MultiLayout
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MultiLayout
     */
    'videowallId'?: number;
    /**
     * 
     * @type {Array<LayoutGroup>}
     * @memberof MultiLayout
     */
    'layoutGroupList'?: Array<LayoutGroup>;
}
/**
 * 
 * @export
 * @interface MultiLayoutUpsertParams
 */
export interface MultiLayoutUpsertParams {
    /**
     * 
     * @type {number}
     * @memberof MultiLayoutUpsertParams
     */
    'videowallId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MultiLayoutUpsertParams
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MultiLayoutUpsertParams
     */
    'multiLayoutId'?: number;
}
/**
 * 
 * @export
 * @interface MultiLineString
 */
export interface MultiLineString extends GeoJsonObject {
    /**
     * 
     * @type {Array<Array<LngLatAlt>>}
     * @memberof MultiLineString
     */
    'coordinates'?: Array<Array<LngLatAlt>>;
}
/**
 * 
 * @export
 * @interface MultiLineStringAllOf
 */
export interface MultiLineStringAllOf {
    /**
     * 
     * @type {Array<Array<LngLatAlt>>}
     * @memberof MultiLineStringAllOf
     */
    'coordinates'?: Array<Array<LngLatAlt>>;
}
/**
 * 
 * @export
 * @interface MultiPoint
 */
export interface MultiPoint extends GeoJsonObject {
    /**
     * 
     * @type {Array<LngLatAlt>}
     * @memberof MultiPoint
     */
    'coordinates'?: Array<LngLatAlt>;
}
/**
 * 
 * @export
 * @interface MultiPolygon
 */
export interface MultiPolygon extends GeoJsonObject {
    /**
     * 
     * @type {Array<Array<Array<LngLatAlt>>>}
     * @memberof MultiPolygon
     */
    'coordinates'?: Array<Array<Array<LngLatAlt>>>;
}
/**
 * 
 * @export
 * @interface MultiPolygonAllOf
 */
export interface MultiPolygonAllOf {
    /**
     * 
     * @type {Array<Array<Array<LngLatAlt>>>}
     * @memberof MultiPolygonAllOf
     */
    'coordinates'?: Array<Array<Array<LngLatAlt>>>;
}
/**
 * 
 * @export
 * @interface NightToDayFilterLevelOptions
 */
export interface NightToDayFilterLevelOptions {
    /**
     * 
     * @type {string}
     * @memberof NightToDayFilterLevelOptions
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof NightToDayFilterLevelOptions
     */
    'opt'?: string;
}
/**
 * 
 * @export
 * @interface NoiseReduce
 */
export interface NoiseReduce {
    /**
     * 
     * @type {string}
     * @memberof NoiseReduce
     */
    'mode'?: string;
    /**
     * 
     * @type {GeneralMode}
     * @memberof NoiseReduce
     */
    'GeneralMode'?: GeneralMode;
    /**
     * 
     * @type {AdvancedMode}
     * @memberof NoiseReduce
     */
    'AdvancedMode'?: AdvancedMode;
}
/**
 * 
 * @export
 * @interface NoiseReduceOptions
 */
export interface NoiseReduceOptions {
    /**
     * 
     * @type {ModeOptions}
     * @memberof NoiseReduceOptions
     */
    'mode'?: ModeOptions;
    /**
     * 
     * @type {GeneralModeOptions}
     * @memberof NoiseReduceOptions
     */
    'GeneralMode'?: GeneralModeOptions;
    /**
     * 
     * @type {AdvancedModeOptions}
     * @memberof NoiseReduceOptions
     */
    'AdvancedMode'?: AdvancedModeOptions;
}
/**
 * 
 * @export
 * @interface ObjectClassificationDTO
 */
export interface ObjectClassificationDTO {
    /**
     * 
     * @type {number}
     * @memberof ObjectClassificationDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ObjectClassificationDTO
     */
    'name'?: string;
    /**
     * 
     * @type {Array<ObjectNamesDTO>}
     * @memberof ObjectClassificationDTO
     */
    'objectNamesList'?: Array<ObjectNamesDTO>;
}
/**
 * 
 * @export
 * @interface ObjectNamesDTO
 */
export interface ObjectNamesDTO {
    /**
     * 
     * @type {number}
     * @memberof ObjectNamesDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ObjectNamesDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ObjectNamesDTO
     */
    'objectId'?: number;
}
/**
 * 
 * @export
 * @interface OnvifImagingOptions
 */
export interface OnvifImagingOptions extends ImagingOptions {
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptions
     */
    'backlightCompensationMode'?: Array<OnvifImagingOptionsBacklightCompensationModeEnum>;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'backlightCompensationLevelMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'backlightCompensationLevelMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'brightnessMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'brightnessMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'colorSaturationMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'colorSaturationMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'contrastMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'contrastMax'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptions
     */
    'exposureMode'?: Array<OnvifImagingOptionsExposureModeEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptions
     */
    'exposurePriority'?: Array<OnvifImagingOptionsExposurePriorityEnum>;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'exposureMinExposureTimeMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'exposureMinExposureTimeMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'exposureMaxExposureTimeMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'exposureMaxExposureTimeMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'exposureMinGainMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'exposureMinGainMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'exposureMaxGainMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'exposureMaxGainMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'exposureMinIrisMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'exposureMinIrisMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'exposureMaxIrisMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'exposureMaxIrisMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'exposureTimeMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'exposureTimeMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'exposureGainMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'exposureGainMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'exposureIrisMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'exposureIrisMax'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptions
     */
    'autoFocusMode'?: Array<OnvifImagingOptionsAutoFocusModeEnum>;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'focusDefaultSpeedMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'focusDefaultSpeedMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'focusNearLimitMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'focusNearLimitMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'focusFarLimitMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'focusFarLimitMax'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptions
     */
    'irCutFilterMode'?: Array<OnvifImagingOptionsIrCutFilterModeEnum>;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'sharpnessMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'sharpnessMax'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptions
     */
    'wideDynamicRangeMode'?: Array<OnvifImagingOptionsWideDynamicRangeModeEnum>;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'wideDynamicRangeLevelMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'wideDynamicRangeLevelMax'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptions
     */
    'whiteBalanceMode'?: Array<OnvifImagingOptionsWhiteBalanceModeEnum>;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'whiteBalanceYrGainMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'whiteBalanceYrGainMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'whiteBalanceYbGainMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'whiteBalanceYbGainMax'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptions
     */
    'imageStabilizationMode'?: Array<OnvifImagingOptionsImageStabilizationModeEnum>;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'imageStabilizationLevelMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptions
     */
    'imageStabilizationLevelMax'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptions
     */
    'toneCompensationMode'?: Array<OnvifImagingOptionsToneCompensationModeEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof OnvifImagingOptions
     */
    'toneCompensationLevel'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptions
     */
    'defoggingMode'?: Array<OnvifImagingOptionsDefoggingModeEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof OnvifImagingOptions
     */
    'defoggingLevel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnvifImagingOptions
     */
    'noiseReductionLevel'?: boolean;
}

export const OnvifImagingOptionsBacklightCompensationModeEnum = {
    On: 'ON',
    Off: 'OFF'
} as const;

export type OnvifImagingOptionsBacklightCompensationModeEnum = typeof OnvifImagingOptionsBacklightCompensationModeEnum[keyof typeof OnvifImagingOptionsBacklightCompensationModeEnum];
export const OnvifImagingOptionsExposureModeEnum = {
    Auto: 'AUTO',
    Manual: 'MANUAL'
} as const;

export type OnvifImagingOptionsExposureModeEnum = typeof OnvifImagingOptionsExposureModeEnum[keyof typeof OnvifImagingOptionsExposureModeEnum];
export const OnvifImagingOptionsExposurePriorityEnum = {
    LowNoise: 'LOW_NOISE',
    FrameRate: 'FRAME_RATE'
} as const;

export type OnvifImagingOptionsExposurePriorityEnum = typeof OnvifImagingOptionsExposurePriorityEnum[keyof typeof OnvifImagingOptionsExposurePriorityEnum];
export const OnvifImagingOptionsAutoFocusModeEnum = {
    Auto: 'AUTO',
    Manual: 'MANUAL'
} as const;

export type OnvifImagingOptionsAutoFocusModeEnum = typeof OnvifImagingOptionsAutoFocusModeEnum[keyof typeof OnvifImagingOptionsAutoFocusModeEnum];
export const OnvifImagingOptionsIrCutFilterModeEnum = {
    On: 'ON',
    Off: 'OFF',
    Auto: 'AUTO'
} as const;

export type OnvifImagingOptionsIrCutFilterModeEnum = typeof OnvifImagingOptionsIrCutFilterModeEnum[keyof typeof OnvifImagingOptionsIrCutFilterModeEnum];
export const OnvifImagingOptionsWideDynamicRangeModeEnum = {
    On: 'ON',
    Off: 'OFF'
} as const;

export type OnvifImagingOptionsWideDynamicRangeModeEnum = typeof OnvifImagingOptionsWideDynamicRangeModeEnum[keyof typeof OnvifImagingOptionsWideDynamicRangeModeEnum];
export const OnvifImagingOptionsWhiteBalanceModeEnum = {
    Auto: 'AUTO',
    Manual: 'MANUAL'
} as const;

export type OnvifImagingOptionsWhiteBalanceModeEnum = typeof OnvifImagingOptionsWhiteBalanceModeEnum[keyof typeof OnvifImagingOptionsWhiteBalanceModeEnum];
export const OnvifImagingOptionsImageStabilizationModeEnum = {
    Off: 'OFF',
    On: 'ON',
    Auto: 'AUTO',
    Extended: 'EXTENDED'
} as const;

export type OnvifImagingOptionsImageStabilizationModeEnum = typeof OnvifImagingOptionsImageStabilizationModeEnum[keyof typeof OnvifImagingOptionsImageStabilizationModeEnum];
export const OnvifImagingOptionsToneCompensationModeEnum = {
    On: 'ON',
    Off: 'OFF',
    Auto: 'AUTO'
} as const;

export type OnvifImagingOptionsToneCompensationModeEnum = typeof OnvifImagingOptionsToneCompensationModeEnum[keyof typeof OnvifImagingOptionsToneCompensationModeEnum];
export const OnvifImagingOptionsDefoggingModeEnum = {
    On: 'ON',
    Off: 'OFF',
    Auto: 'AUTO'
} as const;

export type OnvifImagingOptionsDefoggingModeEnum = typeof OnvifImagingOptionsDefoggingModeEnum[keyof typeof OnvifImagingOptionsDefoggingModeEnum];

/**
 * 
 * @export
 * @interface OnvifImagingOptionsAllOf
 */
export interface OnvifImagingOptionsAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptionsAllOf
     */
    'backlightCompensationMode'?: Array<OnvifImagingOptionsAllOfBacklightCompensationModeEnum>;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'backlightCompensationLevelMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'backlightCompensationLevelMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'brightnessMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'brightnessMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'colorSaturationMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'colorSaturationMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'contrastMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'contrastMax'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureMode'?: Array<OnvifImagingOptionsAllOfExposureModeEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposurePriority'?: Array<OnvifImagingOptionsAllOfExposurePriorityEnum>;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureMinExposureTimeMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureMinExposureTimeMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureMaxExposureTimeMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureMaxExposureTimeMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureMinGainMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureMinGainMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureMaxGainMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureMaxGainMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureMinIrisMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureMinIrisMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureMaxIrisMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureMaxIrisMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureTimeMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureTimeMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureGainMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureGainMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureIrisMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'exposureIrisMax'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptionsAllOf
     */
    'autoFocusMode'?: Array<OnvifImagingOptionsAllOfAutoFocusModeEnum>;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'focusDefaultSpeedMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'focusDefaultSpeedMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'focusNearLimitMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'focusNearLimitMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'focusFarLimitMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'focusFarLimitMax'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptionsAllOf
     */
    'irCutFilterMode'?: Array<OnvifImagingOptionsAllOfIrCutFilterModeEnum>;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'sharpnessMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'sharpnessMax'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptionsAllOf
     */
    'wideDynamicRangeMode'?: Array<OnvifImagingOptionsAllOfWideDynamicRangeModeEnum>;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'wideDynamicRangeLevelMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'wideDynamicRangeLevelMax'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptionsAllOf
     */
    'whiteBalanceMode'?: Array<OnvifImagingOptionsAllOfWhiteBalanceModeEnum>;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'whiteBalanceYrGainMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'whiteBalanceYrGainMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'whiteBalanceYbGainMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'whiteBalanceYbGainMax'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptionsAllOf
     */
    'imageStabilizationMode'?: Array<OnvifImagingOptionsAllOfImageStabilizationModeEnum>;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'imageStabilizationLevelMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingOptionsAllOf
     */
    'imageStabilizationLevelMax'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptionsAllOf
     */
    'toneCompensationMode'?: Array<OnvifImagingOptionsAllOfToneCompensationModeEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof OnvifImagingOptionsAllOf
     */
    'toneCompensationLevel'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnvifImagingOptionsAllOf
     */
    'defoggingMode'?: Array<OnvifImagingOptionsAllOfDefoggingModeEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof OnvifImagingOptionsAllOf
     */
    'defoggingLevel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnvifImagingOptionsAllOf
     */
    'noiseReductionLevel'?: boolean;
}

export const OnvifImagingOptionsAllOfBacklightCompensationModeEnum = {
    On: 'ON',
    Off: 'OFF'
} as const;

export type OnvifImagingOptionsAllOfBacklightCompensationModeEnum = typeof OnvifImagingOptionsAllOfBacklightCompensationModeEnum[keyof typeof OnvifImagingOptionsAllOfBacklightCompensationModeEnum];
export const OnvifImagingOptionsAllOfExposureModeEnum = {
    Auto: 'AUTO',
    Manual: 'MANUAL'
} as const;

export type OnvifImagingOptionsAllOfExposureModeEnum = typeof OnvifImagingOptionsAllOfExposureModeEnum[keyof typeof OnvifImagingOptionsAllOfExposureModeEnum];
export const OnvifImagingOptionsAllOfExposurePriorityEnum = {
    LowNoise: 'LOW_NOISE',
    FrameRate: 'FRAME_RATE'
} as const;

export type OnvifImagingOptionsAllOfExposurePriorityEnum = typeof OnvifImagingOptionsAllOfExposurePriorityEnum[keyof typeof OnvifImagingOptionsAllOfExposurePriorityEnum];
export const OnvifImagingOptionsAllOfAutoFocusModeEnum = {
    Auto: 'AUTO',
    Manual: 'MANUAL'
} as const;

export type OnvifImagingOptionsAllOfAutoFocusModeEnum = typeof OnvifImagingOptionsAllOfAutoFocusModeEnum[keyof typeof OnvifImagingOptionsAllOfAutoFocusModeEnum];
export const OnvifImagingOptionsAllOfIrCutFilterModeEnum = {
    On: 'ON',
    Off: 'OFF',
    Auto: 'AUTO'
} as const;

export type OnvifImagingOptionsAllOfIrCutFilterModeEnum = typeof OnvifImagingOptionsAllOfIrCutFilterModeEnum[keyof typeof OnvifImagingOptionsAllOfIrCutFilterModeEnum];
export const OnvifImagingOptionsAllOfWideDynamicRangeModeEnum = {
    On: 'ON',
    Off: 'OFF'
} as const;

export type OnvifImagingOptionsAllOfWideDynamicRangeModeEnum = typeof OnvifImagingOptionsAllOfWideDynamicRangeModeEnum[keyof typeof OnvifImagingOptionsAllOfWideDynamicRangeModeEnum];
export const OnvifImagingOptionsAllOfWhiteBalanceModeEnum = {
    Auto: 'AUTO',
    Manual: 'MANUAL'
} as const;

export type OnvifImagingOptionsAllOfWhiteBalanceModeEnum = typeof OnvifImagingOptionsAllOfWhiteBalanceModeEnum[keyof typeof OnvifImagingOptionsAllOfWhiteBalanceModeEnum];
export const OnvifImagingOptionsAllOfImageStabilizationModeEnum = {
    Off: 'OFF',
    On: 'ON',
    Auto: 'AUTO',
    Extended: 'EXTENDED'
} as const;

export type OnvifImagingOptionsAllOfImageStabilizationModeEnum = typeof OnvifImagingOptionsAllOfImageStabilizationModeEnum[keyof typeof OnvifImagingOptionsAllOfImageStabilizationModeEnum];
export const OnvifImagingOptionsAllOfToneCompensationModeEnum = {
    On: 'ON',
    Off: 'OFF',
    Auto: 'AUTO'
} as const;

export type OnvifImagingOptionsAllOfToneCompensationModeEnum = typeof OnvifImagingOptionsAllOfToneCompensationModeEnum[keyof typeof OnvifImagingOptionsAllOfToneCompensationModeEnum];
export const OnvifImagingOptionsAllOfDefoggingModeEnum = {
    On: 'ON',
    Off: 'OFF',
    Auto: 'AUTO'
} as const;

export type OnvifImagingOptionsAllOfDefoggingModeEnum = typeof OnvifImagingOptionsAllOfDefoggingModeEnum[keyof typeof OnvifImagingOptionsAllOfDefoggingModeEnum];

/**
 * 
 * @export
 * @interface OnvifImagingSettings
 */
export interface OnvifImagingSettings extends ImagingSettings {
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettings
     */
    'backlightCompensationMode'?: OnvifImagingSettingsBacklightCompensationModeEnum;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'backlightCompensationLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'brightness'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'colorSaturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'contrast'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettings
     */
    'exposureMode'?: OnvifImagingSettingsExposureModeEnum;
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettings
     */
    'exposurePriority'?: OnvifImagingSettingsExposurePriorityEnum;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'exposureWindowBottom'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'exposureWindowTop'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'exposureWindowRight'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'exposureWindowLeft'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'exposureMinExposureTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'exposureMaxExposureTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'exposureMinGain'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'exposureMaxGain'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'exposureMinIris'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'exposureMaxIris'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'exposureTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'exposureGain'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'exposureIris'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettings
     */
    'autoFocusMode'?: OnvifImagingSettingsAutoFocusModeEnum;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'focusDefaultSpeed'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'focusNearLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'focusFarLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettings
     */
    'irCutFilterMode'?: OnvifImagingSettingsIrCutFilterModeEnum;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'sharpness'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettings
     */
    'wideDynamicRangeMode'?: OnvifImagingSettingsWideDynamicRangeModeEnum;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'wideDynamicRangeLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettings
     */
    'whiteBalanceMode'?: OnvifImagingSettingsWhiteBalanceModeEnum;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'whiteBalanceCrGain'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'whiteBalanceCbGain'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettings
     */
    'imageStabilizationMode'?: OnvifImagingSettingsImageStabilizationModeEnum;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'imageStabilizationLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettings
     */
    'toneCompensationMode'?: OnvifImagingSettingsToneCompensationModeEnum;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'toneCompensationLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettings
     */
    'defoggingMode'?: OnvifImagingSettingsDefoggingModeEnum;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'defoggingLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettings
     */
    'noiseReductionLevel'?: number;
}

export const OnvifImagingSettingsBacklightCompensationModeEnum = {
    On: 'ON',
    Off: 'OFF'
} as const;

export type OnvifImagingSettingsBacklightCompensationModeEnum = typeof OnvifImagingSettingsBacklightCompensationModeEnum[keyof typeof OnvifImagingSettingsBacklightCompensationModeEnum];
export const OnvifImagingSettingsExposureModeEnum = {
    Auto: 'AUTO',
    Manual: 'MANUAL'
} as const;

export type OnvifImagingSettingsExposureModeEnum = typeof OnvifImagingSettingsExposureModeEnum[keyof typeof OnvifImagingSettingsExposureModeEnum];
export const OnvifImagingSettingsExposurePriorityEnum = {
    LowNoise: 'LOW_NOISE',
    FrameRate: 'FRAME_RATE'
} as const;

export type OnvifImagingSettingsExposurePriorityEnum = typeof OnvifImagingSettingsExposurePriorityEnum[keyof typeof OnvifImagingSettingsExposurePriorityEnum];
export const OnvifImagingSettingsAutoFocusModeEnum = {
    Auto: 'AUTO',
    Manual: 'MANUAL'
} as const;

export type OnvifImagingSettingsAutoFocusModeEnum = typeof OnvifImagingSettingsAutoFocusModeEnum[keyof typeof OnvifImagingSettingsAutoFocusModeEnum];
export const OnvifImagingSettingsIrCutFilterModeEnum = {
    On: 'ON',
    Off: 'OFF',
    Auto: 'AUTO'
} as const;

export type OnvifImagingSettingsIrCutFilterModeEnum = typeof OnvifImagingSettingsIrCutFilterModeEnum[keyof typeof OnvifImagingSettingsIrCutFilterModeEnum];
export const OnvifImagingSettingsWideDynamicRangeModeEnum = {
    On: 'ON',
    Off: 'OFF'
} as const;

export type OnvifImagingSettingsWideDynamicRangeModeEnum = typeof OnvifImagingSettingsWideDynamicRangeModeEnum[keyof typeof OnvifImagingSettingsWideDynamicRangeModeEnum];
export const OnvifImagingSettingsWhiteBalanceModeEnum = {
    Auto: 'AUTO',
    Manual: 'MANUAL'
} as const;

export type OnvifImagingSettingsWhiteBalanceModeEnum = typeof OnvifImagingSettingsWhiteBalanceModeEnum[keyof typeof OnvifImagingSettingsWhiteBalanceModeEnum];
export const OnvifImagingSettingsImageStabilizationModeEnum = {
    Off: 'OFF',
    On: 'ON',
    Auto: 'AUTO',
    Extended: 'EXTENDED'
} as const;

export type OnvifImagingSettingsImageStabilizationModeEnum = typeof OnvifImagingSettingsImageStabilizationModeEnum[keyof typeof OnvifImagingSettingsImageStabilizationModeEnum];
export const OnvifImagingSettingsToneCompensationModeEnum = {
    On: 'ON',
    Off: 'OFF',
    Auto: 'AUTO'
} as const;

export type OnvifImagingSettingsToneCompensationModeEnum = typeof OnvifImagingSettingsToneCompensationModeEnum[keyof typeof OnvifImagingSettingsToneCompensationModeEnum];
export const OnvifImagingSettingsDefoggingModeEnum = {
    On: 'ON',
    Off: 'OFF',
    Auto: 'AUTO'
} as const;

export type OnvifImagingSettingsDefoggingModeEnum = typeof OnvifImagingSettingsDefoggingModeEnum[keyof typeof OnvifImagingSettingsDefoggingModeEnum];

/**
 * 
 * @export
 * @interface OnvifImagingSettingsAllOf
 */
export interface OnvifImagingSettingsAllOf {
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettingsAllOf
     */
    'backlightCompensationMode'?: OnvifImagingSettingsAllOfBacklightCompensationModeEnum;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'backlightCompensationLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'brightness'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'colorSaturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'contrast'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettingsAllOf
     */
    'exposureMode'?: OnvifImagingSettingsAllOfExposureModeEnum;
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettingsAllOf
     */
    'exposurePriority'?: OnvifImagingSettingsAllOfExposurePriorityEnum;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'exposureWindowBottom'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'exposureWindowTop'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'exposureWindowRight'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'exposureWindowLeft'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'exposureMinExposureTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'exposureMaxExposureTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'exposureMinGain'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'exposureMaxGain'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'exposureMinIris'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'exposureMaxIris'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'exposureTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'exposureGain'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'exposureIris'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettingsAllOf
     */
    'autoFocusMode'?: OnvifImagingSettingsAllOfAutoFocusModeEnum;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'focusDefaultSpeed'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'focusNearLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'focusFarLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettingsAllOf
     */
    'irCutFilterMode'?: OnvifImagingSettingsAllOfIrCutFilterModeEnum;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'sharpness'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettingsAllOf
     */
    'wideDynamicRangeMode'?: OnvifImagingSettingsAllOfWideDynamicRangeModeEnum;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'wideDynamicRangeLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettingsAllOf
     */
    'whiteBalanceMode'?: OnvifImagingSettingsAllOfWhiteBalanceModeEnum;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'whiteBalanceCrGain'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'whiteBalanceCbGain'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettingsAllOf
     */
    'imageStabilizationMode'?: OnvifImagingSettingsAllOfImageStabilizationModeEnum;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'imageStabilizationLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettingsAllOf
     */
    'toneCompensationMode'?: OnvifImagingSettingsAllOfToneCompensationModeEnum;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'toneCompensationLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnvifImagingSettingsAllOf
     */
    'defoggingMode'?: OnvifImagingSettingsAllOfDefoggingModeEnum;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'defoggingLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof OnvifImagingSettingsAllOf
     */
    'noiseReductionLevel'?: number;
}

export const OnvifImagingSettingsAllOfBacklightCompensationModeEnum = {
    On: 'ON',
    Off: 'OFF'
} as const;

export type OnvifImagingSettingsAllOfBacklightCompensationModeEnum = typeof OnvifImagingSettingsAllOfBacklightCompensationModeEnum[keyof typeof OnvifImagingSettingsAllOfBacklightCompensationModeEnum];
export const OnvifImagingSettingsAllOfExposureModeEnum = {
    Auto: 'AUTO',
    Manual: 'MANUAL'
} as const;

export type OnvifImagingSettingsAllOfExposureModeEnum = typeof OnvifImagingSettingsAllOfExposureModeEnum[keyof typeof OnvifImagingSettingsAllOfExposureModeEnum];
export const OnvifImagingSettingsAllOfExposurePriorityEnum = {
    LowNoise: 'LOW_NOISE',
    FrameRate: 'FRAME_RATE'
} as const;

export type OnvifImagingSettingsAllOfExposurePriorityEnum = typeof OnvifImagingSettingsAllOfExposurePriorityEnum[keyof typeof OnvifImagingSettingsAllOfExposurePriorityEnum];
export const OnvifImagingSettingsAllOfAutoFocusModeEnum = {
    Auto: 'AUTO',
    Manual: 'MANUAL'
} as const;

export type OnvifImagingSettingsAllOfAutoFocusModeEnum = typeof OnvifImagingSettingsAllOfAutoFocusModeEnum[keyof typeof OnvifImagingSettingsAllOfAutoFocusModeEnum];
export const OnvifImagingSettingsAllOfIrCutFilterModeEnum = {
    On: 'ON',
    Off: 'OFF',
    Auto: 'AUTO'
} as const;

export type OnvifImagingSettingsAllOfIrCutFilterModeEnum = typeof OnvifImagingSettingsAllOfIrCutFilterModeEnum[keyof typeof OnvifImagingSettingsAllOfIrCutFilterModeEnum];
export const OnvifImagingSettingsAllOfWideDynamicRangeModeEnum = {
    On: 'ON',
    Off: 'OFF'
} as const;

export type OnvifImagingSettingsAllOfWideDynamicRangeModeEnum = typeof OnvifImagingSettingsAllOfWideDynamicRangeModeEnum[keyof typeof OnvifImagingSettingsAllOfWideDynamicRangeModeEnum];
export const OnvifImagingSettingsAllOfWhiteBalanceModeEnum = {
    Auto: 'AUTO',
    Manual: 'MANUAL'
} as const;

export type OnvifImagingSettingsAllOfWhiteBalanceModeEnum = typeof OnvifImagingSettingsAllOfWhiteBalanceModeEnum[keyof typeof OnvifImagingSettingsAllOfWhiteBalanceModeEnum];
export const OnvifImagingSettingsAllOfImageStabilizationModeEnum = {
    Off: 'OFF',
    On: 'ON',
    Auto: 'AUTO',
    Extended: 'EXTENDED'
} as const;

export type OnvifImagingSettingsAllOfImageStabilizationModeEnum = typeof OnvifImagingSettingsAllOfImageStabilizationModeEnum[keyof typeof OnvifImagingSettingsAllOfImageStabilizationModeEnum];
export const OnvifImagingSettingsAllOfToneCompensationModeEnum = {
    On: 'ON',
    Off: 'OFF',
    Auto: 'AUTO'
} as const;

export type OnvifImagingSettingsAllOfToneCompensationModeEnum = typeof OnvifImagingSettingsAllOfToneCompensationModeEnum[keyof typeof OnvifImagingSettingsAllOfToneCompensationModeEnum];
export const OnvifImagingSettingsAllOfDefoggingModeEnum = {
    On: 'ON',
    Off: 'OFF',
    Auto: 'AUTO'
} as const;

export type OnvifImagingSettingsAllOfDefoggingModeEnum = typeof OnvifImagingSettingsAllOfDefoggingModeEnum[keyof typeof OnvifImagingSettingsAllOfDefoggingModeEnum];

/**
 * 
 * @export
 * @interface OverexposeSuppress
 */
export interface OverexposeSuppress {
    /**
     * 
     * @type {boolean}
     * @memberof OverexposeSuppress
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface OverexposeSuppressOptions
 */
export interface OverexposeSuppressOptions {
    /**
     * 
     * @type {EnabledOptions}
     * @memberof OverexposeSuppressOptions
     */
    'enabled'?: EnabledOptions;
}
/**
 * 
 * @export
 * @interface OverlayImage
 */
export interface OverlayImage {
    /**
     * 
     * @type {number}
     * @memberof OverlayImage
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OverlayImage
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof OverlayImage
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof OverlayImage
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof OverlayImage
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof OverlayImage
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof OverlayImage
     */
    'overlayImage'?: string;
}
/**
 * 
 * @export
 * @interface PTControlDirection
 */
export interface PTControlDirection {
    /**
     * 
     * @type {Reverse}
     * @memberof PTControlDirection
     */
    'reverse'?: Reverse;
    /**
     * 
     * @type {PTControlDirectionExtension}
     * @memberof PTControlDirection
     */
    'extension'?: PTControlDirectionExtension;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PTControlDirection
     */
    'otherAttributes'?: { [key: string]: string; };
    /**
     * 
     * @type {EFlip}
     * @memberof PTControlDirection
     */
    'eflip'?: EFlip;
}
/**
 * 
 * @export
 * @interface PTControlDirectionExtension
 */
export interface PTControlDirectionExtension {
    /**
     * 
     * @type {Array<object>}
     * @memberof PTControlDirectionExtension
     */
    'any'?: Array<object>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PTControlDirectionExtension
     */
    'otherAttributes'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface PTZ
 */
export interface PTZ {
    /**
     * 
     * @type {boolean}
     * @memberof PTZ
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface PTZConfiguration
 */
export interface PTZConfiguration {
    /**
     * 
     * @type {string}
     * @memberof PTZConfiguration
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PTZConfiguration
     */
    'useCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PTZConfiguration
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof PTZConfiguration
     */
    'nodeToken': string;
    /**
     * 
     * @type {string}
     * @memberof PTZConfiguration
     */
    'defaultAbsolutePantTiltPositionSpace'?: string;
    /**
     * 
     * @type {string}
     * @memberof PTZConfiguration
     */
    'defaultAbsoluteZoomPositionSpace'?: string;
    /**
     * 
     * @type {string}
     * @memberof PTZConfiguration
     */
    'defaultRelativePanTiltTranslationSpace'?: string;
    /**
     * 
     * @type {string}
     * @memberof PTZConfiguration
     */
    'defaultRelativeZoomTranslationSpace'?: string;
    /**
     * 
     * @type {string}
     * @memberof PTZConfiguration
     */
    'defaultContinuousPanTiltVelocitySpace'?: string;
    /**
     * 
     * @type {string}
     * @memberof PTZConfiguration
     */
    'defaultContinuousZoomVelocitySpace'?: string;
    /**
     * 
     * @type {PTZSpeed}
     * @memberof PTZConfiguration
     */
    'defaultPTZSpeed'?: PTZSpeed;
    /**
     * 
     * @type {PTZConfigurationDefaultPTZTimeout}
     * @memberof PTZConfiguration
     */
    'defaultPTZTimeout'?: PTZConfigurationDefaultPTZTimeout;
    /**
     * 
     * @type {PanTiltLimits}
     * @memberof PTZConfiguration
     */
    'panTiltLimits'?: PanTiltLimits;
    /**
     * 
     * @type {ZoomLimits}
     * @memberof PTZConfiguration
     */
    'zoomLimits'?: ZoomLimits;
    /**
     * 
     * @type {PTZConfigurationExtension}
     * @memberof PTZConfiguration
     */
    'extension'?: PTZConfigurationExtension;
    /**
     * 
     * @type {number}
     * @memberof PTZConfiguration
     */
    'moveRamp'?: number;
    /**
     * 
     * @type {number}
     * @memberof PTZConfiguration
     */
    'presetRamp'?: number;
    /**
     * 
     * @type {number}
     * @memberof PTZConfiguration
     */
    'presetTourRamp'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PTZConfiguration
     */
    'otherAttributes'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface PTZConfigurationDefaultPTZTimeout
 */
export interface PTZConfigurationDefaultPTZTimeout {
    /**
     * 
     * @type {number}
     * @memberof PTZConfigurationDefaultPTZTimeout
     */
    'seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof PTZConfigurationDefaultPTZTimeout
     */
    'years'?: number;
    /**
     * 
     * @type {number}
     * @memberof PTZConfigurationDefaultPTZTimeout
     */
    'months'?: number;
    /**
     * 
     * @type {number}
     * @memberof PTZConfigurationDefaultPTZTimeout
     */
    'days'?: number;
    /**
     * 
     * @type {number}
     * @memberof PTZConfigurationDefaultPTZTimeout
     */
    'hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof PTZConfigurationDefaultPTZTimeout
     */
    'minutes'?: number;
    /**
     * 
     * @type {number}
     * @memberof PTZConfigurationDefaultPTZTimeout
     */
    'sign'?: number;
    /**
     * 
     * @type {PTZConfigurationDefaultPTZTimeoutXmlschemaType}
     * @memberof PTZConfigurationDefaultPTZTimeout
     */
    'xmlschemaType'?: PTZConfigurationDefaultPTZTimeoutXmlschemaType;
}
/**
 * 
 * @export
 * @interface PTZConfigurationDefaultPTZTimeoutXmlschemaType
 */
export interface PTZConfigurationDefaultPTZTimeoutXmlschemaType {
    /**
     * 
     * @type {string}
     * @memberof PTZConfigurationDefaultPTZTimeoutXmlschemaType
     */
    'namespaceURI'?: string;
    /**
     * 
     * @type {string}
     * @memberof PTZConfigurationDefaultPTZTimeoutXmlschemaType
     */
    'localPart'?: string;
    /**
     * 
     * @type {string}
     * @memberof PTZConfigurationDefaultPTZTimeoutXmlschemaType
     */
    'prefix'?: string;
}
/**
 * 
 * @export
 * @interface PTZConfigurationExtension
 */
export interface PTZConfigurationExtension {
    /**
     * 
     * @type {Array<object>}
     * @memberof PTZConfigurationExtension
     */
    'any'?: Array<object>;
    /**
     * 
     * @type {PTZConfigurationExtension2}
     * @memberof PTZConfigurationExtension
     */
    'extension'?: PTZConfigurationExtension2;
    /**
     * 
     * @type {PTControlDirection}
     * @memberof PTZConfigurationExtension
     */
    'ptcontrolDirection'?: PTControlDirection;
}
/**
 * 
 * @export
 * @interface PTZConfigurationExtension2
 */
export interface PTZConfigurationExtension2 {
    /**
     * 
     * @type {Array<object>}
     * @memberof PTZConfigurationExtension2
     */
    'any'?: Array<object>;
}
/**
 * 
 * @export
 * @interface PTZOptions
 */
export interface PTZOptions {
    /**
     * 
     * @type {boolean}
     * @memberof PTZOptions
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface PTZSpeed
 */
export interface PTZSpeed {
    /**
     * 
     * @type {Vector2D}
     * @memberof PTZSpeed
     */
    'panTilt'?: Vector2D;
    /**
     * 
     * @type {Vector1D}
     * @memberof PTZSpeed
     */
    'zoom'?: Vector1D;
}
/**
 * 
 * @export
 * @interface PageAiCameraUpsert
 */
export interface PageAiCameraUpsert {
    /**
     * 
     * @type {number}
     * @memberof PageAiCameraUpsert
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAiCameraUpsert
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAiCameraUpsert
     */
    'size'?: number;
    /**
     * 
     * @type {Array<AiCameraUpsert>}
     * @memberof PageAiCameraUpsert
     */
    'content'?: Array<AiCameraUpsert>;
    /**
     * 
     * @type {number}
     * @memberof PageAiCameraUpsert
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageAiCameraUpsert
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageAiCameraUpsert
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAiCameraUpsert
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageAiCameraUpsert
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageAiCameraUpsert
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageAiCameraUpsert
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageDetectionInfoDTO
 */
export interface PageDetectionInfoDTO {
    /**
     * 
     * @type {number}
     * @memberof PageDetectionInfoDTO
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDetectionInfoDTO
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDetectionInfoDTO
     */
    'size'?: number;
    /**
     * 
     * @type {Array<DetectionInfoDTO>}
     * @memberof PageDetectionInfoDTO
     */
    'content'?: Array<DetectionInfoDTO>;
    /**
     * 
     * @type {number}
     * @memberof PageDetectionInfoDTO
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageDetectionInfoDTO
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageDetectionInfoDTO
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDetectionInfoDTO
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageDetectionInfoDTO
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageDetectionInfoDTO
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageDetectionInfoDTO
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pageable
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageableObject
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
}
/**
 * 
 * @export
 * @interface PanTiltLimits
 */
export interface PanTiltLimits {
    /**
     * 
     * @type {Space2DDescription}
     * @memberof PanTiltLimits
     */
    'range': Space2DDescription;
}
/**
 * 
 * @export
 * @interface Point
 */
export interface Point extends GeoJsonObject {
    /**
     * 
     * @type {LngLatAlt}
     * @memberof Point
     */
    'coordinates'?: LngLatAlt;
}
/**
 * 
 * @export
 * @interface PointAllOf
 */
export interface PointAllOf {
    /**
     * 
     * @type {LngLatAlt}
     * @memberof PointAllOf
     */
    'coordinates'?: LngLatAlt;
}
/**
 * 
 * @export
 * @interface Polygon
 */
export interface Polygon extends GeoJsonObject {
    /**
     * 
     * @type {Array<Array<LngLatAlt>>}
     * @memberof Polygon
     */
    'coordinates'?: Array<Array<LngLatAlt>>;
}
/**
 * 
 * @export
 * @interface PowerLineFrequency
 */
export interface PowerLineFrequency {
    /**
     * 
     * @type {string}
     * @memberof PowerLineFrequency
     */
    'powerLineFrequencyMode'?: string;
}
/**
 * 
 * @export
 * @interface Preset
 */
export interface Preset {
    /**
     * 
     * @type {number}
     * @memberof Preset
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Preset
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Preset
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Preset
     */
    'presetNum'?: number;
    /**
     * 
     * @type {number}
     * @memberof Preset
     */
    'cameraId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Preset
     */
    'cameraName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Preset
     */
    'pan'?: number;
    /**
     * 
     * @type {number}
     * @memberof Preset
     */
    'tilt'?: number;
    /**
     * 
     * @type {number}
     * @memberof Preset
     */
    'zoom'?: number;
}
/**
 * 
 * @export
 * @interface ProportionalPan
 */
export interface ProportionalPan {
    /**
     * 
     * @type {boolean}
     * @memberof ProportionalPan
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface Record
 */
export interface Record {
    /**
     * 
     * @type {number}
     * @memberof Record
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Record
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    'codec'?: string;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    'file'?: string;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    'cameraName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Record
     */
    'cameraId'?: number;
}
/**
 * 
 * @export
 * @interface Reverse
 */
export interface Reverse {
    /**
     * 
     * @type {string}
     * @memberof Reverse
     */
    'mode': ReverseModeEnum;
    /**
     * 
     * @type {Array<object>}
     * @memberof Reverse
     */
    'any'?: Array<object>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Reverse
     */
    'otherAttributes'?: { [key: string]: string; };
}

export const ReverseModeEnum = {
    Off: 'OFF',
    On: 'ON',
    Auto: 'AUTO',
    Extended: 'EXTENDED'
} as const;

export type ReverseModeEnum = typeof ReverseModeEnum[keyof typeof ReverseModeEnum];

/**
 * 
 * @export
 * @interface RtspConnectData
 */
export interface RtspConnectData {
    /**
     * 
     * @type {string}
     * @memberof RtspConnectData
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof RtspConnectData
     */
    'serverId'?: string;
    /**
     * 
     * @type {RtspConnectUser}
     * @memberof RtspConnectData
     */
    'rtspConnectUser'?: RtspConnectUser;
}
/**
 * 
 * @export
 * @interface RtspConnectUser
 */
export interface RtspConnectUser {
    /**
     * 
     * @type {string}
     * @memberof RtspConnectUser
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof RtspConnectUser
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface SaturationLevelOptions
 */
export interface SaturationLevelOptions {
    /**
     * 
     * @type {number}
     * @memberof SaturationLevelOptions
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof SaturationLevelOptions
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof SaturationLevelOptions
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface Schedule
 */
export interface Schedule {
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'scheduleType'?: string;
    /**
     * 
     * @type {TimeRange}
     * @memberof Schedule
     */
    'TimeRange'?: TimeRange;
}
/**
 * 
 * @export
 * @interface ScheduleOptions
 */
export interface ScheduleOptions {
    /**
     * 
     * @type {string}
     * @memberof ScheduleOptions
     */
    'scheduleType'?: string;
    /**
     * 
     * @type {TimeRangeOptions}
     * @memberof ScheduleOptions
     */
    'TimeRange'?: TimeRangeOptions;
}
/**
 * 
 * @export
 * @interface ScheduledTaskDTO
 */
export interface ScheduledTaskDTO {
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskDTO
     */
    'description'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ScheduledTaskDTO
     */
    'year'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ScheduledTaskDTO
     */
    'month'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ScheduledTaskDTO
     */
    'day'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduledTaskDTO
     */
    'startTime'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduledTaskDTO
     */
    'endTime'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskDTO
     */
    'taskType'?: ScheduledTaskDTOTaskTypeEnum;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ScheduledTaskDTO
     */
    'properties'?: { [key: string]: object; };
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledTaskDTO
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ScheduledTaskDTO
     */
    'dayOfWeek'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduledTaskDTO
     */
    'explicitDay'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledTaskDTO
     */
    'finished'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskDTO
     */
    'parentTaskId'?: number;
}

export const ScheduledTaskDTOTaskTypeEnum = {
    Detection: 'DETECTION'
} as const;

export type ScheduledTaskDTOTaskTypeEnum = typeof ScheduledTaskDTOTaskTypeEnum[keyof typeof ScheduledTaskDTOTaskTypeEnum];

/**
 * 
 * @export
 * @interface SelectOption
 */
export interface SelectOption {
    /**
     * 
     * @type {string}
     * @memberof SelectOption
     */
    'label'?: string;
    /**
     * 
     * @type {object}
     * @memberof SelectOption
     */
    'value'?: object;
}
/**
 * @type SetCurrentImagingSettingsOfElementIdRequest
 * @export
 */
export type SetCurrentImagingSettingsOfElementIdRequest = HikvisionImagingSettings | OnvifImagingSettings;

/**
 * 
 * @export
 * @interface SetLayoutBlockProperties
 */
export interface SetLayoutBlockProperties {
    /**
     * 
     * @type {number}
     * @memberof SetLayoutBlockProperties
     */
    'videowallGroupId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SetLayoutBlockProperties
     */
    'layoutBlockId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SetLayoutBlockProperties
     */
    'layoutBlockType'?: SetLayoutBlockPropertiesLayoutBlockTypeEnum;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof SetLayoutBlockProperties
     */
    'layoutBlockProperties'?: { [key: string]: object; };
}

export const SetLayoutBlockPropertiesLayoutBlockTypeEnum = {
    Camera: 'CAMERA',
    Graph: 'GRAPH',
    Webpage: 'WEBPAGE',
    CameraSequence: 'CAMERA_SEQUENCE'
} as const;

export type SetLayoutBlockPropertiesLayoutBlockTypeEnum = typeof SetLayoutBlockPropertiesLayoutBlockTypeEnum[keyof typeof SetLayoutBlockPropertiesLayoutBlockTypeEnum];

/**
 * 
 * @export
 * @interface Sharpness
 */
export interface Sharpness {
    /**
     * 
     * @type {number}
     * @memberof Sharpness
     */
    'SharpnessLevel'?: number;
}
/**
 * 
 * @export
 * @interface SharpnessLevelOptions
 */
export interface SharpnessLevelOptions {
    /**
     * 
     * @type {number}
     * @memberof SharpnessLevelOptions
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof SharpnessLevelOptions
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof SharpnessLevelOptions
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface SharpnessOptions
 */
export interface SharpnessOptions {
    /**
     * 
     * @type {SharpnessLevelOptions}
     * @memberof SharpnessOptions
     */
    'SharpnessLevel'?: SharpnessLevelOptions;
}
/**
 * 
 * @export
 * @interface Shutter
 */
export interface Shutter {
    /**
     * 
     * @type {string}
     * @memberof Shutter
     */
    'ShutterLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof Shutter
     */
    'maxShutterLevelLimit'?: string;
    /**
     * 
     * @type {string}
     * @memberof Shutter
     */
    'minShutterLevelLimit'?: string;
}
/**
 * 
 * @export
 * @interface ShutterLevelOptions
 */
export interface ShutterLevelOptions {
    /**
     * 
     * @type {string}
     * @memberof ShutterLevelOptions
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShutterLevelOptions
     */
    'opt'?: string;
}
/**
 * 
 * @export
 * @interface ShutterOptions
 */
export interface ShutterOptions {
    /**
     * 
     * @type {ShutterLevelOptions}
     * @memberof ShutterOptions
     */
    'ShutterLevel'?: ShutterLevelOptions;
    /**
     * 
     * @type {MaxShutterLevelLimitOptions}
     * @memberof ShutterOptions
     */
    'maxShutterLevelLimit'?: MaxShutterLevelLimitOptions;
    /**
     * 
     * @type {MinShutterLevelLimitOptions}
     * @memberof ShutterOptions
     */
    'minShutterLevelLimit'?: MinShutterLevelLimitOptions;
}
/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'unsorted'?: boolean;
}
/**
 * 
 * @export
 * @interface Space1DDescription
 */
export interface Space1DDescription {
    /**
     * 
     * @type {string}
     * @memberof Space1DDescription
     */
    'uri': string;
    /**
     * 
     * @type {FloatRange}
     * @memberof Space1DDescription
     */
    'xrange'?: FloatRange;
}
/**
 * 
 * @export
 * @interface Space2DDescription
 */
export interface Space2DDescription {
    /**
     * 
     * @type {string}
     * @memberof Space2DDescription
     */
    'uri': string;
    /**
     * 
     * @type {FloatRange}
     * @memberof Space2DDescription
     */
    'xrange'?: FloatRange;
    /**
     * 
     * @type {FloatRange}
     * @memberof Space2DDescription
     */
    'yrange'?: FloatRange;
}
/**
 * 
 * @export
 * @interface SuccessfulResponse
 */
export interface SuccessfulResponse {
    /**
     * 
     * @type {string}
     * @memberof SuccessfulResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface SunriseSunsetDTO
 */
export interface SunriseSunsetDTO {
    /**
     * 
     * @type {SunriseSunsetResultsDTO}
     * @memberof SunriseSunsetDTO
     */
    'results'?: SunriseSunsetResultsDTO;
    /**
     * 
     * @type {string}
     * @memberof SunriseSunsetDTO
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface SunriseSunsetResultsDTO
 */
export interface SunriseSunsetResultsDTO {
    /**
     * 
     * @type {string}
     * @memberof SunriseSunsetResultsDTO
     */
    'sunrise'?: string;
    /**
     * 
     * @type {string}
     * @memberof SunriseSunsetResultsDTO
     */
    'sunset'?: string;
    /**
     * 
     * @type {string}
     * @memberof SunriseSunsetResultsDTO
     */
    'solarMoon'?: string;
    /**
     * 
     * @type {string}
     * @memberof SunriseSunsetResultsDTO
     */
    'dayLength'?: string;
    /**
     * 
     * @type {string}
     * @memberof SunriseSunsetResultsDTO
     */
    'civilTwilightBegin'?: string;
    /**
     * 
     * @type {string}
     * @memberof SunriseSunsetResultsDTO
     */
    'civilTwilightEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof SunriseSunsetResultsDTO
     */
    'nauticalTwilightBegin'?: string;
    /**
     * 
     * @type {string}
     * @memberof SunriseSunsetResultsDTO
     */
    'nauticalTwilightEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof SunriseSunsetResultsDTO
     */
    'astronomicalTwilightBegin'?: string;
    /**
     * 
     * @type {string}
     * @memberof SunriseSunsetResultsDTO
     */
    'astronomicalTwilightEnd'?: string;
}
/**
 * 
 * @export
 * @interface TestCamera
 */
export interface TestCamera {
    /**
     * 
     * @type {number}
     * @memberof TestCamera
     */
    'camId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TestCamera
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof TestCamera
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof TestCamera
     */
    'password'?: string;
    /**
     * 
     * @type {number}
     * @memberof TestCamera
     */
    'port'?: number;
    /**
     * 
     * @type {string}
     * @memberof TestCamera
     */
    'path'?: string;
    /**
     * 
     * @type {number}
     * @memberof TestCamera
     */
    'idProfile'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TestCamera
     */
    'unicast'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TestCamera
     */
    'onvifAuthType'?: TestCameraOnvifAuthTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TestCamera
     */
    'panOffset'?: number;
}

export const TestCameraOnvifAuthTypeEnum = {
    SoapDigest: 'SOAP_DIGEST',
    HttpDigest: 'HTTP_DIGEST',
    HttpBasic: 'HTTP_BASIC'
} as const;

export type TestCameraOnvifAuthTypeEnum = typeof TestCameraOnvifAuthTypeEnum[keyof typeof TestCameraOnvifAuthTypeEnum];

/**
 * 
 * @export
 * @interface TimeRange
 */
export interface TimeRange {
    /**
     * 
     * @type {string}
     * @memberof TimeRange
     */
    'beginTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeRange
     */
    'endTime'?: string;
}
/**
 * 
 * @export
 * @interface TimeRangeOptions
 */
export interface TimeRangeOptions {
    /**
     * 
     * @type {string}
     * @memberof TimeRangeOptions
     */
    'beginTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeRangeOptions
     */
    'endTime'?: string;
}
/**
 * 
 * @export
 * @interface UserCameraTokenData
 */
export interface UserCameraTokenData {
    /**
     * 
     * @type {number}
     * @memberof UserCameraTokenData
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserCameraTokenData
     */
    'userName'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserCameraTokenData
     */
    'maxConcurrentStreams'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCameraTokenData
     */
    'maxSameCameraConcurrentStreams'?: number;
    /**
     * 
     * @type {UserCameraTokenDataCameraTokenMap}
     * @memberof UserCameraTokenData
     */
    'cameraTokenMap'?: UserCameraTokenDataCameraTokenMap;
}
/**
 * 
 * @export
 * @interface UserCameraTokenDataCameraTokenMap
 */
export interface UserCameraTokenDataCameraTokenMap {
    [key: string]: Array<CameraToken> | any;

    /**
     * 
     * @type {boolean}
     * @memberof UserCameraTokenDataCameraTokenMap
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface UserTokenData
 */
export interface UserTokenData {
    /**
     * 
     * @type {number}
     * @memberof UserTokenData
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserTokenData
     */
    'userName'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserTokenData
     */
    'maxConcurrentStreams'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserTokenData
     */
    'maxSameCameraConcurrentStreams'?: number;
}
/**
 * 
 * @export
 * @interface Vector1D
 */
export interface Vector1D {
    /**
     * 
     * @type {number}
     * @memberof Vector1D
     */
    'x'?: number;
    /**
     * 
     * @type {string}
     * @memberof Vector1D
     */
    'space'?: string;
}
/**
 * 
 * @export
 * @interface Vector2D
 */
export interface Vector2D {
    /**
     * 
     * @type {number}
     * @memberof Vector2D
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof Vector2D
     */
    'y'?: number;
    /**
     * 
     * @type {string}
     * @memberof Vector2D
     */
    'space'?: string;
}
/**
 * 
 * @export
 * @interface VideoClip
 */
export interface VideoClip {
    /**
     * 
     * @type {number}
     * @memberof VideoClip
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof VideoClip
     */
    'cameraName'?: string;
    /**
     * 
     * @type {number}
     * @memberof VideoClip
     */
    'cameraId'?: number;
    /**
     * 
     * @type {string}
     * @memberof VideoClip
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof VideoClip
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof VideoClip
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof VideoClip
     */
    'imageWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoClip
     */
    'imageHeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoClip
     */
    'metadataRequestTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoClip
     */
    'fileSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoClip
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof VideoClip
     */
    'videoClipStatusType'?: VideoClipVideoClipStatusTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VideoClip
     */
    'fileName'?: string;
}

export const VideoClipVideoClipStatusTypeEnum = {
    Pending: 'PENDING',
    Busy: 'BUSY',
    Finished: 'FINISHED',
    Failed: 'FAILED'
} as const;

export type VideoClipVideoClipStatusTypeEnum = typeof VideoClipVideoClipStatusTypeEnum[keyof typeof VideoClipVideoClipStatusTypeEnum];

/**
 * 
 * @export
 * @interface VideoStreamUpsert
 */
export interface VideoStreamUpsert {
    /**
     * 
     * @type {number}
     * @memberof VideoStreamUpsert
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoStreamUpsert
     */
    'port'?: number;
    /**
     * 
     * @type {string}
     * @memberof VideoStreamUpsert
     */
    'path'?: string;
    /**
     * 
     * @type {number}
     * @memberof VideoStreamUpsert
     */
    'forceInputFramerate'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoStreamUpsert
     */
    'forceInputWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoStreamUpsert
     */
    'forceInputHeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof VideoStreamUpsert
     */
    'outputVideoCodec'?: string;
    /**
     * 
     * @type {number}
     * @memberof VideoStreamUpsert
     */
    'outputWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoStreamUpsert
     */
    'outputHeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoStreamUpsert
     */
    'outputFrameRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoStreamUpsert
     */
    'imageFlip'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VideoStreamUpsert
     */
    'multicast'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VideoStreamUpsert
     */
    'streamProfileId'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoStreamUpsert
     */
    'maxPanelWidth'?: number;
    /**
     * 
     * @type {string}
     * @memberof VideoStreamUpsert
     */
    'codecType'?: VideoStreamUpsertCodecTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VideoStreamUpsert
     */
    'transportType'?: VideoStreamUpsertTransportTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VideoStreamUpsert
     */
    'emitMulticastAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof VideoStreamUpsert
     */
    'emitMulticastTtl'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoStreamUpsert
     */
    'emitMulticastPort'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoStreamUpsert
     */
    'latency'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VideoStreamUpsert
     */
    'empty'?: boolean;
}

export const VideoStreamUpsertCodecTypeEnum = {
    IgnoreCodec: 'IGNORE_CODEC',
    Raw: 'RAW',
    H264: 'H264',
    H265: 'H265',
    Vp8: 'VP8',
    Vp9: 'VP9',
    Mpeg2: 'MPEG2',
    Mpeg4: 'MPEG4'
} as const;

export type VideoStreamUpsertCodecTypeEnum = typeof VideoStreamUpsertCodecTypeEnum[keyof typeof VideoStreamUpsertCodecTypeEnum];
export const VideoStreamUpsertTransportTypeEnum = {
    Rtsp: 'RTSP',
    File: 'FILE',
    Rtp: 'RTP',
    RtspTcp: 'RTSP_TCP'
} as const;

export type VideoStreamUpsertTransportTypeEnum = typeof VideoStreamUpsertTransportTypeEnum[keyof typeof VideoStreamUpsertTransportTypeEnum];

/**
 * 
 * @export
 * @interface Videowall
 */
export interface Videowall {
    /**
     * 
     * @type {number}
     * @memberof Videowall
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Videowall
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Videowall
     */
    'computerId'?: string;
    /**
     * 
     * @type {Set<VideowallScreen>}
     * @memberof Videowall
     */
    'videowallScreen'?: Set<VideowallScreen>;
    /**
     * 
     * @type {MultiLayout}
     * @memberof Videowall
     */
    'status'?: MultiLayout;
}
/**
 * 
 * @export
 * @interface VideowallLayout
 */
export interface VideowallLayout {
    /**
     * 
     * @type {number}
     * @memberof VideowallLayout
     */
    'videowallId'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideowallLayout
     */
    'layoutId'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideowallLayout
     */
    'scenarioId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof VideowallLayout
     */
    'screens'?: Array<number>;
}
/**
 * 
 * @export
 * @interface VideowallScreen
 */
export interface VideowallScreen {
    /**
     * 
     * @type {number}
     * @memberof VideowallScreen
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof VideowallScreen
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VideowallScreen
     */
    'available'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VideowallScreen
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideowallScreen
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideowallScreen
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideowallScreen
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideowallScreen
     */
    'panelNumber'?: number;
}
/**
 * 
 * @export
 * @interface WDR
 */
export interface WDR {
    /**
     * 
     * @type {string}
     * @memberof WDR
     */
    'mode'?: string;
    /**
     * 
     * @type {number}
     * @memberof WDR
     */
    'WDRLevel'?: number;
}
/**
 * 
 * @export
 * @interface WDRLevelOptions
 */
export interface WDRLevelOptions {
    /**
     * 
     * @type {number}
     * @memberof WDRLevelOptions
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof WDRLevelOptions
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof WDRLevelOptions
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface WDROptions
 */
export interface WDROptions {
    /**
     * 
     * @type {ModeOptions}
     * @memberof WDROptions
     */
    'mode'?: ModeOptions;
    /**
     * 
     * @type {WDRLevelOptions}
     * @memberof WDROptions
     */
    'WDRLevel'?: WDRLevelOptions;
}
/**
 * 
 * @export
 * @interface WhiteBalance
 */
export interface WhiteBalance {
    /**
     * 
     * @type {string}
     * @memberof WhiteBalance
     */
    'WhiteBalanceStyle'?: string;
    /**
     * 
     * @type {number}
     * @memberof WhiteBalance
     */
    'WhiteBalanceRed'?: number;
    /**
     * 
     * @type {number}
     * @memberof WhiteBalance
     */
    'WhiteBalanceBlue'?: number;
}
/**
 * 
 * @export
 * @interface WhiteBalanceBlueOptions
 */
export interface WhiteBalanceBlueOptions {
    /**
     * 
     * @type {number}
     * @memberof WhiteBalanceBlueOptions
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof WhiteBalanceBlueOptions
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof WhiteBalanceBlueOptions
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface WhiteBalanceOptions
 */
export interface WhiteBalanceOptions {
    /**
     * 
     * @type {WhiteBalanceStyleOptions}
     * @memberof WhiteBalanceOptions
     */
    'WhiteBalanceStyle'?: WhiteBalanceStyleOptions;
    /**
     * 
     * @type {WhiteBalanceRedOptions}
     * @memberof WhiteBalanceOptions
     */
    'WhiteBalanceRed'?: WhiteBalanceRedOptions;
    /**
     * 
     * @type {WhiteBalanceBlueOptions}
     * @memberof WhiteBalanceOptions
     */
    'WhiteBalanceBlue'?: WhiteBalanceBlueOptions;
}
/**
 * 
 * @export
 * @interface WhiteBalanceRedOptions
 */
export interface WhiteBalanceRedOptions {
    /**
     * 
     * @type {number}
     * @memberof WhiteBalanceRedOptions
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof WhiteBalanceRedOptions
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof WhiteBalanceRedOptions
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface WhiteBalanceStyleOptions
 */
export interface WhiteBalanceStyleOptions {
    /**
     * 
     * @type {string}
     * @memberof WhiteBalanceStyleOptions
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof WhiteBalanceStyleOptions
     */
    'opt'?: string;
}
/**
 * 
 * @export
 * @interface ZoomLimit
 */
export interface ZoomLimit {
    /**
     * 
     * @type {number}
     * @memberof ZoomLimit
     */
    'ZoomLimitRatio'?: number;
}
/**
 * 
 * @export
 * @interface ZoomLimits
 */
export interface ZoomLimits {
    /**
     * 
     * @type {Space1DDescription}
     * @memberof ZoomLimits
     */
    'range': Space1DDescription;
}

/**
 * AiCameraImagingSettingsScheduleControllerApi - axios parameter creator
 * @export
 */
export const AiCameraImagingSettingsScheduleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementaiCameraImagingSettingsScheduleController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteByIdElementaiCameraImagingSettingsScheduleController', 'id', id)
            const localVarPath = `/aicameraimagingsettingsschedule/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllaiCameraImagingSettingsScheduleController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/aicameraimagingsettingsschedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} aiCameraImagingSettingsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByCameraId3: async (aiCameraImagingSettingsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aiCameraImagingSettingsId' is not null or undefined
            assertParamExists('getByCameraId3', 'aiCameraImagingSettingsId', aiCameraImagingSettingsId)
            const localVarPath = `/aicameraimagingsettingsschedule/imagingsettings/{aiCameraImagingSettingsId}`
                .replace(`{${"aiCameraImagingSettingsId"}}`, encodeURIComponent(String(aiCameraImagingSettingsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementaiCameraImagingSettingsScheduleController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneElementaiCameraImagingSettingsScheduleController', 'id', id)
            const localVarPath = `/aicameraimagingsettingsschedule/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectaiCameraImagingSettingsScheduleController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/aicameraimagingsettingsschedule/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AiCameraImagingSettingsScheduleDTO} aiCameraImagingSettingsScheduleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementaiCameraImagingSettingsScheduleController: async (aiCameraImagingSettingsScheduleDTO: AiCameraImagingSettingsScheduleDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aiCameraImagingSettingsScheduleDTO' is not null or undefined
            assertParamExists('insertElementaiCameraImagingSettingsScheduleController', 'aiCameraImagingSettingsScheduleDTO', aiCameraImagingSettingsScheduleDTO)
            const localVarPath = `/aicameraimagingsettingsschedule/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiCameraImagingSettingsScheduleDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AiCameraImagingSettingsScheduleDTO} aiCameraImagingSettingsScheduleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementaiCameraImagingSettingsScheduleController: async (id: number, aiCameraImagingSettingsScheduleDTO: AiCameraImagingSettingsScheduleDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateElementaiCameraImagingSettingsScheduleController', 'id', id)
            // verify required parameter 'aiCameraImagingSettingsScheduleDTO' is not null or undefined
            assertParamExists('updateElementaiCameraImagingSettingsScheduleController', 'aiCameraImagingSettingsScheduleDTO', aiCameraImagingSettingsScheduleDTO)
            const localVarPath = `/aicameraimagingsettingsschedule/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiCameraImagingSettingsScheduleDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AiCameraImagingSettingsScheduleControllerApi - functional programming interface
 * @export
 */
export const AiCameraImagingSettingsScheduleControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AiCameraImagingSettingsScheduleControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElementaiCameraImagingSettingsScheduleController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElementaiCameraImagingSettingsScheduleController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllaiCameraImagingSettingsScheduleController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiCameraImagingSettingsScheduleDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllaiCameraImagingSettingsScheduleController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} aiCameraImagingSettingsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByCameraId3(aiCameraImagingSettingsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiCameraImagingSettingsScheduleDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByCameraId3(aiCameraImagingSettingsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneElementaiCameraImagingSettingsScheduleController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiCameraImagingSettingsScheduleDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneElementaiCameraImagingSettingsScheduleController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectaiCameraImagingSettingsScheduleController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectaiCameraImagingSettingsScheduleController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AiCameraImagingSettingsScheduleDTO} aiCameraImagingSettingsScheduleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertElementaiCameraImagingSettingsScheduleController(aiCameraImagingSettingsScheduleDTO: AiCameraImagingSettingsScheduleDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiCameraImagingSettingsScheduleDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertElementaiCameraImagingSettingsScheduleController(aiCameraImagingSettingsScheduleDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AiCameraImagingSettingsScheduleDTO} aiCameraImagingSettingsScheduleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateElementaiCameraImagingSettingsScheduleController(id: number, aiCameraImagingSettingsScheduleDTO: AiCameraImagingSettingsScheduleDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiCameraImagingSettingsScheduleDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateElementaiCameraImagingSettingsScheduleController(id, aiCameraImagingSettingsScheduleDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AiCameraImagingSettingsScheduleControllerApi - factory interface
 * @export
 */
export const AiCameraImagingSettingsScheduleControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AiCameraImagingSettingsScheduleControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementaiCameraImagingSettingsScheduleController(id: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElementaiCameraImagingSettingsScheduleController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllaiCameraImagingSettingsScheduleController(options?: any): AxiosPromise<Array<AiCameraImagingSettingsScheduleDTO>> {
            return localVarFp.getAllaiCameraImagingSettingsScheduleController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} aiCameraImagingSettingsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByCameraId3(aiCameraImagingSettingsId: number, options?: any): AxiosPromise<Array<AiCameraImagingSettingsScheduleDTO>> {
            return localVarFp.getByCameraId3(aiCameraImagingSettingsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementaiCameraImagingSettingsScheduleController(id: number, options?: any): AxiosPromise<AiCameraImagingSettingsScheduleDTO> {
            return localVarFp.getOneElementaiCameraImagingSettingsScheduleController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectaiCameraImagingSettingsScheduleController(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getSelectaiCameraImagingSettingsScheduleController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AiCameraImagingSettingsScheduleDTO} aiCameraImagingSettingsScheduleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementaiCameraImagingSettingsScheduleController(aiCameraImagingSettingsScheduleDTO: AiCameraImagingSettingsScheduleDTO, options?: any): AxiosPromise<AiCameraImagingSettingsScheduleDTO> {
            return localVarFp.insertElementaiCameraImagingSettingsScheduleController(aiCameraImagingSettingsScheduleDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AiCameraImagingSettingsScheduleDTO} aiCameraImagingSettingsScheduleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementaiCameraImagingSettingsScheduleController(id: number, aiCameraImagingSettingsScheduleDTO: AiCameraImagingSettingsScheduleDTO, options?: any): AxiosPromise<AiCameraImagingSettingsScheduleDTO> {
            return localVarFp.updateElementaiCameraImagingSettingsScheduleController(id, aiCameraImagingSettingsScheduleDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AiCameraImagingSettingsScheduleControllerApi - object-oriented interface
 * @export
 * @class AiCameraImagingSettingsScheduleControllerApi
 * @extends {BaseAPI}
 */
export class AiCameraImagingSettingsScheduleControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleControllerApi
     */
    public deleteByIdElementaiCameraImagingSettingsScheduleController(id: number, options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleControllerApiFp(this.configuration).deleteByIdElementaiCameraImagingSettingsScheduleController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleControllerApi
     */
    public getAllaiCameraImagingSettingsScheduleController(options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleControllerApiFp(this.configuration).getAllaiCameraImagingSettingsScheduleController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} aiCameraImagingSettingsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleControllerApi
     */
    public getByCameraId3(aiCameraImagingSettingsId: number, options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleControllerApiFp(this.configuration).getByCameraId3(aiCameraImagingSettingsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleControllerApi
     */
    public getOneElementaiCameraImagingSettingsScheduleController(id: number, options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleControllerApiFp(this.configuration).getOneElementaiCameraImagingSettingsScheduleController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleControllerApi
     */
    public getSelectaiCameraImagingSettingsScheduleController(options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleControllerApiFp(this.configuration).getSelectaiCameraImagingSettingsScheduleController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AiCameraImagingSettingsScheduleDTO} aiCameraImagingSettingsScheduleDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleControllerApi
     */
    public insertElementaiCameraImagingSettingsScheduleController(aiCameraImagingSettingsScheduleDTO: AiCameraImagingSettingsScheduleDTO, options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleControllerApiFp(this.configuration).insertElementaiCameraImagingSettingsScheduleController(aiCameraImagingSettingsScheduleDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AiCameraImagingSettingsScheduleDTO} aiCameraImagingSettingsScheduleDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleControllerApi
     */
    public updateElementaiCameraImagingSettingsScheduleController(id: number, aiCameraImagingSettingsScheduleDTO: AiCameraImagingSettingsScheduleDTO, options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleControllerApiFp(this.configuration).updateElementaiCameraImagingSettingsScheduleController(id, aiCameraImagingSettingsScheduleDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AiCameraImagingSettingsScheduleDateControllerApi - axios parameter creator
 * @export
 */
export const AiCameraImagingSettingsScheduleDateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementaiCameraImagingSettingsScheduleDateController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteByIdElementaiCameraImagingSettingsScheduleDateController', 'id', id)
            const localVarPath = `/aicameraimagingsettingsscheduledate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElements1: async (requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteByIdElements1', 'requestBody', requestBody)
            const localVarPath = `/aicameraimagingsettingsscheduledate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllaiCameraImagingSettingsScheduleDateController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/aicameraimagingsettingsscheduledate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} aiCameraImagingSettingsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByCameraId2: async (aiCameraImagingSettingsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aiCameraImagingSettingsId' is not null or undefined
            assertParamExists('getByCameraId2', 'aiCameraImagingSettingsId', aiCameraImagingSettingsId)
            const localVarPath = `/aicameraimagingsettingsscheduledate/imagingsettings/{aiCameraImagingSettingsId}`
                .replace(`{${"aiCameraImagingSettingsId"}}`, encodeURIComponent(String(aiCameraImagingSettingsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementaiCameraImagingSettingsScheduleDateController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneElementaiCameraImagingSettingsScheduleDateController', 'id', id)
            const localVarPath = `/aicameraimagingsettingsscheduledate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectaiCameraImagingSettingsScheduleDateController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/aicameraimagingsettingsscheduledate/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AiCameraImagingSettingsScheduleDateDTO} aiCameraImagingSettingsScheduleDateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementaiCameraImagingSettingsScheduleDateController: async (aiCameraImagingSettingsScheduleDateDTO: AiCameraImagingSettingsScheduleDateDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aiCameraImagingSettingsScheduleDateDTO' is not null or undefined
            assertParamExists('insertElementaiCameraImagingSettingsScheduleDateController', 'aiCameraImagingSettingsScheduleDateDTO', aiCameraImagingSettingsScheduleDateDTO)
            const localVarPath = `/aicameraimagingsettingsscheduledate/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiCameraImagingSettingsScheduleDateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AiCameraImagingSettingsScheduleDateDTO} aiCameraImagingSettingsScheduleDateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementaiCameraImagingSettingsScheduleDateController: async (id: number, aiCameraImagingSettingsScheduleDateDTO: AiCameraImagingSettingsScheduleDateDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateElementaiCameraImagingSettingsScheduleDateController', 'id', id)
            // verify required parameter 'aiCameraImagingSettingsScheduleDateDTO' is not null or undefined
            assertParamExists('updateElementaiCameraImagingSettingsScheduleDateController', 'aiCameraImagingSettingsScheduleDateDTO', aiCameraImagingSettingsScheduleDateDTO)
            const localVarPath = `/aicameraimagingsettingsscheduledate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiCameraImagingSettingsScheduleDateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<AiCameraImagingSettingsScheduleDateDTO>} aiCameraImagingSettingsScheduleDateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsert1: async (aiCameraImagingSettingsScheduleDateDTO: Array<AiCameraImagingSettingsScheduleDateDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aiCameraImagingSettingsScheduleDateDTO' is not null or undefined
            assertParamExists('upsert1', 'aiCameraImagingSettingsScheduleDateDTO', aiCameraImagingSettingsScheduleDateDTO)
            const localVarPath = `/aicameraimagingsettingsscheduledate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiCameraImagingSettingsScheduleDateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AiCameraImagingSettingsScheduleDateControllerApi - functional programming interface
 * @export
 */
export const AiCameraImagingSettingsScheduleDateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AiCameraImagingSettingsScheduleDateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElementaiCameraImagingSettingsScheduleDateController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElementaiCameraImagingSettingsScheduleDateController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElements1(requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElements1(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllaiCameraImagingSettingsScheduleDateController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiCameraImagingSettingsScheduleDateDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllaiCameraImagingSettingsScheduleDateController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} aiCameraImagingSettingsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByCameraId2(aiCameraImagingSettingsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiCameraImagingSettingsScheduleDateDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByCameraId2(aiCameraImagingSettingsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneElementaiCameraImagingSettingsScheduleDateController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiCameraImagingSettingsScheduleDateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneElementaiCameraImagingSettingsScheduleDateController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectaiCameraImagingSettingsScheduleDateController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectaiCameraImagingSettingsScheduleDateController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AiCameraImagingSettingsScheduleDateDTO} aiCameraImagingSettingsScheduleDateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertElementaiCameraImagingSettingsScheduleDateController(aiCameraImagingSettingsScheduleDateDTO: AiCameraImagingSettingsScheduleDateDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiCameraImagingSettingsScheduleDateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertElementaiCameraImagingSettingsScheduleDateController(aiCameraImagingSettingsScheduleDateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AiCameraImagingSettingsScheduleDateDTO} aiCameraImagingSettingsScheduleDateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateElementaiCameraImagingSettingsScheduleDateController(id: number, aiCameraImagingSettingsScheduleDateDTO: AiCameraImagingSettingsScheduleDateDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiCameraImagingSettingsScheduleDateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateElementaiCameraImagingSettingsScheduleDateController(id, aiCameraImagingSettingsScheduleDateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<AiCameraImagingSettingsScheduleDateDTO>} aiCameraImagingSettingsScheduleDateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsert1(aiCameraImagingSettingsScheduleDateDTO: Array<AiCameraImagingSettingsScheduleDateDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsert1(aiCameraImagingSettingsScheduleDateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AiCameraImagingSettingsScheduleDateControllerApi - factory interface
 * @export
 */
export const AiCameraImagingSettingsScheduleDateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AiCameraImagingSettingsScheduleDateControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementaiCameraImagingSettingsScheduleDateController(id: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElementaiCameraImagingSettingsScheduleDateController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElements1(requestBody: Array<number>, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElements1(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllaiCameraImagingSettingsScheduleDateController(options?: any): AxiosPromise<Array<AiCameraImagingSettingsScheduleDateDTO>> {
            return localVarFp.getAllaiCameraImagingSettingsScheduleDateController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} aiCameraImagingSettingsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByCameraId2(aiCameraImagingSettingsId: number, options?: any): AxiosPromise<Array<AiCameraImagingSettingsScheduleDateDTO>> {
            return localVarFp.getByCameraId2(aiCameraImagingSettingsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementaiCameraImagingSettingsScheduleDateController(id: number, options?: any): AxiosPromise<AiCameraImagingSettingsScheduleDateDTO> {
            return localVarFp.getOneElementaiCameraImagingSettingsScheduleDateController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectaiCameraImagingSettingsScheduleDateController(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getSelectaiCameraImagingSettingsScheduleDateController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AiCameraImagingSettingsScheduleDateDTO} aiCameraImagingSettingsScheduleDateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementaiCameraImagingSettingsScheduleDateController(aiCameraImagingSettingsScheduleDateDTO: AiCameraImagingSettingsScheduleDateDTO, options?: any): AxiosPromise<AiCameraImagingSettingsScheduleDateDTO> {
            return localVarFp.insertElementaiCameraImagingSettingsScheduleDateController(aiCameraImagingSettingsScheduleDateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AiCameraImagingSettingsScheduleDateDTO} aiCameraImagingSettingsScheduleDateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementaiCameraImagingSettingsScheduleDateController(id: number, aiCameraImagingSettingsScheduleDateDTO: AiCameraImagingSettingsScheduleDateDTO, options?: any): AxiosPromise<AiCameraImagingSettingsScheduleDateDTO> {
            return localVarFp.updateElementaiCameraImagingSettingsScheduleDateController(id, aiCameraImagingSettingsScheduleDateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<AiCameraImagingSettingsScheduleDateDTO>} aiCameraImagingSettingsScheduleDateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsert1(aiCameraImagingSettingsScheduleDateDTO: Array<AiCameraImagingSettingsScheduleDateDTO>, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.upsert1(aiCameraImagingSettingsScheduleDateDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AiCameraImagingSettingsScheduleDateControllerApi - object-oriented interface
 * @export
 * @class AiCameraImagingSettingsScheduleDateControllerApi
 * @extends {BaseAPI}
 */
export class AiCameraImagingSettingsScheduleDateControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleDateControllerApi
     */
    public deleteByIdElementaiCameraImagingSettingsScheduleDateController(id: number, options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleDateControllerApiFp(this.configuration).deleteByIdElementaiCameraImagingSettingsScheduleDateController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleDateControllerApi
     */
    public deleteByIdElements1(requestBody: Array<number>, options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleDateControllerApiFp(this.configuration).deleteByIdElements1(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleDateControllerApi
     */
    public getAllaiCameraImagingSettingsScheduleDateController(options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleDateControllerApiFp(this.configuration).getAllaiCameraImagingSettingsScheduleDateController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} aiCameraImagingSettingsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleDateControllerApi
     */
    public getByCameraId2(aiCameraImagingSettingsId: number, options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleDateControllerApiFp(this.configuration).getByCameraId2(aiCameraImagingSettingsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleDateControllerApi
     */
    public getOneElementaiCameraImagingSettingsScheduleDateController(id: number, options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleDateControllerApiFp(this.configuration).getOneElementaiCameraImagingSettingsScheduleDateController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleDateControllerApi
     */
    public getSelectaiCameraImagingSettingsScheduleDateController(options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleDateControllerApiFp(this.configuration).getSelectaiCameraImagingSettingsScheduleDateController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AiCameraImagingSettingsScheduleDateDTO} aiCameraImagingSettingsScheduleDateDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleDateControllerApi
     */
    public insertElementaiCameraImagingSettingsScheduleDateController(aiCameraImagingSettingsScheduleDateDTO: AiCameraImagingSettingsScheduleDateDTO, options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleDateControllerApiFp(this.configuration).insertElementaiCameraImagingSettingsScheduleDateController(aiCameraImagingSettingsScheduleDateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AiCameraImagingSettingsScheduleDateDTO} aiCameraImagingSettingsScheduleDateDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleDateControllerApi
     */
    public updateElementaiCameraImagingSettingsScheduleDateController(id: number, aiCameraImagingSettingsScheduleDateDTO: AiCameraImagingSettingsScheduleDateDTO, options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleDateControllerApiFp(this.configuration).updateElementaiCameraImagingSettingsScheduleDateController(id, aiCameraImagingSettingsScheduleDateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<AiCameraImagingSettingsScheduleDateDTO>} aiCameraImagingSettingsScheduleDateDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleDateControllerApi
     */
    public upsert1(aiCameraImagingSettingsScheduleDateDTO: Array<AiCameraImagingSettingsScheduleDateDTO>, options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleDateControllerApiFp(this.configuration).upsert1(aiCameraImagingSettingsScheduleDateDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AiCameraImagingSettingsScheduleHistControllerApi - axios parameter creator
 * @export
 */
export const AiCameraImagingSettingsScheduleHistControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementaiCameraImagingSettingsScheduleHistController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteByIdElementaiCameraImagingSettingsScheduleHistController', 'id', id)
            const localVarPath = `/aicameraimagingsettingsschedulehist/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllaiCameraImagingSettingsScheduleHistController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/aicameraimagingsettingsschedulehist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementaiCameraImagingSettingsScheduleHistController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneElementaiCameraImagingSettingsScheduleHistController', 'id', id)
            const localVarPath = `/aicameraimagingsettingsschedulehist/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectaiCameraImagingSettingsScheduleHistController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/aicameraimagingsettingsschedulehist/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AiCameraImagingSettingsScheduleHistDTO} aiCameraImagingSettingsScheduleHistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementaiCameraImagingSettingsScheduleHistController: async (aiCameraImagingSettingsScheduleHistDTO: AiCameraImagingSettingsScheduleHistDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aiCameraImagingSettingsScheduleHistDTO' is not null or undefined
            assertParamExists('insertElementaiCameraImagingSettingsScheduleHistController', 'aiCameraImagingSettingsScheduleHistDTO', aiCameraImagingSettingsScheduleHistDTO)
            const localVarPath = `/aicameraimagingsettingsschedulehist/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiCameraImagingSettingsScheduleHistDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AiCameraImagingSettingsScheduleHistDTO} aiCameraImagingSettingsScheduleHistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementaiCameraImagingSettingsScheduleHistController: async (id: number, aiCameraImagingSettingsScheduleHistDTO: AiCameraImagingSettingsScheduleHistDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateElementaiCameraImagingSettingsScheduleHistController', 'id', id)
            // verify required parameter 'aiCameraImagingSettingsScheduleHistDTO' is not null or undefined
            assertParamExists('updateElementaiCameraImagingSettingsScheduleHistController', 'aiCameraImagingSettingsScheduleHistDTO', aiCameraImagingSettingsScheduleHistDTO)
            const localVarPath = `/aicameraimagingsettingsschedulehist/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiCameraImagingSettingsScheduleHistDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AiCameraImagingSettingsScheduleHistControllerApi - functional programming interface
 * @export
 */
export const AiCameraImagingSettingsScheduleHistControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AiCameraImagingSettingsScheduleHistControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElementaiCameraImagingSettingsScheduleHistController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElementaiCameraImagingSettingsScheduleHistController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllaiCameraImagingSettingsScheduleHistController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiCameraImagingSettingsScheduleHistDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllaiCameraImagingSettingsScheduleHistController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneElementaiCameraImagingSettingsScheduleHistController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiCameraImagingSettingsScheduleHistDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneElementaiCameraImagingSettingsScheduleHistController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectaiCameraImagingSettingsScheduleHistController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectaiCameraImagingSettingsScheduleHistController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AiCameraImagingSettingsScheduleHistDTO} aiCameraImagingSettingsScheduleHistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertElementaiCameraImagingSettingsScheduleHistController(aiCameraImagingSettingsScheduleHistDTO: AiCameraImagingSettingsScheduleHistDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiCameraImagingSettingsScheduleHistDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertElementaiCameraImagingSettingsScheduleHistController(aiCameraImagingSettingsScheduleHistDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AiCameraImagingSettingsScheduleHistDTO} aiCameraImagingSettingsScheduleHistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateElementaiCameraImagingSettingsScheduleHistController(id: number, aiCameraImagingSettingsScheduleHistDTO: AiCameraImagingSettingsScheduleHistDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiCameraImagingSettingsScheduleHistDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateElementaiCameraImagingSettingsScheduleHistController(id, aiCameraImagingSettingsScheduleHistDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AiCameraImagingSettingsScheduleHistControllerApi - factory interface
 * @export
 */
export const AiCameraImagingSettingsScheduleHistControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AiCameraImagingSettingsScheduleHistControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementaiCameraImagingSettingsScheduleHistController(id: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElementaiCameraImagingSettingsScheduleHistController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllaiCameraImagingSettingsScheduleHistController(options?: any): AxiosPromise<Array<AiCameraImagingSettingsScheduleHistDTO>> {
            return localVarFp.getAllaiCameraImagingSettingsScheduleHistController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementaiCameraImagingSettingsScheduleHistController(id: number, options?: any): AxiosPromise<AiCameraImagingSettingsScheduleHistDTO> {
            return localVarFp.getOneElementaiCameraImagingSettingsScheduleHistController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectaiCameraImagingSettingsScheduleHistController(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getSelectaiCameraImagingSettingsScheduleHistController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AiCameraImagingSettingsScheduleHistDTO} aiCameraImagingSettingsScheduleHistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementaiCameraImagingSettingsScheduleHistController(aiCameraImagingSettingsScheduleHistDTO: AiCameraImagingSettingsScheduleHistDTO, options?: any): AxiosPromise<AiCameraImagingSettingsScheduleHistDTO> {
            return localVarFp.insertElementaiCameraImagingSettingsScheduleHistController(aiCameraImagingSettingsScheduleHistDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AiCameraImagingSettingsScheduleHistDTO} aiCameraImagingSettingsScheduleHistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementaiCameraImagingSettingsScheduleHistController(id: number, aiCameraImagingSettingsScheduleHistDTO: AiCameraImagingSettingsScheduleHistDTO, options?: any): AxiosPromise<AiCameraImagingSettingsScheduleHistDTO> {
            return localVarFp.updateElementaiCameraImagingSettingsScheduleHistController(id, aiCameraImagingSettingsScheduleHistDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AiCameraImagingSettingsScheduleHistControllerApi - object-oriented interface
 * @export
 * @class AiCameraImagingSettingsScheduleHistControllerApi
 * @extends {BaseAPI}
 */
export class AiCameraImagingSettingsScheduleHistControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleHistControllerApi
     */
    public deleteByIdElementaiCameraImagingSettingsScheduleHistController(id: number, options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleHistControllerApiFp(this.configuration).deleteByIdElementaiCameraImagingSettingsScheduleHistController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleHistControllerApi
     */
    public getAllaiCameraImagingSettingsScheduleHistController(options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleHistControllerApiFp(this.configuration).getAllaiCameraImagingSettingsScheduleHistController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleHistControllerApi
     */
    public getOneElementaiCameraImagingSettingsScheduleHistController(id: number, options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleHistControllerApiFp(this.configuration).getOneElementaiCameraImagingSettingsScheduleHistController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleHistControllerApi
     */
    public getSelectaiCameraImagingSettingsScheduleHistController(options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleHistControllerApiFp(this.configuration).getSelectaiCameraImagingSettingsScheduleHistController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AiCameraImagingSettingsScheduleHistDTO} aiCameraImagingSettingsScheduleHistDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleHistControllerApi
     */
    public insertElementaiCameraImagingSettingsScheduleHistController(aiCameraImagingSettingsScheduleHistDTO: AiCameraImagingSettingsScheduleHistDTO, options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleHistControllerApiFp(this.configuration).insertElementaiCameraImagingSettingsScheduleHistController(aiCameraImagingSettingsScheduleHistDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AiCameraImagingSettingsScheduleHistDTO} aiCameraImagingSettingsScheduleHistDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiCameraImagingSettingsScheduleHistControllerApi
     */
    public updateElementaiCameraImagingSettingsScheduleHistController(id: number, aiCameraImagingSettingsScheduleHistDTO: AiCameraImagingSettingsScheduleHistDTO, options?: AxiosRequestConfig) {
        return AiCameraImagingSettingsScheduleHistControllerApiFp(this.configuration).updateElementaiCameraImagingSettingsScheduleHistController(id, aiCameraImagingSettingsScheduleHistDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AiModuleApi - axios parameter creator
 * @export
 */
export const AiModuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementAiModule: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteByIdElementAiModule', 'id', id)
            const localVarPath = `/ai_module/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {string} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile: async (elementId: number, file: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('downloadFile', 'elementId', elementId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('downloadFile', 'file', file)
            const localVarPath = `/ai_module/file/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (elementId !== undefined) {
                localVarQueryParameter['elementId'] = elementId;
            }

            if (file !== undefined) {
                localVarQueryParameter['file'] = file;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAiModule: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ai_module`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementAiModule: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneElementAiModule', 'id', id)
            const localVarPath = `/ai_module/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectAiModule: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ai_module/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AiModuleDTO} aiModuleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementAiModule: async (aiModuleDTO: AiModuleDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aiModuleDTO' is not null or undefined
            assertParamExists('insertElementAiModule', 'aiModuleDTO', aiModuleDTO)
            const localVarPath = `/ai_module/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiModuleDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AiModuleDTO} aiModuleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementAiModule: async (id: number, aiModuleDTO: AiModuleDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateElementAiModule', 'id', id)
            // verify required parameter 'aiModuleDTO' is not null or undefined
            assertParamExists('updateElementAiModule', 'aiModuleDTO', aiModuleDTO)
            const localVarPath = `/ai_module/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiModuleDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AiModuleApi - functional programming interface
 * @export
 */
export const AiModuleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AiModuleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElementAiModule(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElementAiModule(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {string} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFile(elementId: number, file: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFile(elementId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAiModule(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiModuleDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAiModule(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneElementAiModule(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiModuleDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneElementAiModule(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectAiModule(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectAiModule(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AiModuleDTO} aiModuleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertElementAiModule(aiModuleDTO: AiModuleDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiModuleDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertElementAiModule(aiModuleDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AiModuleDTO} aiModuleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateElementAiModule(id: number, aiModuleDTO: AiModuleDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiModuleDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateElementAiModule(id, aiModuleDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AiModuleApi - factory interface
 * @export
 */
export const AiModuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AiModuleApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementAiModule(id: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElementAiModule(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {string} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile(elementId: number, file: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadFile(elementId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAiModule(options?: any): AxiosPromise<Array<AiModuleDTO>> {
            return localVarFp.getAllAiModule(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementAiModule(id: number, options?: any): AxiosPromise<AiModuleDTO> {
            return localVarFp.getOneElementAiModule(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectAiModule(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getSelectAiModule(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AiModuleDTO} aiModuleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementAiModule(aiModuleDTO: AiModuleDTO, options?: any): AxiosPromise<AiModuleDTO> {
            return localVarFp.insertElementAiModule(aiModuleDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AiModuleDTO} aiModuleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementAiModule(id: number, aiModuleDTO: AiModuleDTO, options?: any): AxiosPromise<AiModuleDTO> {
            return localVarFp.updateElementAiModule(id, aiModuleDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AiModuleApi - object-oriented interface
 * @export
 * @class AiModuleApi
 * @extends {BaseAPI}
 */
export class AiModuleApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiModuleApi
     */
    public deleteByIdElementAiModule(id: number, options?: AxiosRequestConfig) {
        return AiModuleApiFp(this.configuration).deleteByIdElementAiModule(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {string} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiModuleApi
     */
    public downloadFile(elementId: number, file: string, options?: AxiosRequestConfig) {
        return AiModuleApiFp(this.configuration).downloadFile(elementId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiModuleApi
     */
    public getAllAiModule(options?: AxiosRequestConfig) {
        return AiModuleApiFp(this.configuration).getAllAiModule(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiModuleApi
     */
    public getOneElementAiModule(id: number, options?: AxiosRequestConfig) {
        return AiModuleApiFp(this.configuration).getOneElementAiModule(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiModuleApi
     */
    public getSelectAiModule(options?: AxiosRequestConfig) {
        return AiModuleApiFp(this.configuration).getSelectAiModule(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AiModuleDTO} aiModuleDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiModuleApi
     */
    public insertElementAiModule(aiModuleDTO: AiModuleDTO, options?: AxiosRequestConfig) {
        return AiModuleApiFp(this.configuration).insertElementAiModule(aiModuleDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AiModuleDTO} aiModuleDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiModuleApi
     */
    public updateElementAiModule(id: number, aiModuleDTO: AiModuleDTO, options?: AxiosRequestConfig) {
        return AiModuleApiFp(this.configuration).updateElementAiModule(id, aiModuleDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CameraBrandControllerApi - axios parameter creator
 * @export
 */
export const CameraBrandControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} brandName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBrand: async (brandName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandName' is not null or undefined
            assertParamExists('deleteBrand', 'brandName', brandName)
            const localVarPath = `/cameras/brands/{brandName}`
                .replace(`{${"brandName"}}`, encodeURIComponent(String(brandName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} elementTypeName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModel: async (elementTypeName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementTypeName' is not null or undefined
            assertParamExists('deleteModel', 'elementTypeName', elementTypeName)
            const localVarPath = `/cameras/brands/models/{elementTypeName}`
                .replace(`{${"elementTypeName"}}`, encodeURIComponent(String(elementTypeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBrandsAvailable: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/brands/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBrandsNamesAvailable: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllModels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/brands/models/names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brandName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllModelsFromBrand: async (brandName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandName' is not null or undefined
            assertParamExists('getAllModelsFromBrand', 'brandName', brandName)
            const localVarPath = `/cameras/brands/{brandName}/models/names`
                .replace(`{${"brandName"}}`, encodeURIComponent(String(brandName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brandName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllModelsFromBrandOptions: async (brandName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandName' is not null or undefined
            assertParamExists('getAllModelsFromBrandOptions', 'brandName', brandName)
            const localVarPath = `/cameras/brands/{brandName}/models/options`
                .replace(`{${"brandName"}}`, encodeURIComponent(String(brandName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllModelsOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/brands/models/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} elementTypeName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandNameFromModel: async (elementTypeName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementTypeName' is not null or undefined
            assertParamExists('getBrandNameFromModel', 'elementTypeName', elementTypeName)
            const localVarPath = `/cameras/brands/models/{elementTypeName}`
                .replace(`{${"elementTypeName"}}`, encodeURIComponent(String(elementTypeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CameraBrandControllerApi - functional programming interface
 * @export
 */
export const CameraBrandControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CameraBrandControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} brandName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBrand(brandName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBrand(brandName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} elementTypeName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteModel(elementTypeName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteModel(elementTypeName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBrandsAvailable(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBrandsAvailable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBrandsNamesAvailable(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBrandsNamesAvailable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllModels(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllModels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brandName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllModelsFromBrand(brandName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllModelsFromBrand(brandName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brandName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllModelsFromBrandOptions(brandName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllModelsFromBrandOptions(brandName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllModelsOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllModelsOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} elementTypeName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrandNameFromModel(elementTypeName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandNameFromModel(elementTypeName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CameraBrandControllerApi - factory interface
 * @export
 */
export const CameraBrandControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CameraBrandControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} brandName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBrand(brandName: string, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteBrand(brandName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} elementTypeName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModel(elementTypeName: string, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteModel(elementTypeName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBrandsAvailable(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getAllBrandsAvailable(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBrandsNamesAvailable(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAllBrandsNamesAvailable(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllModels(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAllModels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brandName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllModelsFromBrand(brandName: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAllModelsFromBrand(brandName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brandName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllModelsFromBrandOptions(brandName: string, options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getAllModelsFromBrandOptions(brandName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllModelsOptions(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getAllModelsOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} elementTypeName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandNameFromModel(elementTypeName: string, options?: any): AxiosPromise<string> {
            return localVarFp.getBrandNameFromModel(elementTypeName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CameraBrandControllerApi - object-oriented interface
 * @export
 * @class CameraBrandControllerApi
 * @extends {BaseAPI}
 */
export class CameraBrandControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} brandName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraBrandControllerApi
     */
    public deleteBrand(brandName: string, options?: AxiosRequestConfig) {
        return CameraBrandControllerApiFp(this.configuration).deleteBrand(brandName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} elementTypeName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraBrandControllerApi
     */
    public deleteModel(elementTypeName: string, options?: AxiosRequestConfig) {
        return CameraBrandControllerApiFp(this.configuration).deleteModel(elementTypeName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraBrandControllerApi
     */
    public getAllBrandsAvailable(options?: AxiosRequestConfig) {
        return CameraBrandControllerApiFp(this.configuration).getAllBrandsAvailable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraBrandControllerApi
     */
    public getAllBrandsNamesAvailable(options?: AxiosRequestConfig) {
        return CameraBrandControllerApiFp(this.configuration).getAllBrandsNamesAvailable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraBrandControllerApi
     */
    public getAllModels(options?: AxiosRequestConfig) {
        return CameraBrandControllerApiFp(this.configuration).getAllModels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brandName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraBrandControllerApi
     */
    public getAllModelsFromBrand(brandName: string, options?: AxiosRequestConfig) {
        return CameraBrandControllerApiFp(this.configuration).getAllModelsFromBrand(brandName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brandName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraBrandControllerApi
     */
    public getAllModelsFromBrandOptions(brandName: string, options?: AxiosRequestConfig) {
        return CameraBrandControllerApiFp(this.configuration).getAllModelsFromBrandOptions(brandName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraBrandControllerApi
     */
    public getAllModelsOptions(options?: AxiosRequestConfig) {
        return CameraBrandControllerApiFp(this.configuration).getAllModelsOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} elementTypeName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraBrandControllerApi
     */
    public getBrandNameFromModel(elementTypeName: string, options?: AxiosRequestConfig) {
        return CameraBrandControllerApiFp(this.configuration).getBrandNameFromModel(elementTypeName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CameraControllerApi - axios parameter creator
 * @export
 */
export const CameraControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addExcelSyncTask: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('addExcelSyncTask', 'file', file)
            const localVarPath = `/cameras/sync/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (file !== undefined) {
                localVarQueryParameter['file'] = file;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AiCameraUpsert} aiCameraUpsert 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewCamera: async (aiCameraUpsert: AiCameraUpsert, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aiCameraUpsert' is not null or undefined
            assertParamExists('addNewCamera', 'aiCameraUpsert', aiCameraUpsert)
            const localVarPath = `/cameras/full`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiCameraUpsert, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} camId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCamera: async (camId: number, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'camId' is not null or undefined
            assertParamExists('deleteCamera', 'camId', camId)
            const localVarPath = `/cameras/{camId}`
                .replace(`{${"camId"}}`, encodeURIComponent(String(camId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeSyncTask: async (hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('executeSyncTask', 'hash', hash)
            const localVarPath = `/cameras/sync/execute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (hash !== undefined) {
                localVarQueryParameter['hash'] = hash;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [areaId] 
         * @param {string} [brand] 
         * @param {'NO_AI' | 'FACE_RECOGNITION'} [aiCameraType] 
         * @param {string} [checkDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraExtendedList: async (areaId?: number, brand?: string, aiCameraType?: 'NO_AI' | 'FACE_RECOGNITION', checkDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/camerasEx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }

            if (brand !== undefined) {
                localVarQueryParameter['brand'] = brand;
            }

            if (aiCameraType !== undefined) {
                localVarQueryParameter['aiCameraType'] = aiCameraType;
            }

            if (checkDate !== undefined) {
                localVarQueryParameter['checkDate'] = (checkDate as any instanceof Date) ?
                    (checkDate as any).toISOString() :
                    checkDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} camId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraLessById: async (camId: number, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'camId' is not null or undefined
            assertParamExists('getCameraLessById', 'camId', camId)
            const localVarPath = `/cameras/less/{camId}`
                .replace(`{${"camId"}}`, encodeURIComponent(String(camId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraLessList: async (areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/less`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraLessPageList: async (pageable: Pageable, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getCameraLessPageList', 'pageable', pageable)
            const localVarPath = `/cameras/page/less`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraLessWithoutArea: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/less-no-area`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [areaId] 
         * @param {string} [brand] 
         * @param {'NO_AI' | 'FACE_RECOGNITION'} [aiCameraType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraList: async (areaId?: number, brand?: string, aiCameraType?: 'NO_AI' | 'FACE_RECOGNITION', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }

            if (brand !== undefined) {
                localVarQueryParameter['brand'] = brand;
            }

            if (aiCameraType !== undefined) {
                localVarQueryParameter['aiCameraType'] = aiCameraType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [areaId] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraUploadTemplate: async (areaId?: number, language?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/template/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} camId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraUpsertById: async (camId: number, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'camId' is not null or undefined
            assertParamExists('getCameraUpsertById', 'camId', camId)
            const localVarPath = `/cameras/full/{camId}`
                .replace(`{${"camId"}}`, encodeURIComponent(String(camId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraUpsertList1: async (areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/full`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionParams: async (elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('getConnectionParams', 'elementId', elementId)
            const localVarPath = `/cameras/{elementId}/connection-params`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExcelSync: async (language?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/sync/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {boolean} [unicast] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRtspUrl: async (cameraId: number, unicast?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getRtspUrl', 'cameraId', cameraId)
            const localVarPath = `/cameras/{cameraId}/onvif/url/rtsp`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (unicast !== undefined) {
                localVarQueryParameter['unicast'] = unicast;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TestCamera} testCamera 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRtspUrlTest: async (testCamera: TestCamera, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testCamera' is not null or undefined
            assertParamExists('getRtspUrlTest', 'testCamera', testCamera)
            const localVarPath = `/cameras/onvif/test/url/rtsp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testCamera, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {number} [width] 
         * @param {number} [height] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotB64: async (cameraId: number, width?: number, height?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getSnapshotB64', 'cameraId', cameraId)
            const localVarPath = `/cameras/{cameraId}/onvif/snapshot/b64`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {number} [width] 
         * @param {number} [height] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotByteArray: async (cameraId: number, width?: number, height?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getSnapshotByteArray', 'cameraId', cameraId)
            const localVarPath = `/cameras/{cameraId}/onvif/bytes/snapshot`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TestCamera} testCamera 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotTest: async (testCamera: TestCamera, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testCamera' is not null or undefined
            assertParamExists('getSnapshotTest', 'testCamera', testCamera)
            const localVarPath = `/cameras/onvif/test/snapshot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testCamera, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TestCamera} testCamera 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotTestB64: async (testCamera: TestCamera, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testCamera' is not null or undefined
            assertParamExists('getSnapshotTestB64', 'testCamera', testCamera)
            const localVarPath = `/cameras/onvif/test/snapshot/b64`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testCamera, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [url] 
         * @param {number} [cameraId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotTestRtspB64: async (url?: string, cameraId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/onvif/test/snapshot/rtsp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }

            if (cameraId !== undefined) {
                localVarQueryParameter['cameraId'] = cameraId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotUrl: async (cameraId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getSnapshotUrl', 'cameraId', cameraId)
            const localVarPath = `/cameras/{cameraId}/onvif/url/snapshot`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TestCamera} testCamera 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStreamsList: async (testCamera: TestCamera, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testCamera' is not null or undefined
            assertParamExists('getStreamsList', 'testCamera', testCamera)
            const localVarPath = `/cameras/onvif/test/profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testCamera, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetCamera: async (elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('resetCamera', 'elementId', elementId)
            const localVarPath = `/cameras/{elementId}/reboot`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} areaId 
         * @param {Array<AiCameraFilter>} aiCameraFilter 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCameras: async (areaId: number, aiCameraFilter: Array<AiCameraFilter>, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('searchCameras', 'areaId', areaId)
            // verify required parameter 'aiCameraFilter' is not null or undefined
            assertParamExists('searchCameras', 'aiCameraFilter', aiCameraFilter)
            const localVarPath = `/cameras/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiCameraFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} panOffset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPanOffsetPosition: async (elementId: number, panOffset: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('setPanOffsetPosition', 'elementId', elementId)
            // verify required parameter 'panOffset' is not null or undefined
            assertParamExists('setPanOffsetPosition', 'panOffset', panOffset)
            const localVarPath = `/cameras/{elementId}/position/pan`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (panOffset !== undefined) {
                localVarQueryParameter['panOffset'] = panOffset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} camId 
         * @param {AiCameraUpsert} aiCameraUpsert 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCameraUpsertById: async (camId: number, aiCameraUpsert: AiCameraUpsert, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'camId' is not null or undefined
            assertParamExists('updateCameraUpsertById', 'camId', camId)
            // verify required parameter 'aiCameraUpsert' is not null or undefined
            assertParamExists('updateCameraUpsertById', 'aiCameraUpsert', aiCameraUpsert)
            const localVarPath = `/cameras/full/{camId}`
                .replace(`{${"camId"}}`, encodeURIComponent(String(camId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiCameraUpsert, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CameraControllerApi - functional programming interface
 * @export
 */
export const CameraControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CameraControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addExcelSyncTask(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExcelSyncTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addExcelSyncTask(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AiCameraUpsert} aiCameraUpsert 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNewCamera(aiCameraUpsert: AiCameraUpsert, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiCameraUpsert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNewCamera(aiCameraUpsert, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} camId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCamera(camId: number, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCamera(camId, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeSyncTask(hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executeSyncTask(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [areaId] 
         * @param {string} [brand] 
         * @param {'NO_AI' | 'FACE_RECOGNITION'} [aiCameraType] 
         * @param {string} [checkDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraExtendedList(areaId?: number, brand?: string, aiCameraType?: 'NO_AI' | 'FACE_RECOGNITION', checkDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiCameraEx>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraExtendedList(areaId, brand, aiCameraType, checkDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} camId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraLessById(camId: number, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiCameraLess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraLessById(camId, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraLessList(areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiCameraLess>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraLessList(areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraLessPageList(pageable: Pageable, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiCameraLess>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraLessPageList(pageable, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraLessWithoutArea(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiCameraLess>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraLessWithoutArea(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [areaId] 
         * @param {string} [brand] 
         * @param {'NO_AI' | 'FACE_RECOGNITION'} [aiCameraType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraList(areaId?: number, brand?: string, aiCameraType?: 'NO_AI' | 'FACE_RECOGNITION', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiCamera>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraList(areaId, brand, aiCameraType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [areaId] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraUploadTemplate(areaId?: number, language?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraUploadTemplate(areaId, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} camId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraUpsertById(camId: number, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiCameraUpsert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraUpsertById(camId, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraUpsertList1(areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiCameraUpsert>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraUpsertList1(areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectionParams(elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionParams>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectionParams(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExcelSync(language?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExcelSync(language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {boolean} [unicast] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRtspUrl(cameraId: number, unicast?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRtspUrl(cameraId, unicast, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TestCamera} testCamera 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRtspUrlTest(testCamera: TestCamera, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRtspUrlTest(testCamera, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {number} [width] 
         * @param {number} [height] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnapshotB64(cameraId: number, width?: number, height?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnapshotB64(cameraId, width, height, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {number} [width] 
         * @param {number} [height] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnapshotByteArray(cameraId: number, width?: number, height?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnapshotByteArray(cameraId, width, height, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TestCamera} testCamera 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnapshotTest(testCamera: TestCamera, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnapshotTest(testCamera, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TestCamera} testCamera 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnapshotTestB64(testCamera: TestCamera, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnapshotTestB64(testCamera, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [url] 
         * @param {number} [cameraId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnapshotTestRtspB64(url?: string, cameraId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnapshotTestRtspB64(url, cameraId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnapshotUrl(cameraId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnapshotUrl(cameraId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TestCamera} testCamera 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStreamsList(testCamera: TestCamera, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiProfile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStreamsList(testCamera, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetCamera(elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetCamera(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} areaId 
         * @param {Array<AiCameraFilter>} aiCameraFilter 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCameras(areaId: number, aiCameraFilter: Array<AiCameraFilter>, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAiCameraUpsert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCameras(areaId, aiCameraFilter, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} panOffset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPanOffsetPosition(elementId: number, panOffset: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPanOffsetPosition(elementId, panOffset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} camId 
         * @param {AiCameraUpsert} aiCameraUpsert 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCameraUpsertById(camId: number, aiCameraUpsert: AiCameraUpsert, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCameraUpsertById(camId, aiCameraUpsert, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CameraControllerApi - factory interface
 * @export
 */
export const CameraControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CameraControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addExcelSyncTask(file: File, options?: any): AxiosPromise<ExcelSyncTask> {
            return localVarFp.addExcelSyncTask(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AiCameraUpsert} aiCameraUpsert 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewCamera(aiCameraUpsert: AiCameraUpsert, areaId?: number, options?: any): AxiosPromise<AiCameraUpsert> {
            return localVarFp.addNewCamera(aiCameraUpsert, areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} camId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCamera(camId: number, areaId?: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteCamera(camId, areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeSyncTask(hash: string, options?: any): AxiosPromise<void> {
            return localVarFp.executeSyncTask(hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [areaId] 
         * @param {string} [brand] 
         * @param {'NO_AI' | 'FACE_RECOGNITION'} [aiCameraType] 
         * @param {string} [checkDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraExtendedList(areaId?: number, brand?: string, aiCameraType?: 'NO_AI' | 'FACE_RECOGNITION', checkDate?: string, options?: any): AxiosPromise<Array<AiCameraEx>> {
            return localVarFp.getCameraExtendedList(areaId, brand, aiCameraType, checkDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} camId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraLessById(camId: number, areaId?: number, options?: any): AxiosPromise<AiCameraLess> {
            return localVarFp.getCameraLessById(camId, areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraLessList(areaId?: number, options?: any): AxiosPromise<Array<AiCameraLess>> {
            return localVarFp.getCameraLessList(areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraLessPageList(pageable: Pageable, areaId?: number, options?: any): AxiosPromise<Array<AiCameraLess>> {
            return localVarFp.getCameraLessPageList(pageable, areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraLessWithoutArea(options?: any): AxiosPromise<Array<AiCameraLess>> {
            return localVarFp.getCameraLessWithoutArea(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [areaId] 
         * @param {string} [brand] 
         * @param {'NO_AI' | 'FACE_RECOGNITION'} [aiCameraType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraList(areaId?: number, brand?: string, aiCameraType?: 'NO_AI' | 'FACE_RECOGNITION', options?: any): AxiosPromise<Array<AiCamera>> {
            return localVarFp.getCameraList(areaId, brand, aiCameraType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [areaId] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraUploadTemplate(areaId?: number, language?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getCameraUploadTemplate(areaId, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} camId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraUpsertById(camId: number, areaId?: number, options?: any): AxiosPromise<AiCameraUpsert> {
            return localVarFp.getCameraUpsertById(camId, areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraUpsertList1(areaId?: number, options?: any): AxiosPromise<Array<AiCameraUpsert>> {
            return localVarFp.getCameraUpsertList1(areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionParams(elementId: number, options?: any): AxiosPromise<ConnectionParams> {
            return localVarFp.getConnectionParams(elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExcelSync(language?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getExcelSync(language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {boolean} [unicast] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRtspUrl(cameraId: number, unicast?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.getRtspUrl(cameraId, unicast, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TestCamera} testCamera 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRtspUrlTest(testCamera: TestCamera, areaId?: number, options?: any): AxiosPromise<string> {
            return localVarFp.getRtspUrlTest(testCamera, areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {number} [width] 
         * @param {number} [height] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotB64(cameraId: number, width?: number, height?: number, options?: any): AxiosPromise<string> {
            return localVarFp.getSnapshotB64(cameraId, width, height, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {number} [width] 
         * @param {number} [height] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotByteArray(cameraId: number, width?: number, height?: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getSnapshotByteArray(cameraId, width, height, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TestCamera} testCamera 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotTest(testCamera: TestCamera, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getSnapshotTest(testCamera, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TestCamera} testCamera 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotTestB64(testCamera: TestCamera, areaId?: number, options?: any): AxiosPromise<string> {
            return localVarFp.getSnapshotTestB64(testCamera, areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [url] 
         * @param {number} [cameraId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotTestRtspB64(url?: string, cameraId?: number, options?: any): AxiosPromise<string> {
            return localVarFp.getSnapshotTestRtspB64(url, cameraId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotUrl(cameraId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getSnapshotUrl(cameraId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TestCamera} testCamera 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStreamsList(testCamera: TestCamera, areaId?: number, options?: any): AxiosPromise<Array<ApiProfile>> {
            return localVarFp.getStreamsList(testCamera, areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetCamera(elementId: number, options?: any): AxiosPromise<string> {
            return localVarFp.resetCamera(elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} areaId 
         * @param {Array<AiCameraFilter>} aiCameraFilter 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCameras(areaId: number, aiCameraFilter: Array<AiCameraFilter>, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageAiCameraUpsert> {
            return localVarFp.searchCameras(areaId, aiCameraFilter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} panOffset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPanOffsetPosition(elementId: number, panOffset: number, options?: any): AxiosPromise<void> {
            return localVarFp.setPanOffsetPosition(elementId, panOffset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} camId 
         * @param {AiCameraUpsert} aiCameraUpsert 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCameraUpsertById(camId: number, aiCameraUpsert: AiCameraUpsert, areaId?: number, options?: any): AxiosPromise<string> {
            return localVarFp.updateCameraUpsertById(camId, aiCameraUpsert, areaId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CameraControllerApi - object-oriented interface
 * @export
 * @class CameraControllerApi
 * @extends {BaseAPI}
 */
export class CameraControllerApi extends BaseAPI {
    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public addExcelSyncTask(file: File, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).addExcelSyncTask(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AiCameraUpsert} aiCameraUpsert 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public addNewCamera(aiCameraUpsert: AiCameraUpsert, areaId?: number, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).addNewCamera(aiCameraUpsert, areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} camId 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public deleteCamera(camId: number, areaId?: number, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).deleteCamera(camId, areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public executeSyncTask(hash: string, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).executeSyncTask(hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [areaId] 
     * @param {string} [brand] 
     * @param {'NO_AI' | 'FACE_RECOGNITION'} [aiCameraType] 
     * @param {string} [checkDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getCameraExtendedList(areaId?: number, brand?: string, aiCameraType?: 'NO_AI' | 'FACE_RECOGNITION', checkDate?: string, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getCameraExtendedList(areaId, brand, aiCameraType, checkDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} camId 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getCameraLessById(camId: number, areaId?: number, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getCameraLessById(camId, areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getCameraLessList(areaId?: number, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getCameraLessList(areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getCameraLessPageList(pageable: Pageable, areaId?: number, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getCameraLessPageList(pageable, areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getCameraLessWithoutArea(options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getCameraLessWithoutArea(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [areaId] 
     * @param {string} [brand] 
     * @param {'NO_AI' | 'FACE_RECOGNITION'} [aiCameraType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getCameraList(areaId?: number, brand?: string, aiCameraType?: 'NO_AI' | 'FACE_RECOGNITION', options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getCameraList(areaId, brand, aiCameraType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [areaId] 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getCameraUploadTemplate(areaId?: number, language?: string, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getCameraUploadTemplate(areaId, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} camId 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getCameraUpsertById(camId: number, areaId?: number, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getCameraUpsertById(camId, areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getCameraUpsertList1(areaId?: number, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getCameraUpsertList1(areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getConnectionParams(elementId: number, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getConnectionParams(elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getExcelSync(language?: string, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getExcelSync(language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {boolean} [unicast] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getRtspUrl(cameraId: number, unicast?: boolean, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getRtspUrl(cameraId, unicast, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TestCamera} testCamera 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getRtspUrlTest(testCamera: TestCamera, areaId?: number, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getRtspUrlTest(testCamera, areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {number} [width] 
     * @param {number} [height] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getSnapshotB64(cameraId: number, width?: number, height?: number, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getSnapshotB64(cameraId, width, height, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {number} [width] 
     * @param {number} [height] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getSnapshotByteArray(cameraId: number, width?: number, height?: number, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getSnapshotByteArray(cameraId, width, height, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TestCamera} testCamera 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getSnapshotTest(testCamera: TestCamera, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getSnapshotTest(testCamera, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TestCamera} testCamera 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getSnapshotTestB64(testCamera: TestCamera, areaId?: number, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getSnapshotTestB64(testCamera, areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [url] 
     * @param {number} [cameraId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getSnapshotTestRtspB64(url?: string, cameraId?: number, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getSnapshotTestRtspB64(url, cameraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getSnapshotUrl(cameraId: number, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getSnapshotUrl(cameraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TestCamera} testCamera 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public getStreamsList(testCamera: TestCamera, areaId?: number, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).getStreamsList(testCamera, areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public resetCamera(elementId: number, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).resetCamera(elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} areaId 
     * @param {Array<AiCameraFilter>} aiCameraFilter 
     * @param {number} [page] Page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public searchCameras(areaId: number, aiCameraFilter: Array<AiCameraFilter>, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).searchCameras(areaId, aiCameraFilter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {number} panOffset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public setPanOffsetPosition(elementId: number, panOffset: number, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).setPanOffsetPosition(elementId, panOffset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} camId 
     * @param {AiCameraUpsert} aiCameraUpsert 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraControllerApi
     */
    public updateCameraUpsertById(camId: number, aiCameraUpsert: AiCameraUpsert, areaId?: number, options?: AxiosRequestConfig) {
        return CameraControllerApiFp(this.configuration).updateCameraUpsertById(camId, aiCameraUpsert, areaId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CameraImagingSettingsControllerApi - axios parameter creator
 * @export
 */
export const CameraImagingSettingsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementcameraImagingSettingsController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteByIdElementcameraImagingSettingsController', 'id', id)
            const localVarPath = `/cameras/onvif/imaging/settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllcameraImagingSettingsController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/onvif/imaging/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentImagingSettingsOfElementId: async (elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('getCurrentImagingSettingsOfElementId', 'elementId', elementId)
            const localVarPath = `/cameras/onvif/imaging/settings/{elementId}/current`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagingOptionsOfElementId: async (elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('getImagingOptionsOfElementId', 'elementId', elementId)
            const localVarPath = `/cameras/onvif/imaging/settings/{elementId}/options`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementcameraImagingSettingsController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneElementcameraImagingSettingsController', 'id', id)
            const localVarPath = `/cameras/onvif/imaging/settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectcameraImagingSettingsController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/onvif/imaging/settings/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ImagingSettingsDTO} imagingSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementcameraImagingSettingsController: async (imagingSettingsDTO: ImagingSettingsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imagingSettingsDTO' is not null or undefined
            assertParamExists('insertElementcameraImagingSettingsController', 'imagingSettingsDTO', imagingSettingsDTO)
            const localVarPath = `/cameras/onvif/imaging/settings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imagingSettingsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} imagingSettingsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCurrentImagingSettingsIdSavedOfElementId: async (elementId: number, imagingSettingsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('setCurrentImagingSettingsIdSavedOfElementId', 'elementId', elementId)
            // verify required parameter 'imagingSettingsId' is not null or undefined
            assertParamExists('setCurrentImagingSettingsIdSavedOfElementId', 'imagingSettingsId', imagingSettingsId)
            const localVarPath = `/cameras/onvif/imaging/settings/{elementId}/current/{imagingSettingsId}`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)))
                .replace(`{${"imagingSettingsId"}}`, encodeURIComponent(String(imagingSettingsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {SetCurrentImagingSettingsOfElementIdRequest} setCurrentImagingSettingsOfElementIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCurrentImagingSettingsOfElementId: async (elementId: number, setCurrentImagingSettingsOfElementIdRequest: SetCurrentImagingSettingsOfElementIdRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('setCurrentImagingSettingsOfElementId', 'elementId', elementId)
            // verify required parameter 'setCurrentImagingSettingsOfElementIdRequest' is not null or undefined
            assertParamExists('setCurrentImagingSettingsOfElementId', 'setCurrentImagingSettingsOfElementIdRequest', setCurrentImagingSettingsOfElementIdRequest)
            const localVarPath = `/cameras/onvif/imaging/settings/{elementId}/current`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setCurrentImagingSettingsOfElementIdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {string} imagingSettingsName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCurrentImagingSettingsOfElementId1: async (elementId: number, imagingSettingsName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('setCurrentImagingSettingsOfElementId1', 'elementId', elementId)
            // verify required parameter 'imagingSettingsName' is not null or undefined
            assertParamExists('setCurrentImagingSettingsOfElementId1', 'imagingSettingsName', imagingSettingsName)
            const localVarPath = `/cameras/onvif/imaging/settings/{elementId}/current/save`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (imagingSettingsName !== undefined) {
                localVarQueryParameter['imagingSettingsName'] = imagingSettingsName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ImagingSettingsDTO} imagingSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementcameraImagingSettingsController: async (id: number, imagingSettingsDTO: ImagingSettingsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateElementcameraImagingSettingsController', 'id', id)
            // verify required parameter 'imagingSettingsDTO' is not null or undefined
            assertParamExists('updateElementcameraImagingSettingsController', 'imagingSettingsDTO', imagingSettingsDTO)
            const localVarPath = `/cameras/onvif/imaging/settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imagingSettingsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CameraImagingSettingsControllerApi - functional programming interface
 * @export
 */
export const CameraImagingSettingsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CameraImagingSettingsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElementcameraImagingSettingsController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElementcameraImagingSettingsController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllcameraImagingSettingsController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImagingSettingsDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllcameraImagingSettingsController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentImagingSettingsOfElementId(elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagingSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentImagingSettingsOfElementId(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImagingOptionsOfElementId(elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetImagingOptionsOfElementId200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImagingOptionsOfElementId(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneElementcameraImagingSettingsController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagingSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneElementcameraImagingSettingsController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectcameraImagingSettingsController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectcameraImagingSettingsController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ImagingSettingsDTO} imagingSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertElementcameraImagingSettingsController(imagingSettingsDTO: ImagingSettingsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagingSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertElementcameraImagingSettingsController(imagingSettingsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} imagingSettingsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCurrentImagingSettingsIdSavedOfElementId(elementId: number, imagingSettingsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagingSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCurrentImagingSettingsIdSavedOfElementId(elementId, imagingSettingsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {SetCurrentImagingSettingsOfElementIdRequest} setCurrentImagingSettingsOfElementIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCurrentImagingSettingsOfElementId(elementId: number, setCurrentImagingSettingsOfElementIdRequest: SetCurrentImagingSettingsOfElementIdRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagingSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCurrentImagingSettingsOfElementId(elementId, setCurrentImagingSettingsOfElementIdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {string} imagingSettingsName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCurrentImagingSettingsOfElementId1(elementId: number, imagingSettingsName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagingSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCurrentImagingSettingsOfElementId1(elementId, imagingSettingsName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ImagingSettingsDTO} imagingSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateElementcameraImagingSettingsController(id: number, imagingSettingsDTO: ImagingSettingsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagingSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateElementcameraImagingSettingsController(id, imagingSettingsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CameraImagingSettingsControllerApi - factory interface
 * @export
 */
export const CameraImagingSettingsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CameraImagingSettingsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementcameraImagingSettingsController(id: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElementcameraImagingSettingsController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllcameraImagingSettingsController(options?: any): AxiosPromise<Array<ImagingSettingsDTO>> {
            return localVarFp.getAllcameraImagingSettingsController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentImagingSettingsOfElementId(elementId: number, options?: any): AxiosPromise<ImagingSettingsDTO> {
            return localVarFp.getCurrentImagingSettingsOfElementId(elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagingOptionsOfElementId(elementId: number, options?: any): AxiosPromise<GetImagingOptionsOfElementId200Response> {
            return localVarFp.getImagingOptionsOfElementId(elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementcameraImagingSettingsController(id: number, options?: any): AxiosPromise<ImagingSettingsDTO> {
            return localVarFp.getOneElementcameraImagingSettingsController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectcameraImagingSettingsController(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getSelectcameraImagingSettingsController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ImagingSettingsDTO} imagingSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementcameraImagingSettingsController(imagingSettingsDTO: ImagingSettingsDTO, options?: any): AxiosPromise<ImagingSettingsDTO> {
            return localVarFp.insertElementcameraImagingSettingsController(imagingSettingsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} imagingSettingsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCurrentImagingSettingsIdSavedOfElementId(elementId: number, imagingSettingsId: number, options?: any): AxiosPromise<ImagingSettingsDTO> {
            return localVarFp.setCurrentImagingSettingsIdSavedOfElementId(elementId, imagingSettingsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {SetCurrentImagingSettingsOfElementIdRequest} setCurrentImagingSettingsOfElementIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCurrentImagingSettingsOfElementId(elementId: number, setCurrentImagingSettingsOfElementIdRequest: SetCurrentImagingSettingsOfElementIdRequest, options?: any): AxiosPromise<ImagingSettingsDTO> {
            return localVarFp.setCurrentImagingSettingsOfElementId(elementId, setCurrentImagingSettingsOfElementIdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {string} imagingSettingsName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCurrentImagingSettingsOfElementId1(elementId: number, imagingSettingsName: string, options?: any): AxiosPromise<ImagingSettingsDTO> {
            return localVarFp.setCurrentImagingSettingsOfElementId1(elementId, imagingSettingsName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ImagingSettingsDTO} imagingSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementcameraImagingSettingsController(id: number, imagingSettingsDTO: ImagingSettingsDTO, options?: any): AxiosPromise<ImagingSettingsDTO> {
            return localVarFp.updateElementcameraImagingSettingsController(id, imagingSettingsDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CameraImagingSettingsControllerApi - object-oriented interface
 * @export
 * @class CameraImagingSettingsControllerApi
 * @extends {BaseAPI}
 */
export class CameraImagingSettingsControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraImagingSettingsControllerApi
     */
    public deleteByIdElementcameraImagingSettingsController(id: number, options?: AxiosRequestConfig) {
        return CameraImagingSettingsControllerApiFp(this.configuration).deleteByIdElementcameraImagingSettingsController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraImagingSettingsControllerApi
     */
    public getAllcameraImagingSettingsController(options?: AxiosRequestConfig) {
        return CameraImagingSettingsControllerApiFp(this.configuration).getAllcameraImagingSettingsController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraImagingSettingsControllerApi
     */
    public getCurrentImagingSettingsOfElementId(elementId: number, options?: AxiosRequestConfig) {
        return CameraImagingSettingsControllerApiFp(this.configuration).getCurrentImagingSettingsOfElementId(elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraImagingSettingsControllerApi
     */
    public getImagingOptionsOfElementId(elementId: number, options?: AxiosRequestConfig) {
        return CameraImagingSettingsControllerApiFp(this.configuration).getImagingOptionsOfElementId(elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraImagingSettingsControllerApi
     */
    public getOneElementcameraImagingSettingsController(id: number, options?: AxiosRequestConfig) {
        return CameraImagingSettingsControllerApiFp(this.configuration).getOneElementcameraImagingSettingsController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraImagingSettingsControllerApi
     */
    public getSelectcameraImagingSettingsController(options?: AxiosRequestConfig) {
        return CameraImagingSettingsControllerApiFp(this.configuration).getSelectcameraImagingSettingsController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImagingSettingsDTO} imagingSettingsDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraImagingSettingsControllerApi
     */
    public insertElementcameraImagingSettingsController(imagingSettingsDTO: ImagingSettingsDTO, options?: AxiosRequestConfig) {
        return CameraImagingSettingsControllerApiFp(this.configuration).insertElementcameraImagingSettingsController(imagingSettingsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {number} imagingSettingsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraImagingSettingsControllerApi
     */
    public setCurrentImagingSettingsIdSavedOfElementId(elementId: number, imagingSettingsId: number, options?: AxiosRequestConfig) {
        return CameraImagingSettingsControllerApiFp(this.configuration).setCurrentImagingSettingsIdSavedOfElementId(elementId, imagingSettingsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {SetCurrentImagingSettingsOfElementIdRequest} setCurrentImagingSettingsOfElementIdRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraImagingSettingsControllerApi
     */
    public setCurrentImagingSettingsOfElementId(elementId: number, setCurrentImagingSettingsOfElementIdRequest: SetCurrentImagingSettingsOfElementIdRequest, options?: AxiosRequestConfig) {
        return CameraImagingSettingsControllerApiFp(this.configuration).setCurrentImagingSettingsOfElementId(elementId, setCurrentImagingSettingsOfElementIdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {string} imagingSettingsName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraImagingSettingsControllerApi
     */
    public setCurrentImagingSettingsOfElementId1(elementId: number, imagingSettingsName: string, options?: AxiosRequestConfig) {
        return CameraImagingSettingsControllerApiFp(this.configuration).setCurrentImagingSettingsOfElementId1(elementId, imagingSettingsName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ImagingSettingsDTO} imagingSettingsDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraImagingSettingsControllerApi
     */
    public updateElementcameraImagingSettingsController(id: number, imagingSettingsDTO: ImagingSettingsDTO, options?: AxiosRequestConfig) {
        return CameraImagingSettingsControllerApiFp(this.configuration).updateElementcameraImagingSettingsController(id, imagingSettingsDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CameraPresetControllerApi - axios parameter creator
 * @export
 */
export const CameraPresetControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} elementId 
         * @param {number} presetNum 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePreset: async (elementId: number, presetNum: number, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('deletePreset', 'elementId', elementId)
            // verify required parameter 'presetNum' is not null or undefined
            assertParamExists('deletePreset', 'presetNum', presetNum)
            const localVarPath = `/cameras/{elementId}/presets/{presetNum}`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)))
                .replace(`{${"presetNum"}}`, encodeURIComponent(String(presetNum)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {string} presetName 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePresetByName: async (elementId: number, presetName: string, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('deletePresetByName', 'elementId', elementId)
            // verify required parameter 'presetName' is not null or undefined
            assertParamExists('deletePresetByName', 'presetName', presetName)
            const localVarPath = `/cameras/{elementId}/presets`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }

            if (presetName !== undefined) {
                localVarQueryParameter['presetName'] = presetName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPresetList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/presets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPreset: async (elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('getCurrentPreset', 'elementId', elementId)
            const localVarPath = `/cameras/{elementId}/onvif/presets/current`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPresetsByCamera: async (elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('getCurrentPresetsByCamera', 'elementId', elementId)
            const localVarPath = `/cameras/{elementId}/currentPreset`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} presetNum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreset: async (elementId: number, presetNum: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('getPreset', 'elementId', elementId)
            // verify required parameter 'presetNum' is not null or undefined
            assertParamExists('getPreset', 'presetNum', presetNum)
            const localVarPath = `/cameras/{elementId}/presets/{presetNum}`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)))
                .replace(`{${"presetNum"}}`, encodeURIComponent(String(presetNum)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} presetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetImageB64: async (elementId: number, presetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('getPresetImageB64', 'elementId', elementId)
            // verify required parameter 'presetId' is not null or undefined
            assertParamExists('getPresetImageB64', 'presetId', presetId)
            const localVarPath = `/cameras/{elementId}/onvif/presets/{presetId}/getImageB64`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)))
                .replace(`{${"presetId"}}`, encodeURIComponent(String(presetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetListByCamera: async (elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('getPresetListByCamera', 'elementId', elementId)
            const localVarPath = `/cameras/{elementId}/presets`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresets: async (elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('getPresets', 'elementId', elementId)
            const localVarPath = `/cameras/{elementId}/onvif/presets`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} presetId 
         * @param {number} speedValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        goToPreset: async (presetId: number, speedValue: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'presetId' is not null or undefined
            assertParamExists('goToPreset', 'presetId', presetId)
            // verify required parameter 'speedValue' is not null or undefined
            assertParamExists('goToPreset', 'speedValue', speedValue)
            const localVarPath = `/cameras/onvif/presets/{presetId}/go`
                .replace(`{${"presetId"}}`, encodeURIComponent(String(presetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (speedValue !== undefined) {
                localVarQueryParameter['speedValue'] = speedValue;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {string} presetName 
         * @param {number} speedValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        goToPresetByName: async (elementId: number, presetName: string, speedValue: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('goToPresetByName', 'elementId', elementId)
            // verify required parameter 'presetName' is not null or undefined
            assertParamExists('goToPresetByName', 'presetName', presetName)
            // verify required parameter 'speedValue' is not null or undefined
            assertParamExists('goToPresetByName', 'speedValue', speedValue)
            const localVarPath = `/cameras/{elementId}/vdg/presets/{presetName}/go`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)))
                .replace(`{${"presetName"}}`, encodeURIComponent(String(presetName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (speedValue !== undefined) {
                localVarQueryParameter['speedValue'] = speedValue;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} presetNum 
         * @param {number} speedValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        goToPresetId: async (elementId: number, presetNum: number, speedValue: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('goToPresetId', 'elementId', elementId)
            // verify required parameter 'presetNum' is not null or undefined
            assertParamExists('goToPresetId', 'presetNum', presetNum)
            // verify required parameter 'speedValue' is not null or undefined
            assertParamExists('goToPresetId', 'speedValue', speedValue)
            const localVarPath = `/cameras/{elementId}/onvif/presets/{presetNum}/go`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)))
                .replace(`{${"presetNum"}}`, encodeURIComponent(String(presetNum)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (speedValue !== undefined) {
                localVarQueryParameter['speedValue'] = speedValue;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshPresetsFromCamera: async (elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('refreshPresetsFromCamera', 'elementId', elementId)
            const localVarPath = `/cameras/{elementId}/presets/refresh`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {Preset} preset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePreset: async (cameraId: number, preset: Preset, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('savePreset', 'cameraId', cameraId)
            // verify required parameter 'preset' is not null or undefined
            assertParamExists('savePreset', 'preset', preset)
            const localVarPath = `/cameras/{cameraId}/presets`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(preset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} presetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePresetImage: async (elementId: number, presetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('updatePresetImage', 'elementId', elementId)
            // verify required parameter 'presetId' is not null or undefined
            assertParamExists('updatePresetImage', 'presetId', presetId)
            const localVarPath = `/cameras/{elementId}/onvif/presets/{presetId}/updateImage`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)))
                .replace(`{${"presetId"}}`, encodeURIComponent(String(presetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CameraPresetControllerApi - functional programming interface
 * @export
 */
export const CameraPresetControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CameraPresetControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} elementId 
         * @param {number} presetNum 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePreset(elementId: number, presetNum: number, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePreset(elementId, presetNum, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {string} presetName 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePresetByName(elementId: number, presetName: string, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePresetByName(elementId, presetName, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPresetList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Preset>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPresetList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentPreset(elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentPreset(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentPresetsByCamera(elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentPresetsByCamera(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} presetNum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreset(elementId: number, presetNum: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Preset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreset(elementId, presetNum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} presetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPresetImageB64(elementId: number, presetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPresetImageB64(elementId, presetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPresetListByCamera(elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Preset>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPresetListByCamera(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPresets(elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPresets(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} presetId 
         * @param {number} speedValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async goToPreset(presetId: number, speedValue: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.goToPreset(presetId, speedValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {string} presetName 
         * @param {number} speedValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async goToPresetByName(elementId: number, presetName: string, speedValue: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.goToPresetByName(elementId, presetName, speedValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} presetNum 
         * @param {number} speedValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async goToPresetId(elementId: number, presetNum: number, speedValue: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.goToPresetId(elementId, presetNum, speedValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshPresetsFromCamera(elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshPresetsFromCamera(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {Preset} preset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePreset(cameraId: number, preset: Preset, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Preset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePreset(cameraId, preset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} presetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePresetImage(elementId: number, presetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePresetImage(elementId, presetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CameraPresetControllerApi - factory interface
 * @export
 */
export const CameraPresetControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CameraPresetControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} elementId 
         * @param {number} presetNum 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePreset(elementId: number, presetNum: number, areaId?: number, options?: any): AxiosPromise<string> {
            return localVarFp.deletePreset(elementId, presetNum, areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {string} presetName 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePresetByName(elementId: number, presetName: string, areaId?: number, options?: any): AxiosPromise<string> {
            return localVarFp.deletePresetByName(elementId, presetName, areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPresetList(options?: any): AxiosPromise<Array<Preset>> {
            return localVarFp.getAllPresetList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPreset(elementId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getCurrentPreset(elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPresetsByCamera(elementId: number, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.getCurrentPresetsByCamera(elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} presetNum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreset(elementId: number, presetNum: number, options?: any): AxiosPromise<Preset> {
            return localVarFp.getPreset(elementId, presetNum, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} presetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetImageB64(elementId: number, presetId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getPresetImageB64(elementId, presetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetListByCamera(elementId: number, options?: any): AxiosPromise<Array<Preset>> {
            return localVarFp.getPresetListByCamera(elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresets(elementId: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getPresets(elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} presetId 
         * @param {number} speedValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        goToPreset(presetId: number, speedValue: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.goToPreset(presetId, speedValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {string} presetName 
         * @param {number} speedValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        goToPresetByName(elementId: number, presetName: string, speedValue: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.goToPresetByName(elementId, presetName, speedValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} presetNum 
         * @param {number} speedValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        goToPresetId(elementId: number, presetNum: number, speedValue: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.goToPresetId(elementId, presetNum, speedValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshPresetsFromCamera(elementId: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.refreshPresetsFromCamera(elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {Preset} preset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePreset(cameraId: number, preset: Preset, options?: any): AxiosPromise<Preset> {
            return localVarFp.savePreset(cameraId, preset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} presetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePresetImage(elementId: number, presetId: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.updatePresetImage(elementId, presetId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CameraPresetControllerApi - object-oriented interface
 * @export
 * @class CameraPresetControllerApi
 * @extends {BaseAPI}
 */
export class CameraPresetControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} elementId 
     * @param {number} presetNum 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPresetControllerApi
     */
    public deletePreset(elementId: number, presetNum: number, areaId?: number, options?: AxiosRequestConfig) {
        return CameraPresetControllerApiFp(this.configuration).deletePreset(elementId, presetNum, areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {string} presetName 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPresetControllerApi
     */
    public deletePresetByName(elementId: number, presetName: string, areaId?: number, options?: AxiosRequestConfig) {
        return CameraPresetControllerApiFp(this.configuration).deletePresetByName(elementId, presetName, areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPresetControllerApi
     */
    public getAllPresetList(options?: AxiosRequestConfig) {
        return CameraPresetControllerApiFp(this.configuration).getAllPresetList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPresetControllerApi
     */
    public getCurrentPreset(elementId: number, options?: AxiosRequestConfig) {
        return CameraPresetControllerApiFp(this.configuration).getCurrentPreset(elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPresetControllerApi
     */
    public getCurrentPresetsByCamera(elementId: number, options?: AxiosRequestConfig) {
        return CameraPresetControllerApiFp(this.configuration).getCurrentPresetsByCamera(elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {number} presetNum 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPresetControllerApi
     */
    public getPreset(elementId: number, presetNum: number, options?: AxiosRequestConfig) {
        return CameraPresetControllerApiFp(this.configuration).getPreset(elementId, presetNum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {number} presetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPresetControllerApi
     */
    public getPresetImageB64(elementId: number, presetId: number, options?: AxiosRequestConfig) {
        return CameraPresetControllerApiFp(this.configuration).getPresetImageB64(elementId, presetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPresetControllerApi
     */
    public getPresetListByCamera(elementId: number, options?: AxiosRequestConfig) {
        return CameraPresetControllerApiFp(this.configuration).getPresetListByCamera(elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPresetControllerApi
     */
    public getPresets(elementId: number, options?: AxiosRequestConfig) {
        return CameraPresetControllerApiFp(this.configuration).getPresets(elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} presetId 
     * @param {number} speedValue 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPresetControllerApi
     */
    public goToPreset(presetId: number, speedValue: number, options?: AxiosRequestConfig) {
        return CameraPresetControllerApiFp(this.configuration).goToPreset(presetId, speedValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {string} presetName 
     * @param {number} speedValue 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPresetControllerApi
     */
    public goToPresetByName(elementId: number, presetName: string, speedValue: number, options?: AxiosRequestConfig) {
        return CameraPresetControllerApiFp(this.configuration).goToPresetByName(elementId, presetName, speedValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {number} presetNum 
     * @param {number} speedValue 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPresetControllerApi
     */
    public goToPresetId(elementId: number, presetNum: number, speedValue: number, options?: AxiosRequestConfig) {
        return CameraPresetControllerApiFp(this.configuration).goToPresetId(elementId, presetNum, speedValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPresetControllerApi
     */
    public refreshPresetsFromCamera(elementId: number, options?: AxiosRequestConfig) {
        return CameraPresetControllerApiFp(this.configuration).refreshPresetsFromCamera(elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {Preset} preset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPresetControllerApi
     */
    public savePreset(cameraId: number, preset: Preset, options?: AxiosRequestConfig) {
        return CameraPresetControllerApiFp(this.configuration).savePreset(cameraId, preset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {number} presetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPresetControllerApi
     */
    public updatePresetImage(elementId: number, presetId: number, options?: AxiosRequestConfig) {
        return CameraPresetControllerApiFp(this.configuration).updatePresetImage(elementId, presetId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CameraPtzControllerApi - axios parameter creator
 * @export
 */
export const CameraPtzControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosition: async (elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('getPosition', 'elementId', elementId)
            const localVarPath = `/cameras/{elementId}/onvif/ptz/position`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositionCache: async (elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('getPositionCache', 'elementId', elementId)
            const localVarPath = `/cameras/{elementId}/onvif/ptz/position/cache`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetsAndId: async (elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('getPresetsAndId', 'elementId', elementId)
            const localVarPath = `/cameras/{elementId}/onvif/ptz/presetsAndId`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPtzConfig: async (elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('getPtzConfig', 'elementId', elementId)
            const localVarPath = `/cameras/{elementId}/onvif/ptz/ptzconfig`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isPtz: async (elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('isPtz', 'elementId', elementId)
            const localVarPath = `/cameras/{elementId}/onvif/ptz/isptz`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {CameraContinousMove} cameraContinousMove 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        move: async (elementId: number, cameraContinousMove: CameraContinousMove, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('move', 'elementId', elementId)
            // verify required parameter 'cameraContinousMove' is not null or undefined
            assertParamExists('move', 'cameraContinousMove', cameraContinousMove)
            const localVarPath = `/cameras/{elementId}/onvif/ptz/move`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cameraContinousMove, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {CameraMove} cameraMove 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPosition: async (elementId: number, cameraMove: CameraMove, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('setPosition', 'elementId', elementId)
            // verify required parameter 'cameraMove' is not null or undefined
            assertParamExists('setPosition', 'cameraMove', cameraMove)
            const localVarPath = `/cameras/{elementId}/onvif/ptz/position`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cameraMove, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopMove: async (elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('stopMove', 'elementId', elementId)
            const localVarPath = `/cameras/{elementId}/onvif/ptz/stop`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CameraPtzControllerApi - functional programming interface
 * @export
 */
export const CameraPtzControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CameraPtzControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPosition(elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CameraPosition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPosition(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPositionCache(elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CameraPosition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPositionCache(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPresetsAndId(elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPresetsAndId(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPtzConfig(elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PTZConfiguration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPtzConfig(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isPtz(elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isPtz(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {CameraContinousMove} cameraContinousMove 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async move(elementId: number, cameraContinousMove: CameraContinousMove, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.move(elementId, cameraContinousMove, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {CameraMove} cameraMove 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPosition(elementId: number, cameraMove: CameraMove, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPosition(elementId, cameraMove, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopMove(elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopMove(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CameraPtzControllerApi - factory interface
 * @export
 */
export const CameraPtzControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CameraPtzControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosition(elementId: number, options?: any): AxiosPromise<CameraPosition> {
            return localVarFp.getPosition(elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositionCache(elementId: number, options?: any): AxiosPromise<CameraPosition> {
            return localVarFp.getPositionCache(elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetsAndId(elementId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getPresetsAndId(elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPtzConfig(elementId: number, options?: any): AxiosPromise<Array<PTZConfiguration>> {
            return localVarFp.getPtzConfig(elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isPtz(elementId: number, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.isPtz(elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {CameraContinousMove} cameraContinousMove 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        move(elementId: number, cameraContinousMove: CameraContinousMove, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.move(elementId, cameraContinousMove, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {CameraMove} cameraMove 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPosition(elementId: number, cameraMove: CameraMove, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.setPosition(elementId, cameraMove, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopMove(elementId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.stopMove(elementId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CameraPtzControllerApi - object-oriented interface
 * @export
 * @class CameraPtzControllerApi
 * @extends {BaseAPI}
 */
export class CameraPtzControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPtzControllerApi
     */
    public getPosition(elementId: number, options?: AxiosRequestConfig) {
        return CameraPtzControllerApiFp(this.configuration).getPosition(elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPtzControllerApi
     */
    public getPositionCache(elementId: number, options?: AxiosRequestConfig) {
        return CameraPtzControllerApiFp(this.configuration).getPositionCache(elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPtzControllerApi
     */
    public getPresetsAndId(elementId: number, options?: AxiosRequestConfig) {
        return CameraPtzControllerApiFp(this.configuration).getPresetsAndId(elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPtzControllerApi
     */
    public getPtzConfig(elementId: number, options?: AxiosRequestConfig) {
        return CameraPtzControllerApiFp(this.configuration).getPtzConfig(elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPtzControllerApi
     */
    public isPtz(elementId: number, options?: AxiosRequestConfig) {
        return CameraPtzControllerApiFp(this.configuration).isPtz(elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {CameraContinousMove} cameraContinousMove 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPtzControllerApi
     */
    public move(elementId: number, cameraContinousMove: CameraContinousMove, options?: AxiosRequestConfig) {
        return CameraPtzControllerApiFp(this.configuration).move(elementId, cameraContinousMove, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {CameraMove} cameraMove 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPtzControllerApi
     */
    public setPosition(elementId: number, cameraMove: CameraMove, options?: AxiosRequestConfig) {
        return CameraPtzControllerApiFp(this.configuration).setPosition(elementId, cameraMove, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraPtzControllerApi
     */
    public stopMove(elementId: number, options?: AxiosRequestConfig) {
        return CameraPtzControllerApiFp(this.configuration).stopMove(elementId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CameraRecordControllerApi - axios parameter creator
 * @export
 */
export const CameraRecordControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextRecordById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNextRecordById', 'id', id)
            const localVarPath = `/records/{id}/next`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} camId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordByCameraIdAndDate: async (camId: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'camId' is not null or undefined
            assertParamExists('getRecordByCameraIdAndDate', 'camId', camId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getRecordByCameraIdAndDate', 'date', date)
            const localVarPath = `/cameras/{camId}/records`
                .replace(`{${"camId"}}`, encodeURIComponent(String(camId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRecordById', 'id', id)
            const localVarPath = `/records/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} camId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordRangesByCameraIdAndDate: async (camId: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'camId' is not null or undefined
            assertParamExists('getRecordRangesByCameraIdAndDate', 'camId', camId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getRecordRangesByCameraIdAndDate', 'date', date)
            const localVarPath = `/cameras/{camId}/records/ranges`
                .replace(`{${"camId"}}`, encodeURIComponent(String(camId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordStreamById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRecordStreamById', 'id', id)
            const localVarPath = `/records/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CameraRecordControllerApi - functional programming interface
 * @export
 */
export const CameraRecordControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CameraRecordControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNextRecordById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Record>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNextRecordById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} camId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecordByCameraIdAndDate(camId: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Record>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecordByCameraIdAndDate(camId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecordById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Record>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecordById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} camId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecordRangesByCameraIdAndDate(camId: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<Record>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecordRangesByCameraIdAndDate(camId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecordStreamById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecordStreamById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CameraRecordControllerApi - factory interface
 * @export
 */
export const CameraRecordControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CameraRecordControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextRecordById(id: number, options?: any): AxiosPromise<Record> {
            return localVarFp.getNextRecordById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} camId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordByCameraIdAndDate(camId: number, date: string, options?: any): AxiosPromise<Array<Record>> {
            return localVarFp.getRecordByCameraIdAndDate(camId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordById(id: number, options?: any): AxiosPromise<Record> {
            return localVarFp.getRecordById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} camId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordRangesByCameraIdAndDate(camId: number, date: string, options?: any): AxiosPromise<Array<Array<Record>>> {
            return localVarFp.getRecordRangesByCameraIdAndDate(camId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordStreamById(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getRecordStreamById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CameraRecordControllerApi - object-oriented interface
 * @export
 * @class CameraRecordControllerApi
 * @extends {BaseAPI}
 */
export class CameraRecordControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRecordControllerApi
     */
    public getNextRecordById(id: number, options?: AxiosRequestConfig) {
        return CameraRecordControllerApiFp(this.configuration).getNextRecordById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} camId 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRecordControllerApi
     */
    public getRecordByCameraIdAndDate(camId: number, date: string, options?: AxiosRequestConfig) {
        return CameraRecordControllerApiFp(this.configuration).getRecordByCameraIdAndDate(camId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRecordControllerApi
     */
    public getRecordById(id: number, options?: AxiosRequestConfig) {
        return CameraRecordControllerApiFp(this.configuration).getRecordById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} camId 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRecordControllerApi
     */
    public getRecordRangesByCameraIdAndDate(camId: number, date: string, options?: AxiosRequestConfig) {
        return CameraRecordControllerApiFp(this.configuration).getRecordRangesByCameraIdAndDate(camId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRecordControllerApi
     */
    public getRecordStreamById(id: number, options?: AxiosRequestConfig) {
        return CameraRecordControllerApiFp(this.configuration).getRecordStreamById(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CameraRemoteManagementControllerApi - axios parameter creator
 * @export
 */
export const CameraRemoteManagementControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} cameraId 
         * @param {CameraContinousMove} cameraContinousMove 
         * @param {boolean} [ignoreSpeedMult] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousPTZMove: async (cameraId: number, cameraContinousMove: CameraContinousMove, ignoreSpeedMult?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('continuousPTZMove', 'cameraId', cameraId)
            // verify required parameter 'cameraContinousMove' is not null or undefined
            assertParamExists('continuousPTZMove', 'cameraContinousMove', cameraContinousMove)
            const localVarPath = `/cameras/management/{cameraId}/move`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ignoreSpeedMult !== undefined) {
                localVarQueryParameter['ignoreSpeedMult'] = ignoreSpeedMult;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cameraContinousMove, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {string} presetName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePreset1: async (cameraId: number, presetName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('deletePreset1', 'cameraId', cameraId)
            // verify required parameter 'presetName' is not null or undefined
            assertParamExists('deletePreset1', 'presetName', presetName)
            const localVarPath = `/cameras/management/{cameraId}/presets`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (presetName !== undefined) {
                localVarQueryParameter['presetName'] = presetName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPTZPosition: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/management/position`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/management/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraUpsertList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/management/cameras/full`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPresetName: async (cameraId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getCurrentPresetName', 'cameraId', cameraId)
            const localVarPath = `/cameras/management/{cameraId}/preset`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPTZPosition: async (cameraId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getPTZPosition', 'cameraId', cameraId)
            const localVarPath = `/cameras/management/{cameraId}/position`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresets1: async (cameraId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getPresets1', 'cameraId', cameraId)
            const localVarPath = `/cameras/management/{cameraId}/presets`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileList: async (cameraId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getProfileList', 'cameraId', cameraId)
            const localVarPath = `/cameras/management/{cameraId}/profileList`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {number} idProfile 
         * @param {boolean} [unicast] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRtspUrl1: async (cameraId: number, idProfile: number, unicast?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getRtspUrl1', 'cameraId', cameraId)
            // verify required parameter 'idProfile' is not null or undefined
            assertParamExists('getRtspUrl1', 'idProfile', idProfile)
            const localVarPath = `/cameras/management/{cameraId}/url/rtsp`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (idProfile !== undefined) {
                localVarQueryParameter['idProfile'] = idProfile;
            }

            if (unicast !== undefined) {
                localVarQueryParameter['unicast'] = unicast;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {number} [width] 
         * @param {number} [height] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotByteArray1: async (cameraId: number, width?: number, height?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getSnapshotByteArray1', 'cameraId', cameraId)
            const localVarPath = `/cameras/management/{cameraId}/snapshot`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotUrl1: async (cameraId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getSnapshotUrl1', 'cameraId', cameraId)
            const localVarPath = `/cameras/management/{cameraId}/url/snapshot`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus: async (cameraId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getStatus', 'cameraId', cameraId)
            const localVarPath = `/cameras/management/{cameraId}/status`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {string} presetName 
         * @param {number} speedValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gotoPreset: async (cameraId: number, presetName: string, speedValue: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('gotoPreset', 'cameraId', cameraId)
            // verify required parameter 'presetName' is not null or undefined
            assertParamExists('gotoPreset', 'presetName', presetName)
            // verify required parameter 'speedValue' is not null or undefined
            assertParamExists('gotoPreset', 'speedValue', speedValue)
            const localVarPath = `/cameras/management/{cameraId}/gotoPreset`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (presetName !== undefined) {
                localVarQueryParameter['presetName'] = presetName;
            }

            if (speedValue !== undefined) {
                localVarQueryParameter['speedValue'] = speedValue;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasPtz: async (cameraId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('hasPtz', 'cameraId', cameraId)
            const localVarPath = `/cameras/management/{cameraId}/ptzs`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {string} presetName 
         * @param {number} presetNum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePreset1: async (cameraId: number, presetName: string, presetNum: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('savePreset1', 'cameraId', cameraId)
            // verify required parameter 'presetName' is not null or undefined
            assertParamExists('savePreset1', 'presetName', presetName)
            // verify required parameter 'presetNum' is not null or undefined
            assertParamExists('savePreset1', 'presetNum', presetNum)
            const localVarPath = `/cameras/management/{cameraId}/presets`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (presetName !== undefined) {
                localVarQueryParameter['presetName'] = presetName;
            }

            if (presetNum !== undefined) {
                localVarQueryParameter['presetNum'] = presetNum;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {CameraMove} cameraMove 
         * @param {boolean} [ignoreSpeedMult] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPTZPosition: async (cameraId: number, cameraMove: CameraMove, ignoreSpeedMult?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('setPTZPosition', 'cameraId', cameraId)
            // verify required parameter 'cameraMove' is not null or undefined
            assertParamExists('setPTZPosition', 'cameraMove', cameraMove)
            const localVarPath = `/cameras/management/{cameraId}/position`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ignoreSpeedMult !== undefined) {
                localVarQueryParameter['ignoreSpeedMult'] = ignoreSpeedMult;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cameraMove, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} panOffset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPanOffsetPosition1: async (elementId: number, panOffset: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('setPanOffsetPosition1', 'elementId', elementId)
            // verify required parameter 'panOffset' is not null or undefined
            assertParamExists('setPanOffsetPosition1', 'panOffset', panOffset)
            const localVarPath = `/cameras/management/{elementId}/position/pan`
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (panOffset !== undefined) {
                localVarQueryParameter['panOffset'] = panOffset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopMove1: async (cameraId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('stopMove1', 'cameraId', cameraId)
            const localVarPath = `/cameras/management/{cameraId}/stop`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CameraRemoteManagementControllerApi - functional programming interface
 * @export
 */
export const CameraRemoteManagementControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CameraRemoteManagementControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} cameraId 
         * @param {CameraContinousMove} cameraContinousMove 
         * @param {boolean} [ignoreSpeedMult] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continuousPTZMove(cameraId: number, cameraContinousMove: CameraContinousMove, ignoreSpeedMult?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.continuousPTZMove(cameraId, cameraContinousMove, ignoreSpeedMult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {string} presetName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePreset1(cameraId: number, presetName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePreset1(cameraId, presetName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPTZPosition(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CameraPosition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPTZPosition(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiCameraStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraUpsertList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiCameraUpsert>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraUpsertList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentPresetName(cameraId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentPresetName(cameraId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPTZPosition(cameraId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CameraPosition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPTZPosition(cameraId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPresets1(cameraId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiPreset>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPresets1(cameraId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileList(cameraId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiProfile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileList(cameraId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {number} idProfile 
         * @param {boolean} [unicast] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRtspUrl1(cameraId: number, idProfile: number, unicast?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRtspUrl1(cameraId, idProfile, unicast, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {number} [width] 
         * @param {number} [height] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnapshotByteArray1(cameraId: number, width?: number, height?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<string>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnapshotByteArray1(cameraId, width, height, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnapshotUrl1(cameraId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnapshotUrl1(cameraId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatus(cameraId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatus(cameraId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {string} presetName 
         * @param {number} speedValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gotoPreset(cameraId: number, presetName: string, speedValue: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gotoPreset(cameraId, presetName, speedValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasPtz(cameraId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasPtz(cameraId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {string} presetName 
         * @param {number} presetNum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePreset1(cameraId: number, presetName: string, presetNum: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePreset1(cameraId, presetName, presetNum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {CameraMove} cameraMove 
         * @param {boolean} [ignoreSpeedMult] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPTZPosition(cameraId: number, cameraMove: CameraMove, ignoreSpeedMult?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPTZPosition(cameraId, cameraMove, ignoreSpeedMult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} panOffset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPanOffsetPosition1(elementId: number, panOffset: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPanOffsetPosition1(elementId, panOffset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopMove1(cameraId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopMove1(cameraId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CameraRemoteManagementControllerApi - factory interface
 * @export
 */
export const CameraRemoteManagementControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CameraRemoteManagementControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} cameraId 
         * @param {CameraContinousMove} cameraContinousMove 
         * @param {boolean} [ignoreSpeedMult] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousPTZMove(cameraId: number, cameraContinousMove: CameraContinousMove, ignoreSpeedMult?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.continuousPTZMove(cameraId, cameraContinousMove, ignoreSpeedMult, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {string} presetName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePreset1(cameraId: number, presetName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePreset1(cameraId, presetName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPTZPosition(options?: any): AxiosPromise<Array<CameraPosition>> {
            return localVarFp.getAllPTZPosition(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStatus(options?: any): AxiosPromise<Array<ApiCameraStatus>> {
            return localVarFp.getAllStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraUpsertList(options?: any): AxiosPromise<Array<AiCameraUpsert>> {
            return localVarFp.getCameraUpsertList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPresetName(cameraId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getCurrentPresetName(cameraId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPTZPosition(cameraId: number, options?: any): AxiosPromise<CameraPosition> {
            return localVarFp.getPTZPosition(cameraId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresets1(cameraId: number, options?: any): AxiosPromise<Array<ApiPreset>> {
            return localVarFp.getPresets1(cameraId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileList(cameraId: number, options?: any): AxiosPromise<Array<ApiProfile>> {
            return localVarFp.getProfileList(cameraId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {number} idProfile 
         * @param {boolean} [unicast] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRtspUrl1(cameraId: number, idProfile: number, unicast?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.getRtspUrl1(cameraId, idProfile, unicast, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {number} [width] 
         * @param {number} [height] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotByteArray1(cameraId: number, width?: number, height?: number, options?: any): AxiosPromise<Array<Array<string>>> {
            return localVarFp.getSnapshotByteArray1(cameraId, width, height, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotUrl1(cameraId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getSnapshotUrl1(cameraId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus(cameraId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getStatus(cameraId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {string} presetName 
         * @param {number} speedValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gotoPreset(cameraId: number, presetName: string, speedValue: number, options?: any): AxiosPromise<void> {
            return localVarFp.gotoPreset(cameraId, presetName, speedValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasPtz(cameraId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.hasPtz(cameraId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {string} presetName 
         * @param {number} presetNum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePreset1(cameraId: number, presetName: string, presetNum: number, options?: any): AxiosPromise<void> {
            return localVarFp.savePreset1(cameraId, presetName, presetNum, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {CameraMove} cameraMove 
         * @param {boolean} [ignoreSpeedMult] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPTZPosition(cameraId: number, cameraMove: CameraMove, ignoreSpeedMult?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.setPTZPosition(cameraId, cameraMove, ignoreSpeedMult, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} panOffset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPanOffsetPosition1(elementId: number, panOffset: number, options?: any): AxiosPromise<void> {
            return localVarFp.setPanOffsetPosition1(elementId, panOffset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopMove1(cameraId: number, options?: any): AxiosPromise<void> {
            return localVarFp.stopMove1(cameraId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CameraRemoteManagementControllerApi - object-oriented interface
 * @export
 * @class CameraRemoteManagementControllerApi
 * @extends {BaseAPI}
 */
export class CameraRemoteManagementControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} cameraId 
     * @param {CameraContinousMove} cameraContinousMove 
     * @param {boolean} [ignoreSpeedMult] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public continuousPTZMove(cameraId: number, cameraContinousMove: CameraContinousMove, ignoreSpeedMult?: boolean, options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).continuousPTZMove(cameraId, cameraContinousMove, ignoreSpeedMult, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {string} presetName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public deletePreset1(cameraId: number, presetName: string, options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).deletePreset1(cameraId, presetName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public getAllPTZPosition(options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).getAllPTZPosition(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public getAllStatus(options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).getAllStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public getCameraUpsertList(options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).getCameraUpsertList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public getCurrentPresetName(cameraId: number, options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).getCurrentPresetName(cameraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public getPTZPosition(cameraId: number, options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).getPTZPosition(cameraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public getPresets1(cameraId: number, options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).getPresets1(cameraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public getProfileList(cameraId: number, options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).getProfileList(cameraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {number} idProfile 
     * @param {boolean} [unicast] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public getRtspUrl1(cameraId: number, idProfile: number, unicast?: boolean, options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).getRtspUrl1(cameraId, idProfile, unicast, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {number} [width] 
     * @param {number} [height] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public getSnapshotByteArray1(cameraId: number, width?: number, height?: number, options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).getSnapshotByteArray1(cameraId, width, height, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public getSnapshotUrl1(cameraId: number, options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).getSnapshotUrl1(cameraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public getStatus(cameraId: number, options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).getStatus(cameraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {string} presetName 
     * @param {number} speedValue 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public gotoPreset(cameraId: number, presetName: string, speedValue: number, options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).gotoPreset(cameraId, presetName, speedValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public hasPtz(cameraId: number, options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).hasPtz(cameraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {string} presetName 
     * @param {number} presetNum 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public savePreset1(cameraId: number, presetName: string, presetNum: number, options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).savePreset1(cameraId, presetName, presetNum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {CameraMove} cameraMove 
     * @param {boolean} [ignoreSpeedMult] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public setPTZPosition(cameraId: number, cameraMove: CameraMove, ignoreSpeedMult?: boolean, options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).setPTZPosition(cameraId, cameraMove, ignoreSpeedMult, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {number} panOffset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public setPanOffsetPosition1(elementId: number, panOffset: number, options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).setPanOffsetPosition1(elementId, panOffset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraRemoteManagementControllerApi
     */
    public stopMove1(cameraId: number, options?: AxiosRequestConfig) {
        return CameraRemoteManagementControllerApiFp(this.configuration).stopMove1(cameraId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CameraSequenceControllerApi - axios parameter creator
 * @export
 */
export const CameraSequenceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CameraSequence} cameraSequence 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSequence: async (cameraSequence: CameraSequence, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraSequence' is not null or undefined
            assertParamExists('addSequence', 'cameraSequence', cameraSequence)
            const localVarPath = `/sequences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cameraSequence, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seqId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSequence: async (seqId: number, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seqId' is not null or undefined
            assertParamExists('deleteSequence', 'seqId', seqId)
            const localVarPath = `/sequences/{seqId}`
                .replace(`{${"seqId"}}`, encodeURIComponent(String(seqId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seqId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceById: async (seqId: number, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seqId' is not null or undefined
            assertParamExists('getSequenceById', 'seqId', seqId)
            const localVarPath = `/sequences/{seqId}`
                .replace(`{${"seqId"}}`, encodeURIComponent(String(seqId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequences: async (areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sequences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seqId 
         * @param {CameraSequence} cameraSequence 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSequence: async (seqId: number, cameraSequence: CameraSequence, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seqId' is not null or undefined
            assertParamExists('updateSequence', 'seqId', seqId)
            // verify required parameter 'cameraSequence' is not null or undefined
            assertParamExists('updateSequence', 'cameraSequence', cameraSequence)
            const localVarPath = `/sequences/{seqId}`
                .replace(`{${"seqId"}}`, encodeURIComponent(String(seqId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cameraSequence, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CameraSequenceControllerApi - functional programming interface
 * @export
 */
export const CameraSequenceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CameraSequenceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CameraSequence} cameraSequence 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSequence(cameraSequence: CameraSequence, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CameraSequence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSequence(cameraSequence, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seqId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSequence(seqId: number, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSequence(seqId, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seqId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSequenceById(seqId: number, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CameraSequence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSequenceById(seqId, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSequences(areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CameraSequence>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSequences(areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seqId 
         * @param {CameraSequence} cameraSequence 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSequence(seqId: number, cameraSequence: CameraSequence, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSequence(seqId, cameraSequence, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CameraSequenceControllerApi - factory interface
 * @export
 */
export const CameraSequenceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CameraSequenceControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CameraSequence} cameraSequence 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSequence(cameraSequence: CameraSequence, areaId?: number, options?: any): AxiosPromise<CameraSequence> {
            return localVarFp.addSequence(cameraSequence, areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seqId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSequence(seqId: number, areaId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSequence(seqId, areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seqId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceById(seqId: number, areaId?: number, options?: any): AxiosPromise<CameraSequence> {
            return localVarFp.getSequenceById(seqId, areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequences(areaId?: number, options?: any): AxiosPromise<Array<CameraSequence>> {
            return localVarFp.getSequences(areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seqId 
         * @param {CameraSequence} cameraSequence 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSequence(seqId: number, cameraSequence: CameraSequence, areaId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.updateSequence(seqId, cameraSequence, areaId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CameraSequenceControllerApi - object-oriented interface
 * @export
 * @class CameraSequenceControllerApi
 * @extends {BaseAPI}
 */
export class CameraSequenceControllerApi extends BaseAPI {
    /**
     * 
     * @param {CameraSequence} cameraSequence 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraSequenceControllerApi
     */
    public addSequence(cameraSequence: CameraSequence, areaId?: number, options?: AxiosRequestConfig) {
        return CameraSequenceControllerApiFp(this.configuration).addSequence(cameraSequence, areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seqId 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraSequenceControllerApi
     */
    public deleteSequence(seqId: number, areaId?: number, options?: AxiosRequestConfig) {
        return CameraSequenceControllerApiFp(this.configuration).deleteSequence(seqId, areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seqId 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraSequenceControllerApi
     */
    public getSequenceById(seqId: number, areaId?: number, options?: AxiosRequestConfig) {
        return CameraSequenceControllerApiFp(this.configuration).getSequenceById(seqId, areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraSequenceControllerApi
     */
    public getSequences(areaId?: number, options?: AxiosRequestConfig) {
        return CameraSequenceControllerApiFp(this.configuration).getSequences(areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seqId 
     * @param {CameraSequence} cameraSequence 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraSequenceControllerApi
     */
    public updateSequence(seqId: number, cameraSequence: CameraSequence, areaId?: number, options?: AxiosRequestConfig) {
        return CameraSequenceControllerApiFp(this.configuration).updateSequence(seqId, cameraSequence, areaId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CameraSpeedAdjustementControllerApi - axios parameter creator
 * @export
 */
export const CameraSpeedAdjustementControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementcameraSpeedAdjustementController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteByIdElementcameraSpeedAdjustementController', 'id', id)
            const localVarPath = `/cameras/brands/models/speeds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllcameraSpeedAdjustementController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/brands/models/speeds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementTypeId 
         * @param {'PAN' | 'TILT' | 'ZOOM'} ptzSpeedType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraSpeedAdjustmentByTypeAndModel: async (elementTypeId: number, ptzSpeedType: 'PAN' | 'TILT' | 'ZOOM', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementTypeId' is not null or undefined
            assertParamExists('getCameraSpeedAdjustmentByTypeAndModel', 'elementTypeId', elementTypeId)
            // verify required parameter 'ptzSpeedType' is not null or undefined
            assertParamExists('getCameraSpeedAdjustmentByTypeAndModel', 'ptzSpeedType', ptzSpeedType)
            const localVarPath = `/cameras/brands/models/speeds/{elementTypeId}/{ptzSpeedType}`
                .replace(`{${"elementTypeId"}}`, encodeURIComponent(String(elementTypeId)))
                .replace(`{${"ptzSpeedType"}}`, encodeURIComponent(String(ptzSpeedType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementcameraSpeedAdjustementController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneElementcameraSpeedAdjustementController', 'id', id)
            const localVarPath = `/cameras/brands/models/speeds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectcameraSpeedAdjustementController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/brands/models/speeds/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AiCameraSpeedAdjustment} aiCameraSpeedAdjustment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementcameraSpeedAdjustementController: async (aiCameraSpeedAdjustment: AiCameraSpeedAdjustment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aiCameraSpeedAdjustment' is not null or undefined
            assertParamExists('insertElementcameraSpeedAdjustementController', 'aiCameraSpeedAdjustment', aiCameraSpeedAdjustment)
            const localVarPath = `/cameras/brands/models/speeds/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiCameraSpeedAdjustment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AiCameraSpeedAdjustment} aiCameraSpeedAdjustment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementcameraSpeedAdjustementController: async (id: number, aiCameraSpeedAdjustment: AiCameraSpeedAdjustment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateElementcameraSpeedAdjustementController', 'id', id)
            // verify required parameter 'aiCameraSpeedAdjustment' is not null or undefined
            assertParamExists('updateElementcameraSpeedAdjustementController', 'aiCameraSpeedAdjustment', aiCameraSpeedAdjustment)
            const localVarPath = `/cameras/brands/models/speeds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiCameraSpeedAdjustment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CameraSpeedAdjustementControllerApi - functional programming interface
 * @export
 */
export const CameraSpeedAdjustementControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CameraSpeedAdjustementControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElementcameraSpeedAdjustementController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElementcameraSpeedAdjustementController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllcameraSpeedAdjustementController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiCameraSpeedAdjustment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllcameraSpeedAdjustementController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementTypeId 
         * @param {'PAN' | 'TILT' | 'ZOOM'} ptzSpeedType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraSpeedAdjustmentByTypeAndModel(elementTypeId: number, ptzSpeedType: 'PAN' | 'TILT' | 'ZOOM', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiCameraSpeedAdjustment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraSpeedAdjustmentByTypeAndModel(elementTypeId, ptzSpeedType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneElementcameraSpeedAdjustementController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiCameraSpeedAdjustment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneElementcameraSpeedAdjustementController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectcameraSpeedAdjustementController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectcameraSpeedAdjustementController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AiCameraSpeedAdjustment} aiCameraSpeedAdjustment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertElementcameraSpeedAdjustementController(aiCameraSpeedAdjustment: AiCameraSpeedAdjustment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiCameraSpeedAdjustment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertElementcameraSpeedAdjustementController(aiCameraSpeedAdjustment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AiCameraSpeedAdjustment} aiCameraSpeedAdjustment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateElementcameraSpeedAdjustementController(id: number, aiCameraSpeedAdjustment: AiCameraSpeedAdjustment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiCameraSpeedAdjustment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateElementcameraSpeedAdjustementController(id, aiCameraSpeedAdjustment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CameraSpeedAdjustementControllerApi - factory interface
 * @export
 */
export const CameraSpeedAdjustementControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CameraSpeedAdjustementControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementcameraSpeedAdjustementController(id: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElementcameraSpeedAdjustementController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllcameraSpeedAdjustementController(options?: any): AxiosPromise<Array<AiCameraSpeedAdjustment>> {
            return localVarFp.getAllcameraSpeedAdjustementController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementTypeId 
         * @param {'PAN' | 'TILT' | 'ZOOM'} ptzSpeedType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraSpeedAdjustmentByTypeAndModel(elementTypeId: number, ptzSpeedType: 'PAN' | 'TILT' | 'ZOOM', options?: any): AxiosPromise<Array<AiCameraSpeedAdjustment>> {
            return localVarFp.getCameraSpeedAdjustmentByTypeAndModel(elementTypeId, ptzSpeedType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementcameraSpeedAdjustementController(id: number, options?: any): AxiosPromise<AiCameraSpeedAdjustment> {
            return localVarFp.getOneElementcameraSpeedAdjustementController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectcameraSpeedAdjustementController(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getSelectcameraSpeedAdjustementController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AiCameraSpeedAdjustment} aiCameraSpeedAdjustment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementcameraSpeedAdjustementController(aiCameraSpeedAdjustment: AiCameraSpeedAdjustment, options?: any): AxiosPromise<AiCameraSpeedAdjustment> {
            return localVarFp.insertElementcameraSpeedAdjustementController(aiCameraSpeedAdjustment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AiCameraSpeedAdjustment} aiCameraSpeedAdjustment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementcameraSpeedAdjustementController(id: number, aiCameraSpeedAdjustment: AiCameraSpeedAdjustment, options?: any): AxiosPromise<AiCameraSpeedAdjustment> {
            return localVarFp.updateElementcameraSpeedAdjustementController(id, aiCameraSpeedAdjustment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CameraSpeedAdjustementControllerApi - object-oriented interface
 * @export
 * @class CameraSpeedAdjustementControllerApi
 * @extends {BaseAPI}
 */
export class CameraSpeedAdjustementControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraSpeedAdjustementControllerApi
     */
    public deleteByIdElementcameraSpeedAdjustementController(id: number, options?: AxiosRequestConfig) {
        return CameraSpeedAdjustementControllerApiFp(this.configuration).deleteByIdElementcameraSpeedAdjustementController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraSpeedAdjustementControllerApi
     */
    public getAllcameraSpeedAdjustementController(options?: AxiosRequestConfig) {
        return CameraSpeedAdjustementControllerApiFp(this.configuration).getAllcameraSpeedAdjustementController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementTypeId 
     * @param {'PAN' | 'TILT' | 'ZOOM'} ptzSpeedType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraSpeedAdjustementControllerApi
     */
    public getCameraSpeedAdjustmentByTypeAndModel(elementTypeId: number, ptzSpeedType: 'PAN' | 'TILT' | 'ZOOM', options?: AxiosRequestConfig) {
        return CameraSpeedAdjustementControllerApiFp(this.configuration).getCameraSpeedAdjustmentByTypeAndModel(elementTypeId, ptzSpeedType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraSpeedAdjustementControllerApi
     */
    public getOneElementcameraSpeedAdjustementController(id: number, options?: AxiosRequestConfig) {
        return CameraSpeedAdjustementControllerApiFp(this.configuration).getOneElementcameraSpeedAdjustementController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraSpeedAdjustementControllerApi
     */
    public getSelectcameraSpeedAdjustementController(options?: AxiosRequestConfig) {
        return CameraSpeedAdjustementControllerApiFp(this.configuration).getSelectcameraSpeedAdjustementController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AiCameraSpeedAdjustment} aiCameraSpeedAdjustment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraSpeedAdjustementControllerApi
     */
    public insertElementcameraSpeedAdjustementController(aiCameraSpeedAdjustment: AiCameraSpeedAdjustment, options?: AxiosRequestConfig) {
        return CameraSpeedAdjustementControllerApiFp(this.configuration).insertElementcameraSpeedAdjustementController(aiCameraSpeedAdjustment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AiCameraSpeedAdjustment} aiCameraSpeedAdjustment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraSpeedAdjustementControllerApi
     */
    public updateElementcameraSpeedAdjustementController(id: number, aiCameraSpeedAdjustment: AiCameraSpeedAdjustment, options?: AxiosRequestConfig) {
        return CameraSpeedAdjustementControllerApiFp(this.configuration).updateElementcameraSpeedAdjustementController(id, aiCameraSpeedAdjustment, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CameraTourControllerApi - axios parameter creator
 * @export
 */
export const CameraTourControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementcameraTourController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteByIdElementcameraTourController', 'id', id)
            const localVarPath = `/cameratour/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllcameraTourController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameratour`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementcameraTourController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneElementcameraTourController', 'id', id)
            const localVarPath = `/cameratour/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectcameraTourController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameratour/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CameraTourDTO} cameraTourDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementcameraTourController: async (cameraTourDTO: CameraTourDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraTourDTO' is not null or undefined
            assertParamExists('insertElementcameraTourController', 'cameraTourDTO', cameraTourDTO)
            const localVarPath = `/cameratour/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cameraTourDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraTourId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCameraTour: async (cameraTourId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraTourId' is not null or undefined
            assertParamExists('startCameraTour', 'cameraTourId', cameraTourId)
            const localVarPath = `/cameratour/{cameraTourId}/start`
                .replace(`{${"cameraTourId"}}`, encodeURIComponent(String(cameraTourId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraTourId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopCameraTour: async (cameraTourId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraTourId' is not null or undefined
            assertParamExists('stopCameraTour', 'cameraTourId', cameraTourId)
            const localVarPath = `/cameratour/{cameraTourId}/stop`
                .replace(`{${"cameraTourId"}}`, encodeURIComponent(String(cameraTourId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CameraTourDTO} cameraTourDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementcameraTourController: async (id: number, cameraTourDTO: CameraTourDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateElementcameraTourController', 'id', id)
            // verify required parameter 'cameraTourDTO' is not null or undefined
            assertParamExists('updateElementcameraTourController', 'cameraTourDTO', cameraTourDTO)
            const localVarPath = `/cameratour/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cameraTourDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CameraTourControllerApi - functional programming interface
 * @export
 */
export const CameraTourControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CameraTourControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElementcameraTourController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElementcameraTourController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllcameraTourController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CameraTourDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllcameraTourController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneElementcameraTourController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CameraTourDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneElementcameraTourController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectcameraTourController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectcameraTourController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CameraTourDTO} cameraTourDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertElementcameraTourController(cameraTourDTO: CameraTourDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CameraTourDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertElementcameraTourController(cameraTourDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraTourId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startCameraTour(cameraTourId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startCameraTour(cameraTourId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraTourId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopCameraTour(cameraTourId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopCameraTour(cameraTourId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CameraTourDTO} cameraTourDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateElementcameraTourController(id: number, cameraTourDTO: CameraTourDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CameraTourDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateElementcameraTourController(id, cameraTourDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CameraTourControllerApi - factory interface
 * @export
 */
export const CameraTourControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CameraTourControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementcameraTourController(id: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElementcameraTourController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllcameraTourController(options?: any): AxiosPromise<Array<CameraTourDTO>> {
            return localVarFp.getAllcameraTourController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementcameraTourController(id: number, options?: any): AxiosPromise<CameraTourDTO> {
            return localVarFp.getOneElementcameraTourController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectcameraTourController(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getSelectcameraTourController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CameraTourDTO} cameraTourDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementcameraTourController(cameraTourDTO: CameraTourDTO, options?: any): AxiosPromise<CameraTourDTO> {
            return localVarFp.insertElementcameraTourController(cameraTourDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraTourId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCameraTour(cameraTourId: number, options?: any): AxiosPromise<void> {
            return localVarFp.startCameraTour(cameraTourId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraTourId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopCameraTour(cameraTourId: number, options?: any): AxiosPromise<void> {
            return localVarFp.stopCameraTour(cameraTourId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CameraTourDTO} cameraTourDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementcameraTourController(id: number, cameraTourDTO: CameraTourDTO, options?: any): AxiosPromise<CameraTourDTO> {
            return localVarFp.updateElementcameraTourController(id, cameraTourDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CameraTourControllerApi - object-oriented interface
 * @export
 * @class CameraTourControllerApi
 * @extends {BaseAPI}
 */
export class CameraTourControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraTourControllerApi
     */
    public deleteByIdElementcameraTourController(id: number, options?: AxiosRequestConfig) {
        return CameraTourControllerApiFp(this.configuration).deleteByIdElementcameraTourController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraTourControllerApi
     */
    public getAllcameraTourController(options?: AxiosRequestConfig) {
        return CameraTourControllerApiFp(this.configuration).getAllcameraTourController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraTourControllerApi
     */
    public getOneElementcameraTourController(id: number, options?: AxiosRequestConfig) {
        return CameraTourControllerApiFp(this.configuration).getOneElementcameraTourController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraTourControllerApi
     */
    public getSelectcameraTourController(options?: AxiosRequestConfig) {
        return CameraTourControllerApiFp(this.configuration).getSelectcameraTourController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CameraTourDTO} cameraTourDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraTourControllerApi
     */
    public insertElementcameraTourController(cameraTourDTO: CameraTourDTO, options?: AxiosRequestConfig) {
        return CameraTourControllerApiFp(this.configuration).insertElementcameraTourController(cameraTourDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraTourId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraTourControllerApi
     */
    public startCameraTour(cameraTourId: number, options?: AxiosRequestConfig) {
        return CameraTourControllerApiFp(this.configuration).startCameraTour(cameraTourId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraTourId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraTourControllerApi
     */
    public stopCameraTour(cameraTourId: number, options?: AxiosRequestConfig) {
        return CameraTourControllerApiFp(this.configuration).stopCameraTour(cameraTourId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CameraTourDTO} cameraTourDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraTourControllerApi
     */
    public updateElementcameraTourController(id: number, cameraTourDTO: CameraTourDTO, options?: AxiosRequestConfig) {
        return CameraTourControllerApiFp(this.configuration).updateElementcameraTourController(id, cameraTourDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChartControllerApi - axios parameter creator
 * @export
 */
export const ChartControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} elementTypeId 
         * @param {'PAN' | 'TILT' | 'ZOOM'} ptzSpeedType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartSpeedAdjustment: async (elementTypeId: number, ptzSpeedType: 'PAN' | 'TILT' | 'ZOOM', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementTypeId' is not null or undefined
            assertParamExists('getChartSpeedAdjustment', 'elementTypeId', elementTypeId)
            // verify required parameter 'ptzSpeedType' is not null or undefined
            assertParamExists('getChartSpeedAdjustment', 'ptzSpeedType', ptzSpeedType)
            const localVarPath = `/charts/speedAdjustment/{elementTypeId}/{ptzSpeedType}`
                .replace(`{${"elementTypeId"}}`, encodeURIComponent(String(elementTypeId)))
                .replace(`{${"ptzSpeedType"}}`, encodeURIComponent(String(ptzSpeedType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartTotalCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/charts/cameras`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreeSpace: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/charts/freespace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsage: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/charts/usage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChartControllerApi - functional programming interface
 * @export
 */
export const ChartControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChartControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} elementTypeId 
         * @param {'PAN' | 'TILT' | 'ZOOM'} ptzSpeedType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartSpeedAdjustment(elementTypeId: number, ptzSpeedType: 'PAN' | 'TILT' | 'ZOOM', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartSpeedAdjustment(elementTypeId, ptzSpeedType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartTotalCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartTotalCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFreeSpace(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFreeSpace(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsage(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChartControllerApi - factory interface
 * @export
 */
export const ChartControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChartControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} elementTypeId 
         * @param {'PAN' | 'TILT' | 'ZOOM'} ptzSpeedType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartSpeedAdjustment(elementTypeId: number, ptzSpeedType: 'PAN' | 'TILT' | 'ZOOM', options?: any): AxiosPromise<ChartWrapper> {
            return localVarFp.getChartSpeedAdjustment(elementTypeId, ptzSpeedType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartTotalCount(options?: any): AxiosPromise<ChartWrapper> {
            return localVarFp.getChartTotalCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreeSpace(options?: any): AxiosPromise<ChartWrapper> {
            return localVarFp.getFreeSpace(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsage(options?: any): AxiosPromise<ChartWrapper> {
            return localVarFp.getUsage(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChartControllerApi - object-oriented interface
 * @export
 * @class ChartControllerApi
 * @extends {BaseAPI}
 */
export class ChartControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} elementTypeId 
     * @param {'PAN' | 'TILT' | 'ZOOM'} ptzSpeedType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartControllerApi
     */
    public getChartSpeedAdjustment(elementTypeId: number, ptzSpeedType: 'PAN' | 'TILT' | 'ZOOM', options?: AxiosRequestConfig) {
        return ChartControllerApiFp(this.configuration).getChartSpeedAdjustment(elementTypeId, ptzSpeedType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartControllerApi
     */
    public getChartTotalCount(options?: AxiosRequestConfig) {
        return ChartControllerApiFp(this.configuration).getChartTotalCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartControllerApi
     */
    public getFreeSpace(options?: AxiosRequestConfig) {
        return ChartControllerApiFp(this.configuration).getFreeSpace(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartControllerApi
     */
    public getUsage(options?: AxiosRequestConfig) {
        return ChartControllerApiFp(this.configuration).getUsage(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClipControllerApi - axios parameter creator
 * @export
 */
export const ClipControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClip: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteClip', 'id', id)
            const localVarPath = `/clips/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadClip: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadClip', 'id', id)
            const localVarPath = `/clips/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClip: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getClip', 'id', id)
            const localVarPath = `/clips/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClips: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VideoClip} videoClip 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newClip: async (videoClip: VideoClip, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'videoClip' is not null or undefined
            assertParamExists('newClip', 'videoClip', videoClip)
            const localVarPath = `/clips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(videoClip, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClipControllerApi - functional programming interface
 * @export
 */
export const ClipControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClipControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClip(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClip(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadClip(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadClip(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClip(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoClip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClip(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClips(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VideoClip>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClips(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VideoClip} videoClip 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newClip(videoClip: VideoClip, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoClip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newClip(videoClip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClipControllerApi - factory interface
 * @export
 */
export const ClipControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClipControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClip(id: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteClip(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadClip(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.downloadClip(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClip(id: number, options?: any): AxiosPromise<VideoClip> {
            return localVarFp.getClip(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClips(options?: any): AxiosPromise<Array<VideoClip>> {
            return localVarFp.getClips(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VideoClip} videoClip 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newClip(videoClip: VideoClip, options?: any): AxiosPromise<VideoClip> {
            return localVarFp.newClip(videoClip, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClipControllerApi - object-oriented interface
 * @export
 * @class ClipControllerApi
 * @extends {BaseAPI}
 */
export class ClipControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClipControllerApi
     */
    public deleteClip(id: number, options?: AxiosRequestConfig) {
        return ClipControllerApiFp(this.configuration).deleteClip(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClipControllerApi
     */
    public downloadClip(id: number, options?: AxiosRequestConfig) {
        return ClipControllerApiFp(this.configuration).downloadClip(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClipControllerApi
     */
    public getClip(id: number, options?: AxiosRequestConfig) {
        return ClipControllerApiFp(this.configuration).getClip(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClipControllerApi
     */
    public getClips(options?: AxiosRequestConfig) {
        return ClipControllerApiFp(this.configuration).getClips(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VideoClip} videoClip 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClipControllerApi
     */
    public newClip(videoClip: VideoClip, options?: AxiosRequestConfig) {
        return ClipControllerApiFp(this.configuration).newClip(videoClip, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomVersionControllerApi - axios parameter creator
 * @export
 */
export const CustomVersionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBBDDVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bbddversion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkerVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/workerversion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomVersionControllerApi - functional programming interface
 * @export
 */
export const CustomVersionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomVersionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBBDDVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBBDDVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkerVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkerVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomVersionControllerApi - factory interface
 * @export
 */
export const CustomVersionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomVersionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBBDDVersion(options?: any): AxiosPromise<string> {
            return localVarFp.getBBDDVersion(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkerVersion(options?: any): AxiosPromise<string> {
            return localVarFp.getWorkerVersion(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomVersionControllerApi - object-oriented interface
 * @export
 * @class CustomVersionControllerApi
 * @extends {BaseAPI}
 */
export class CustomVersionControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomVersionControllerApi
     */
    public getBBDDVersion(options?: AxiosRequestConfig) {
        return CustomVersionControllerApiFp(this.configuration).getBBDDVersion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomVersionControllerApi
     */
    public getWorkerVersion(options?: AxiosRequestConfig) {
        return CustomVersionControllerApiFp(this.configuration).getWorkerVersion(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DebugControllerApi - axios parameter creator
 * @export
 */
export const DebugControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUnsavedFiles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/debug/addUnsavedFiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUnsavedFilesForce: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/debug/addUnsavedFiles/force`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClips: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/debug/deleteClips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecords: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/debug/deleteRecords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoteIpAddress: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/debug/getIp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testReloadCameras: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/debug/reload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DebugControllerApi - functional programming interface
 * @export
 */
export const DebugControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DebugControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUnsavedFiles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUnsavedFiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUnsavedFilesForce(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUnsavedFilesForce(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClips(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClips(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRecords(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRecords(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRemoteIpAddress(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRemoteIpAddress(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testReloadCameras(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testReloadCameras(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DebugControllerApi - factory interface
 * @export
 */
export const DebugControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DebugControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUnsavedFiles(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.addUnsavedFiles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUnsavedFilesForce(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.addUnsavedFilesForce(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClips(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.deleteClips(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecords(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.deleteRecords(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoteIpAddress(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getRemoteIpAddress(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testReloadCameras(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.testReloadCameras(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DebugControllerApi - object-oriented interface
 * @export
 * @class DebugControllerApi
 * @extends {BaseAPI}
 */
export class DebugControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugControllerApi
     */
    public addUnsavedFiles(options?: AxiosRequestConfig) {
        return DebugControllerApiFp(this.configuration).addUnsavedFiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugControllerApi
     */
    public addUnsavedFilesForce(options?: AxiosRequestConfig) {
        return DebugControllerApiFp(this.configuration).addUnsavedFilesForce(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugControllerApi
     */
    public deleteClips(options?: AxiosRequestConfig) {
        return DebugControllerApiFp(this.configuration).deleteClips(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugControllerApi
     */
    public deleteRecords(options?: AxiosRequestConfig) {
        return DebugControllerApiFp(this.configuration).deleteRecords(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugControllerApi
     */
    public getRemoteIpAddress(options?: AxiosRequestConfig) {
        return DebugControllerApiFp(this.configuration).getRemoteIpAddress(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugControllerApi
     */
    public testReloadCameras(options?: AxiosRequestConfig) {
        return DebugControllerApiFp(this.configuration).testReloadCameras(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DetectionAreaControllerApi - axios parameter creator
 * @export
 */
export const DetectionAreaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {boolean} enabled 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alterVals: async (id: number, enabled: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('alterVals', 'id', id)
            // verify required parameter 'enabled' is not null or undefined
            assertParamExists('alterVals', 'enabled', enabled)
            const localVarPath = `/alter-detection-areas-validation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (enabled !== undefined) {
                localVarQueryParameter['enabled'] = enabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} detectionAreaId 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calibrateArea: async (detectionAreaId: number, cameraId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'detectionAreaId' is not null or undefined
            assertParamExists('calibrateArea', 'detectionAreaId', detectionAreaId)
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('calibrateArea', 'cameraId', cameraId)
            const localVarPath = `/calibrate-area`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (detectionAreaId !== undefined) {
                localVarQueryParameter['detectionAreaId'] = detectionAreaId;
            }

            if (cameraId !== undefined) {
                localVarQueryParameter['cameraId'] = cameraId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetectionAreaByCode: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getDetectionAreaByCode', 'code', code)
            const localVarPath = `/detection-areas/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {number} presetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetectionAreasComplete: async (cameraId: number, presetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getDetectionAreasComplete', 'cameraId', cameraId)
            // verify required parameter 'presetId' is not null or undefined
            assertParamExists('getDetectionAreasComplete', 'presetId', presetId)
            const localVarPath = `/cameras/{cameraId}/presets/{presetId}/detection-areas`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)))
                .replace(`{${"presetId"}}`, encodeURIComponent(String(presetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetectionAreasValidation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/detection-areas-validation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DetectionAreaSyncDTO} detectionAreaSyncDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sync: async (detectionAreaSyncDTO: DetectionAreaSyncDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'detectionAreaSyncDTO' is not null or undefined
            assertParamExists('sync', 'detectionAreaSyncDTO', detectionAreaSyncDTO)
            const localVarPath = `/detection-areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detectionAreaSyncDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DetectionAreaControllerApi - functional programming interface
 * @export
 */
export const DetectionAreaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DetectionAreaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {boolean} enabled 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alterVals(id: number, enabled: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionAreaValidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alterVals(id, enabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} detectionAreaId 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calibrateArea(detectionAreaId: number, cameraId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calibrateArea(detectionAreaId, cameraId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetectionAreaByCode(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionAreaDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetectionAreaByCode(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {number} presetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetectionAreasComplete(cameraId: number, presetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetectionAreaDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetectionAreasComplete(cameraId, presetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetectionAreasValidation(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetectionAreaValidation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetectionAreasValidation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DetectionAreaSyncDTO} detectionAreaSyncDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sync(detectionAreaSyncDTO: DetectionAreaSyncDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sync(detectionAreaSyncDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DetectionAreaControllerApi - factory interface
 * @export
 */
export const DetectionAreaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DetectionAreaControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {boolean} enabled 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alterVals(id: number, enabled: boolean, options?: any): AxiosPromise<DetectionAreaValidation> {
            return localVarFp.alterVals(id, enabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} detectionAreaId 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calibrateArea(detectionAreaId: number, cameraId: number, options?: any): AxiosPromise<void> {
            return localVarFp.calibrateArea(detectionAreaId, cameraId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetectionAreaByCode(code: string, options?: any): AxiosPromise<DetectionAreaDTO> {
            return localVarFp.getDetectionAreaByCode(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {number} presetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetectionAreasComplete(cameraId: number, presetId: number, options?: any): AxiosPromise<Array<DetectionAreaDTO>> {
            return localVarFp.getDetectionAreasComplete(cameraId, presetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetectionAreasValidation(options?: any): AxiosPromise<Array<DetectionAreaValidation>> {
            return localVarFp.getDetectionAreasValidation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DetectionAreaSyncDTO} detectionAreaSyncDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sync(detectionAreaSyncDTO: DetectionAreaSyncDTO, options?: any): AxiosPromise<void> {
            return localVarFp.sync(detectionAreaSyncDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DetectionAreaControllerApi - object-oriented interface
 * @export
 * @class DetectionAreaControllerApi
 * @extends {BaseAPI}
 */
export class DetectionAreaControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {boolean} enabled 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionAreaControllerApi
     */
    public alterVals(id: number, enabled: boolean, options?: AxiosRequestConfig) {
        return DetectionAreaControllerApiFp(this.configuration).alterVals(id, enabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} detectionAreaId 
     * @param {number} cameraId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionAreaControllerApi
     */
    public calibrateArea(detectionAreaId: number, cameraId: number, options?: AxiosRequestConfig) {
        return DetectionAreaControllerApiFp(this.configuration).calibrateArea(detectionAreaId, cameraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionAreaControllerApi
     */
    public getDetectionAreaByCode(code: string, options?: AxiosRequestConfig) {
        return DetectionAreaControllerApiFp(this.configuration).getDetectionAreaByCode(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {number} presetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionAreaControllerApi
     */
    public getDetectionAreasComplete(cameraId: number, presetId: number, options?: AxiosRequestConfig) {
        return DetectionAreaControllerApiFp(this.configuration).getDetectionAreasComplete(cameraId, presetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionAreaControllerApi
     */
    public getDetectionAreasValidation(options?: AxiosRequestConfig) {
        return DetectionAreaControllerApiFp(this.configuration).getDetectionAreasValidation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DetectionAreaSyncDTO} detectionAreaSyncDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionAreaControllerApi
     */
    public sync(detectionAreaSyncDTO: DetectionAreaSyncDTO, options?: AxiosRequestConfig) {
        return DetectionAreaControllerApiFp(this.configuration).sync(detectionAreaSyncDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DetectionCameraControllerApi - axios parameter creator
 * @export
 */
export const DetectionCameraControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DetectionInfoDTO} detectionInfoDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDetectionInfo: async (detectionInfoDTO: DetectionInfoDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'detectionInfoDTO' is not null or undefined
            assertParamExists('createDetectionInfo', 'detectionInfoDTO', detectionInfoDTO)
            const localVarPath = `/cameras/detectionInfos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detectionInfoDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateDetectionInfoFromProtoRequest} [createDetectionInfoFromProtoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDetectionInfoFromProto: async (createDetectionInfoFromProtoRequest?: CreateDetectionInfoFromProtoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/detectionInfos/fromProto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDetectionInfoFromProtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DetectionInfoDTO} detectionInfoDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProtoFromDetectionInfo: async (detectionInfoDTO: DetectionInfoDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'detectionInfoDTO' is not null or undefined
            assertParamExists('createProtoFromDetectionInfo', 'detectionInfoDTO', detectionInfoDTO)
            const localVarPath = `/cameras/detectionInfos/toProto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detectionInfoDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDetectionInfo: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDetectionInfo', 'id', id)
            const localVarPath = `/cameras/detectionInfos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableDetectionInfo: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('enableDetectionInfo', 'id', id)
            const localVarPath = `/cameras/detectionInfos/{id}/enable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} presetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetectionAreasChartLess: async (presetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'presetId' is not null or undefined
            assertParamExists('getDetectionAreasChartLess', 'presetId', presetId)
            const localVarPath = `/cameras/detectionInfos/detectionAreas/chart-less`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (presetId !== undefined) {
                localVarQueryParameter['presetId'] = presetId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} presetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetectionAreasLess: async (presetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'presetId' is not null or undefined
            assertParamExists('getDetectionAreasLess', 'presetId', presetId)
            const localVarPath = `/cameras/detectionInfos/detectionAreas/less`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (presetId !== undefined) {
                localVarQueryParameter['presetId'] = presetId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetectionCameraById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDetectionCameraById', 'id', id)
            const localVarPath = `/cameras/detectionInfos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetectionCameras: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/detectionInfos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} camId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetectionCamerasByCameraId: async (camId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'camId' is not null or undefined
            assertParamExists('getDetectionCamerasByCameraId', 'camId', camId)
            const localVarPath = `/cameras/{camId}/detectionInfos`
                .replace(`{${"camId"}}`, encodeURIComponent(String(camId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetectionProtoCameraById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDetectionProtoCameraById', 'id', id)
            const localVarPath = `/cameras/detectionInfos/{id}/proto`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} camId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectionIdByCameraId: async (camId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'camId' is not null or undefined
            assertParamExists('getSectionIdByCameraId', 'camId', camId)
            const localVarPath = `/cameras/{camId}/sections`
                .replace(`{${"camId"}}`, encodeURIComponent(String(camId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} camId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSunriseSunset: async (camId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'camId' is not null or undefined
            assertParamExists('getSunriseSunset', 'camId', camId)
            const localVarPath = `/cameras/{camId}/sunriseSunset`
                .replace(`{${"camId"}}`, encodeURIComponent(String(camId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<DetectionInfoFilter>} detectionInfoFilter 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDetectionInfos: async (detectionInfoFilter: Array<DetectionInfoFilter>, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'detectionInfoFilter' is not null or undefined
            assertParamExists('searchDetectionInfos', 'detectionInfoFilter', detectionInfoFilter)
            const localVarPath = `/cameras/detectionInfos/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detectionInfoFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DetectionInfoDTO} detectionInfoDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDetectionInfo: async (id: number, detectionInfoDTO: DetectionInfoDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDetectionInfo', 'id', id)
            // verify required parameter 'detectionInfoDTO' is not null or undefined
            assertParamExists('updateDetectionInfo', 'detectionInfoDTO', detectionInfoDTO)
            const localVarPath = `/cameras/detectionInfos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detectionInfoDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DetectionCameraControllerApi - functional programming interface
 * @export
 */
export const DetectionCameraControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DetectionCameraControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DetectionInfoDTO} detectionInfoDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDetectionInfo(detectionInfoDTO: DetectionInfoDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDetectionInfo(detectionInfoDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateDetectionInfoFromProtoRequest} [createDetectionInfoFromProtoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDetectionInfoFromProto(createDetectionInfoFromProtoRequest?: CreateDetectionInfoFromProtoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDetectionInfoFromProto(createDetectionInfoFromProtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DetectionInfoDTO} detectionInfoDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProtoFromDetectionInfo(detectionInfoDTO: DetectionInfoDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProtoFromDetectionInfo(detectionInfoDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDetectionInfo(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDetectionInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableDetectionInfo(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableDetectionInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} presetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetectionAreasChartLess(presetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetectionAreaLess>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetectionAreasChartLess(presetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} presetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetectionAreasLess(presetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetectionAreaLess>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetectionAreasLess(presetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetectionCameraById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetectionCameraById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetectionCameras(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetectionInfoDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetectionCameras(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} camId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetectionCamerasByCameraId(camId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetectionInfoDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetectionCamerasByCameraId(camId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetectionProtoCameraById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetectionProtoCameraById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} camId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSectionIdByCameraId(camId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSectionIdByCameraId(camId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} camId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSunriseSunset(camId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SunriseSunsetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSunriseSunset(camId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<DetectionInfoFilter>} detectionInfoFilter 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDetectionInfos(detectionInfoFilter: Array<DetectionInfoFilter>, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDetectionInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDetectionInfos(detectionInfoFilter, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DetectionInfoDTO} detectionInfoDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDetectionInfo(id: number, detectionInfoDTO: DetectionInfoDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDetectionInfo(id, detectionInfoDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DetectionCameraControllerApi - factory interface
 * @export
 */
export const DetectionCameraControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DetectionCameraControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {DetectionInfoDTO} detectionInfoDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDetectionInfo(detectionInfoDTO: DetectionInfoDTO, options?: any): AxiosPromise<void> {
            return localVarFp.createDetectionInfo(detectionInfoDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateDetectionInfoFromProtoRequest} [createDetectionInfoFromProtoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDetectionInfoFromProto(createDetectionInfoFromProtoRequest?: CreateDetectionInfoFromProtoRequest, options?: any): AxiosPromise<DetectionInfoDTO> {
            return localVarFp.createDetectionInfoFromProto(createDetectionInfoFromProtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DetectionInfoDTO} detectionInfoDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProtoFromDetectionInfo(detectionInfoDTO: DetectionInfoDTO, options?: any): AxiosPromise<void> {
            return localVarFp.createProtoFromDetectionInfo(detectionInfoDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDetectionInfo(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDetectionInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableDetectionInfo(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.enableDetectionInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} presetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetectionAreasChartLess(presetId: number, options?: any): AxiosPromise<Array<DetectionAreaLess>> {
            return localVarFp.getDetectionAreasChartLess(presetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} presetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetectionAreasLess(presetId: number, options?: any): AxiosPromise<Array<DetectionAreaLess>> {
            return localVarFp.getDetectionAreasLess(presetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetectionCameraById(id: number, options?: any): AxiosPromise<DetectionInfoDTO> {
            return localVarFp.getDetectionCameraById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetectionCameras(options?: any): AxiosPromise<Array<DetectionInfoDTO>> {
            return localVarFp.getDetectionCameras(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} camId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetectionCamerasByCameraId(camId: number, options?: any): AxiosPromise<Array<DetectionInfoDTO>> {
            return localVarFp.getDetectionCamerasByCameraId(camId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetectionProtoCameraById(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getDetectionProtoCameraById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} camId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectionIdByCameraId(camId: number, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.getSectionIdByCameraId(camId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} camId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSunriseSunset(camId: number, options?: any): AxiosPromise<SunriseSunsetDTO> {
            return localVarFp.getSunriseSunset(camId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<DetectionInfoFilter>} detectionInfoFilter 
         * @param {number} [page] Page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDetectionInfos(detectionInfoFilter: Array<DetectionInfoFilter>, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDetectionInfoDTO> {
            return localVarFp.searchDetectionInfos(detectionInfoFilter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DetectionInfoDTO} detectionInfoDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDetectionInfo(id: number, detectionInfoDTO: DetectionInfoDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateDetectionInfo(id, detectionInfoDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DetectionCameraControllerApi - object-oriented interface
 * @export
 * @class DetectionCameraControllerApi
 * @extends {BaseAPI}
 */
export class DetectionCameraControllerApi extends BaseAPI {
    /**
     * 
     * @param {DetectionInfoDTO} detectionInfoDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraControllerApi
     */
    public createDetectionInfo(detectionInfoDTO: DetectionInfoDTO, options?: AxiosRequestConfig) {
        return DetectionCameraControllerApiFp(this.configuration).createDetectionInfo(detectionInfoDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateDetectionInfoFromProtoRequest} [createDetectionInfoFromProtoRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraControllerApi
     */
    public createDetectionInfoFromProto(createDetectionInfoFromProtoRequest?: CreateDetectionInfoFromProtoRequest, options?: AxiosRequestConfig) {
        return DetectionCameraControllerApiFp(this.configuration).createDetectionInfoFromProto(createDetectionInfoFromProtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DetectionInfoDTO} detectionInfoDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraControllerApi
     */
    public createProtoFromDetectionInfo(detectionInfoDTO: DetectionInfoDTO, options?: AxiosRequestConfig) {
        return DetectionCameraControllerApiFp(this.configuration).createProtoFromDetectionInfo(detectionInfoDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraControllerApi
     */
    public deleteDetectionInfo(id: number, options?: AxiosRequestConfig) {
        return DetectionCameraControllerApiFp(this.configuration).deleteDetectionInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraControllerApi
     */
    public enableDetectionInfo(id: number, options?: AxiosRequestConfig) {
        return DetectionCameraControllerApiFp(this.configuration).enableDetectionInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} presetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraControllerApi
     */
    public getDetectionAreasChartLess(presetId: number, options?: AxiosRequestConfig) {
        return DetectionCameraControllerApiFp(this.configuration).getDetectionAreasChartLess(presetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} presetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraControllerApi
     */
    public getDetectionAreasLess(presetId: number, options?: AxiosRequestConfig) {
        return DetectionCameraControllerApiFp(this.configuration).getDetectionAreasLess(presetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraControllerApi
     */
    public getDetectionCameraById(id: number, options?: AxiosRequestConfig) {
        return DetectionCameraControllerApiFp(this.configuration).getDetectionCameraById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraControllerApi
     */
    public getDetectionCameras(options?: AxiosRequestConfig) {
        return DetectionCameraControllerApiFp(this.configuration).getDetectionCameras(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} camId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraControllerApi
     */
    public getDetectionCamerasByCameraId(camId: number, options?: AxiosRequestConfig) {
        return DetectionCameraControllerApiFp(this.configuration).getDetectionCamerasByCameraId(camId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraControllerApi
     */
    public getDetectionProtoCameraById(id: number, options?: AxiosRequestConfig) {
        return DetectionCameraControllerApiFp(this.configuration).getDetectionProtoCameraById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} camId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraControllerApi
     */
    public getSectionIdByCameraId(camId: number, options?: AxiosRequestConfig) {
        return DetectionCameraControllerApiFp(this.configuration).getSectionIdByCameraId(camId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} camId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraControllerApi
     */
    public getSunriseSunset(camId: number, options?: AxiosRequestConfig) {
        return DetectionCameraControllerApiFp(this.configuration).getSunriseSunset(camId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<DetectionInfoFilter>} detectionInfoFilter 
     * @param {number} [page] Page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraControllerApi
     */
    public searchDetectionInfos(detectionInfoFilter: Array<DetectionInfoFilter>, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return DetectionCameraControllerApiFp(this.configuration).searchDetectionInfos(detectionInfoFilter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DetectionInfoDTO} detectionInfoDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraControllerApi
     */
    public updateDetectionInfo(id: number, detectionInfoDTO: DetectionInfoDTO, options?: AxiosRequestConfig) {
        return DetectionCameraControllerApiFp(this.configuration).updateDetectionInfo(id, detectionInfoDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DetectionCameraScheduleControllerApi - axios parameter creator
 * @export
 */
export const DetectionCameraScheduleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementdetectionCameraScheduleController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteByIdElementdetectionCameraScheduleController', 'id', id)
            const localVarPath = `/detectioncameraschedule/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlldetectionCameraScheduleController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/detectioncameraschedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} camId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByCameraId1: async (camId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'camId' is not null or undefined
            assertParamExists('getByCameraId1', 'camId', camId)
            const localVarPath = `/detectioncameraschedule/camera/{camId}`
                .replace(`{${"camId"}}`, encodeURIComponent(String(camId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementdetectionCameraScheduleController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneElementdetectionCameraScheduleController', 'id', id)
            const localVarPath = `/detectioncameraschedule/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectdetectionCameraScheduleController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/detectioncameraschedule/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DetectionCameraScheduleDTO} detectionCameraScheduleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementdetectionCameraScheduleController: async (detectionCameraScheduleDTO: DetectionCameraScheduleDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'detectionCameraScheduleDTO' is not null or undefined
            assertParamExists('insertElementdetectionCameraScheduleController', 'detectionCameraScheduleDTO', detectionCameraScheduleDTO)
            const localVarPath = `/detectioncameraschedule/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detectionCameraScheduleDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DetectionCameraScheduleDTO} detectionCameraScheduleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementdetectionCameraScheduleController: async (id: number, detectionCameraScheduleDTO: DetectionCameraScheduleDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateElementdetectionCameraScheduleController', 'id', id)
            // verify required parameter 'detectionCameraScheduleDTO' is not null or undefined
            assertParamExists('updateElementdetectionCameraScheduleController', 'detectionCameraScheduleDTO', detectionCameraScheduleDTO)
            const localVarPath = `/detectioncameraschedule/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detectionCameraScheduleDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DetectionCameraScheduleControllerApi - functional programming interface
 * @export
 */
export const DetectionCameraScheduleControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DetectionCameraScheduleControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElementdetectionCameraScheduleController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElementdetectionCameraScheduleController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlldetectionCameraScheduleController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetectionCameraScheduleDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlldetectionCameraScheduleController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} camId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByCameraId1(camId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetectionCameraScheduleDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByCameraId1(camId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneElementdetectionCameraScheduleController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionCameraScheduleDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneElementdetectionCameraScheduleController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectdetectionCameraScheduleController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectdetectionCameraScheduleController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DetectionCameraScheduleDTO} detectionCameraScheduleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertElementdetectionCameraScheduleController(detectionCameraScheduleDTO: DetectionCameraScheduleDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionCameraScheduleDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertElementdetectionCameraScheduleController(detectionCameraScheduleDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DetectionCameraScheduleDTO} detectionCameraScheduleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateElementdetectionCameraScheduleController(id: number, detectionCameraScheduleDTO: DetectionCameraScheduleDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionCameraScheduleDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateElementdetectionCameraScheduleController(id, detectionCameraScheduleDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DetectionCameraScheduleControllerApi - factory interface
 * @export
 */
export const DetectionCameraScheduleControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DetectionCameraScheduleControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementdetectionCameraScheduleController(id: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElementdetectionCameraScheduleController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlldetectionCameraScheduleController(options?: any): AxiosPromise<Array<DetectionCameraScheduleDTO>> {
            return localVarFp.getAlldetectionCameraScheduleController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} camId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByCameraId1(camId: number, options?: any): AxiosPromise<Array<DetectionCameraScheduleDTO>> {
            return localVarFp.getByCameraId1(camId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementdetectionCameraScheduleController(id: number, options?: any): AxiosPromise<DetectionCameraScheduleDTO> {
            return localVarFp.getOneElementdetectionCameraScheduleController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectdetectionCameraScheduleController(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getSelectdetectionCameraScheduleController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DetectionCameraScheduleDTO} detectionCameraScheduleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementdetectionCameraScheduleController(detectionCameraScheduleDTO: DetectionCameraScheduleDTO, options?: any): AxiosPromise<DetectionCameraScheduleDTO> {
            return localVarFp.insertElementdetectionCameraScheduleController(detectionCameraScheduleDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DetectionCameraScheduleDTO} detectionCameraScheduleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementdetectionCameraScheduleController(id: number, detectionCameraScheduleDTO: DetectionCameraScheduleDTO, options?: any): AxiosPromise<DetectionCameraScheduleDTO> {
            return localVarFp.updateElementdetectionCameraScheduleController(id, detectionCameraScheduleDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DetectionCameraScheduleControllerApi - object-oriented interface
 * @export
 * @class DetectionCameraScheduleControllerApi
 * @extends {BaseAPI}
 */
export class DetectionCameraScheduleControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleControllerApi
     */
    public deleteByIdElementdetectionCameraScheduleController(id: number, options?: AxiosRequestConfig) {
        return DetectionCameraScheduleControllerApiFp(this.configuration).deleteByIdElementdetectionCameraScheduleController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleControllerApi
     */
    public getAlldetectionCameraScheduleController(options?: AxiosRequestConfig) {
        return DetectionCameraScheduleControllerApiFp(this.configuration).getAlldetectionCameraScheduleController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} camId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleControllerApi
     */
    public getByCameraId1(camId: number, options?: AxiosRequestConfig) {
        return DetectionCameraScheduleControllerApiFp(this.configuration).getByCameraId1(camId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleControllerApi
     */
    public getOneElementdetectionCameraScheduleController(id: number, options?: AxiosRequestConfig) {
        return DetectionCameraScheduleControllerApiFp(this.configuration).getOneElementdetectionCameraScheduleController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleControllerApi
     */
    public getSelectdetectionCameraScheduleController(options?: AxiosRequestConfig) {
        return DetectionCameraScheduleControllerApiFp(this.configuration).getSelectdetectionCameraScheduleController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DetectionCameraScheduleDTO} detectionCameraScheduleDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleControllerApi
     */
    public insertElementdetectionCameraScheduleController(detectionCameraScheduleDTO: DetectionCameraScheduleDTO, options?: AxiosRequestConfig) {
        return DetectionCameraScheduleControllerApiFp(this.configuration).insertElementdetectionCameraScheduleController(detectionCameraScheduleDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DetectionCameraScheduleDTO} detectionCameraScheduleDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleControllerApi
     */
    public updateElementdetectionCameraScheduleController(id: number, detectionCameraScheduleDTO: DetectionCameraScheduleDTO, options?: AxiosRequestConfig) {
        return DetectionCameraScheduleControllerApiFp(this.configuration).updateElementdetectionCameraScheduleController(id, detectionCameraScheduleDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DetectionCameraScheduleDateControllerApi - axios parameter creator
 * @export
 */
export const DetectionCameraScheduleDateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementdetectionCameraScheduleDateController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteByIdElementdetectionCameraScheduleDateController', 'id', id)
            const localVarPath = `/detectioncamerascheduledate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElements: async (requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteByIdElements', 'requestBody', requestBody)
            const localVarPath = `/detectioncamerascheduledate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlldetectionCameraScheduleDateController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/detectioncamerascheduledate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} camId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByCameraId: async (camId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'camId' is not null or undefined
            assertParamExists('getByCameraId', 'camId', camId)
            const localVarPath = `/detectioncamerascheduledate/camera/{camId}`
                .replace(`{${"camId"}}`, encodeURIComponent(String(camId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementdetectionCameraScheduleDateController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneElementdetectionCameraScheduleDateController', 'id', id)
            const localVarPath = `/detectioncamerascheduledate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectdetectionCameraScheduleDateController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/detectioncamerascheduledate/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DetectionCameraScheduleDateDTO} detectionCameraScheduleDateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementdetectionCameraScheduleDateController: async (detectionCameraScheduleDateDTO: DetectionCameraScheduleDateDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'detectionCameraScheduleDateDTO' is not null or undefined
            assertParamExists('insertElementdetectionCameraScheduleDateController', 'detectionCameraScheduleDateDTO', detectionCameraScheduleDateDTO)
            const localVarPath = `/detectioncamerascheduledate/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detectionCameraScheduleDateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DetectionCameraScheduleDateDTO} detectionCameraScheduleDateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementdetectionCameraScheduleDateController: async (id: number, detectionCameraScheduleDateDTO: DetectionCameraScheduleDateDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateElementdetectionCameraScheduleDateController', 'id', id)
            // verify required parameter 'detectionCameraScheduleDateDTO' is not null or undefined
            assertParamExists('updateElementdetectionCameraScheduleDateController', 'detectionCameraScheduleDateDTO', detectionCameraScheduleDateDTO)
            const localVarPath = `/detectioncamerascheduledate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detectionCameraScheduleDateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<DetectionCameraScheduleDateDTO>} detectionCameraScheduleDateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsert: async (detectionCameraScheduleDateDTO: Array<DetectionCameraScheduleDateDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'detectionCameraScheduleDateDTO' is not null or undefined
            assertParamExists('upsert', 'detectionCameraScheduleDateDTO', detectionCameraScheduleDateDTO)
            const localVarPath = `/detectioncamerascheduledate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detectionCameraScheduleDateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DetectionCameraScheduleDateControllerApi - functional programming interface
 * @export
 */
export const DetectionCameraScheduleDateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DetectionCameraScheduleDateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElementdetectionCameraScheduleDateController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElementdetectionCameraScheduleDateController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElements(requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElements(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlldetectionCameraScheduleDateController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetectionCameraScheduleDateDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlldetectionCameraScheduleDateController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} camId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByCameraId(camId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetectionCameraScheduleDateDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByCameraId(camId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneElementdetectionCameraScheduleDateController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionCameraScheduleDateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneElementdetectionCameraScheduleDateController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectdetectionCameraScheduleDateController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectdetectionCameraScheduleDateController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DetectionCameraScheduleDateDTO} detectionCameraScheduleDateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertElementdetectionCameraScheduleDateController(detectionCameraScheduleDateDTO: DetectionCameraScheduleDateDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionCameraScheduleDateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertElementdetectionCameraScheduleDateController(detectionCameraScheduleDateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DetectionCameraScheduleDateDTO} detectionCameraScheduleDateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateElementdetectionCameraScheduleDateController(id: number, detectionCameraScheduleDateDTO: DetectionCameraScheduleDateDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionCameraScheduleDateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateElementdetectionCameraScheduleDateController(id, detectionCameraScheduleDateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<DetectionCameraScheduleDateDTO>} detectionCameraScheduleDateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsert(detectionCameraScheduleDateDTO: Array<DetectionCameraScheduleDateDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsert(detectionCameraScheduleDateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DetectionCameraScheduleDateControllerApi - factory interface
 * @export
 */
export const DetectionCameraScheduleDateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DetectionCameraScheduleDateControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementdetectionCameraScheduleDateController(id: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElementdetectionCameraScheduleDateController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElements(requestBody: Array<number>, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElements(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlldetectionCameraScheduleDateController(options?: any): AxiosPromise<Array<DetectionCameraScheduleDateDTO>> {
            return localVarFp.getAlldetectionCameraScheduleDateController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} camId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByCameraId(camId: number, options?: any): AxiosPromise<Array<DetectionCameraScheduleDateDTO>> {
            return localVarFp.getByCameraId(camId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementdetectionCameraScheduleDateController(id: number, options?: any): AxiosPromise<DetectionCameraScheduleDateDTO> {
            return localVarFp.getOneElementdetectionCameraScheduleDateController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectdetectionCameraScheduleDateController(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getSelectdetectionCameraScheduleDateController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DetectionCameraScheduleDateDTO} detectionCameraScheduleDateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementdetectionCameraScheduleDateController(detectionCameraScheduleDateDTO: DetectionCameraScheduleDateDTO, options?: any): AxiosPromise<DetectionCameraScheduleDateDTO> {
            return localVarFp.insertElementdetectionCameraScheduleDateController(detectionCameraScheduleDateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DetectionCameraScheduleDateDTO} detectionCameraScheduleDateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementdetectionCameraScheduleDateController(id: number, detectionCameraScheduleDateDTO: DetectionCameraScheduleDateDTO, options?: any): AxiosPromise<DetectionCameraScheduleDateDTO> {
            return localVarFp.updateElementdetectionCameraScheduleDateController(id, detectionCameraScheduleDateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<DetectionCameraScheduleDateDTO>} detectionCameraScheduleDateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsert(detectionCameraScheduleDateDTO: Array<DetectionCameraScheduleDateDTO>, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.upsert(detectionCameraScheduleDateDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DetectionCameraScheduleDateControllerApi - object-oriented interface
 * @export
 * @class DetectionCameraScheduleDateControllerApi
 * @extends {BaseAPI}
 */
export class DetectionCameraScheduleDateControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleDateControllerApi
     */
    public deleteByIdElementdetectionCameraScheduleDateController(id: number, options?: AxiosRequestConfig) {
        return DetectionCameraScheduleDateControllerApiFp(this.configuration).deleteByIdElementdetectionCameraScheduleDateController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleDateControllerApi
     */
    public deleteByIdElements(requestBody: Array<number>, options?: AxiosRequestConfig) {
        return DetectionCameraScheduleDateControllerApiFp(this.configuration).deleteByIdElements(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleDateControllerApi
     */
    public getAlldetectionCameraScheduleDateController(options?: AxiosRequestConfig) {
        return DetectionCameraScheduleDateControllerApiFp(this.configuration).getAlldetectionCameraScheduleDateController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} camId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleDateControllerApi
     */
    public getByCameraId(camId: number, options?: AxiosRequestConfig) {
        return DetectionCameraScheduleDateControllerApiFp(this.configuration).getByCameraId(camId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleDateControllerApi
     */
    public getOneElementdetectionCameraScheduleDateController(id: number, options?: AxiosRequestConfig) {
        return DetectionCameraScheduleDateControllerApiFp(this.configuration).getOneElementdetectionCameraScheduleDateController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleDateControllerApi
     */
    public getSelectdetectionCameraScheduleDateController(options?: AxiosRequestConfig) {
        return DetectionCameraScheduleDateControllerApiFp(this.configuration).getSelectdetectionCameraScheduleDateController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DetectionCameraScheduleDateDTO} detectionCameraScheduleDateDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleDateControllerApi
     */
    public insertElementdetectionCameraScheduleDateController(detectionCameraScheduleDateDTO: DetectionCameraScheduleDateDTO, options?: AxiosRequestConfig) {
        return DetectionCameraScheduleDateControllerApiFp(this.configuration).insertElementdetectionCameraScheduleDateController(detectionCameraScheduleDateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DetectionCameraScheduleDateDTO} detectionCameraScheduleDateDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleDateControllerApi
     */
    public updateElementdetectionCameraScheduleDateController(id: number, detectionCameraScheduleDateDTO: DetectionCameraScheduleDateDTO, options?: AxiosRequestConfig) {
        return DetectionCameraScheduleDateControllerApiFp(this.configuration).updateElementdetectionCameraScheduleDateController(id, detectionCameraScheduleDateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<DetectionCameraScheduleDateDTO>} detectionCameraScheduleDateDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleDateControllerApi
     */
    public upsert(detectionCameraScheduleDateDTO: Array<DetectionCameraScheduleDateDTO>, options?: AxiosRequestConfig) {
        return DetectionCameraScheduleDateControllerApiFp(this.configuration).upsert(detectionCameraScheduleDateDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DetectionCameraScheduleHistControllerApi - axios parameter creator
 * @export
 */
export const DetectionCameraScheduleHistControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementdetectionCameraScheduleHistController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteByIdElementdetectionCameraScheduleHistController', 'id', id)
            const localVarPath = `/detectioncameraschedulehist/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlldetectionCameraScheduleHistController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/detectioncameraschedulehist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementdetectionCameraScheduleHistController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneElementdetectionCameraScheduleHistController', 'id', id)
            const localVarPath = `/detectioncameraschedulehist/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectdetectionCameraScheduleHistController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/detectioncameraschedulehist/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DetectionCameraScheduleHistDTO} detectionCameraScheduleHistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementdetectionCameraScheduleHistController: async (detectionCameraScheduleHistDTO: DetectionCameraScheduleHistDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'detectionCameraScheduleHistDTO' is not null or undefined
            assertParamExists('insertElementdetectionCameraScheduleHistController', 'detectionCameraScheduleHistDTO', detectionCameraScheduleHistDTO)
            const localVarPath = `/detectioncameraschedulehist/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detectionCameraScheduleHistDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DetectionCameraScheduleHistDTO} detectionCameraScheduleHistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementdetectionCameraScheduleHistController: async (id: number, detectionCameraScheduleHistDTO: DetectionCameraScheduleHistDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateElementdetectionCameraScheduleHistController', 'id', id)
            // verify required parameter 'detectionCameraScheduleHistDTO' is not null or undefined
            assertParamExists('updateElementdetectionCameraScheduleHistController', 'detectionCameraScheduleHistDTO', detectionCameraScheduleHistDTO)
            const localVarPath = `/detectioncameraschedulehist/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detectionCameraScheduleHistDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DetectionCameraScheduleHistControllerApi - functional programming interface
 * @export
 */
export const DetectionCameraScheduleHistControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DetectionCameraScheduleHistControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElementdetectionCameraScheduleHistController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElementdetectionCameraScheduleHistController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlldetectionCameraScheduleHistController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetectionCameraScheduleHistDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlldetectionCameraScheduleHistController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneElementdetectionCameraScheduleHistController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionCameraScheduleHistDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneElementdetectionCameraScheduleHistController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectdetectionCameraScheduleHistController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectdetectionCameraScheduleHistController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DetectionCameraScheduleHistDTO} detectionCameraScheduleHistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertElementdetectionCameraScheduleHistController(detectionCameraScheduleHistDTO: DetectionCameraScheduleHistDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionCameraScheduleHistDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertElementdetectionCameraScheduleHistController(detectionCameraScheduleHistDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DetectionCameraScheduleHistDTO} detectionCameraScheduleHistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateElementdetectionCameraScheduleHistController(id: number, detectionCameraScheduleHistDTO: DetectionCameraScheduleHistDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionCameraScheduleHistDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateElementdetectionCameraScheduleHistController(id, detectionCameraScheduleHistDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DetectionCameraScheduleHistControllerApi - factory interface
 * @export
 */
export const DetectionCameraScheduleHistControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DetectionCameraScheduleHistControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementdetectionCameraScheduleHistController(id: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElementdetectionCameraScheduleHistController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlldetectionCameraScheduleHistController(options?: any): AxiosPromise<Array<DetectionCameraScheduleHistDTO>> {
            return localVarFp.getAlldetectionCameraScheduleHistController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementdetectionCameraScheduleHistController(id: number, options?: any): AxiosPromise<DetectionCameraScheduleHistDTO> {
            return localVarFp.getOneElementdetectionCameraScheduleHistController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectdetectionCameraScheduleHistController(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getSelectdetectionCameraScheduleHistController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DetectionCameraScheduleHistDTO} detectionCameraScheduleHistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementdetectionCameraScheduleHistController(detectionCameraScheduleHistDTO: DetectionCameraScheduleHistDTO, options?: any): AxiosPromise<DetectionCameraScheduleHistDTO> {
            return localVarFp.insertElementdetectionCameraScheduleHistController(detectionCameraScheduleHistDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DetectionCameraScheduleHistDTO} detectionCameraScheduleHistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementdetectionCameraScheduleHistController(id: number, detectionCameraScheduleHistDTO: DetectionCameraScheduleHistDTO, options?: any): AxiosPromise<DetectionCameraScheduleHistDTO> {
            return localVarFp.updateElementdetectionCameraScheduleHistController(id, detectionCameraScheduleHistDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DetectionCameraScheduleHistControllerApi - object-oriented interface
 * @export
 * @class DetectionCameraScheduleHistControllerApi
 * @extends {BaseAPI}
 */
export class DetectionCameraScheduleHistControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleHistControllerApi
     */
    public deleteByIdElementdetectionCameraScheduleHistController(id: number, options?: AxiosRequestConfig) {
        return DetectionCameraScheduleHistControllerApiFp(this.configuration).deleteByIdElementdetectionCameraScheduleHistController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleHistControllerApi
     */
    public getAlldetectionCameraScheduleHistController(options?: AxiosRequestConfig) {
        return DetectionCameraScheduleHistControllerApiFp(this.configuration).getAlldetectionCameraScheduleHistController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleHistControllerApi
     */
    public getOneElementdetectionCameraScheduleHistController(id: number, options?: AxiosRequestConfig) {
        return DetectionCameraScheduleHistControllerApiFp(this.configuration).getOneElementdetectionCameraScheduleHistController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleHistControllerApi
     */
    public getSelectdetectionCameraScheduleHistController(options?: AxiosRequestConfig) {
        return DetectionCameraScheduleHistControllerApiFp(this.configuration).getSelectdetectionCameraScheduleHistController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DetectionCameraScheduleHistDTO} detectionCameraScheduleHistDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleHistControllerApi
     */
    public insertElementdetectionCameraScheduleHistController(detectionCameraScheduleHistDTO: DetectionCameraScheduleHistDTO, options?: AxiosRequestConfig) {
        return DetectionCameraScheduleHistControllerApiFp(this.configuration).insertElementdetectionCameraScheduleHistController(detectionCameraScheduleHistDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DetectionCameraScheduleHistDTO} detectionCameraScheduleHistDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectionCameraScheduleHistControllerApi
     */
    public updateElementdetectionCameraScheduleHistController(id: number, detectionCameraScheduleHistDTO: DetectionCameraScheduleHistDTO, options?: AxiosRequestConfig) {
        return DetectionCameraScheduleHistControllerApiFp(this.configuration).updateElementdetectionCameraScheduleHistController(id, detectionCameraScheduleHistDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DetectorApi - axios parameter creator
 * @export
 */
export const DetectorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementDetector: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteByIdElementDetector', 'id', id)
            const localVarPath = `/detector/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDetector: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/detector`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementDetector: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneElementDetector', 'id', id)
            const localVarPath = `/detector/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectDetector: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/detector/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DetectorDTO} detectorDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementDetector: async (detectorDTO: DetectorDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'detectorDTO' is not null or undefined
            assertParamExists('insertElementDetector', 'detectorDTO', detectorDTO)
            const localVarPath = `/detector/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detectorDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DetectorDTO} detectorDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementDetector: async (id: number, detectorDTO: DetectorDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateElementDetector', 'id', id)
            // verify required parameter 'detectorDTO' is not null or undefined
            assertParamExists('updateElementDetector', 'detectorDTO', detectorDTO)
            const localVarPath = `/detector/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detectorDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DetectorApi - functional programming interface
 * @export
 */
export const DetectorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DetectorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElementDetector(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElementDetector(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDetector(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetectorDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDetector(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneElementDetector(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectorDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneElementDetector(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectDetector(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectDetector(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DetectorDTO} detectorDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertElementDetector(detectorDTO: DetectorDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectorDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertElementDetector(detectorDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DetectorDTO} detectorDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateElementDetector(id: number, detectorDTO: DetectorDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectorDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateElementDetector(id, detectorDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DetectorApi - factory interface
 * @export
 */
export const DetectorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DetectorApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementDetector(id: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElementDetector(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDetector(options?: any): AxiosPromise<Array<DetectorDTO>> {
            return localVarFp.getAllDetector(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementDetector(id: number, options?: any): AxiosPromise<DetectorDTO> {
            return localVarFp.getOneElementDetector(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectDetector(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getSelectDetector(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DetectorDTO} detectorDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementDetector(detectorDTO: DetectorDTO, options?: any): AxiosPromise<DetectorDTO> {
            return localVarFp.insertElementDetector(detectorDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DetectorDTO} detectorDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementDetector(id: number, detectorDTO: DetectorDTO, options?: any): AxiosPromise<DetectorDTO> {
            return localVarFp.updateElementDetector(id, detectorDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DetectorApi - object-oriented interface
 * @export
 * @class DetectorApi
 * @extends {BaseAPI}
 */
export class DetectorApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectorApi
     */
    public deleteByIdElementDetector(id: number, options?: AxiosRequestConfig) {
        return DetectorApiFp(this.configuration).deleteByIdElementDetector(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectorApi
     */
    public getAllDetector(options?: AxiosRequestConfig) {
        return DetectorApiFp(this.configuration).getAllDetector(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectorApi
     */
    public getOneElementDetector(id: number, options?: AxiosRequestConfig) {
        return DetectorApiFp(this.configuration).getOneElementDetector(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectorApi
     */
    public getSelectDetector(options?: AxiosRequestConfig) {
        return DetectorApiFp(this.configuration).getSelectDetector(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DetectorDTO} detectorDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectorApi
     */
    public insertElementDetector(detectorDTO: DetectorDTO, options?: AxiosRequestConfig) {
        return DetectorApiFp(this.configuration).insertElementDetector(detectorDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DetectorDTO} detectorDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetectorApi
     */
    public updateElementDetector(id: number, detectorDTO: DetectorDTO, options?: AxiosRequestConfig) {
        return DetectorApiFp(this.configuration).updateElementDetector(id, detectorDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GeoDataControllerApi - axios parameter creator
 * @export
 */
export const GeoDataControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cameras: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/geodata/cameras`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeoDataControllerApi - functional programming interface
 * @export
 */
export const GeoDataControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeoDataControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cameras(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cameras(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeoDataControllerApi - factory interface
 * @export
 */
export const GeoDataControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeoDataControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cameras(options?: any): AxiosPromise<FeatureCollection> {
            return localVarFp.cameras(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeoDataControllerApi - object-oriented interface
 * @export
 * @class GeoDataControllerApi
 * @extends {BaseAPI}
 */
export class GeoDataControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoDataControllerApi
     */
    public cameras(options?: AxiosRequestConfig) {
        return GeoDataControllerApiFp(this.configuration).cameras(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JoystickControllerApi - axios parameter creator
 * @export
 */
export const JoystickControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJoystick: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('deleteJoystick', 'uuid', uuid)
            const localVarPath = `/joysticks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJoystick1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteJoystick1', 'id', id)
            const localVarPath = `/joysticks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJoystickActiveJoystick: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/joysticks/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJoystickById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getJoystickById', 'id', id)
            const localVarPath = `/joysticks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJoysticks1: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getJoysticks1', 'uuid', uuid)
            const localVarPath = `/joysticks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Joystick} joystick 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertJoystick: async (joystick: Joystick, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'joystick' is not null or undefined
            assertParamExists('insertJoystick', 'joystick', joystick)
            const localVarPath = `/joysticks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(joystick, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setJoystickActiveCamera: async (cameraId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('setJoystickActiveCamera', 'cameraId', cameraId)
            const localVarPath = `/joysticks/active/camera/{cameraId}`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Joystick} joystick 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJoystick: async (id: number, joystick: Joystick, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateJoystick', 'id', id)
            // verify required parameter 'joystick' is not null or undefined
            assertParamExists('updateJoystick', 'joystick', joystick)
            const localVarPath = `/joysticks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(joystick, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JoystickControllerApi - functional programming interface
 * @export
 */
export const JoystickControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JoystickControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJoystick(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJoystick(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJoystick1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJoystick1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJoystickActiveJoystick(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Joystick>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJoystickActiveJoystick(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJoystickById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Joystick>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJoystickById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJoysticks1(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetJoysticks1200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJoysticks1(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Joystick} joystick 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertJoystick(joystick: Joystick, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Joystick>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertJoystick(joystick, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setJoystickActiveCamera(cameraId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setJoystickActiveCamera(cameraId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Joystick} joystick 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateJoystick(id: number, joystick: Joystick, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Joystick>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateJoystick(id, joystick, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JoystickControllerApi - factory interface
 * @export
 */
export const JoystickControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JoystickControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJoystick(uuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteJoystick(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJoystick1(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteJoystick1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJoystickActiveJoystick(options?: any): AxiosPromise<Joystick> {
            return localVarFp.getJoystickActiveJoystick(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJoystickById(id: number, options?: any): AxiosPromise<Joystick> {
            return localVarFp.getJoystickById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJoysticks1(uuid: string, options?: any): AxiosPromise<GetJoysticks1200Response> {
            return localVarFp.getJoysticks1(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Joystick} joystick 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertJoystick(joystick: Joystick, options?: any): AxiosPromise<Joystick> {
            return localVarFp.insertJoystick(joystick, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setJoystickActiveCamera(cameraId: number, options?: any): AxiosPromise<void> {
            return localVarFp.setJoystickActiveCamera(cameraId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Joystick} joystick 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJoystick(id: number, joystick: Joystick, options?: any): AxiosPromise<Joystick> {
            return localVarFp.updateJoystick(id, joystick, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JoystickControllerApi - object-oriented interface
 * @export
 * @class JoystickControllerApi
 * @extends {BaseAPI}
 */
export class JoystickControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JoystickControllerApi
     */
    public deleteJoystick(uuid: string, options?: AxiosRequestConfig) {
        return JoystickControllerApiFp(this.configuration).deleteJoystick(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JoystickControllerApi
     */
    public deleteJoystick1(id: number, options?: AxiosRequestConfig) {
        return JoystickControllerApiFp(this.configuration).deleteJoystick1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JoystickControllerApi
     */
    public getJoystickActiveJoystick(options?: AxiosRequestConfig) {
        return JoystickControllerApiFp(this.configuration).getJoystickActiveJoystick(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JoystickControllerApi
     */
    public getJoystickById(id: number, options?: AxiosRequestConfig) {
        return JoystickControllerApiFp(this.configuration).getJoystickById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JoystickControllerApi
     */
    public getJoysticks1(uuid: string, options?: AxiosRequestConfig) {
        return JoystickControllerApiFp(this.configuration).getJoysticks1(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Joystick} joystick 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JoystickControllerApi
     */
    public insertJoystick(joystick: Joystick, options?: AxiosRequestConfig) {
        return JoystickControllerApiFp(this.configuration).insertJoystick(joystick, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JoystickControllerApi
     */
    public setJoystickActiveCamera(cameraId: number, options?: AxiosRequestConfig) {
        return JoystickControllerApiFp(this.configuration).setJoystickActiveCamera(cameraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Joystick} joystick 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JoystickControllerApi
     */
    public updateJoystick(id: number, joystick: Joystick, options?: AxiosRequestConfig) {
        return JoystickControllerApiFp(this.configuration).updateJoystick(id, joystick, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LayoutControllerApi - axios parameter creator
 * @export
 */
export const LayoutControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Layout} layout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateLayout: async (layout: Layout, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'layout' is not null or undefined
            assertParamExists('createOrUpdateLayout', 'layout', layout)
            const localVarPath = `/layouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(layout, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} layoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLayout: async (layoutId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'layoutId' is not null or undefined
            assertParamExists('deleteLayout', 'layoutId', layoutId)
            const localVarPath = `/layouts/{layoutId}`
                .replace(`{${"layoutId"}}`, encodeURIComponent(String(layoutId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayouts: async (name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/layouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LayoutControllerApi - functional programming interface
 * @export
 */
export const LayoutControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LayoutControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Layout} layout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateLayout(layout: Layout, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Layout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateLayout(layout, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} layoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLayout(layoutId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLayout(layoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLayouts(name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Layout>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLayouts(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LayoutControllerApi - factory interface
 * @export
 */
export const LayoutControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LayoutControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Layout} layout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateLayout(layout: Layout, options?: any): AxiosPromise<Layout> {
            return localVarFp.createOrUpdateLayout(layout, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} layoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLayout(layoutId: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteLayout(layoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayouts(name?: string, options?: any): AxiosPromise<Array<Layout>> {
            return localVarFp.getLayouts(name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LayoutControllerApi - object-oriented interface
 * @export
 * @class LayoutControllerApi
 * @extends {BaseAPI}
 */
export class LayoutControllerApi extends BaseAPI {
    /**
     * 
     * @param {Layout} layout 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutControllerApi
     */
    public createOrUpdateLayout(layout: Layout, options?: AxiosRequestConfig) {
        return LayoutControllerApiFp(this.configuration).createOrUpdateLayout(layout, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} layoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutControllerApi
     */
    public deleteLayout(layoutId: number, options?: AxiosRequestConfig) {
        return LayoutControllerApiFp(this.configuration).deleteLayout(layoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutControllerApi
     */
    public getLayouts(name?: string, options?: AxiosRequestConfig) {
        return LayoutControllerApiFp(this.configuration).getLayouts(name, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LicenseControllerApi - axios parameter creator
 * @export
 */
export const LicenseControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/license`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LicenseControllerApi - functional programming interface
 * @export
 */
export const LicenseControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LicenseControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenseStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DvrLicenseData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenseStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LicenseControllerApi - factory interface
 * @export
 */
export const LicenseControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LicenseControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseStatus(options?: any): AxiosPromise<DvrLicenseData> {
            return localVarFp.getLicenseStatus(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LicenseControllerApi - object-oriented interface
 * @export
 * @class LicenseControllerApi
 * @extends {BaseAPI}
 */
export class LicenseControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseControllerApi
     */
    public getLicenseStatus(options?: AxiosRequestConfig) {
        return LicenseControllerApiFp(this.configuration).getLicenseStatus(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogExtractControllerApi - axios parameter creator
 * @export
 */
export const LogExtractControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDvrLog: async (elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('getDvrLog', 'elementId', elementId)
            const localVarPath = `/log/dvr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (elementId !== undefined) {
                localVarQueryParameter['elementId'] = elementId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} [lines] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullLog: async (elementId: number, lines?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('getFullLog', 'elementId', elementId)
            const localVarPath = `/log/full`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (elementId !== undefined) {
                localVarQueryParameter['elementId'] = elementId;
            }

            if (lines !== undefined) {
                localVarQueryParameter['lines'] = lines;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaLog: async (elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('getMediaLog', 'elementId', elementId)
            const localVarPath = `/log/media`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (elementId !== undefined) {
                localVarQueryParameter['elementId'] = elementId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogExtractControllerApi - functional programming interface
 * @export
 */
export const LogExtractControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogExtractControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDvrLog(elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDvrLog(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} [lines] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFullLog(elementId: number, lines?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFullLog(elementId, lines, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMediaLog(elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMediaLog(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogExtractControllerApi - factory interface
 * @export
 */
export const LogExtractControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogExtractControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDvrLog(elementId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getDvrLog(elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {number} [lines] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullLog(elementId: number, lines?: number, options?: any): AxiosPromise<string> {
            return localVarFp.getFullLog(elementId, lines, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaLog(elementId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getMediaLog(elementId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogExtractControllerApi - object-oriented interface
 * @export
 * @class LogExtractControllerApi
 * @extends {BaseAPI}
 */
export class LogExtractControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogExtractControllerApi
     */
    public getDvrLog(elementId: number, options?: AxiosRequestConfig) {
        return LogExtractControllerApiFp(this.configuration).getDvrLog(elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {number} [lines] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogExtractControllerApi
     */
    public getFullLog(elementId: number, lines?: number, options?: AxiosRequestConfig) {
        return LogExtractControllerApiFp(this.configuration).getFullLog(elementId, lines, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogExtractControllerApi
     */
    public getMediaLog(elementId: number, options?: AxiosRequestConfig) {
        return LogExtractControllerApiFp(this.configuration).getMediaLog(elementId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MultiLayoutControllerApi - axios parameter creator
 * @export
 */
export const MultiLayoutControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MultiLayoutUpsertParams} multiLayoutUpsertParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateMultiLayout: async (multiLayoutUpsertParams: MultiLayoutUpsertParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'multiLayoutUpsertParams' is not null or undefined
            assertParamExists('createOrUpdateMultiLayout', 'multiLayoutUpsertParams', multiLayoutUpsertParams)
            const localVarPath = `/multilayouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(multiLayoutUpsertParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} multiLayoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMultiLayout: async (multiLayoutId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'multiLayoutId' is not null or undefined
            assertParamExists('deleteMultiLayout', 'multiLayoutId', multiLayoutId)
            const localVarPath = `/multilayouts/{multiLayoutId}`
                .replace(`{${"multiLayoutId"}}`, encodeURIComponent(String(multiLayoutId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} multiLayoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultiLayoutById: async (multiLayoutId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'multiLayoutId' is not null or undefined
            assertParamExists('getMultiLayoutById', 'multiLayoutId', multiLayoutId)
            const localVarPath = `/multilayouts/{multiLayoutId}`
                .replace(`{${"multiLayoutId"}}`, encodeURIComponent(String(multiLayoutId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [videowallId] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultiLayouts: async (videowallId?: number, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/multilayouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (videowallId !== undefined) {
                localVarQueryParameter['videowallId'] = videowallId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MultiLayoutControllerApi - functional programming interface
 * @export
 */
export const MultiLayoutControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MultiLayoutControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MultiLayoutUpsertParams} multiLayoutUpsertParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateMultiLayout(multiLayoutUpsertParams: MultiLayoutUpsertParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiLayout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateMultiLayout(multiLayoutUpsertParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} multiLayoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMultiLayout(multiLayoutId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMultiLayout(multiLayoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} multiLayoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMultiLayoutById(multiLayoutId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiLayout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMultiLayoutById(multiLayoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [videowallId] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMultiLayouts(videowallId?: number, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MultiLayout>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMultiLayouts(videowallId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MultiLayoutControllerApi - factory interface
 * @export
 */
export const MultiLayoutControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MultiLayoutControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {MultiLayoutUpsertParams} multiLayoutUpsertParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateMultiLayout(multiLayoutUpsertParams: MultiLayoutUpsertParams, options?: any): AxiosPromise<MultiLayout> {
            return localVarFp.createOrUpdateMultiLayout(multiLayoutUpsertParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} multiLayoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMultiLayout(multiLayoutId: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteMultiLayout(multiLayoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} multiLayoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultiLayoutById(multiLayoutId: number, options?: any): AxiosPromise<MultiLayout> {
            return localVarFp.getMultiLayoutById(multiLayoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [videowallId] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultiLayouts(videowallId?: number, name?: string, options?: any): AxiosPromise<Array<MultiLayout>> {
            return localVarFp.getMultiLayouts(videowallId, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MultiLayoutControllerApi - object-oriented interface
 * @export
 * @class MultiLayoutControllerApi
 * @extends {BaseAPI}
 */
export class MultiLayoutControllerApi extends BaseAPI {
    /**
     * 
     * @param {MultiLayoutUpsertParams} multiLayoutUpsertParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiLayoutControllerApi
     */
    public createOrUpdateMultiLayout(multiLayoutUpsertParams: MultiLayoutUpsertParams, options?: AxiosRequestConfig) {
        return MultiLayoutControllerApiFp(this.configuration).createOrUpdateMultiLayout(multiLayoutUpsertParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} multiLayoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiLayoutControllerApi
     */
    public deleteMultiLayout(multiLayoutId: number, options?: AxiosRequestConfig) {
        return MultiLayoutControllerApiFp(this.configuration).deleteMultiLayout(multiLayoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} multiLayoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiLayoutControllerApi
     */
    public getMultiLayoutById(multiLayoutId: number, options?: AxiosRequestConfig) {
        return MultiLayoutControllerApiFp(this.configuration).getMultiLayoutById(multiLayoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [videowallId] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiLayoutControllerApi
     */
    public getMultiLayouts(videowallId?: number, name?: string, options?: AxiosRequestConfig) {
        return MultiLayoutControllerApiFp(this.configuration).getMultiLayouts(videowallId, name, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OverlayImageApi - axios parameter creator
 * @export
 */
export const OverlayImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} overlayImageId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOverlayImage: async (overlayImageId: number, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'overlayImageId' is not null or undefined
            assertParamExists('deleteOverlayImage', 'overlayImageId', overlayImageId)
            const localVarPath = `/overlay/images/{overlayImageId}`
                .replace(`{${"overlayImageId"}}`, encodeURIComponent(String(overlayImageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [elementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverlayImages: async (elementId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/overlay/images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (elementId !== undefined) {
                localVarQueryParameter['elementId'] = elementId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} overlayImageId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverlayImagesById: async (overlayImageId: number, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'overlayImageId' is not null or undefined
            assertParamExists('getOverlayImagesById', 'overlayImageId', overlayImageId)
            const localVarPath = `/overlay/images/{overlayImageId}`
                .replace(`{${"overlayImageId"}}`, encodeURIComponent(String(overlayImageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OverlayImage} overlayImage 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertOverlayImage: async (overlayImage: OverlayImage, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'overlayImage' is not null or undefined
            assertParamExists('insertOverlayImage', 'overlayImage', overlayImage)
            const localVarPath = `/overlay/images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(overlayImage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} overlayImageId 
         * @param {OverlayImage} overlayImage 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOverlayImage: async (overlayImageId: number, overlayImage: OverlayImage, areaId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'overlayImageId' is not null or undefined
            assertParamExists('updateOverlayImage', 'overlayImageId', overlayImageId)
            // verify required parameter 'overlayImage' is not null or undefined
            assertParamExists('updateOverlayImage', 'overlayImage', overlayImage)
            const localVarPath = `/overlay/images/{overlayImageId}`
                .replace(`{${"overlayImageId"}}`, encodeURIComponent(String(overlayImageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(overlayImage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OverlayImageApi - functional programming interface
 * @export
 */
export const OverlayImageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OverlayImageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} overlayImageId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOverlayImage(overlayImageId: number, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOverlayImage(overlayImageId, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [elementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOverlayImages(elementId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OverlayImage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOverlayImages(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} overlayImageId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOverlayImagesById(overlayImageId: number, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OverlayImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOverlayImagesById(overlayImageId, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OverlayImage} overlayImage 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertOverlayImage(overlayImage: OverlayImage, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertOverlayImage(overlayImage, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} overlayImageId 
         * @param {OverlayImage} overlayImage 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOverlayImage(overlayImageId: number, overlayImage: OverlayImage, areaId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOverlayImage(overlayImageId, overlayImage, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OverlayImageApi - factory interface
 * @export
 */
export const OverlayImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OverlayImageApiFp(configuration)
    return {
        /**
         * 
         * @param {number} overlayImageId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOverlayImage(overlayImageId: number, areaId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOverlayImage(overlayImageId, areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [elementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverlayImages(elementId?: number, options?: any): AxiosPromise<Array<OverlayImage>> {
            return localVarFp.getOverlayImages(elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} overlayImageId 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverlayImagesById(overlayImageId: number, areaId?: number, options?: any): AxiosPromise<OverlayImage> {
            return localVarFp.getOverlayImagesById(overlayImageId, areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OverlayImage} overlayImage 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertOverlayImage(overlayImage: OverlayImage, areaId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.insertOverlayImage(overlayImage, areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} overlayImageId 
         * @param {OverlayImage} overlayImage 
         * @param {number} [areaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOverlayImage(overlayImageId: number, overlayImage: OverlayImage, areaId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.updateOverlayImage(overlayImageId, overlayImage, areaId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OverlayImageApi - object-oriented interface
 * @export
 * @class OverlayImageApi
 * @extends {BaseAPI}
 */
export class OverlayImageApi extends BaseAPI {
    /**
     * 
     * @param {number} overlayImageId 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverlayImageApi
     */
    public deleteOverlayImage(overlayImageId: number, areaId?: number, options?: AxiosRequestConfig) {
        return OverlayImageApiFp(this.configuration).deleteOverlayImage(overlayImageId, areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [elementId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverlayImageApi
     */
    public getOverlayImages(elementId?: number, options?: AxiosRequestConfig) {
        return OverlayImageApiFp(this.configuration).getOverlayImages(elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} overlayImageId 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverlayImageApi
     */
    public getOverlayImagesById(overlayImageId: number, areaId?: number, options?: AxiosRequestConfig) {
        return OverlayImageApiFp(this.configuration).getOverlayImagesById(overlayImageId, areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OverlayImage} overlayImage 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverlayImageApi
     */
    public insertOverlayImage(overlayImage: OverlayImage, areaId?: number, options?: AxiosRequestConfig) {
        return OverlayImageApiFp(this.configuration).insertOverlayImage(overlayImage, areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} overlayImageId 
     * @param {OverlayImage} overlayImage 
     * @param {number} [areaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverlayImageApi
     */
    public updateOverlayImage(overlayImageId: number, overlayImage: OverlayImage, areaId?: number, options?: AxiosRequestConfig) {
        return OverlayImageApiFp(this.configuration).updateOverlayImage(overlayImageId, overlayImage, areaId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RtspApi - axios parameter creator
 * @export
 */
export const RtspApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rtspConnectData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rtsp/connectData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RtspApi - functional programming interface
 * @export
 */
export const RtspApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RtspApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rtspConnectData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RtspConnectData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rtspConnectData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RtspApi - factory interface
 * @export
 */
export const RtspApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RtspApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rtspConnectData(options?: any): AxiosPromise<RtspConnectData> {
            return localVarFp.rtspConnectData(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RtspApi - object-oriented interface
 * @export
 * @class RtspApi
 * @extends {BaseAPI}
 */
export class RtspApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RtspApi
     */
    public rtspConnectData(options?: AxiosRequestConfig) {
        return RtspApiFp(this.configuration).rtspConnectData(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScenarioApi - axios parameter creator
 * @export
 */
export const ScenarioApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} layoutScenarioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLayoutScenario: async (layoutScenarioId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'layoutScenarioId' is not null or undefined
            assertParamExists('deleteLayoutScenario', 'layoutScenarioId', layoutScenarioId)
            const localVarPath = `/scenarios/{layoutScenarioId}`
                .replace(`{${"layoutScenarioId"}}`, encodeURIComponent(String(layoutScenarioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLayoutScenarios: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scenarios`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LayoutScenario} layoutScenario 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertLayoutScenario: async (layoutScenario: LayoutScenario, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'layoutScenario' is not null or undefined
            assertParamExists('upsertLayoutScenario', 'layoutScenario', layoutScenario)
            const localVarPath = `/scenarios`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(layoutScenario, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScenarioApi - functional programming interface
 * @export
 */
export const ScenarioApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScenarioApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} layoutScenarioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLayoutScenario(layoutScenarioId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLayoutScenario(layoutScenarioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllLayoutScenarios(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LayoutScenario>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllLayoutScenarios(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LayoutScenario} layoutScenario 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertLayoutScenario(layoutScenario: LayoutScenario, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayoutScenario>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertLayoutScenario(layoutScenario, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScenarioApi - factory interface
 * @export
 */
export const ScenarioApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScenarioApiFp(configuration)
    return {
        /**
         * 
         * @param {number} layoutScenarioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLayoutScenario(layoutScenarioId: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteLayoutScenario(layoutScenarioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLayoutScenarios(options?: any): AxiosPromise<Array<LayoutScenario>> {
            return localVarFp.getAllLayoutScenarios(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LayoutScenario} layoutScenario 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertLayoutScenario(layoutScenario: LayoutScenario, options?: any): AxiosPromise<LayoutScenario> {
            return localVarFp.upsertLayoutScenario(layoutScenario, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScenarioApi - object-oriented interface
 * @export
 * @class ScenarioApi
 * @extends {BaseAPI}
 */
export class ScenarioApi extends BaseAPI {
    /**
     * 
     * @param {number} layoutScenarioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioApi
     */
    public deleteLayoutScenario(layoutScenarioId: number, options?: AxiosRequestConfig) {
        return ScenarioApiFp(this.configuration).deleteLayoutScenario(layoutScenarioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioApi
     */
    public getAllLayoutScenarios(options?: AxiosRequestConfig) {
        return ScenarioApiFp(this.configuration).getAllLayoutScenarios(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LayoutScenario} layoutScenario 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioApi
     */
    public upsertLayoutScenario(layoutScenario: LayoutScenario, options?: AxiosRequestConfig) {
        return ScenarioApiFp(this.configuration).upsertLayoutScenario(layoutScenario, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaskControllerApi - axios parameter creator
 * @export
 */
export const TaskControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementtaskController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteByIdElementtaskController', 'id', id)
            const localVarPath = `/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlltaskController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementtaskController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneElementtaskController', 'id', id)
            const localVarPath = `/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelecttaskController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tasks/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ScheduledTaskDTO} scheduledTaskDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementtaskController: async (scheduledTaskDTO: ScheduledTaskDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledTaskDTO' is not null or undefined
            assertParamExists('insertElementtaskController', 'scheduledTaskDTO', scheduledTaskDTO)
            const localVarPath = `/tasks/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduledTaskDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ScheduledTaskDTO} scheduledTaskDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementtaskController: async (id: number, scheduledTaskDTO: ScheduledTaskDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateElementtaskController', 'id', id)
            // verify required parameter 'scheduledTaskDTO' is not null or undefined
            assertParamExists('updateElementtaskController', 'scheduledTaskDTO', scheduledTaskDTO)
            const localVarPath = `/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduledTaskDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskControllerApi - functional programming interface
 * @export
 */
export const TaskControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElementtaskController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElementtaskController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlltaskController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledTaskDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlltaskController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneElementtaskController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledTaskDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneElementtaskController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelecttaskController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelecttaskController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ScheduledTaskDTO} scheduledTaskDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertElementtaskController(scheduledTaskDTO: ScheduledTaskDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledTaskDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertElementtaskController(scheduledTaskDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ScheduledTaskDTO} scheduledTaskDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateElementtaskController(id: number, scheduledTaskDTO: ScheduledTaskDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledTaskDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateElementtaskController(id, scheduledTaskDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskControllerApi - factory interface
 * @export
 */
export const TaskControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementtaskController(id: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElementtaskController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlltaskController(options?: any): AxiosPromise<Array<ScheduledTaskDTO>> {
            return localVarFp.getAlltaskController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementtaskController(id: number, options?: any): AxiosPromise<ScheduledTaskDTO> {
            return localVarFp.getOneElementtaskController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelecttaskController(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getSelecttaskController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ScheduledTaskDTO} scheduledTaskDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementtaskController(scheduledTaskDTO: ScheduledTaskDTO, options?: any): AxiosPromise<ScheduledTaskDTO> {
            return localVarFp.insertElementtaskController(scheduledTaskDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ScheduledTaskDTO} scheduledTaskDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementtaskController(id: number, scheduledTaskDTO: ScheduledTaskDTO, options?: any): AxiosPromise<ScheduledTaskDTO> {
            return localVarFp.updateElementtaskController(id, scheduledTaskDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskControllerApi - object-oriented interface
 * @export
 * @class TaskControllerApi
 * @extends {BaseAPI}
 */
export class TaskControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public deleteByIdElementtaskController(id: number, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).deleteByIdElementtaskController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getAlltaskController(options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getAlltaskController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getOneElementtaskController(id: number, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getOneElementtaskController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public getSelecttaskController(options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).getSelecttaskController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ScheduledTaskDTO} scheduledTaskDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public insertElementtaskController(scheduledTaskDTO: ScheduledTaskDTO, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).insertElementtaskController(scheduledTaskDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ScheduledTaskDTO} scheduledTaskDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskControllerApi
     */
    public updateElementtaskController(id: number, scheduledTaskDTO: ScheduledTaskDTO, options?: AxiosRequestConfig) {
        return TaskControllerApiFp(this.configuration).updateElementtaskController(id, scheduledTaskDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VersionControllerApi - axios parameter creator
 * @export
 */
export const VersionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionControllerApi - functional programming interface
 * @export
 */
export const VersionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VersionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VersionControllerApi - factory interface
 * @export
 */
export const VersionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VersionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(options?: any): AxiosPromise<string> {
            return localVarFp.getVersion(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VersionControllerApi - object-oriented interface
 * @export
 * @class VersionControllerApi
 * @extends {BaseAPI}
 */
export class VersionControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionControllerApi
     */
    public getVersion(options?: AxiosRequestConfig) {
        return VersionControllerApiFp(this.configuration).getVersion(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VideoApiControllerApi - axios parameter creator
 * @export
 */
export const VideoApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeAllStreams: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/videoapi/streams/close`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} streamToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeStreamById: async (streamToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'streamToken' is not null or undefined
            assertParamExists('closeStreamById', 'streamToken', streamToken)
            const localVarPath = `/videoapi/streams/{streamToken}/close`
                .replace(`{${"streamToken"}}`, encodeURIComponent(String(streamToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCameras: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/videoapi/cameras`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOpenStreams: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/videoapi/streams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/videoapi/user/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {'DEFAULT' | 'RECORD' | 'LOW' | 'DETECTION' | 'UNRECOGNIZED'} streamType 
         * @param {number} expirationTimeMsecs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewCameraById: async (cameraId: number, streamType: 'DEFAULT' | 'RECORD' | 'LOW' | 'DETECTION' | 'UNRECOGNIZED', expirationTimeMsecs: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('viewCameraById', 'cameraId', cameraId)
            // verify required parameter 'streamType' is not null or undefined
            assertParamExists('viewCameraById', 'streamType', streamType)
            // verify required parameter 'expirationTimeMsecs' is not null or undefined
            assertParamExists('viewCameraById', 'expirationTimeMsecs', expirationTimeMsecs)
            const localVarPath = `/videoapi/cameras/{cameraId}/view`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (streamType !== undefined) {
                localVarQueryParameter['streamType'] = streamType;
            }

            if (expirationTimeMsecs !== undefined) {
                localVarQueryParameter['expirationTimeMsecs'] = expirationTimeMsecs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VideoApiControllerApi - functional programming interface
 * @export
 */
export const VideoApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VideoApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closeAllStreams(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closeAllStreams(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} streamToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closeStreamById(streamToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closeStreamById(streamToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCameras(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiCameraUpsert>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCameras(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllOpenStreams(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCameraTokenData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllOpenStreams(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserTokenData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {'DEFAULT' | 'RECORD' | 'LOW' | 'DETECTION' | 'UNRECOGNIZED'} streamType 
         * @param {number} expirationTimeMsecs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async viewCameraById(cameraId: number, streamType: 'DEFAULT' | 'RECORD' | 'LOW' | 'DETECTION' | 'UNRECOGNIZED', expirationTimeMsecs: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CameraToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.viewCameraById(cameraId, streamType, expirationTimeMsecs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VideoApiControllerApi - factory interface
 * @export
 */
export const VideoApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VideoApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeAllStreams(options?: any): AxiosPromise<boolean> {
            return localVarFp.closeAllStreams(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} streamToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeStreamById(streamToken: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.closeStreamById(streamToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCameras(options?: any): AxiosPromise<Array<AiCameraUpsert>> {
            return localVarFp.getAllCameras(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOpenStreams(options?: any): AxiosPromise<UserCameraTokenData> {
            return localVarFp.getAllOpenStreams(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(options?: any): AxiosPromise<UserTokenData> {
            return localVarFp.getUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cameraId 
         * @param {'DEFAULT' | 'RECORD' | 'LOW' | 'DETECTION' | 'UNRECOGNIZED'} streamType 
         * @param {number} expirationTimeMsecs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewCameraById(cameraId: number, streamType: 'DEFAULT' | 'RECORD' | 'LOW' | 'DETECTION' | 'UNRECOGNIZED', expirationTimeMsecs: number, options?: any): AxiosPromise<CameraToken> {
            return localVarFp.viewCameraById(cameraId, streamType, expirationTimeMsecs, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VideoApiControllerApi - object-oriented interface
 * @export
 * @class VideoApiControllerApi
 * @extends {BaseAPI}
 */
export class VideoApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApiControllerApi
     */
    public closeAllStreams(options?: AxiosRequestConfig) {
        return VideoApiControllerApiFp(this.configuration).closeAllStreams(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} streamToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApiControllerApi
     */
    public closeStreamById(streamToken: string, options?: AxiosRequestConfig) {
        return VideoApiControllerApiFp(this.configuration).closeStreamById(streamToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApiControllerApi
     */
    public getAllCameras(options?: AxiosRequestConfig) {
        return VideoApiControllerApiFp(this.configuration).getAllCameras(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApiControllerApi
     */
    public getAllOpenStreams(options?: AxiosRequestConfig) {
        return VideoApiControllerApiFp(this.configuration).getAllOpenStreams(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApiControllerApi
     */
    public getUserInfo(options?: AxiosRequestConfig) {
        return VideoApiControllerApiFp(this.configuration).getUserInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cameraId 
     * @param {'DEFAULT' | 'RECORD' | 'LOW' | 'DETECTION' | 'UNRECOGNIZED'} streamType 
     * @param {number} expirationTimeMsecs 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApiControllerApi
     */
    public viewCameraById(cameraId: number, streamType: 'DEFAULT' | 'RECORD' | 'LOW' | 'DETECTION' | 'UNRECOGNIZED', expirationTimeMsecs: number, options?: AxiosRequestConfig) {
        return VideoApiControllerApiFp(this.configuration).viewCameraById(cameraId, streamType, expirationTimeMsecs, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VideoEventControllerApi - axios parameter creator
 * @export
 */
export const VideoEventControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEvent} createEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateVideoEvent: async (createEvent: CreateEvent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEvent' is not null or undefined
            assertParamExists('generateVideoEvent', 'createEvent', createEvent)
            const localVarPath = `/videoevents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {string} videoType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideoEventById: async (eventId: number, videoType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getVideoEventById', 'eventId', eventId)
            // verify required parameter 'videoType' is not null or undefined
            assertParamExists('getVideoEventById', 'videoType', videoType)
            const localVarPath = `/videoevents/{eventId}/download/{videoType}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)))
                .replace(`{${"videoType"}}`, encodeURIComponent(String(videoType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VideoEventControllerApi - functional programming interface
 * @export
 */
export const VideoEventControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VideoEventControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEvent} createEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateVideoEvent(createEvent: CreateEvent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateVideoEvent(createEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {string} videoType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVideoEventById(eventId: number, videoType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVideoEventById(eventId, videoType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VideoEventControllerApi - factory interface
 * @export
 */
export const VideoEventControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VideoEventControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEvent} createEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateVideoEvent(createEvent: CreateEvent, options?: any): AxiosPromise<string> {
            return localVarFp.generateVideoEvent(createEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {string} videoType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideoEventById(eventId: number, videoType: string, options?: any): AxiosPromise<void> {
            return localVarFp.getVideoEventById(eventId, videoType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VideoEventControllerApi - object-oriented interface
 * @export
 * @class VideoEventControllerApi
 * @extends {BaseAPI}
 */
export class VideoEventControllerApi extends BaseAPI {
    /**
     * 
     * @param {CreateEvent} createEvent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoEventControllerApi
     */
    public generateVideoEvent(createEvent: CreateEvent, options?: AxiosRequestConfig) {
        return VideoEventControllerApiFp(this.configuration).generateVideoEvent(createEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {string} videoType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoEventControllerApi
     */
    public getVideoEventById(eventId: number, videoType: string, options?: AxiosRequestConfig) {
        return VideoEventControllerApiFp(this.configuration).getVideoEventById(eventId, videoType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VideowallApi - axios parameter creator
 * @export
 */
export const VideowallApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Videowall} videowall 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateVideowall: async (videowall: Videowall, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'videowall' is not null or undefined
            assertParamExists('createOrUpdateVideowall', 'videowall', videowall)
            const localVarPath = `/videowalls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(videowall, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVideowall: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteVideowall', 'id', id)
            const localVarPath = `/videowalls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [connected] 
         * @param {boolean} [getStatus] 
         * @param {string} [name] 
         * @param {string} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findVideowalls: async (connected?: boolean, getStatus?: boolean, name?: string, computerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/videowalls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (connected !== undefined) {
                localVarQueryParameter['connected'] = connected;
            }

            if (getStatus !== undefined) {
                localVarQueryParameter['getStatus'] = getStatus;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (computerId !== undefined) {
                localVarQueryParameter['computerId'] = computerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideowallsById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVideowallsById', 'id', id)
            const localVarPath = `/videowalls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} videowallId 
         * @param {Array<VideowallLayout>} videowallLayout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLayout: async (videowallId: number, videowallLayout: Array<VideowallLayout>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'videowallId' is not null or undefined
            assertParamExists('setLayout', 'videowallId', videowallId)
            // verify required parameter 'videowallLayout' is not null or undefined
            assertParamExists('setLayout', 'videowallLayout', videowallLayout)
            const localVarPath = `/videowalls/{videowallId}/layouts`
                .replace(`{${"videowallId"}}`, encodeURIComponent(String(videowallId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(videowallLayout, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} videowallId 
         * @param {SetLayoutBlockProperties} setLayoutBlockProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLayoutBlockProperties: async (videowallId: number, setLayoutBlockProperties: SetLayoutBlockProperties, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'videowallId' is not null or undefined
            assertParamExists('setLayoutBlockProperties', 'videowallId', videowallId)
            // verify required parameter 'setLayoutBlockProperties' is not null or undefined
            assertParamExists('setLayoutBlockProperties', 'setLayoutBlockProperties', setLayoutBlockProperties)
            const localVarPath = `/videowalls/{videowallId}/setLayoutBlockProperties`
                .replace(`{${"videowallId"}}`, encodeURIComponent(String(videowallId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setLayoutBlockProperties, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} videowallId 
         * @param {MultiLayout} multiLayout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMultiLayout: async (videowallId: number, multiLayout: MultiLayout, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'videowallId' is not null or undefined
            assertParamExists('setMultiLayout', 'videowallId', videowallId)
            // verify required parameter 'multiLayout' is not null or undefined
            assertParamExists('setMultiLayout', 'multiLayout', multiLayout)
            const localVarPath = `/videowalls/{videowallId}/multilayouts`
                .replace(`{${"videowallId"}}`, encodeURIComponent(String(videowallId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(multiLayout, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VideowallApi - functional programming interface
 * @export
 */
export const VideowallApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VideowallApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Videowall} videowall 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateVideowall(videowall: Videowall, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Videowall>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateVideowall(videowall, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVideowall(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVideowall(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [connected] 
         * @param {boolean} [getStatus] 
         * @param {string} [name] 
         * @param {string} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findVideowalls(connected?: boolean, getStatus?: boolean, name?: string, computerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Videowall>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findVideowalls(connected, getStatus, name, computerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVideowallsById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Videowall>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVideowallsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} videowallId 
         * @param {Array<VideowallLayout>} videowallLayout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setLayout(videowallId: number, videowallLayout: Array<VideowallLayout>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setLayout(videowallId, videowallLayout, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} videowallId 
         * @param {SetLayoutBlockProperties} setLayoutBlockProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setLayoutBlockProperties(videowallId: number, setLayoutBlockProperties: SetLayoutBlockProperties, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setLayoutBlockProperties(videowallId, setLayoutBlockProperties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} videowallId 
         * @param {MultiLayout} multiLayout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMultiLayout(videowallId: number, multiLayout: MultiLayout, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMultiLayout(videowallId, multiLayout, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VideowallApi - factory interface
 * @export
 */
export const VideowallApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VideowallApiFp(configuration)
    return {
        /**
         * 
         * @param {Videowall} videowall 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateVideowall(videowall: Videowall, options?: any): AxiosPromise<Videowall> {
            return localVarFp.createOrUpdateVideowall(videowall, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVideowall(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteVideowall(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [connected] 
         * @param {boolean} [getStatus] 
         * @param {string} [name] 
         * @param {string} [computerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findVideowalls(connected?: boolean, getStatus?: boolean, name?: string, computerId?: string, options?: any): AxiosPromise<Array<Videowall>> {
            return localVarFp.findVideowalls(connected, getStatus, name, computerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideowallsById(id: number, options?: any): AxiosPromise<Videowall> {
            return localVarFp.getVideowallsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} videowallId 
         * @param {Array<VideowallLayout>} videowallLayout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLayout(videowallId: number, videowallLayout: Array<VideowallLayout>, options?: any): AxiosPromise<string> {
            return localVarFp.setLayout(videowallId, videowallLayout, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} videowallId 
         * @param {SetLayoutBlockProperties} setLayoutBlockProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLayoutBlockProperties(videowallId: number, setLayoutBlockProperties: SetLayoutBlockProperties, options?: any): AxiosPromise<string> {
            return localVarFp.setLayoutBlockProperties(videowallId, setLayoutBlockProperties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} videowallId 
         * @param {MultiLayout} multiLayout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMultiLayout(videowallId: number, multiLayout: MultiLayout, options?: any): AxiosPromise<string> {
            return localVarFp.setMultiLayout(videowallId, multiLayout, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VideowallApi - object-oriented interface
 * @export
 * @class VideowallApi
 * @extends {BaseAPI}
 */
export class VideowallApi extends BaseAPI {
    /**
     * 
     * @param {Videowall} videowall 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideowallApi
     */
    public createOrUpdateVideowall(videowall: Videowall, options?: AxiosRequestConfig) {
        return VideowallApiFp(this.configuration).createOrUpdateVideowall(videowall, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideowallApi
     */
    public deleteVideowall(id: number, options?: AxiosRequestConfig) {
        return VideowallApiFp(this.configuration).deleteVideowall(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [connected] 
     * @param {boolean} [getStatus] 
     * @param {string} [name] 
     * @param {string} [computerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideowallApi
     */
    public findVideowalls(connected?: boolean, getStatus?: boolean, name?: string, computerId?: string, options?: AxiosRequestConfig) {
        return VideowallApiFp(this.configuration).findVideowalls(connected, getStatus, name, computerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideowallApi
     */
    public getVideowallsById(id: number, options?: AxiosRequestConfig) {
        return VideowallApiFp(this.configuration).getVideowallsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} videowallId 
     * @param {Array<VideowallLayout>} videowallLayout 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideowallApi
     */
    public setLayout(videowallId: number, videowallLayout: Array<VideowallLayout>, options?: AxiosRequestConfig) {
        return VideowallApiFp(this.configuration).setLayout(videowallId, videowallLayout, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} videowallId 
     * @param {SetLayoutBlockProperties} setLayoutBlockProperties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideowallApi
     */
    public setLayoutBlockProperties(videowallId: number, setLayoutBlockProperties: SetLayoutBlockProperties, options?: AxiosRequestConfig) {
        return VideowallApiFp(this.configuration).setLayoutBlockProperties(videowallId, setLayoutBlockProperties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} videowallId 
     * @param {MultiLayout} multiLayout 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideowallApi
     */
    public setMultiLayout(videowallId: number, multiLayout: MultiLayout, options?: AxiosRequestConfig) {
        return VideowallApiFp(this.configuration).setMultiLayout(videowallId, multiLayout, options).then((request) => request(this.axios, this.basePath));
    }
}


