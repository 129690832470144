import React from 'react';
import {APP_WEBSERVICE_PATH} from "~/external-modules/dlv-web-common/util/LoginUtils";
import {appHasMetabaseDashboard} from "~/common/checks/app-checks";


function Loading() {
  return <div>Loading...</div>;
}

const SendEmailView =React.lazy( () => import('../../private/view/Mail/SendEmailView'));
const DevicesView =React.lazy( () => import('../../private/view/Element/ElementsView'));
const ListUsersView =React.lazy( () => import('../../private/view/User/List/ListUsersView'));

const PermissionView =React.lazy( () => import('../../private/view/Role/Permission/PermissionView'));

const DashboardView =React.lazy( () => import('../view/Dashboard/Dashboard'));
const ExternalDashboardView =React.lazy( () => import('../view/Dashboard/ExternalDashboardView'));
const DefaultExternalDashboardView =React.lazy( () => import('../view/Dashboard/DefaultDashboardView'));
const VersionView =React.lazy( () => import('../view/Version/VersionView'));



// const GenericIframe =React.lazy( () => import('../view/GenericIframe/GenericIframe'),
//
//   render(loaded,props){
//     let Component = loaded.GenericIframe;
//     return <Component {... props} />
//   }
// });
// const CVatIframe = Loadable({
//   loader: () => import('../view/GenericIframe/GenericIframe'),
//
//   render(loaded,props){
//     let Component = loaded.GenericIframe;
//     let tempProps = {...props};
//     tempProps.url = "https://localhost:8443"
//     return <Component {... tempProps} />
//   }
// });

// const GenericIframeHider =React.lazy( () => import('../view/GenericIframe/GenericIframeHider'),
//
//   render(loaded,props){
//     let Component = loaded.GenericIframeHider;
//     return <Component {... props} />
//   }
// });

const PageRedirect =React.lazy( () => import('../../external-modules/dlv-web-common/component/Redirect/PageRedirect'));

const ProfileView =React.lazy( () => import('../../common/view/User/Profile/UserProfileView'));
const ChangePasswordEmailTemplateView =React.lazy( () => import('../../private/view/Mail/Template/ChangePasswordEmailTemplateView'));

const NewRegisterEmailTemplateView =React.lazy( () => import('../../private/view/Mail/Template/NewRegisterEmailTemplateView'));

const EmaiLogView =React.lazy( () => import('../../private/view/Mail/Log/ListEmailLogView'));

const ListEventsView =React.lazy( () => import('../../private/view/Events/ListEventsView'));

const InfoEventView =React.lazy( () => import('../view/Events/InfoEventView'));

const DetectionView =React.lazy( () => import('../../private/view/Detection/DetectionView'));
const VehicleChartViewSection =React.lazy( () => import('../../private/view/Chart/VehicleChartBySectionView'));

const GenericLayoutView =React.lazy( () => import('../view/GenericLayout/GenericLayout/GenericLayoutContainer'));

const CameraViewRecording =React.lazy( () => import('../../private/view/AiCamera/Recording/CameraViewRecording'));

const UpsertAiCameraView =React.lazy( () => import('../view/AiCamera/UpsertAiCamera/UpsertAiCameraView'));

const CameraSequencesView =React.lazy( () => import('../view/AiCamera/Sequence/CameraSequenceListView'));


const MapView =React.lazy( () => import('../view/Map/MapView/MapView'));

const VideowallView =React.lazy( () => import('../view/VideowallLayout/VideowallLayoutSelect'));

const FaceRecognitionIdByUrlView =React.lazy( () => import('../../external-modules/dlv-ai-common/component/Facerecognition/FaceRecognitionIdByUrlView'));

const RolesCrud =React.lazy( () => import('../view/Role/CRUD/RolesCrudView'));

const AreasCrud =React.lazy( () => import('../view/Area/CRUD/AreaCrudView'));

const ZonesCrud =React.lazy( () => import('../view/Zone/ZoneView'));

const ImageOverlayCrud =React.lazy( () => import('../view/ImageOverlay/CRUD/ImageOverlayCrudView'));

const FaceRecognitionTable =React.lazy( () => import('../../external-modules/dlv-ai-common/component/Facerecognition/Table/FaceRecognitionTableView'));

const AlprTable =React.lazy( () => import('../view/Alpr/Table/AlprTableView'));

const AlprBLacklistGroupCrud =React.lazy( () => import('../view/AlprBlacklist/CRUD/AlprBlacklistGroupCrudView'));
const AlprWhitelistGroupCrud =React.lazy( () => import('../view/AlprWhitelist/CRUD/AlprWhitelistGroupCrudView'));



const RadarCrud =React.lazy( () => import('../view/Radar/CRUD/RadarCrudView'));

const TaskView =React.lazy( () => import('../view/Task/TaskView'));

const ElementStatusTableView =React.lazy( () => import('../view/ElementStatus/Table/ElementStatusTableView'));

const ForecastCrudView =React.lazy( () => import('../view/Forecast/CRUD/ForecastCrudView'));
const DetectionCameraScheduleView =React.lazy( () => import('../view/DetectionCameraSchedule/DetectionCameraScheduleView'));



const RouteView =React.lazy( () => import('../view/Route/RouteView/RouteView'));

const RouteMapSearchView =React.lazy( () => import('../view/Route/RouteMapSearchView/RouteMapSearchView'));

const DataCountView =React.lazy( () => import('../view/DataCount/DataCountInfoContainer'));

const ExternalBoardView =React.lazy( () => import('../view/ExternalBoard/ExternalBoardView'));
const CameraTourView =React.lazy( () => import('../view/CameraTour/CameraTourView'));
const AiModulesView =React.lazy( () => import('../view/AiModules/AiModulesView'));

const VehicleCategoriesView =React.lazy( () => import('../view/VehicleCategory/VehicleCategoryView'));

const ParameterAlarm =React.lazy( () => import('../view/ParameterAlarm/ParameterAlarmView'));
const HikvisionSettings = React.lazy(() => import('../view/HikvisionCameraSettings/HikvisionCamSettingsView'));
const HistoricalStatus = React.lazy(() => import('../view/HistoricalStatusData/HistoricalStatusView'));


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
function routes(t) {
  let defaultDashboard = DashboardView;

   if (appHasMetabaseDashboard()){
  //   if (externalBoardsApi.getDashboardHashByName("METABASE","default") != null)

       defaultDashboard = DefaultExternalDashboardView;
  }
  const SectionCrud = React.lazy(() => import('../view/Section/SectionView'));
  const CameraSequencesView = React.lazy( () => import('../view/AiCamera/Sequence/CameraSequenceListView'));
  const RoadSectionView = React.lazy( () => import('../view/RoadSection/RoadSectionView'));

  let routesMap= [
  { path: APP_WEBSERVICE_PATH+'/private/dashboard', name: t('route.dashboard'), component: defaultDashboard },
  { path: APP_WEBSERVICE_PATH+'/private', exact: true, name: t('route.home'), component: defaultDashboard },
  { path: APP_WEBSERVICE_PATH+'/private/edashboard', exact: true, name: t('route.home'), component: ExternalDashboardView },
  { path: APP_WEBSERVICE_PATH+'/private/map', name: t('route.map'), component: MapView},
  { path: APP_WEBSERVICE_PATH+'/private/email/send-massive', name: t('route.send_mail'), component: SendEmailView },
  { path: APP_WEBSERVICE_PATH+'/private/elements',name: t('route.devices'),component: DevicesView},
  { path: APP_WEBSERVICE_PATH+'/private/user/list',name: t('route.users'),component: ListUsersView},
  { path: APP_WEBSERVICE_PATH+'/private/permissions',name: t('route.permissions'),component: PermissionView},
  { path: APP_WEBSERVICE_PATH+'/private/user/profile', name: t('route.profile'), component: ProfileView },
  { path: APP_WEBSERVICE_PATH+'/private/email/template-email-password', name: t('route.edit_template_password'), component: ChangePasswordEmailTemplateView },
  { path: APP_WEBSERVICE_PATH+'/private/email/template-email-register', name: t('route.edit_template_register'), component: NewRegisterEmailTemplateView },
  { path: APP_WEBSERVICE_PATH+'/private/email/log', name: t('route.log_mails'), component: EmaiLogView },
  { path: APP_WEBSERVICE_PATH+'/private/events/', name: t('route.event_list'), component: ListEventsView },
  { path: APP_WEBSERVICE_PATH+'/private/vehicle/chart-section', name: t('route.vehicle_charts'), component: VehicleChartViewSection },
  { path: APP_WEBSERVICE_PATH+'/private/devices', name: t('route.edit_cameras'), component: UpsertAiCameraView },
  { path: APP_WEBSERVICE_PATH+'/private/sequences', name: t('route.edit_sequences'), component: CameraSequencesView },
  { path: APP_WEBSERVICE_PATH+'/private/layout', name: t('route.camera_layout'), component: GenericLayoutView },
  { path: APP_WEBSERVICE_PATH+'/private/recordings', name: t('route.camera_recordings'), component: CameraViewRecording },
  { path: APP_WEBSERVICE_PATH+'/private/videowalls', name: t('route.videowalls'), component: VideowallView },
  { path: APP_WEBSERVICE_PATH+'/private/facerecognition/show', name: t('route.face_recognition'), component: FaceRecognitionIdByUrlView },
  { path: APP_WEBSERVICE_PATH+'/private/roles', name:t('route.roles'), component: RolesCrud },
  { path: APP_WEBSERVICE_PATH+'/private/areas', name:t('route.areas'), component: AreasCrud },
  { path: APP_WEBSERVICE_PATH+'/private/zones', name:t('route.zones'), component: ZonesCrud },
  { path: APP_WEBSERVICE_PATH+'/private/imageoverlay', name:t('route.image_overlay'), component: ImageOverlayCrud },
  { path: APP_WEBSERVICE_PATH+'/private/facerecognition', name: t('route.face_recognition'), component: FaceRecognitionTable },
  { path: APP_WEBSERVICE_PATH+'/private/alpr', name: t('route.alpr'), component: AlprTable },
  { path: APP_WEBSERVICE_PATH+'/private/alprblacklist', name: t('route.alprBlacklistGroup'), component: AlprBLacklistGroupCrud },
  { path: APP_WEBSERVICE_PATH+'/private/alprwhitelist', name: t('route.alprWhitelistGroup'), component: AlprWhitelistGroupCrud },
  { path: APP_WEBSERVICE_PATH+'/private/detection', name: t('route.detections'), component: DetectionView },
  { path: APP_WEBSERVICE_PATH+'/private/taskview', name: t('route.taskview'), component: TaskView },
  { path: APP_WEBSERVICE_PATH+'/private/radar', name: t('route.radars'), component: RadarCrud },
  { path: APP_WEBSERVICE_PATH+'/private/sections', name: t('route.sections'), component: SectionCrud },
  { path: APP_WEBSERVICE_PATH+'/private/elementstatus', name: t('route.elementstatus'), component: ElementStatusTableView },
  { path: APP_WEBSERVICE_PATH+'/private/detectioncameraschedule', name: t('route.detectioncameraschedule'), component: DetectionCameraScheduleView },
  { path: APP_WEBSERVICE_PATH+'/private/forecast', name: t('route.forecast_labels'), component: ForecastCrudView },
  { path: APP_WEBSERVICE_PATH+'/private/routes', name: t('route.routes'), component: RouteView },
  { path: APP_WEBSERVICE_PATH+'/private/routes-map', name: t('route.routes_map'), component: RouteMapSearchView },
  { path: APP_WEBSERVICE_PATH+'/private/data-count', name: t('route.data-count'), component: DataCountView },
  { path: APP_WEBSERVICE_PATH+'/private/external_board', name: t('route.external_board'), component: ExternalBoardView },
  { path: APP_WEBSERVICE_PATH+'/private/camera_tour', name: t('route.camera_tour'), component: CameraTourView },
  { path: APP_WEBSERVICE_PATH+'/private/ai_modules', name: t('route.ai_modules'), component: AiModulesView },
  { path: APP_WEBSERVICE_PATH+'/private/vehicle_categories', name: t('route.vehicle_categories'), component: VehicleCategoriesView },
  { path: APP_WEBSERVICE_PATH+'/private/parameter_alarm', name: t('route.parameter_alarm'), component: ParameterAlarm },
  { path: APP_WEBSERVICE_PATH+'/private/event-show', name: t('route.event_list'), component: InfoEventView },
  { path: APP_WEBSERVICE_PATH+'/private/hikvisionsettings',name:t('route.hikvisionsettings'),component:HikvisionSettings},
  { path: APP_WEBSERVICE_PATH+'/private/historicalstatus',name:t('route.historical_status'),component:HistoricalStatus},
  { path: APP_WEBSERVICE_PATH+'/private/roadsections',name:t('route.road_sections'),component:RoadSectionView},
    { path: APP_WEBSERVICE_PATH+'/private/version',name:t('route.version'),component:VersionView}
//    { path: APP_WEBSERVICE_PATH+'/private/iframe', name: t('route.event_list'), component: GenericIframe },
    // { path: APP_WEBSERVICE_PATH+'/private/cvat', name: t('route.event_list'), component: CVatIframe },

  ];
return routesMap;
}


export default routes;

