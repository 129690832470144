import React from 'react';
import {  useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import * as H from "history";

export interface RouteComponentProps<
  Params extends { [K in keyof Params]?: string } = {},
  S = H.LocationState,
  > {
  history: H.History<S>;
  location: H.Location<S>;
}


const withRouter = WrappedComponent => props => {
  const params = useParams();
  //const history = useHistory();
  const location = useLocation();

  return (
    <WrappedComponent
      {...props}
      params={params}
      location={location}
     // history={history}
    />
  );
};

export default withRouter;
