import React, {Component} from "react";
import {withTranslation} from "react-i18next";
//import 'react-flags-select/css/react-flags-select.css';
import ReactFlagsSelect from "react-flags-select";
import {Col} from "reactstrap";
import i18n from "~/config/i18n.jsx";

class FlagLanguageSelector extends Component {

  constructor(props) {
    super(props);
    this.onSelectFlag=this.onSelectFlag.bind(this);
    this.state = {
      selected:""
    }
  }

   static getSavedLanguage() {
    return localStorage.getItem("lng");
  }

  componentDidMount(){
    let savedLanguage = FlagLanguageSelector.getSavedLanguage();
    let selectedLanguage = null;
      if (savedLanguage != null) {
        selectedLanguage = savedLanguage;
      }else{
        selectedLanguage = this.props.defaultLanguage;
      }
    if (i18n.language !== selectedLanguage.toLowerCase()) {
      i18n.changeLanguage(selectedLanguage.toLowerCase());
    }
    if (this.state.selected !== selectedLanguage.toUpperCase())
      this.setState({selected:selectedLanguage.toUpperCase()});

  //  this.onSelectFlag(this.props.defaultLanguage);
  }

  //onSelect Method
  onSelectFlag(countryCode){
    i18n.changeLanguage(countryCode.toLowerCase());
    this.setState({selected : countryCode});
    localStorage.setItem("lng", countryCode.toLowerCase());
  }



  render() {

    // eslint-disable-next-line
    const {t} = this.props;

    return (
      <React.Fragment>
        <Col>
        <ReactFlagsSelect
          selected={this.state.selected}
          defaultCountry={this.props.defaultLanguage}
          countries={["BR", "ES", "US"]}
          customLabels={{"ES": "Español","US": "English","BR": "Português"}}
          selectButtonClassName={"d-flex header-text"}
          className={"text-black "}

          onSelect={this.onSelectFlag}
        />
        </Col>
      </React.Fragment>
    );
  }
}



export default withTranslation('common')(FlagLanguageSelector);
