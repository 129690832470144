import {APP_WEBSERVICE_PATH, clearAccessToken, getAccessToken, getAccessTokenRenewIfNeeded} from "./LoginUtils";
import axios from 'axios';

let CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const newRequestPage = () => {

  source.cancel("New Page Requested");
  CancelToken = axios.CancelToken;
  source = CancelToken.source();
};

export const request = (options) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');
  let accessToken = getAccessToken();
  if (accessToken) {
    headers.append('Authorization', 'Bearer ' + accessToken)
  }
  //Auto transformamos a objeto si aplica
  if (options.body != null && typeof options.body === 'object') {
    options.body = JSON.stringify(options.body);
  }

  const defaults = {headers: headers};
  options = Object.assign({}, defaults, options);
  let url;
  if (options.params != null) {
    url = new URL(options.url, document.location);
    Object.keys(options.params).forEach(key => url.searchParams.append(key, options.params[key]));
  }
  else {
    url = options.url;
  }
  return fetch(url, options)
    .then(response =>
      response.json().then(json => {
        if (!response.ok) {
          //Si tenemos un 401, no estamos autenticados... limpiamos token y forzamos refresh
          if (response.status === 401) {
            clearAccessToken();
            window.location.href = "/";
          }

          return Promise.reject(json);
        }
        return json;
      })
    );

};

export const privRequest = (options) => {
    let accessToken = getAccessTokenRenewIfNeeded();
    if (accessToken != null) {
      if (!options.headers)
        options.headers = {};
      options.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    options.cancelToken = source.token;
    return axios(options).then(response => {
        return Promise.resolve(response);
      }
    ).catch(error => {
      if (axios.isCancel(error)){
        return Promise.reject(error);
      }
      if (error != null && error.response != null && error.response.status === 401) {
        clearAccessToken();
        window.location.href =  APP_WEBSERVICE_PATH+"/login?location=" + btoa(window.location.pathname);
      }
      return Promise.reject(error);
    });
  }
;

export const pubRequest = (options) => {
  options.cancelToken = source.token;
  return axios(options).then(response => {
    return Promise.resolve(response);
  }).catch(error => {
    return Promise.reject(error);
  });
};


/**
 * This function allow you to modify a JS Promise by adding some status properties.
 * Based on: http://stackoverflow.com/questions/21485545/is-there-a-way-to-tell-if-an-es6-promise-is-fulfilled-rejected-resolved
 * But modified according to the specs of promises : https://promisesaplus.com/
 */
export function MakeQuerablePromise(promise) {
  // Don't modify any promise that has been already modified.
  if (promise.isResolved) return promise;

  // Set initial state
  let isPending = true;
  let isRejected = false;
  let isFulfilled = false;

  // Observe the promise, saving the fulfillment in a closure scope.
  let result = promise.then(
    function (v) {
      isFulfilled = true;
      isPending = false;
      return v;
    },
    function (e) {
      isRejected = true;
      isPending = false;
      throw e;
    }
  );

  result.isFulfilled = function () {
    return isFulfilled;
  };
  result.isPending = function () {
    return isPending;
  };
  result.isRejected = function () {
    return isRejected;
  };
  return result;
}
