import React, {Component} from 'react';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row
} from 'reactstrap';
import {login,isLogged} from "~/external-modules/dlv-web-common/util/LoginUtils";
import queryString from 'query-string'
import {logo_login} from "~/custom/logo";
import {withTranslation} from "react-i18next";
import {APP_WEBSERVICE_PATH} from "~/external-modules/dlv-web-common/util/LoginUtils";
import withRouter from "~/external-modules/dlv-web-common/component/Route/WithRouter";



class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      errorText: '',
      readyToShow: false,
      passwordClicked: false
    };

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.redirectToPrivateIfLogged = this.redirectToPrivateIfLogged.bind(this);
    this.handlePasswordClick = this.handlePasswordClick.bind(this);
  }

  componentDidMount() {

    this.redirectToPrivateIfLogged();

  }

  handlePasswordClick(e){
    if (this.state.passwordClicked === false){
      e.target.select();
      this.setState({passwordClicked:true});
    }
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleClick();
    }
  }

  redirectToPrivateIfLogged() {
    if (isLogged()) {
      window.location.href = APP_WEBSERVICE_PATH +'/private/';
    } else {
      //Prevenir render de login
      this.setState({readyToShow: true});
    }
  }

  createUrl(newPath) {
    let newUrl = window.location.protocol + "//" + window.location.hostname;
    if (window.location.port !== '') {
      newUrl += window.location.port;
    }
    return newUrl + newPath;
  }

  handleClick() {
    const {t} = this.props;
    //login()
    let that = this;
    login(this.state.username, this.state.password).then(function (token) {
      if (token != null) {
        //console.log(that.props.location);
        if (that.props.location.search != null && that.props.location.search !== "") {
          const values = queryString.parse(that.props.location.search);
          //console.log(values);
          //console.log(that.props.location.search);
          window.location.href = atob(values.location);
        } else {
          window.location.href = APP_WEBSERVICE_PATH +'/private/';
        }
      } else {
        that.setState({
          errorText: t("login.error.error_login")
        });
      }
    }).catch(function (errorData) {
      let newErrorText = "";
      //const errorText = errorData.error_description ? errorData.error_description : errorData.message;
      //const errorType = errorData.error ? errorData.error : errorData.stack;
      if (errorData.response != null) {
        if (errorData.response.status === 401) {
          newErrorText = t("login.error.user_password_incorrect");
        } else if (errorData.response.status === 503) {
          newErrorText = t("login.error.service_login_unavailable");
        } else {
          newErrorText = errorData.response.statusText;
          console.log(errorData.response);
        }
      } else {
        newErrorText = errorData.error_description ? errorData.error_description : errorData.message;
      }
      that.setState({
        errorText: newErrorText,
        passwordClicked:false
      });
    });

  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }


  render() {
    const {t} = this.props;

    const errorParagraph =
      this.state.errorText ?
        <React.Fragment>
          <span className="test-muted">{t("login.error.error_login")}: </span>
          <p className="test-muted">{this.state.errorText}</p>
        </React.Fragment>
        : null;

    return (
      this.state.readyToShow ?
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="4">
                <CardGroup>
                  <Card className="p-4">
                    <CardBody>
                      <h1 style={{"display": "block", "margin": "auto", "textAlign": "center"}}>{t("login.login")}</h1>
                      <img className="login-logo" alt="logo" src={logo_login}
                           />
                      {errorParagraph}
                      <InputGroup className="mb-3">

                          <InputGroupText>
                            <i className="fa fa-user"></i>
                          </InputGroupText>

                        <Input type="text" placeholder={t("login.username")} name="username" value={this.state.username}
                               onChange={this.handleInputChange}/>
                      </InputGroup>
                      <InputGroup className="mb-4">

                          <InputGroupText>
                            <i className="fa fa-lock"></i>
                          </InputGroupText>

                        <Input name="password" type="password" placeholder={t("login.password")}value={this.state.password}
                               onChange={this.handleInputChange} onKeyPress={this.handleKeyPress} onClick={this.handlePasswordClick}/>
                      </InputGroup>
                      <Row>
                        <Col xs={{size: 6, offset: 3}} className="text-center">
                          <Button onClick={this.handleClick} color="primary" /*className="px-4"*/>{t("login.login_button")}</Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div> : ''
    );
  }
}

export default withRouter(withTranslation('common')(Login));
