/* tslint:disable */
/* eslint-disable */
/**
 * VehicleDataService API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiError
 */
export interface ApiError {
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'errorCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'target'?: string;
}
/**
 * 
 * @export
 * @interface ApiErrorResponse
 */
export interface ApiErrorResponse {
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiErrorResponse
     */
    'errorList'?: Array<ApiError>;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiErrorResponse
     */
    'error'?: ApiError;
}
/**
 * 
 * @export
 * @interface Chart
 */
export interface Chart {
    /**
     * 
     * @type {Array<string>}
     * @memberof Chart
     */
    'labels'?: Array<string>;
    /**
     * 
     * @type {Array<Dataset>}
     * @memberof Chart
     */
    'datasets'?: Array<Dataset>;
}
/**
 * 
 * @export
 * @interface ChartWrapper
 */
export interface ChartWrapper {
    /**
     * 
     * @type {Chart}
     * @memberof ChartWrapper
     */
    'chart'?: Chart;
    /**
     * 
     * @type {string}
     * @memberof ChartWrapper
     */
    'type'?: ChartWrapperTypeEnum;
}

export const ChartWrapperTypeEnum = {
    Xlabel: 'XLABEL',
    Xylabel: 'XYLABEL'
} as const;

export type ChartWrapperTypeEnum = typeof ChartWrapperTypeEnum[keyof typeof ChartWrapperTypeEnum];

/**
 * 
 * @export
 * @interface Crs
 */
export interface Crs {
    /**
     * 
     * @type {string}
     * @memberof Crs
     */
    'type'?: CrsTypeEnum;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Crs
     */
    'properties'?: { [key: string]: object; };
}

export const CrsTypeEnum = {
    Name: 'name',
    Link: 'link'
} as const;

export type CrsTypeEnum = typeof CrsTypeEnum[keyof typeof CrsTypeEnum];

/**
 * 
 * @export
 * @interface DataCountCameraSearch
 */
export interface DataCountCameraSearch {
    /**
     * 
     * @type {number}
     * @memberof DataCountCameraSearch
     */
    'cameraId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataCountCameraSearch
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface DataCountDTO
 */
export interface DataCountDTO {
    /**
     * 
     * @type {number}
     * @memberof DataCountDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataCountDTO
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof DataCountDTO
     */
    'detectionAreaId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataCountDTO
     */
    'sectionIdStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataCountDTO
     */
    'sectionIdEnd'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataCountDTO
     */
    'cameraId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataCountDTO
     */
    'moduleId'?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof DataCountDTO
     */
    'properties'?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof DataCountDTO
     */
    'objectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataCountDTO
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataCountDTO
     */
    'serverDate'?: string;
}
/**
 * 
 * @export
 * @interface Dataset
 */
export interface Dataset {
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'label'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof Dataset
     */
    'data'?: Array<object>;
}
/**
 * 
 * @export
 * @interface ExternalBoardDTO
 */
export interface ExternalBoardDTO {
    /**
     * 
     * @type {number}
     * @memberof ExternalBoardDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExternalBoardDTO
     */
    'type'?: ExternalBoardDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ExternalBoardDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalBoardDTO
     */
    'hash'?: string;
}

export const ExternalBoardDTOTypeEnum = {
    Metabase: 'METABASE',
    Jasper: 'JASPER'
} as const;

export type ExternalBoardDTOTypeEnum = typeof ExternalBoardDTOTypeEnum[keyof typeof ExternalBoardDTOTypeEnum];

/**
 * 
 * @export
 * @interface Feature
 */
export interface Feature {
    /**
     * 
     * @type {Crs}
     * @memberof Feature
     */
    'crs'?: Crs;
    /**
     * 
     * @type {Array<number>}
     * @memberof Feature
     */
    'bbox'?: Array<number>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Feature
     */
    'properties'?: { [key: string]: object; };
    /**
     * 
     * @type {FeatureGeometry}
     * @memberof Feature
     */
    'geometry'?: FeatureGeometry;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface FeatureCollection
 */
export interface FeatureCollection {
    /**
     * 
     * @type {Crs}
     * @memberof FeatureCollection
     */
    'crs'?: Crs;
    /**
     * 
     * @type {Array<number>}
     * @memberof FeatureCollection
     */
    'bbox'?: Array<number>;
    /**
     * 
     * @type {Array<Feature>}
     * @memberof FeatureCollection
     */
    'features'?: Array<Feature>;
}
/**
 * @type FeatureGeometry
 * @export
 */
export type FeatureGeometry = Feature | FeatureCollection | GeometryCollection | LineString | MultiLineString | MultiPoint | MultiPolygon | Point | Polygon;

/**
 * 
 * @export
 * @interface GeoJsonObject
 */
export interface GeoJsonObject {
    /**
     * 
     * @type {Crs}
     * @memberof GeoJsonObject
     */
    'crs'?: Crs;
    /**
     * 
     * @type {Array<number>}
     * @memberof GeoJsonObject
     */
    'bbox'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof GeoJsonObject
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface GeometryCollection
 */
export interface GeometryCollection extends GeoJsonObject {
    /**
     * 
     * @type {Array<GeoJsonObject>}
     * @memberof GeometryCollection
     */
    'geometries'?: Array<GeoJsonObject>;
}
/**
 * 
 * @export
 * @interface GeometryCollectionAllOf
 */
export interface GeometryCollectionAllOf {
    /**
     * 
     * @type {Array<GeoJsonObject>}
     * @memberof GeometryCollectionAllOf
     */
    'geometries'?: Array<GeoJsonObject>;
}
/**
 * 
 * @export
 * @interface Hash
 */
export interface Hash {
    /**
     * 
     * @type {string}
     * @memberof Hash
     */
    'urlHash'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hash
     */
    'bbddHash'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hash
     */
    'externalBoardType'?: HashExternalBoardTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Hash
     */
    'lastUpdate'?: string;
}

export const HashExternalBoardTypeEnum = {
    Metabase: 'METABASE',
    Jasper: 'JASPER'
} as const;

export type HashExternalBoardTypeEnum = typeof HashExternalBoardTypeEnum[keyof typeof HashExternalBoardTypeEnum];

/**
 * 
 * @export
 * @interface LineString
 */
export interface LineString extends GeoJsonObject {
    /**
     * 
     * @type {Array<LngLatAlt>}
     * @memberof LineString
     */
    'coordinates'?: Array<LngLatAlt>;
}
/**
 * 
 * @export
 * @interface LineStringAllOf
 */
export interface LineStringAllOf {
    /**
     * 
     * @type {Array<LngLatAlt>}
     * @memberof LineStringAllOf
     */
    'coordinates'?: Array<LngLatAlt>;
}
/**
 * 
 * @export
 * @interface LngLatAlt
 */
export interface LngLatAlt {
    /**
     * 
     * @type {number}
     * @memberof LngLatAlt
     */
    'longitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof LngLatAlt
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof LngLatAlt
     */
    'altitude'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof LngLatAlt
     */
    'additionalElements'?: Array<number>;
}
/**
 * 
 * @export
 * @interface MultiLineString
 */
export interface MultiLineString extends GeoJsonObject {
    /**
     * 
     * @type {Array<Array<LngLatAlt>>}
     * @memberof MultiLineString
     */
    'coordinates'?: Array<Array<LngLatAlt>>;
}
/**
 * 
 * @export
 * @interface MultiLineStringAllOf
 */
export interface MultiLineStringAllOf {
    /**
     * 
     * @type {Array<Array<LngLatAlt>>}
     * @memberof MultiLineStringAllOf
     */
    'coordinates'?: Array<Array<LngLatAlt>>;
}
/**
 * 
 * @export
 * @interface MultiPoint
 */
export interface MultiPoint extends GeoJsonObject {
    /**
     * 
     * @type {Array<LngLatAlt>}
     * @memberof MultiPoint
     */
    'coordinates'?: Array<LngLatAlt>;
}
/**
 * 
 * @export
 * @interface MultiPolygon
 */
export interface MultiPolygon extends GeoJsonObject {
    /**
     * 
     * @type {Array<Array<Array<LngLatAlt>>>}
     * @memberof MultiPolygon
     */
    'coordinates'?: Array<Array<Array<LngLatAlt>>>;
}
/**
 * 
 * @export
 * @interface MultiPolygonAllOf
 */
export interface MultiPolygonAllOf {
    /**
     * 
     * @type {Array<Array<Array<LngLatAlt>>>}
     * @memberof MultiPolygonAllOf
     */
    'coordinates'?: Array<Array<Array<LngLatAlt>>>;
}
/**
 * 
 * @export
 * @interface ParameterAlarmCheckDTO
 */
export interface ParameterAlarmCheckDTO {
    /**
     * 
     * @type {number}
     * @memberof ParameterAlarmCheckDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterAlarmCheckDTO
     */
    'parameterAlarmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterAlarmCheckDTO
     */
    'elementId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParameterAlarmCheckDTO
     */
    'checkType'?: ParameterAlarmCheckDTOCheckTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ParameterAlarmCheckDTO
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterAlarmCheckDTO
     */
    'deactivationOffset'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParameterAlarmCheckDTO
     */
    'properties'?: string;
}

export const ParameterAlarmCheckDTOCheckTypeEnum = {
    Eq: 'EQ',
    Contains: 'CONTAINS',
    Neq: 'NEQ',
    Ge: 'GE',
    Le: 'LE',
    Gt: 'GT',
    Lt: 'LT',
    EqNull: 'EQ_NULL',
    None: 'NONE'
} as const;

export type ParameterAlarmCheckDTOCheckTypeEnum = typeof ParameterAlarmCheckDTOCheckTypeEnum[keyof typeof ParameterAlarmCheckDTOCheckTypeEnum];

/**
 * 
 * @export
 * @interface ParameterAlarmDTO
 */
export interface ParameterAlarmDTO {
    /**
     * 
     * @type {number}
     * @memberof ParameterAlarmDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParameterAlarmDTO
     */
    'tableName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParameterAlarmDTO
     */
    'columnName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParameterAlarmDTO
     */
    'eventTypeId'?: number;
}
/**
 * 
 * @export
 * @interface Point
 */
export interface Point extends GeoJsonObject {
    /**
     * 
     * @type {LngLatAlt}
     * @memberof Point
     */
    'coordinates'?: LngLatAlt;
}
/**
 * 
 * @export
 * @interface PointAllOf
 */
export interface PointAllOf {
    /**
     * 
     * @type {LngLatAlt}
     * @memberof PointAllOf
     */
    'coordinates'?: LngLatAlt;
}
/**
 * 
 * @export
 * @interface Polygon
 */
export interface Polygon extends GeoJsonObject {
    /**
     * 
     * @type {Array<Array<LngLatAlt>>}
     * @memberof Polygon
     */
    'coordinates'?: Array<Array<LngLatAlt>>;
}
/**
 * 
 * @export
 * @interface RequestChartData
 */
export interface RequestChartData {
    /**
     * 
     * @type {string}
     * @memberof RequestChartData
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestChartData
     */
    'to'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof RequestChartData
     */
    'objectClassificationIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof RequestChartData
     */
    'sectionIdStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestChartData
     */
    'sectionIdEnd'?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestChartData
     */
    'side'?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestChartData
     */
    'dateRangeSortIntervalNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof RequestChartData
     */
    'dateRangeSortIntervalType'?: RequestChartDataDateRangeSortIntervalTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof RequestChartData
     */
    'daysOfWeek'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof RequestChartData
     */
    'maxPercentErrorAllowed'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RequestChartData
     */
    'fillEmptyDatesInDB'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestChartData
     */
    'autofit'?: boolean;
}

export const RequestChartDataDateRangeSortIntervalTypeEnum = {
    Minute: 'MINUTE',
    Hour: 'HOUR',
    Day: 'DAY',
    Week: 'WEEK',
    Month: 'MONTH',
    Year: 'YEAR'
} as const;

export type RequestChartDataDateRangeSortIntervalTypeEnum = typeof RequestChartDataDateRangeSortIntervalTypeEnum[keyof typeof RequestChartDataDateRangeSortIntervalTypeEnum];

/**
 * 
 * @export
 * @interface RoadDTO
 */
export interface RoadDTO {
    /**
     * 
     * @type {number}
     * @memberof RoadDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoadDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoadDTO
     */
    'pk'?: string;
    /**
     * 
     * @type {Array<RoadSectionDTO>}
     * @memberof RoadDTO
     */
    'roadSectionDTOList'?: Array<RoadSectionDTO>;
}
/**
 * 
 * @export
 * @interface RoadSectionDTO
 */
export interface RoadSectionDTO {
    /**
     * 
     * @type {number}
     * @memberof RoadSectionDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoadSectionDTO
     */
    'dir'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoadSectionDTO
     */
    'sectionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoadSectionDTO
     */
    'roadId'?: number;
}
/**
 * 
 * @export
 * @interface SectionDTO
 */
export interface SectionDTO {
    /**
     * 
     * @type {number}
     * @memberof SectionDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionDTO
     */
    'code'?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionDTO
     */
    'offsetLat'?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionDTO
     */
    'offsetLong'?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionDTO
     */
    'sectionType'?: SectionDTOSectionTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SectionDTO
     */
    'visible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SectionDTO
     */
    'zoneId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionDTO
     */
    'maxSpeed'?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionDTO
     */
    'sectionPair'?: number;
    /**
     * 
     * @type {Array<SectionGeometryDTO>}
     * @memberof SectionDTO
     */
    'sectionGeometryDTO'?: Array<SectionGeometryDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof SectionDTO
     */
    'active'?: boolean;
}

export const SectionDTOSectionTypeEnum = {
    Unknown: 'UNKNOWN',
    Route: 'ROUTE',
    Segment: 'SEGMENT',
    BusRoute: 'BUS_ROUTE'
} as const;

export type SectionDTOSectionTypeEnum = typeof SectionDTOSectionTypeEnum[keyof typeof SectionDTOSectionTypeEnum];

/**
 * 
 * @export
 * @interface SectionGeometryDTO
 */
export interface SectionGeometryDTO {
    /**
     * 
     * @type {number}
     * @memberof SectionGeometryDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionGeometryDTO
     */
    'index'?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionGeometryDTO
     */
    'longitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionGeometryDTO
     */
    'latitude'?: number;
}
/**
 * 
 * @export
 * @interface SelectOption
 */
export interface SelectOption {
    /**
     * 
     * @type {string}
     * @memberof SelectOption
     */
    'label'?: string;
    /**
     * 
     * @type {object}
     * @memberof SelectOption
     */
    'value'?: object;
}
/**
 * 
 * @export
 * @interface SuccessfulResponse
 */
export interface SuccessfulResponse {
    /**
     * 
     * @type {string}
     * @memberof SuccessfulResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface VehicleDataCount
 */
export interface VehicleDataCount {
    /**
     * 
     * @type {string}
     * @memberof VehicleDataCount
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataCount
     */
    'elementId'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataCount
     */
    'objectNameId'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataCount
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataCount
     */
    'side'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof VehicleDataCount
     */
    'speed'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataCount
     */
    'sectionIdStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataCount
     */
    'sectionIdEnd'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataCount
     */
    'lane'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataCount
     */
    'occupancyTimePercentAvg'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataCount
     */
    'occupancySpacePercentAvg'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataCount
     */
    'headwayTimeAvg'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataCount
     */
    'headwaySpaceAvg'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataCount
     */
    'lengthAvg'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataCount
     */
    'density'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataCount
     */
    'gapAvg'?: number;
}
/**
 * 
 * @export
 * @interface VehicleDataHist
 */
export interface VehicleDataHist {
    /**
     * 
     * @type {string}
     * @memberof VehicleDataHist
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataHist
     */
    'elementId'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataHist
     */
    'sectionIdStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataHist
     */
    'sectionIdEnd'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataHist
     */
    'objectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataHist
     */
    'side'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataHist
     */
    'speed'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataHist
     */
    'gap'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataHist
     */
    'lane'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataHist
     */
    'length'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataHist
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataHist
     */
    'headwayTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataHist
     */
    'headwaySpace'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataHist
     */
    'occupancySpace'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataHist
     */
    'occupancyTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDataHist
     */
    'trackId'?: number;
}

/**
 * ChartDataControllerApi - axios parameter creator
 * @export
 */
export const ChartDataControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartADTHour2: async (requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartADTHour2', 'requestChartData', requestChartData)
            const localVarPath = `/chart/adt/hour`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartADTHour3: async (requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartADTHour3', 'requestChartData', requestChartData)
            const localVarPath = `/chart/adt/hour/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartADTWeekday2: async (requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartADTWeekday2', 'requestChartData', requestChartData)
            const localVarPath = `/chart/adt/weekday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartSpeed2: async (requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartSpeed2', 'requestChartData', requestChartData)
            const localVarPath = `/chart/speeds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartSpeed3: async (requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartSpeed3', 'requestChartData', requestChartData)
            const localVarPath = `/chart/speeds/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartTotalCount2: async (requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartTotalCount2', 'requestChartData', requestChartData)
            const localVarPath = `/chart/totalCount/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartTotalCount3: async (requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartTotalCount3', 'requestChartData', requestChartData)
            const localVarPath = `/chart/totalCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartTotalCountVsSpeed2: async (requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartTotalCountVsSpeed2', 'requestChartData', requestChartData)
            const localVarPath = `/chart/totalCountVsSpeed/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartTotalCountVsSpeed3: async (requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartTotalCountVsSpeed3', 'requestChartData', requestChartData)
            const localVarPath = `/chart/totalCountVsSpeed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTest2: async (requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getTest2', 'requestChartData', requestChartData)
            const localVarPath = `/chart/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTest3: async (requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getTest3', 'requestChartData', requestChartData)
            const localVarPath = `/chart/test/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChartDataControllerApi - functional programming interface
 * @export
 */
export const ChartDataControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChartDataControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartADTHour2(requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartADTHour2(requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartADTHour3(requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartADTHour3(requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartADTWeekday2(requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartADTWeekday2(requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartSpeed2(requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartSpeed2(requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartSpeed3(requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartSpeed3(requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartTotalCount2(requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartTotalCount2(requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartTotalCount3(requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartTotalCount3(requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartTotalCountVsSpeed2(requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartTotalCountVsSpeed2(requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartTotalCountVsSpeed3(requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartTotalCountVsSpeed3(requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTest2(requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTest2(requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTest3(requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTest3(requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChartDataControllerApi - factory interface
 * @export
 */
export const ChartDataControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChartDataControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartADTHour2(requestChartData: RequestChartData, options?: any): AxiosPromise<ChartWrapper> {
            return localVarFp.getChartADTHour2(requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartADTHour3(requestChartData: RequestChartData, options?: any): AxiosPromise<ChartWrapper> {
            return localVarFp.getChartADTHour3(requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartADTWeekday2(requestChartData: RequestChartData, options?: any): AxiosPromise<ChartWrapper> {
            return localVarFp.getChartADTWeekday2(requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartSpeed2(requestChartData: RequestChartData, options?: any): AxiosPromise<ChartWrapper> {
            return localVarFp.getChartSpeed2(requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartSpeed3(requestChartData: RequestChartData, options?: any): AxiosPromise<ChartWrapper> {
            return localVarFp.getChartSpeed3(requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartTotalCount2(requestChartData: RequestChartData, options?: any): AxiosPromise<ChartWrapper> {
            return localVarFp.getChartTotalCount2(requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartTotalCount3(requestChartData: RequestChartData, options?: any): AxiosPromise<ChartWrapper> {
            return localVarFp.getChartTotalCount3(requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartTotalCountVsSpeed2(requestChartData: RequestChartData, options?: any): AxiosPromise<ChartWrapper> {
            return localVarFp.getChartTotalCountVsSpeed2(requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartTotalCountVsSpeed3(requestChartData: RequestChartData, options?: any): AxiosPromise<ChartWrapper> {
            return localVarFp.getChartTotalCountVsSpeed3(requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTest2(requestChartData: RequestChartData, options?: any): AxiosPromise<void> {
            return localVarFp.getTest2(requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTest3(requestChartData: RequestChartData, options?: any): AxiosPromise<void> {
            return localVarFp.getTest3(requestChartData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChartDataControllerApi - object-oriented interface
 * @export
 * @class ChartDataControllerApi
 * @extends {BaseAPI}
 */
export class ChartDataControllerApi extends BaseAPI {
    /**
     * 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartDataControllerApi
     */
    public getChartADTHour2(requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ChartDataControllerApiFp(this.configuration).getChartADTHour2(requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartDataControllerApi
     */
    public getChartADTHour3(requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ChartDataControllerApiFp(this.configuration).getChartADTHour3(requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartDataControllerApi
     */
    public getChartADTWeekday2(requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ChartDataControllerApiFp(this.configuration).getChartADTWeekday2(requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartDataControllerApi
     */
    public getChartSpeed2(requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ChartDataControllerApiFp(this.configuration).getChartSpeed2(requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartDataControllerApi
     */
    public getChartSpeed3(requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ChartDataControllerApiFp(this.configuration).getChartSpeed3(requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartDataControllerApi
     */
    public getChartTotalCount2(requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ChartDataControllerApiFp(this.configuration).getChartTotalCount2(requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartDataControllerApi
     */
    public getChartTotalCount3(requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ChartDataControllerApiFp(this.configuration).getChartTotalCount3(requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartDataControllerApi
     */
    public getChartTotalCountVsSpeed2(requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ChartDataControllerApiFp(this.configuration).getChartTotalCountVsSpeed2(requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartDataControllerApi
     */
    public getChartTotalCountVsSpeed3(requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ChartDataControllerApiFp(this.configuration).getChartTotalCountVsSpeed3(requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartDataControllerApi
     */
    public getTest2(requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ChartDataControllerApiFp(this.configuration).getTest2(requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartDataControllerApi
     */
    public getTest3(requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ChartDataControllerApiFp(this.configuration).getTest3(requestChartData, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataCountApi - axios parameter creator
 * @export
 */
export const DataCountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementDataCount: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteByIdElementDataCount', 'id', id)
            const localVarPath = `/dataCount/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDataCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dataCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataCountCameraSearch} dataCountCameraSearch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataCountByCameraAndDate: async (dataCountCameraSearch: DataCountCameraSearch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataCountCameraSearch' is not null or undefined
            assertParamExists('getDataCountByCameraAndDate', 'dataCountCameraSearch', dataCountCameraSearch)
            const localVarPath = `/dataCount/search/camera`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataCountCameraSearch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [face] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataCountImage: async (id: number, face?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDataCountImage', 'id', id)
            const localVarPath = `/dataCount/image/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (face !== undefined) {
                localVarQueryParameter['face'] = face;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [face] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataCountImageB64: async (id: number, face?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDataCountImageB64', 'id', id)
            const localVarPath = `/dataCount/image/{id}/b64`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (face !== undefined) {
                localVarQueryParameter['face'] = face;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementDataCount: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneElementDataCount', 'id', id)
            const localVarPath = `/dataCount/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectDataCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dataCount/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<DataCountDTO>} dataCountDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertDataCountBatch: async (dataCountDTO: Array<DataCountDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataCountDTO' is not null or undefined
            assertParamExists('insertDataCountBatch', 'dataCountDTO', dataCountDTO)
            const localVarPath = `/dataCount/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataCountDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataCountDTO} dataCountDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementDataCount: async (dataCountDTO: DataCountDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataCountDTO' is not null or undefined
            assertParamExists('insertElementDataCount', 'dataCountDTO', dataCountDTO)
            const localVarPath = `/dataCount/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataCountDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DataCountDTO} dataCountDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementDataCount: async (id: number, dataCountDTO: DataCountDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateElementDataCount', 'id', id)
            // verify required parameter 'dataCountDTO' is not null or undefined
            assertParamExists('updateElementDataCount', 'dataCountDTO', dataCountDTO)
            const localVarPath = `/dataCount/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataCountDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataCountApi - functional programming interface
 * @export
 */
export const DataCountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataCountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElementDataCount(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElementDataCount(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDataCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataCountDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDataCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DataCountCameraSearch} dataCountCameraSearch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataCountByCameraAndDate(dataCountCameraSearch: DataCountCameraSearch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataCountDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataCountByCameraAndDate(dataCountCameraSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [face] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataCountImage(id: number, face?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataCountImage(id, face, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [face] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataCountImageB64(id: number, face?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataCountImageB64(id, face, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneElementDataCount(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCountDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneElementDataCount(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectDataCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectDataCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<DataCountDTO>} dataCountDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertDataCountBatch(dataCountDTO: Array<DataCountDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataCountDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertDataCountBatch(dataCountDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DataCountDTO} dataCountDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertElementDataCount(dataCountDTO: DataCountDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCountDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertElementDataCount(dataCountDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DataCountDTO} dataCountDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateElementDataCount(id: number, dataCountDTO: DataCountDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCountDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateElementDataCount(id, dataCountDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataCountApi - factory interface
 * @export
 */
export const DataCountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataCountApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementDataCount(id: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElementDataCount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDataCount(options?: any): AxiosPromise<Array<DataCountDTO>> {
            return localVarFp.getAllDataCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataCountCameraSearch} dataCountCameraSearch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataCountByCameraAndDate(dataCountCameraSearch: DataCountCameraSearch, options?: any): AxiosPromise<Array<DataCountDTO>> {
            return localVarFp.getDataCountByCameraAndDate(dataCountCameraSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [face] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataCountImage(id: number, face?: boolean, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getDataCountImage(id, face, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [face] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataCountImageB64(id: number, face?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.getDataCountImageB64(id, face, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementDataCount(id: number, options?: any): AxiosPromise<DataCountDTO> {
            return localVarFp.getOneElementDataCount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectDataCount(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getSelectDataCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<DataCountDTO>} dataCountDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertDataCountBatch(dataCountDTO: Array<DataCountDTO>, options?: any): AxiosPromise<Array<DataCountDTO>> {
            return localVarFp.insertDataCountBatch(dataCountDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataCountDTO} dataCountDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementDataCount(dataCountDTO: DataCountDTO, options?: any): AxiosPromise<DataCountDTO> {
            return localVarFp.insertElementDataCount(dataCountDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DataCountDTO} dataCountDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementDataCount(id: number, dataCountDTO: DataCountDTO, options?: any): AxiosPromise<DataCountDTO> {
            return localVarFp.updateElementDataCount(id, dataCountDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataCountApi - object-oriented interface
 * @export
 * @class DataCountApi
 * @extends {BaseAPI}
 */
export class DataCountApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataCountApi
     */
    public deleteByIdElementDataCount(id: number, options?: AxiosRequestConfig) {
        return DataCountApiFp(this.configuration).deleteByIdElementDataCount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataCountApi
     */
    public getAllDataCount(options?: AxiosRequestConfig) {
        return DataCountApiFp(this.configuration).getAllDataCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataCountCameraSearch} dataCountCameraSearch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataCountApi
     */
    public getDataCountByCameraAndDate(dataCountCameraSearch: DataCountCameraSearch, options?: AxiosRequestConfig) {
        return DataCountApiFp(this.configuration).getDataCountByCameraAndDate(dataCountCameraSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {boolean} [face] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataCountApi
     */
    public getDataCountImage(id: number, face?: boolean, options?: AxiosRequestConfig) {
        return DataCountApiFp(this.configuration).getDataCountImage(id, face, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {boolean} [face] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataCountApi
     */
    public getDataCountImageB64(id: number, face?: boolean, options?: AxiosRequestConfig) {
        return DataCountApiFp(this.configuration).getDataCountImageB64(id, face, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataCountApi
     */
    public getOneElementDataCount(id: number, options?: AxiosRequestConfig) {
        return DataCountApiFp(this.configuration).getOneElementDataCount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataCountApi
     */
    public getSelectDataCount(options?: AxiosRequestConfig) {
        return DataCountApiFp(this.configuration).getSelectDataCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<DataCountDTO>} dataCountDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataCountApi
     */
    public insertDataCountBatch(dataCountDTO: Array<DataCountDTO>, options?: AxiosRequestConfig) {
        return DataCountApiFp(this.configuration).insertDataCountBatch(dataCountDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataCountDTO} dataCountDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataCountApi
     */
    public insertElementDataCount(dataCountDTO: DataCountDTO, options?: AxiosRequestConfig) {
        return DataCountApiFp(this.configuration).insertElementDataCount(dataCountDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DataCountDTO} dataCountDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataCountApi
     */
    public updateElementDataCount(id: number, dataCountDTO: DataCountDTO, options?: AxiosRequestConfig) {
        return DataCountApiFp(this.configuration).updateElementDataCount(id, dataCountDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExcelDataControllerApi - axios parameter creator
 * @export
 */
export const ExcelDataControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartADTHour1: async (requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartADTHour1', 'requestChartData', requestChartData)
            const localVarPath = `/excel/adt/hour`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartADTWeekday1: async (requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartADTWeekday1', 'requestChartData', requestChartData)
            const localVarPath = `/excel/adt/weekday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartSpeed1: async (requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartSpeed1', 'requestChartData', requestChartData)
            const localVarPath = `/excel/speeds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartTotalCount1: async (requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartTotalCount1', 'requestChartData', requestChartData)
            const localVarPath = `/excel/totalCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartTotalCountVsSpeed1: async (requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartTotalCountVsSpeed1', 'requestChartData', requestChartData)
            const localVarPath = `/excel/totalCountVsSpeed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTest1: async (requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getTest1', 'requestChartData', requestChartData)
            const localVarPath = `/excel/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExcelDataControllerApi - functional programming interface
 * @export
 */
export const ExcelDataControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExcelDataControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartADTHour1(requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartADTHour1(requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartADTWeekday1(requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartADTWeekday1(requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartSpeed1(requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartSpeed1(requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartTotalCount1(requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartTotalCount1(requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartTotalCountVsSpeed1(requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartTotalCountVsSpeed1(requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTest1(requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTest1(requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExcelDataControllerApi - factory interface
 * @export
 */
export const ExcelDataControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExcelDataControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartADTHour1(requestChartData: RequestChartData, options?: any): AxiosPromise<void> {
            return localVarFp.getChartADTHour1(requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartADTWeekday1(requestChartData: RequestChartData, options?: any): AxiosPromise<void> {
            return localVarFp.getChartADTWeekday1(requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartSpeed1(requestChartData: RequestChartData, options?: any): AxiosPromise<void> {
            return localVarFp.getChartSpeed1(requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartTotalCount1(requestChartData: RequestChartData, options?: any): AxiosPromise<void> {
            return localVarFp.getChartTotalCount1(requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartTotalCountVsSpeed1(requestChartData: RequestChartData, options?: any): AxiosPromise<void> {
            return localVarFp.getChartTotalCountVsSpeed1(requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTest1(requestChartData: RequestChartData, options?: any): AxiosPromise<void> {
            return localVarFp.getTest1(requestChartData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExcelDataControllerApi - object-oriented interface
 * @export
 * @class ExcelDataControllerApi
 * @extends {BaseAPI}
 */
export class ExcelDataControllerApi extends BaseAPI {
    /**
     * 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelDataControllerApi
     */
    public getChartADTHour1(requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ExcelDataControllerApiFp(this.configuration).getChartADTHour1(requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelDataControllerApi
     */
    public getChartADTWeekday1(requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ExcelDataControllerApiFp(this.configuration).getChartADTWeekday1(requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelDataControllerApi
     */
    public getChartSpeed1(requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ExcelDataControllerApiFp(this.configuration).getChartSpeed1(requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelDataControllerApi
     */
    public getChartTotalCount1(requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ExcelDataControllerApiFp(this.configuration).getChartTotalCount1(requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelDataControllerApi
     */
    public getChartTotalCountVsSpeed1(requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ExcelDataControllerApiFp(this.configuration).getChartTotalCountVsSpeed1(requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelDataControllerApi
     */
    public getTest1(requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ExcelDataControllerApiFp(this.configuration).getTest1(requestChartData, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExternalBoardsApi - axios parameter creator
 * @export
 */
export const ExternalBoardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementExternalBoards: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteByIdElementExternalBoards', 'id', id)
            const localVarPath = `/external-boards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllExternalBoards: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/external-boards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardHashById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDashboardHashById', 'id', id)
            const localVarPath = `/external-boards/boards/id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'METABASE' | 'JASPER'} externalBoardType 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardHashByName: async (externalBoardType: 'METABASE' | 'JASPER', name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalBoardType' is not null or undefined
            assertParamExists('getDashboardHashByName', 'externalBoardType', externalBoardType)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getDashboardHashByName', 'name', name)
            const localVarPath = `/external-boards/boards/{external-board-type}/{name}`
                .replace(`{${"external-board-type"}}`, encodeURIComponent(String(externalBoardType)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementExternalBoards: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneElementExternalBoards', 'id', id)
            const localVarPath = `/external-boards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectExternalBoards: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/external-boards/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExternalBoardDTO} externalBoardDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementExternalBoards: async (externalBoardDTO: ExternalBoardDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalBoardDTO' is not null or undefined
            assertParamExists('insertElementExternalBoards', 'externalBoardDTO', externalBoardDTO)
            const localVarPath = `/external-boards/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(externalBoardDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxy: async (requestEntity: object, hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestEntity' is not null or undefined
            assertParamExists('proxy', 'requestEntity', requestEntity)
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('proxy', 'hash', hash)
            const localVarPath = `/external-boards/boards/{hash}/**`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (requestEntity !== undefined) {
                localVarQueryParameter['requestEntity'] = requestEntity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxy1: async (requestEntity: object, hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestEntity' is not null or undefined
            assertParamExists('proxy1', 'requestEntity', requestEntity)
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('proxy1', 'hash', hash)
            const localVarPath = `/external-boards/boards/{hash}/**`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (requestEntity !== undefined) {
                localVarQueryParameter['requestEntity'] = requestEntity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxy2: async (requestEntity: object, hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestEntity' is not null or undefined
            assertParamExists('proxy2', 'requestEntity', requestEntity)
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('proxy2', 'hash', hash)
            const localVarPath = `/external-boards/boards/{hash}/**`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (requestEntity !== undefined) {
                localVarQueryParameter['requestEntity'] = requestEntity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxy3: async (requestEntity: object, hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestEntity' is not null or undefined
            assertParamExists('proxy3', 'requestEntity', requestEntity)
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('proxy3', 'hash', hash)
            const localVarPath = `/external-boards/boards/{hash}/**`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (requestEntity !== undefined) {
                localVarQueryParameter['requestEntity'] = requestEntity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxy4: async (requestEntity: object, hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestEntity' is not null or undefined
            assertParamExists('proxy4', 'requestEntity', requestEntity)
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('proxy4', 'hash', hash)
            const localVarPath = `/external-boards/boards/{hash}/**`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (requestEntity !== undefined) {
                localVarQueryParameter['requestEntity'] = requestEntity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxy5: async (requestEntity: object, hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestEntity' is not null or undefined
            assertParamExists('proxy5', 'requestEntity', requestEntity)
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('proxy5', 'hash', hash)
            const localVarPath = `/external-boards/boards/{hash}/**`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (requestEntity !== undefined) {
                localVarQueryParameter['requestEntity'] = requestEntity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxy6: async (requestEntity: object, hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestEntity' is not null or undefined
            assertParamExists('proxy6', 'requestEntity', requestEntity)
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('proxy6', 'hash', hash)
            const localVarPath = `/external-boards/boards/{hash}/**`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (requestEntity !== undefined) {
                localVarQueryParameter['requestEntity'] = requestEntity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ExternalBoardDTO} externalBoardDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementExternalBoards: async (id: number, externalBoardDTO: ExternalBoardDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateElementExternalBoards', 'id', id)
            // verify required parameter 'externalBoardDTO' is not null or undefined
            assertParamExists('updateElementExternalBoards', 'externalBoardDTO', externalBoardDTO)
            const localVarPath = `/external-boards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(externalBoardDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHash: async (hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('updateHash', 'hash', hash)
            const localVarPath = `/external-boards/boards/hash/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalBoardsApi - functional programming interface
 * @export
 */
export const ExternalBoardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExternalBoardsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElementExternalBoards(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElementExternalBoards(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllExternalBoards(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExternalBoardDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllExternalBoards(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardHashById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Hash>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardHashById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'METABASE' | 'JASPER'} externalBoardType 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardHashByName(externalBoardType: 'METABASE' | 'JASPER', name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Hash>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardHashByName(externalBoardType, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneElementExternalBoards(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalBoardDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneElementExternalBoards(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectExternalBoards(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectExternalBoards(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExternalBoardDTO} externalBoardDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertElementExternalBoards(externalBoardDTO: ExternalBoardDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalBoardDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertElementExternalBoards(externalBoardDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxy(requestEntity: object, hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxy(requestEntity, hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxy1(requestEntity: object, hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxy1(requestEntity, hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxy2(requestEntity: object, hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxy2(requestEntity, hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxy3(requestEntity: object, hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxy3(requestEntity, hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxy4(requestEntity: object, hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxy4(requestEntity, hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxy5(requestEntity: object, hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxy5(requestEntity, hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxy6(requestEntity: object, hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxy6(requestEntity, hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ExternalBoardDTO} externalBoardDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateElementExternalBoards(id: number, externalBoardDTO: ExternalBoardDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalBoardDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateElementExternalBoards(id, externalBoardDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHash(hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHash(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExternalBoardsApi - factory interface
 * @export
 */
export const ExternalBoardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExternalBoardsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementExternalBoards(id: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElementExternalBoards(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllExternalBoards(options?: any): AxiosPromise<Array<ExternalBoardDTO>> {
            return localVarFp.getAllExternalBoards(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardHashById(id: number, options?: any): AxiosPromise<Hash> {
            return localVarFp.getDashboardHashById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'METABASE' | 'JASPER'} externalBoardType 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardHashByName(externalBoardType: 'METABASE' | 'JASPER', name: string, options?: any): AxiosPromise<Hash> {
            return localVarFp.getDashboardHashByName(externalBoardType, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementExternalBoards(id: number, options?: any): AxiosPromise<ExternalBoardDTO> {
            return localVarFp.getOneElementExternalBoards(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectExternalBoards(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getSelectExternalBoards(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExternalBoardDTO} externalBoardDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementExternalBoards(externalBoardDTO: ExternalBoardDTO, options?: any): AxiosPromise<ExternalBoardDTO> {
            return localVarFp.insertElementExternalBoards(externalBoardDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxy(requestEntity: object, hash: string, options?: any): AxiosPromise<object> {
            return localVarFp.proxy(requestEntity, hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxy1(requestEntity: object, hash: string, options?: any): AxiosPromise<object> {
            return localVarFp.proxy1(requestEntity, hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxy2(requestEntity: object, hash: string, options?: any): AxiosPromise<object> {
            return localVarFp.proxy2(requestEntity, hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxy3(requestEntity: object, hash: string, options?: any): AxiosPromise<object> {
            return localVarFp.proxy3(requestEntity, hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxy4(requestEntity: object, hash: string, options?: any): AxiosPromise<object> {
            return localVarFp.proxy4(requestEntity, hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxy5(requestEntity: object, hash: string, options?: any): AxiosPromise<object> {
            return localVarFp.proxy5(requestEntity, hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} requestEntity 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxy6(requestEntity: object, hash: string, options?: any): AxiosPromise<object> {
            return localVarFp.proxy6(requestEntity, hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ExternalBoardDTO} externalBoardDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementExternalBoards(id: number, externalBoardDTO: ExternalBoardDTO, options?: any): AxiosPromise<ExternalBoardDTO> {
            return localVarFp.updateElementExternalBoards(id, externalBoardDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHash(hash: string, options?: any): AxiosPromise<string> {
            return localVarFp.updateHash(hash, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExternalBoardsApi - object-oriented interface
 * @export
 * @class ExternalBoardsApi
 * @extends {BaseAPI}
 */
export class ExternalBoardsApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalBoardsApi
     */
    public deleteByIdElementExternalBoards(id: number, options?: AxiosRequestConfig) {
        return ExternalBoardsApiFp(this.configuration).deleteByIdElementExternalBoards(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalBoardsApi
     */
    public getAllExternalBoards(options?: AxiosRequestConfig) {
        return ExternalBoardsApiFp(this.configuration).getAllExternalBoards(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalBoardsApi
     */
    public getDashboardHashById(id: number, options?: AxiosRequestConfig) {
        return ExternalBoardsApiFp(this.configuration).getDashboardHashById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'METABASE' | 'JASPER'} externalBoardType 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalBoardsApi
     */
    public getDashboardHashByName(externalBoardType: 'METABASE' | 'JASPER', name: string, options?: AxiosRequestConfig) {
        return ExternalBoardsApiFp(this.configuration).getDashboardHashByName(externalBoardType, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalBoardsApi
     */
    public getOneElementExternalBoards(id: number, options?: AxiosRequestConfig) {
        return ExternalBoardsApiFp(this.configuration).getOneElementExternalBoards(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalBoardsApi
     */
    public getSelectExternalBoards(options?: AxiosRequestConfig) {
        return ExternalBoardsApiFp(this.configuration).getSelectExternalBoards(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExternalBoardDTO} externalBoardDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalBoardsApi
     */
    public insertElementExternalBoards(externalBoardDTO: ExternalBoardDTO, options?: AxiosRequestConfig) {
        return ExternalBoardsApiFp(this.configuration).insertElementExternalBoards(externalBoardDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} requestEntity 
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalBoardsApi
     */
    public proxy(requestEntity: object, hash: string, options?: AxiosRequestConfig) {
        return ExternalBoardsApiFp(this.configuration).proxy(requestEntity, hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} requestEntity 
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalBoardsApi
     */
    public proxy1(requestEntity: object, hash: string, options?: AxiosRequestConfig) {
        return ExternalBoardsApiFp(this.configuration).proxy1(requestEntity, hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} requestEntity 
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalBoardsApi
     */
    public proxy2(requestEntity: object, hash: string, options?: AxiosRequestConfig) {
        return ExternalBoardsApiFp(this.configuration).proxy2(requestEntity, hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} requestEntity 
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalBoardsApi
     */
    public proxy3(requestEntity: object, hash: string, options?: AxiosRequestConfig) {
        return ExternalBoardsApiFp(this.configuration).proxy3(requestEntity, hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} requestEntity 
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalBoardsApi
     */
    public proxy4(requestEntity: object, hash: string, options?: AxiosRequestConfig) {
        return ExternalBoardsApiFp(this.configuration).proxy4(requestEntity, hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} requestEntity 
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalBoardsApi
     */
    public proxy5(requestEntity: object, hash: string, options?: AxiosRequestConfig) {
        return ExternalBoardsApiFp(this.configuration).proxy5(requestEntity, hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} requestEntity 
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalBoardsApi
     */
    public proxy6(requestEntity: object, hash: string, options?: AxiosRequestConfig) {
        return ExternalBoardsApiFp(this.configuration).proxy6(requestEntity, hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ExternalBoardDTO} externalBoardDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalBoardsApi
     */
    public updateElementExternalBoards(id: number, externalBoardDTO: ExternalBoardDTO, options?: AxiosRequestConfig) {
        return ExternalBoardsApiFp(this.configuration).updateElementExternalBoards(id, externalBoardDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalBoardsApi
     */
    public updateHash(hash: string, options?: AxiosRequestConfig) {
        return ExternalBoardsApiFp(this.configuration).updateHash(hash, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GeoDataControllerApi - axios parameter creator
 * @export
 */
export const GeoDataControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sections: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/geodata/sections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeoDataControllerApi - functional programming interface
 * @export
 */
export const GeoDataControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeoDataControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sections(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sections(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeoDataControllerApi - factory interface
 * @export
 */
export const GeoDataControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeoDataControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sections(options?: any): AxiosPromise<FeatureCollection> {
            return localVarFp.sections(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeoDataControllerApi - object-oriented interface
 * @export
 * @class GeoDataControllerApi
 * @extends {BaseAPI}
 */
export class GeoDataControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoDataControllerApi
     */
    public sections(options?: AxiosRequestConfig) {
        return GeoDataControllerApiFp(this.configuration).sections(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ParameterAlarmCheckControllerApi - axios parameter creator
 * @export
 */
export const ParameterAlarmCheckControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementparameterAlarmCheckController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteByIdElementparameterAlarmCheckController', 'id', id)
            const localVarPath = `/parameteralarmcheck/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllParameterAlarm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parameteralarmcheck/parameteralarm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllparameterAlarmCheckController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parameteralarmcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementparameterAlarmCheckController: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneElementparameterAlarmCheckController', 'id', id)
            const localVarPath = `/parameteralarmcheck/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParameterAlarm: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getParameterAlarm', 'id', id)
            const localVarPath = `/parameteralarmcheck/parameteralarm/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectparameterAlarmCheckController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parameteralarmcheck/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParameterAlarmCheckDTO} parameterAlarmCheckDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementparameterAlarmCheckController: async (parameterAlarmCheckDTO: ParameterAlarmCheckDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parameterAlarmCheckDTO' is not null or undefined
            assertParamExists('insertElementparameterAlarmCheckController', 'parameterAlarmCheckDTO', parameterAlarmCheckDTO)
            const localVarPath = `/parameteralarmcheck/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parameterAlarmCheckDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ParameterAlarmCheckDTO} parameterAlarmCheckDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementparameterAlarmCheckController: async (id: number, parameterAlarmCheckDTO: ParameterAlarmCheckDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateElementparameterAlarmCheckController', 'id', id)
            // verify required parameter 'parameterAlarmCheckDTO' is not null or undefined
            assertParamExists('updateElementparameterAlarmCheckController', 'parameterAlarmCheckDTO', parameterAlarmCheckDTO)
            const localVarPath = `/parameteralarmcheck/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parameterAlarmCheckDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParameterAlarmCheckControllerApi - functional programming interface
 * @export
 */
export const ParameterAlarmCheckControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParameterAlarmCheckControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElementparameterAlarmCheckController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElementparameterAlarmCheckController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllParameterAlarm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParameterAlarmDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllParameterAlarm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllparameterAlarmCheckController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParameterAlarmCheckDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllparameterAlarmCheckController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneElementparameterAlarmCheckController(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParameterAlarmCheckDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneElementparameterAlarmCheckController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParameterAlarm(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParameterAlarmDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParameterAlarm(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectparameterAlarmCheckController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectparameterAlarmCheckController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ParameterAlarmCheckDTO} parameterAlarmCheckDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertElementparameterAlarmCheckController(parameterAlarmCheckDTO: ParameterAlarmCheckDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParameterAlarmCheckDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertElementparameterAlarmCheckController(parameterAlarmCheckDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ParameterAlarmCheckDTO} parameterAlarmCheckDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateElementparameterAlarmCheckController(id: number, parameterAlarmCheckDTO: ParameterAlarmCheckDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParameterAlarmCheckDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateElementparameterAlarmCheckController(id, parameterAlarmCheckDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParameterAlarmCheckControllerApi - factory interface
 * @export
 */
export const ParameterAlarmCheckControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParameterAlarmCheckControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementparameterAlarmCheckController(id: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElementparameterAlarmCheckController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllParameterAlarm(options?: any): AxiosPromise<Array<ParameterAlarmDTO>> {
            return localVarFp.getAllParameterAlarm(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllparameterAlarmCheckController(options?: any): AxiosPromise<Array<ParameterAlarmCheckDTO>> {
            return localVarFp.getAllparameterAlarmCheckController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementparameterAlarmCheckController(id: number, options?: any): AxiosPromise<ParameterAlarmCheckDTO> {
            return localVarFp.getOneElementparameterAlarmCheckController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParameterAlarm(id: number, options?: any): AxiosPromise<ParameterAlarmDTO> {
            return localVarFp.getParameterAlarm(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectparameterAlarmCheckController(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getSelectparameterAlarmCheckController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParameterAlarmCheckDTO} parameterAlarmCheckDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementparameterAlarmCheckController(parameterAlarmCheckDTO: ParameterAlarmCheckDTO, options?: any): AxiosPromise<ParameterAlarmCheckDTO> {
            return localVarFp.insertElementparameterAlarmCheckController(parameterAlarmCheckDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ParameterAlarmCheckDTO} parameterAlarmCheckDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementparameterAlarmCheckController(id: number, parameterAlarmCheckDTO: ParameterAlarmCheckDTO, options?: any): AxiosPromise<ParameterAlarmCheckDTO> {
            return localVarFp.updateElementparameterAlarmCheckController(id, parameterAlarmCheckDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParameterAlarmCheckControllerApi - object-oriented interface
 * @export
 * @class ParameterAlarmCheckControllerApi
 * @extends {BaseAPI}
 */
export class ParameterAlarmCheckControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterAlarmCheckControllerApi
     */
    public deleteByIdElementparameterAlarmCheckController(id: number, options?: AxiosRequestConfig) {
        return ParameterAlarmCheckControllerApiFp(this.configuration).deleteByIdElementparameterAlarmCheckController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterAlarmCheckControllerApi
     */
    public getAllParameterAlarm(options?: AxiosRequestConfig) {
        return ParameterAlarmCheckControllerApiFp(this.configuration).getAllParameterAlarm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterAlarmCheckControllerApi
     */
    public getAllparameterAlarmCheckController(options?: AxiosRequestConfig) {
        return ParameterAlarmCheckControllerApiFp(this.configuration).getAllparameterAlarmCheckController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterAlarmCheckControllerApi
     */
    public getOneElementparameterAlarmCheckController(id: number, options?: AxiosRequestConfig) {
        return ParameterAlarmCheckControllerApiFp(this.configuration).getOneElementparameterAlarmCheckController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterAlarmCheckControllerApi
     */
    public getParameterAlarm(id: number, options?: AxiosRequestConfig) {
        return ParameterAlarmCheckControllerApiFp(this.configuration).getParameterAlarm(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterAlarmCheckControllerApi
     */
    public getSelectparameterAlarmCheckController(options?: AxiosRequestConfig) {
        return ParameterAlarmCheckControllerApiFp(this.configuration).getSelectparameterAlarmCheckController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ParameterAlarmCheckDTO} parameterAlarmCheckDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterAlarmCheckControllerApi
     */
    public insertElementparameterAlarmCheckController(parameterAlarmCheckDTO: ParameterAlarmCheckDTO, options?: AxiosRequestConfig) {
        return ParameterAlarmCheckControllerApiFp(this.configuration).insertElementparameterAlarmCheckController(parameterAlarmCheckDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ParameterAlarmCheckDTO} parameterAlarmCheckDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterAlarmCheckControllerApi
     */
    public updateElementparameterAlarmCheckController(id: number, parameterAlarmCheckDTO: ParameterAlarmCheckDTO, options?: AxiosRequestConfig) {
        return ParameterAlarmCheckControllerApiFp(this.configuration).updateElementparameterAlarmCheckController(id, parameterAlarmCheckDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportDataControllerApi - axios parameter creator
 * @export
 */
export const ReportDataControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartADTHour: async (reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('getChartADTHour', 'reportType', reportType)
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartADTHour', 'requestChartData', requestChartData)
            const localVarPath = `/report/{reportType}/adt/hour`
                .replace(`{${"reportType"}}`, encodeURIComponent(String(reportType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartADTWeekday: async (reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('getChartADTWeekday', 'reportType', reportType)
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartADTWeekday', 'requestChartData', requestChartData)
            const localVarPath = `/report/{reportType}/adt/weekday`
                .replace(`{${"reportType"}}`, encodeURIComponent(String(reportType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartSpeed: async (reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('getChartSpeed', 'reportType', reportType)
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartSpeed', 'requestChartData', requestChartData)
            const localVarPath = `/report/{reportType}/speeds`
                .replace(`{${"reportType"}}`, encodeURIComponent(String(reportType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartTotalCount: async (reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('getChartTotalCount', 'reportType', reportType)
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartTotalCount', 'requestChartData', requestChartData)
            const localVarPath = `/report/{reportType}/totalCount`
                .replace(`{${"reportType"}}`, encodeURIComponent(String(reportType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartTotalCountVsSpeed: async (reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('getChartTotalCountVsSpeed', 'reportType', reportType)
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getChartTotalCountVsSpeed', 'requestChartData', requestChartData)
            const localVarPath = `/report/{reportType}/totalCountVsSpeed`
                .replace(`{${"reportType"}}`, encodeURIComponent(String(reportType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTest: async (reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('getTest', 'reportType', reportType)
            // verify required parameter 'requestChartData' is not null or undefined
            assertParamExists('getTest', 'requestChartData', requestChartData)
            const localVarPath = `/report/{reportType}/test`
                .replace(`{${"reportType"}}`, encodeURIComponent(String(reportType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChartData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportDataControllerApi - functional programming interface
 * @export
 */
export const ReportDataControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportDataControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartADTHour(reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartADTHour(reportType, requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartADTWeekday(reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartADTWeekday(reportType, requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartSpeed(reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartSpeed(reportType, requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartTotalCount(reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartTotalCount(reportType, requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartTotalCountVsSpeed(reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartTotalCountVsSpeed(reportType, requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTest(reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTest(reportType, requestChartData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportDataControllerApi - factory interface
 * @export
 */
export const ReportDataControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportDataControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartADTHour(reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options?: any): AxiosPromise<void> {
            return localVarFp.getChartADTHour(reportType, requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartADTWeekday(reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options?: any): AxiosPromise<void> {
            return localVarFp.getChartADTWeekday(reportType, requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartSpeed(reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options?: any): AxiosPromise<void> {
            return localVarFp.getChartSpeed(reportType, requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartTotalCount(reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options?: any): AxiosPromise<void> {
            return localVarFp.getChartTotalCount(reportType, requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartTotalCountVsSpeed(reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options?: any): AxiosPromise<void> {
            return localVarFp.getChartTotalCountVsSpeed(reportType, requestChartData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
         * @param {RequestChartData} requestChartData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTest(reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options?: any): AxiosPromise<void> {
            return localVarFp.getTest(reportType, requestChartData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportDataControllerApi - object-oriented interface
 * @export
 * @class ReportDataControllerApi
 * @extends {BaseAPI}
 */
export class ReportDataControllerApi extends BaseAPI {
    /**
     * 
     * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportDataControllerApi
     */
    public getChartADTHour(reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ReportDataControllerApiFp(this.configuration).getChartADTHour(reportType, requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportDataControllerApi
     */
    public getChartADTWeekday(reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ReportDataControllerApiFp(this.configuration).getChartADTWeekday(reportType, requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportDataControllerApi
     */
    public getChartSpeed(reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ReportDataControllerApiFp(this.configuration).getChartSpeed(reportType, requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportDataControllerApi
     */
    public getChartTotalCount(reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ReportDataControllerApiFp(this.configuration).getChartTotalCount(reportType, requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportDataControllerApi
     */
    public getChartTotalCountVsSpeed(reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ReportDataControllerApiFp(this.configuration).getChartTotalCountVsSpeed(reportType, requestChartData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT'} reportType 
     * @param {RequestChartData} requestChartData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportDataControllerApi
     */
    public getTest(reportType: 'EXCEL' | 'PDF' | 'CSV' | 'HTML' | 'TXT', requestChartData: RequestChartData, options?: AxiosRequestConfig) {
        return ReportDataControllerApiFp(this.configuration).getTest(reportType, requestChartData, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoadApi - axios parameter creator
 * @export
 */
export const RoadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementRoad: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteByIdElementRoad', 'id', id)
            const localVarPath = `/road/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRoad: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/road`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementRoad: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneElementRoad', 'id', id)
            const localVarPath = `/road/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectRoad: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/road/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RoadDTO} roadDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementRoad: async (roadDTO: RoadDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roadDTO' is not null or undefined
            assertParamExists('insertElementRoad', 'roadDTO', roadDTO)
            const localVarPath = `/road/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roadDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RoadDTO} roadDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementRoad: async (id: number, roadDTO: RoadDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateElementRoad', 'id', id)
            // verify required parameter 'roadDTO' is not null or undefined
            assertParamExists('updateElementRoad', 'roadDTO', roadDTO)
            const localVarPath = `/road/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roadDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoadApi - functional programming interface
 * @export
 */
export const RoadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElementRoad(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElementRoad(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRoad(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoadDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRoad(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneElementRoad(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoadDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneElementRoad(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectRoad(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectRoad(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RoadDTO} roadDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertElementRoad(roadDTO: RoadDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoadDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertElementRoad(roadDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RoadDTO} roadDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateElementRoad(id: number, roadDTO: RoadDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoadDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateElementRoad(id, roadDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoadApi - factory interface
 * @export
 */
export const RoadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoadApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementRoad(id: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElementRoad(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRoad(options?: any): AxiosPromise<Array<RoadDTO>> {
            return localVarFp.getAllRoad(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementRoad(id: number, options?: any): AxiosPromise<RoadDTO> {
            return localVarFp.getOneElementRoad(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectRoad(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getSelectRoad(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RoadDTO} roadDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementRoad(roadDTO: RoadDTO, options?: any): AxiosPromise<RoadDTO> {
            return localVarFp.insertElementRoad(roadDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {RoadDTO} roadDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementRoad(id: number, roadDTO: RoadDTO, options?: any): AxiosPromise<RoadDTO> {
            return localVarFp.updateElementRoad(id, roadDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoadApi - object-oriented interface
 * @export
 * @class RoadApi
 * @extends {BaseAPI}
 */
export class RoadApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadApi
     */
    public deleteByIdElementRoad(id: number, options?: AxiosRequestConfig) {
        return RoadApiFp(this.configuration).deleteByIdElementRoad(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadApi
     */
    public getAllRoad(options?: AxiosRequestConfig) {
        return RoadApiFp(this.configuration).getAllRoad(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadApi
     */
    public getOneElementRoad(id: number, options?: AxiosRequestConfig) {
        return RoadApiFp(this.configuration).getOneElementRoad(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadApi
     */
    public getSelectRoad(options?: AxiosRequestConfig) {
        return RoadApiFp(this.configuration).getSelectRoad(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RoadDTO} roadDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadApi
     */
    public insertElementRoad(roadDTO: RoadDTO, options?: AxiosRequestConfig) {
        return RoadApiFp(this.configuration).insertElementRoad(roadDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {RoadDTO} roadDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoadApi
     */
    public updateElementRoad(id: number, roadDTO: RoadDTO, options?: AxiosRequestConfig) {
        return RoadApiFp(this.configuration).updateElementRoad(id, roadDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SectionApi - axios parameter creator
 * @export
 */
export const SectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementSection: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteByIdElementSection', 'id', id)
            const localVarPath = `/section/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSection: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/section`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementSection: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneElementSection', 'id', id)
            const localVarPath = `/section/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} camId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectionsFromCam: async (camId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'camId' is not null or undefined
            assertParamExists('getSectionsFromCam', 'camId', camId)
            const localVarPath = `/section/cameras/{camId}`
                .replace(`{${"camId"}}`, encodeURIComponent(String(camId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectSection: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/section/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SectionDTO} sectionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementSection: async (sectionDTO: SectionDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sectionDTO' is not null or undefined
            assertParamExists('insertElementSection', 'sectionDTO', sectionDTO)
            const localVarPath = `/section/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setActualSections: async (requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('setActualSections', 'requestBody', requestBody)
            const localVarPath = `/section/actual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionDTO} sectionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementSection: async (id: number, sectionDTO: SectionDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateElementSection', 'id', id)
            // verify required parameter 'sectionDTO' is not null or undefined
            assertParamExists('updateElementSection', 'sectionDTO', sectionDTO)
            const localVarPath = `/section/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectionApi - functional programming interface
 * @export
 */
export const SectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByIdElementSection(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIdElementSection(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSection(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSection(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneElementSection(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneElementSection(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} camId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSectionsFromCam(camId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSectionsFromCam(camId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectSection(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectSection(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SectionDTO} sectionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertElementSection(sectionDTO: SectionDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertElementSection(sectionDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setActualSections(requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setActualSections(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionDTO} sectionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateElementSection(id: number, sectionDTO: SectionDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateElementSection(id, sectionDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SectionApi - factory interface
 * @export
 */
export const SectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SectionApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIdElementSection(id: number, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.deleteByIdElementSection(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSection(options?: any): AxiosPromise<Array<SectionDTO>> {
            return localVarFp.getAllSection(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneElementSection(id: number, options?: any): AxiosPromise<SectionDTO> {
            return localVarFp.getOneElementSection(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} camId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectionsFromCam(camId: number, options?: any): AxiosPromise<Array<SectionDTO>> {
            return localVarFp.getSectionsFromCam(camId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectSection(options?: any): AxiosPromise<Array<SelectOption>> {
            return localVarFp.getSelectSection(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SectionDTO} sectionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertElementSection(sectionDTO: SectionDTO, options?: any): AxiosPromise<SectionDTO> {
            return localVarFp.insertElementSection(sectionDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setActualSections(requestBody: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.setActualSections(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionDTO} sectionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementSection(id: number, sectionDTO: SectionDTO, options?: any): AxiosPromise<SectionDTO> {
            return localVarFp.updateElementSection(id, sectionDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SectionApi - object-oriented interface
 * @export
 * @class SectionApi
 * @extends {BaseAPI}
 */
export class SectionApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public deleteByIdElementSection(id: number, options?: AxiosRequestConfig) {
        return SectionApiFp(this.configuration).deleteByIdElementSection(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public getAllSection(options?: AxiosRequestConfig) {
        return SectionApiFp(this.configuration).getAllSection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public getOneElementSection(id: number, options?: AxiosRequestConfig) {
        return SectionApiFp(this.configuration).getOneElementSection(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} camId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public getSectionsFromCam(camId: number, options?: AxiosRequestConfig) {
        return SectionApiFp(this.configuration).getSectionsFromCam(camId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public getSelectSection(options?: AxiosRequestConfig) {
        return SectionApiFp(this.configuration).getSelectSection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SectionDTO} sectionDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public insertElementSection(sectionDTO: SectionDTO, options?: AxiosRequestConfig) {
        return SectionApiFp(this.configuration).insertElementSection(sectionDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public setActualSections(requestBody: Array<number>, options?: AxiosRequestConfig) {
        return SectionApiFp(this.configuration).setActualSections(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SectionDTO} sectionDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public updateElementSection(id: number, sectionDTO: SectionDTO, options?: AxiosRequestConfig) {
        return SectionApiFp(this.configuration).updateElementSection(id, sectionDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VehicleDataControllerApi - axios parameter creator
 * @export
 */
export const VehicleDataControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaxHistDateForEntity: async (elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('getMaxHistDateForEntity', 'elementId', elementId)
            const localVarPath = `/vehicleData/maxDate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (elementId !== undefined) {
                localVarQueryParameter['elementId'] = elementId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<VehicleDataHist>} vehicleDataHist 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newVehicleData: async (vehicleDataHist: Array<VehicleDataHist>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleDataHist' is not null or undefined
            assertParamExists('newVehicleData', 'vehicleDataHist', vehicleDataHist)
            const localVarPath = `/vehicleData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleDataHist, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<VehicleDataCount>} vehicleDataCount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newVehicleDataCount: async (vehicleDataCount: Array<VehicleDataCount>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleDataCount' is not null or undefined
            assertParamExists('newVehicleDataCount', 'vehicleDataCount', vehicleDataCount)
            const localVarPath = `/vehicleDataCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleDataCount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleDataControllerApi - functional programming interface
 * @export
 */
export const VehicleDataControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleDataControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMaxHistDateForEntity(elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMaxHistDateForEntity(elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<VehicleDataHist>} vehicleDataHist 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newVehicleData(vehicleDataHist: Array<VehicleDataHist>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newVehicleData(vehicleDataHist, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<VehicleDataCount>} vehicleDataCount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newVehicleDataCount(vehicleDataCount: Array<VehicleDataCount>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newVehicleDataCount(vehicleDataCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VehicleDataControllerApi - factory interface
 * @export
 */
export const VehicleDataControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleDataControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaxHistDateForEntity(elementId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getMaxHistDateForEntity(elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<VehicleDataHist>} vehicleDataHist 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newVehicleData(vehicleDataHist: Array<VehicleDataHist>, options?: any): AxiosPromise<void> {
            return localVarFp.newVehicleData(vehicleDataHist, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<VehicleDataCount>} vehicleDataCount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newVehicleDataCount(vehicleDataCount: Array<VehicleDataCount>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.newVehicleDataCount(vehicleDataCount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehicleDataControllerApi - object-oriented interface
 * @export
 * @class VehicleDataControllerApi
 * @extends {BaseAPI}
 */
export class VehicleDataControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDataControllerApi
     */
    public getMaxHistDateForEntity(elementId: number, options?: AxiosRequestConfig) {
        return VehicleDataControllerApiFp(this.configuration).getMaxHistDateForEntity(elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<VehicleDataHist>} vehicleDataHist 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDataControllerApi
     */
    public newVehicleData(vehicleDataHist: Array<VehicleDataHist>, options?: AxiosRequestConfig) {
        return VehicleDataControllerApiFp(this.configuration).newVehicleData(vehicleDataHist, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<VehicleDataCount>} vehicleDataCount 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDataControllerApi
     */
    public newVehicleDataCount(vehicleDataCount: Array<VehicleDataCount>, options?: AxiosRequestConfig) {
        return VehicleDataControllerApiFp(this.configuration).newVehicleDataCount(vehicleDataCount, options).then((request) => request(this.axios, this.basePath));
    }
}


