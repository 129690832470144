import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import common_en from "../translation/en/common";
import common_es from "../translation/es/common";
import common_pt from "../translation/pt/common";
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
export const i18nHasMissingKeys = [];

i18n
// load translation using xhr -> see /public/locales
// learn more: https://github.com/i18next/i18next-xhr-backend
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
  interpolation: {escapeValue: false},  // React already does escaping
    parseMissingKeyHandler : (key) => {
      i18nHasMissingKeys.push(key);
      console.log("Missing translation key: " + key);
      return key;
    },
  lng: 'us',                              // language to use
  resources: {
    us: {
      common: common_en               // 'common' is our custom namespace
    },
    es: {
      common: common_es
    },
    br: {
      common: common_pt
    },
  },
});


export default i18n;
