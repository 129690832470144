import {API_BASE_URL, getAccessTokenRenewIfNeeded} from "~/external-modules/dlv-web-common/util/LoginUtils";
import {
  ChartDataControllerApi,
  DataCountApi,
  ExternalBoardsApi,
  GeoDataControllerApi,
  ParameterAlarmCheckControllerApi,
  RoadApi,
  SectionApi,
  VehicleDataControllerApi

} from "~/external-modules/dlv-ai-common/openapi/data";
import {Configuration} from "~/external-modules/dlv-ai-common/openapi/eventstate";

const commonConfig = new Configuration({
  accessToken: getAccessTokenRenewIfNeeded,
  basePath: API_BASE_URL + "vehicle"
});


export const sectionApi = new SectionApi(commonConfig);
export const vehicleApi = new VehicleDataControllerApi(commonConfig);
export const vehicleChartApi = new ChartDataControllerApi(commonConfig);
export const dataCountApi = new DataCountApi(commonConfig);
export const externalBoardsApi = new ExternalBoardsApi(commonConfig);
export const parameterAlarmCheckControllerApi = new ParameterAlarmCheckControllerApi(commonConfig);
export const sectionGeoDataApi = new GeoDataControllerApi(commonConfig);
export const roadApi = new RoadApi(commonConfig);


