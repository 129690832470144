import moment from "moment";
//

export const convertVhToPx = (vh) => {
  const oneVhInPx = window.innerHeight / 100;
  return oneVhInPx * vh;
};


export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}


export function momentInUtc(date){
  return moment.utc({year:date.getFullYear(),month:date.getMonth(),day:date.getDate(),hours:date.getHours(),
    minutes:date.getMinutes(),seconds:date.getSeconds()});
}

export function momentSameInUtc(origMoment){
  return moment.utc({year:origMoment.year(),month:origMoment.month(),day:origMoment.date(),hours:origMoment.hour(),
    minutes:origMoment.minutes(),seconds:origMoment.seconds()})
}

export function excludeI18n(props){
  let newProps = Object.assign({},props);
  delete newProps.t;
  delete newProps.i18n;
  delete newProps.tReady;
  return newProps;
}
