import Login from "~/public/view/Login/Login";
import React, {Component} from "react";
import {ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav} from "reactstrap";
import {getUserData, isLogged, logout} from "~/external-modules/dlv-web-common/util/LoginUtils";
import {Link} from "react-router-dom";
import {FaDoorOpen, FaUser, FaWrench} from "react-icons/fa";
import Security from "~/external-modules/dlv-web-common/component/Security/Security";
import {withTranslation} from "react-i18next";
import {APP_WEBSERVICE_PATH} from "~/external-modules/dlv-web-common/util/LoginUtils";


 class LoginDropdown extends Component {

  constructor(props) {
    super(props);
    this.handleLoggedChanged = this.handleLoggedChanged.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.toggle = this.toggle.bind(this);
    let userData = getUserData();
    this.state = {
      isLogged: isLogged(),
      user: userData == null ? '' : userData.user_name,
      dropdownOpen: false,

    }
  }

  handleLoggedChanged() {
    let userData = getUserData();
    this.setState({
      isLogged: isLogged(),
      user: userData == null ? '' : userData.user_name
    });
    if (isLogged())
      this.setState({dropdownOpen: false});
  }

  handleLogout() {
    logout();
    this.handleLoggedChanged();
    window.location.href = APP_WEBSERVICE_PATH +"/";
  }

  handleConfigClick() {
    window.location = APP_WEBSERVICE_PATH +"/private/";
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {
    const {t} = this.props;
    const prefixProfile = (this.props.prefixLink === undefined ? '' : this.props.prefixLink)+ "/user/profile";

    return (<Nav className="header-text px-2" navbar>

        <ButtonDropdown nav direction="down" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle className="header-text-color" outline color="dark"  style={{border:"none"}} caret size="md">
            <label className="pr-1" style={{cursor:"pointer"}}>{this.state.isLogged ? this.state.user : "Inicio de sesión"} </label>
            <i className="fa fa-user-circle fa-2x header-text-color"/>

            {/*<Button className="dropdown-toggle">Acceso</Button>*/}

          </DropdownToggle>
          <DropdownMenu end>
            {
              !this.state.isLogged ? (
                  <div style={{right: 'auto', minWidth: '250px'}}>
                    <Login loggedChanged={this.handleLoggedChanged}/>
                  </div>)
                :
                (<React.Fragment>
                  <DropdownItem header tag="div"
                                className="text-center"><strong>{this.state.user}</strong></DropdownItem>
                  <DropdownItem tag={Link} to={APP_WEBSERVICE_PATH+prefixProfile}><FaUser/> {t('login_dropdown.my_account')}</DropdownItem>
                  <Security authority='view_private'>
                    <DropdownItem onClick={this.handleConfigClick}><FaWrench/> {t('login_dropdown.configuration')}</DropdownItem>
                  </Security>
                  <DropdownItem onClick={this.handleLogout}><FaDoorOpen/> {t('login_dropdown.logout')}</DropdownItem>

                </React.Fragment>)
            }


          </DropdownMenu>

        </ButtonDropdown>

    </Nav>)
  }
}
export default withTranslation('common')(LoginDropdown);
