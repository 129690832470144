

const enableFaceRecognition = import.meta.env.REACT_APP_ENABLE_FACE_RECOGNITION;
const enableAlpr = import.meta.env.REACT_APP_ENABLE_ALPR;
const enableVehicleCategories = import.meta.env.REACT_APP_ENABLE_VEHICLE_CATEGORIES;
const enableFullAi = import.meta.env.REACT_APP_ENABLE_FULL_AI;
const disableFlags = import.meta.env.REACT_APP_DISABLE_FLAGS;
const layoutWidth =  import.meta.env.REACT_APP_LAYOUT_WIDTH;
const layoutHeight =  import.meta.env.REACT_APP_LAYOUT_HEIGHT;
const newPermission = import.meta.env.REACT_APP_NEW_PERMISSION;
const metabaseDashboard = import.meta.env.REACT_APP_METABASE_DASHBOARD;
const enableElementStatus = import.meta.env.REACT_APP_ENABLE_ELEMENT_STATUS;
const alprDgtDatatable = import.meta.env.REACT_APP_ALPR_DGT_DATATABLE;
const alprDgtData = import.meta.env.REACT_APP_ALPR_DGT_DATA;
const jasperWithNginx = import.meta.env.REACT_APP_JASPER_WITH_NGINX;


export function appHasJasperWithNginx(){
  if (typeof jasperWithNginx === "string" || jasperWithNginx as any instanceof String)
    return jasperWithNginx === "true";
  else
    return jasperWithNginx;
}

export function appHasMetabaseDashboard(){
  if (typeof metabaseDashboard === "string" || metabaseDashboard as any instanceof String)
    return metabaseDashboard === "true";
  else
    return metabaseDashboard;
}

export function appHasFaceRecognition(){
  if (typeof enableFaceRecognition === "string" || enableFaceRecognition as any instanceof String)
    return enableFaceRecognition === "true";
  else
    return enableFaceRecognition;
}
export function appHasAlpr(){
  if (typeof enableAlpr === "string" || enableAlpr as any instanceof String)
    return enableAlpr === "true";
  else
    return enableAlpr;
}
export function appHasVehicleCategories(){
  if (typeof enableVehicleCategories === "string" || enableVehicleCategories as any instanceof String)
    return enableVehicleCategories === "true";
  else
    return enableVehicleCategories;
}

export function appHasFullAi(){
  if (typeof enableFullAi === "string" || enableFullAi as any instanceof String)
    return enableFullAi === "true";
  else
    return enableFullAi;
}

export function appHasElementStatus(){
  if (typeof enableElementStatus === "string" || enableElementStatus as any instanceof String)
    return enableElementStatus === "true";
  else
    return enableElementStatus;
}


export function appHasDisableFlags(){
  if (typeof disableFlags === "string" || disableFlags as any instanceof String)
    return disableFlags === "true";
  else
    return disableFlags;
}
export function appDefaultLang(){
  return import.meta.env.REACT_APP_DEFAULT_LANG ? import.meta.env.REACT_APP_DEFAULT_LANG
    : "ES";
}

export function appHasNewPermission(){
  if (typeof newPermission === "string" || newPermission as any instanceof String)
    return newPermission === "true";
  else
    return newPermission;
}


export function appHasAlprDgtDatatable(){
  if (typeof alprDgtDatatable === "string" || alprDgtDatatable as any instanceof String)
    return alprDgtDatatable === "true";
  else
    return alprDgtDatatable;
}

export function appHasAlprDgtData(){
  if (typeof alprDgtData === "string" || alprDgtData as any instanceof String)
    return alprDgtData === "true";
  else
    return alprDgtData;
}

export function getAppLayoutWidth() {
  let num = parseInt(layoutWidth);
  if (!num || num <=0)
      return 200;
  else
    return num;
}
export function getAppLayoutHeight() {
  let num = parseInt(layoutHeight);
  if (!num || num <=0)
    return 100;
  else
    return num;
}
