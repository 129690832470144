class LayoutEvents{
  constructor(){
    this.layoutListeners = [];
  }

  layoutChanged = () => {
    this.layoutListeners.forEach((listener) =>{
      listener();
    } );
  }
  addListener = (newListener) => {
    this.layoutListeners.push(newListener);
  }
  removeListener = (listener) => {
    this.layoutListeners.splice(this.layoutListeners.indexOf(listener,1));
  }
}
export default new LayoutEvents();
