//Funcion generacion sidebar, recibe el elemento "t" de traduccion para poder traducir los elementos del sidebar

import {
  userHasAccess,
  userHasAccessOrDefaultSidebar, userHasAccessOrDefaultSidebarWithExtraCheck,
  userHasAccessWithExtraCheck
} from "~/external-modules/dlv-web-common/util/LoginUtils";
import {
  appHasAlpr,
  appHasFaceRecognition,
  appHasFullAi,
  appHasElementStatus,
  appHasNewPermission,
  appHasVehicleCategories
} from "~/common/checks/app-checks";
import {APP_WEBSERVICE_PATH} from "~/external-modules/dlv-web-common/util/LoginUtils";


function getFirstMenu(t, hasVideowall){
  let items=[
    ...(userHasAccess("view_dashboard",[
      {
        name: t('dashboard.title'),
        url: APP_WEBSERVICE_PATH+'/private/dashboard',
        icon: 'nav-icon icon-speedometer',
      }],[])),
    ...(userHasAccessOrDefaultSidebar("view_map", appHasFullAi(), [{
      name: t('map'),
      url: APP_WEBSERVICE_PATH+'/private/map',
      icon: 'fa fa-globe',
      }],[])),
    ...(userHasAccessOrDefaultSidebar("view_layouts", appHasNewPermission(), [{
      id: 'layouts',
      name: t('layouts'),
      url: APP_WEBSERVICE_PATH+'/private/layout',
      icon: 'icon-grid',
      children: [
      ]
    }],[])),
    ...(userHasAccessOrDefaultSidebarWithExtraCheck("view_videowall",hasVideowall, appHasNewPermission(),  [
      {
        id: 'videowall',
        name: t('videowalls'),
        url: APP_WEBSERVICE_PATH+'/private/videowalls',
        icon: "fa fa-tv"
      }] , []))
  ];
  if(items.length>0)
    return items;
  else
    return [];
}

function getManagementMenu(t, hasVideowall){
  let items=[
    {
      title: true,
      name: t('management')
    },
    ...(userHasAccess("view_element_camera_ai", [{
      id: 'devices',
      name: t('devices'),
      url: APP_WEBSERVICE_PATH+'/private/devices',
      icon: 'fa fa-video-camera',
    }],[])),
    ...(userHasAccessWithExtraCheck("view_detection_camera_schedule", appHasFullAi(),[{
      id: 'detectioncameraschedule',
      name: t('route.detectioncameraschedule'),
      url: APP_WEBSERVICE_PATH+'/private/detectioncameraschedule',
      icon: 'fa fa-calendar',
    }],[])),
    ...(userHasAccessWithExtraCheck("view_element_status", appHasElementStatus(),[{
      id: 'detection',
      name: t('route.elementstatus'),
      url: APP_WEBSERVICE_PATH+'/private/elementstatus',
      icon: 'fa  fa-check-square',
    }],[])),
    ...(userHasAccessWithExtraCheck("view_element_status", appHasElementStatus(),[{
      id: 'detection',
      name: t('route.historical_status'),
      url: APP_WEBSERVICE_PATH+'/private/historicalstatus',
      icon: 'fa  fa-list-check',
    }],[])),

    ...(userHasAccessWithExtraCheck("view_alpr", appHasAlpr(),[{
      id: 'view_routes',
      name: t('route.routes'),
      url: APP_WEBSERVICE_PATH+'/private/routes',
      icon: 'fa fa-binoculars',
    }],[])),
    ...(userHasAccessWithExtraCheck("view_alpr", appHasAlpr(),[{
      id: 'view_routes_map',
      name: t('route.routes_map'),
      url: APP_WEBSERVICE_PATH+'/private/routes-map',
      icon: 'fa fa-map-signs',
    }],[])),

    ...(userHasAccessWithExtraCheck("view_ai_modules", false, [{
      name: t('ai_modules.title'),
      url: APP_WEBSERVICE_PATH+'/private/ai_modules',
      icon: 'fa fa-cog',
    },],[])),



  ];
  if(items.length>1)
    return items;
  else
    return [];
}

function getDataMenu(t){
  let items=[
    {
      id: 'data',
      title: "true",
      name: t('data')
    },
    ...(userHasAccessWithExtraCheck("view_alpr", appHasAlpr(),[{
      id: 'alpr',
      name: t('route.alpr'),
      url: APP_WEBSERVICE_PATH+'/private/alpr',
      icon: 'fa cicon-ALPR',
    }],[])),


    ...(userHasAccessOrDefaultSidebar("view_event", appHasNewPermission(), [
      {
        name: t('events.title'),
        url: APP_WEBSERVICE_PATH+'/private/events',
        icon: 'fa-regular fa-bell',
      },],[])),
    ...(userHasAccessOrDefaultSidebar("view_recording", appHasNewPermission(), [{
      id: 'view-recordings',
      name: t('view-recordings'),
      url: APP_WEBSERVICE_PATH+'/private/recordings',
      icon: 'fa-regular fa-file-video',
    },],[])),

    ...(userHasAccessWithExtraCheck("view_data-count", appHasAlpr(),[{
      id: 'data-count',
      name: t('route.data-count'),
      url: APP_WEBSERVICE_PATH+'/private/data-count',
      icon: 'fa fa-male',
    }],[])),
    ...(userHasAccessWithExtraCheck("view_vehicle_charts", appHasFullAi(),[{
      id: 'dashboardList',
      name: t('route.dashboardList'),
      url: APP_WEBSERVICE_PATH+'/private/dashboardList',
      icon: 'fa fa-dashboard',
      children: [
      ]
    }],[])),

    ...(userHasAccessWithExtraCheck("view_vehicle_charts", appHasFullAi(),[{
      id: 'reportList',
      name: t('route.reportList'),
      url: APP_WEBSERVICE_PATH+'/private/reportList',
      icon: 'fa fa-file',
      children: [
      ]
    }],[])),
    ...(userHasAccessWithExtraCheck("view_vehicle_charts", appHasFullAi(),[{
      id: 'vehiclechart',
      name: t('route.vehicle_charts'),
      url: APP_WEBSERVICE_PATH+'/private/vehicle/chart-section',
      icon: 'fa fa-bar-chart',
    }],[])),

    ...(userHasAccessWithExtraCheck("view_facerecognition", appHasFaceRecognition(),[{
      id: 'facerecognition',
      name: t('route.face_recognition'),
      url: APP_WEBSERVICE_PATH+'/private/facerecognition',
      icon: 'fa fa-user-secret',
    }],[])),


  ];
  if(items.length>1)
    return items;
  else
    return [];
}

function getUsersMenu(t){
  let items=[
    {
      title: true,
      name: t('users')
    },
    ...(userHasAccess("view_permissions",[
      {
        name: t('permissions.title'),
        url: APP_WEBSERVICE_PATH+'/private/roles',
        icon: 'fa fa-lock',
      }],[])),
    ...(userHasAccess("view_users",[
      {
        name: t('users'),
        url: APP_WEBSERVICE_PATH+'/private/user/list',
        icon: 'fa fa-users',
      }],[]))
  ];
  if(items.length>1)
    return items;
  else
    return [];
}


function getConfigurationMenu(t){
  let items=[
    {
      title: true,
      name: t('configuration')
    },
    ...(userHasAccessWithExtraCheck("view_sections",appHasFullAi(), [{
      id: 'sections',
      name: t('route.sections'),
      url: APP_WEBSERVICE_PATH+'/private/sections',
      icon: 'fa fa-road',
    }],[])),

    ...(userHasAccessWithExtraCheck("view_alpr_blacklist", appHasAlpr(),[{
      id: 'alpr_blacklist',
      name: t('route.alprBlacklistGroup'),
      url: APP_WEBSERVICE_PATH+'/private/alprblacklist',
      icon: 'fa fa-legal',
    }],[])),

    ...(userHasAccessWithExtraCheck("view_alpr_whitelist", appHasAlpr(),[{
      id: 'alpr',
      name: t('route.alprWhitelistGroup'),
      url: APP_WEBSERVICE_PATH+'/private/alprwhitelist',
      icon: 'fa-regular fa-id-card',
    }],[])),

    // ...(userHasAccessWithExtraCheck("view_parameter_check", appHasNewPermission(), [{
    //   id: 'parameter_alarm',
    //   name: t('parameter_alarm.title'),
    //   url: APP_WEBSERVICE_PATH+'/private/parameter_alarm',
    //   icon: 'fa fa-bullhorn',
    // },],[])),

    ...(userHasAccess("view_camera_ai_sequence",[
      {
        id: 'sequences',
        name: t('sequences.sequences'),
        url: APP_WEBSERVICE_PATH+'/private/sequences',
        icon: 'fa fa-random',
      }],[])),

    // ...(userHasAccessOrDefaultSidebar("view_camera_tour", appHasNewPermission(), [{
    //   name: t('camera_tour.title'),
    //   url: APP_WEBSERVICE_PATH+'/private/camera_tour',
    //   icon: 'fa-regular fa-dot-circle',
    // },],[])),

    ...(userHasAccessWithExtraCheck("view_vehicle_categories", appHasVehicleCategories(), [{
      name: t('vehicle_categories.title'),
      url: APP_WEBSERVICE_PATH+'/private/vehicle_categories',
      icon: 'fa fa-taxi',
    },],[])),

    ...(userHasAccess("view_area_DISABLED_ASC",[
      {
        name: t('area.title'),
        url: APP_WEBSERVICE_PATH+'/private/areas',
        icon: 'fa fa-sitemap',
      }],[])),

    ...(userHasAccessWithExtraCheck("view_external_board", appHasFullAi(), [{
      name: t('external_board.title'),
      url: APP_WEBSERVICE_PATH+'/private/external_board',
      icon: 'fa fa-window-restore',
    },],[])),

    ...(userHasAccessWithExtraCheck("view_forecast", appHasAlpr(),[{
      id: 'view_forecast',
      name: t('route.forecast_labels'),
      url: APP_WEBSERVICE_PATH+'/private/forecast',
      icon: 'fa fa-podcast',
    }],[])),
    ...(userHasAccessWithExtraCheck("view_detection_area", appHasFullAi(),[{
      id: 'detection',
      name: t('route.detections'),
      url: APP_WEBSERVICE_PATH+'/private/detection',
      icon: 'fa fa-area-chart',
    }],[])),

    ...(userHasAccessOrDefaultSidebar("view_image_overlay", appHasNewPermission(), [{
      id: 'imageoverlay',
      name: t('overlay_image.title'),
      url: APP_WEBSERVICE_PATH+'/private/imageoverlay',
      icon: 'fa fa-object-group',
    }],[])),

    ...(userHasAccessOrDefaultSidebar("view_zone", appHasNewPermission(), [{
      name: t('zone.title'),
      url: APP_WEBSERVICE_PATH+'/private/zones',
      icon: 'fa fa-code-fork',
    },],[])),
  ];
  if(items.length>1)
    return items;
  else
    return [];
}



function sidebar(t,hasVideowall = false) {

  //Instanciamos sidebar
  let sidebarElements = {items: [ {
      id:'',
      children: [],
      title: true,
      name: t('menu')
    },]};
  //Agregamos los menus
  Array.prototype.push.apply(sidebarElements.items,getFirstMenu(t,hasVideowall));
  Array.prototype.push.apply(sidebarElements.items,getManagementMenu(t,hasVideowall));
  Array.prototype.push.apply(sidebarElements.items,getDataMenu(t));
  Array.prototype.push.apply(sidebarElements.items,getConfigurationMenu(t));
  Array.prototype.push.apply(sidebarElements.items,getUsersMenu(t));

  //console.log(sidebarElements);
  //devolvemos
  return sidebarElements;
};

export default sidebar;
/*        {
          id: 'configuration',
          name: t('configuration'),
          url: '/private/elements',
          icon: 'fa fa-cogs',
        },
                {
          name: t('map'),
          url: '/private/map',
          icon: 'fa fa-globe',
        },
        */
