import {API_BASE_URL} from "./LoginUtils";
import {MakeQuerablePromise, privRequest} from "./RequestUtils";


let CURRENT_AREA = "currentArea";



export function isCurrentAreaSet(){
  return getCurrentArea() != null;
}

export function initCurrentArea(){
  return new Promise(
    (resolve, reject) => { // fat arrow
   getDefaultAreaId()
    .then(function (area) {
      setCurrentArea(area);
     resolve();
    }).catch(function (errorData) {
      reject(errorData);
    }) }
    )
}

export function getDefaultAreaId(){
  return getDefaultArea()
    .then(function (area) {
      return area.id;
    }).catch(function (errorData) {
    throw errorData;
  });
}

export function getCurrentArea(){
  return localStorage.getItem(CURRENT_AREA);
}

export function setCurrentArea(areaId){
   localStorage.setItem(CURRENT_AREA,areaId);
}

export function clearCurrentArea(){
  localStorage.removeItem(CURRENT_AREA);
}

export function getDefaultArea() {
  return privRequest({
    url: API_BASE_URL + "auth/area/default",
    method: 'GET',
    //body: formData,
    params: null
  }).then( (response)=>{
    return response.data;
  }).catch(function(error){
    if (error != null && error.response != null) {
      console.log(error.response);
      throw error.response;
    }else{
      console.log(error);
      throw error;
    }
  });
}

export function getAreasAvailable() {
  return privRequest({
    url: API_BASE_URL + "auth/area/available",
    method: 'GET',
    //body: formData,
    params: null
  }).then((response) => {
    return response.data;
  }).catch(function (error) {
    if (error != null && error.response != null) {
      console.log(error.response);
      throw error.response;
    } else {
      console.log(error);
      throw error;
    }
  });
}

export function getSingleArea(areaIdToGet,areaId) {
      let resultPromise = privRequest({
        url: API_BASE_URL + "auth/area/"+areaIdToGet+"?area="+areaId,
        method: 'GET',
        //body: formData,
        params: null
      }).then(function(response){

        return response.data;
      }).catch(function(errorData){
        if (errorData != null && errorData.response != null){
          console.log(errorData.response);
          throw errorData.response;
        }
        else{
          console.log(errorData);
          throw errorData;
        }

      });
      return MakeQuerablePromise(resultPromise);
}


export function updateSingleArea(areaId, areaToUpdateId, areaBody) {
    let resultPromise = privRequest({
      url: API_BASE_URL + "auth/area/"+areaToUpdateId+"?area="+areaId,
      method: 'PUT',
      data: areaBody,
      params: null
    }).then(function(response){

      return response.data;
    }).catch(function(errorData){
      console.log(errorData);
      throw errorData;
    });
    return MakeQuerablePromise(resultPromise);
 }

export function insertSingleArea(areaId, areaBody) {
    let resultPromise = privRequest({
      url: API_BASE_URL + "auth/area/?area="+areaId,
      method: 'POST',
      data: areaBody,
      params: null
    }).then(function(response){

      return response.data;
    }).catch(function(errorData){
      console.log(errorData);
      throw errorData;
    });
    return MakeQuerablePromise(resultPromise);
}

export function deleteSingleArea(areaId, areaToDeleteId) {
    let resultPromise = privRequest({
      url: API_BASE_URL + "auth/area/"+areaToDeleteId+"?area="+areaId,
      method: 'DELETE',
      //data: roleBody,
      params: null
    }).then(function(response){

      return response.data;
    }).catch(function(errorData){
      console.log(errorData);
      throw errorData;
    });
    return MakeQuerablePromise(resultPromise);
}



