import {privRequest, pubRequest} from "./RequestUtils";
import {initCurrentArea,clearCurrentArea} from "./AreaUtils";

let ACCESS_TOKEN = "accessToken";
let REFRESH_TOKEN = "refreshToken";
let EXPIRATION_TOKEN_DATE = "expirationTokenDate";
export const APP_WEBSERVICE_PATH = import.meta.env.REACT_APP_WEBSERVICE_PATH?import.meta.env.REACT_APP_WEBSERVICE_PATH:"";
export const API_BASE_URL = APP_WEBSERVICE_PATH+'/api/';

//export const API_BASE_URL = 'http://192.168.110.20/api/';
let refreshingLogging = false;


export function getLocalStorageItem(item){
  return localStorage.getItem(APP_WEBSERVICE_PATH + "_" + item);
}
export function setLocalStorageItem(item,value){
  localStorage.setItem(APP_WEBSERVICE_PATH + "_" + item,value);
}
export function removeLocalStorageItem(item){
  return localStorage.removeItem(APP_WEBSERVICE_PATH + "_" + item);
}

export function getAccessToken()
{
  return getLocalStorageItem(ACCESS_TOKEN);
}

export function getAccessTokenRenewIfNeeded(){
  let expirationDate = getExpirationDate();
   if ((Date.now() / 1000 < expirationDate) &&
     (((Date.now() / 1000) > (expirationDate - 300)) && !refreshingLogging)){

    refreshingLogging = true;
    loginWithRefreshToken();
  }
  return getAccessToken();
}


function loginWithRefreshToken() {
  //const formData = new FormData();
  //const headers = new Headers();
  let refreshToken = getLocalStorageItem(REFRESH_TOKEN);
  let params = {refreshToken:refreshToken};

  //headers.set('Authorization', 'Basic ' + btoa("user" + ":" + "user"));

  let resultPromise =  privRequest({
    url: API_BASE_URL + "auth/user/refreshToken",
    method: 'POST',
 //   headers: headers,
    data: params

  }).then(function(response){
    //console.log(response);
    return setAccessToken(response.data.access_token,response.data.refresh_token);
  }).catch(function(errorData){
    //console.log(errorData);
    refreshingLogging = false;
  });
  return resultPromise;

}
export function setAccessToken(newAccessToken,refreshToken){

  let token = parseJwt(newAccessToken);
  setLocalStorageItem(ACCESS_TOKEN,newAccessToken);
  setLocalStorageItem(EXPIRATION_TOKEN_DATE,token.exp);
  setLocalStorageItem(REFRESH_TOKEN,refreshToken);
  refreshingLogging = false;
  return newAccessToken;
}


function parseJwt (token) {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
}

function getExpirationDate(){
  return getLocalStorageItem(EXPIRATION_TOKEN_DATE);
}

export function isLogged()
{
  if (getAccessToken() != null){
    let expirationDate = getExpirationDate();
    if (Date.now() / 1000 < expirationDate)
      return true;
  }
  return false;
}




export function getUserData()
{
  if (!isLogged())
    return null;
  else
    return parseJwt(getAccessToken());
}
//Comprueba si el usuario tiene el authority indicado, segun eso devuelve el valor
//con access o no access
export function userHasAccess(authority,retValAccess,retValNoAccess = undefined ){
  if (userHasAuthority(authority))
    return retValAccess;
  else
    return retValNoAccess;
}
//Comprueba si el usuario tiene el authority indicado, segun eso devuelve el valor
//con access o no access
export function userHasAccessWithExtraCheck(authority,extrachecks,retValAccess,retValNoAccess = undefined ){
  if (userHasAuthority(authority) && (extrachecks === true || extrachecks === "true"))
    return retValAccess;
  else
    return retValNoAccess;
}

//Comprueba si el usuario tiene el authority indicado, segun eso devuelve el valor
//con access o no access
export function userHasAccessOrDefaultSidebar(authority,newPermission,retValAccess,retValNoAccess = undefined ){
  if (!newPermission)
    return retValAccess;
  else if (userHasAuthority(authority) && newPermission)
    return retValAccess;
  else
    return retValNoAccess;
}
//Comprueba si el usuario tiene el authority indicado, segun eso devuelve el valor
//con access o no access
export function userHasAccessOrDefaultSidebarWithExtraCheck(authority,extraChecks,newPermission,retValAccess,retValNoAccess = undefined ){
  if (!newPermission)
    if (extraChecks)
      return retValAccess;
    else
      return retValNoAccess;
  else if (userHasAuthority(authority) && newPermission && extraChecks)
    return retValAccess;
  else
    return retValNoAccess;
}

//Comprueba si un usuario tiene el authority indicado
export function userHasAuthority(authority){
  let userData = getUserData();
  let checkArray;
  if (Array.isArray(authority)) {
    checkArray = authority;
  } else {
    checkArray = [authority];
  }

  if (userData == null) {
    return false;
  } else if (userData.authorities != null) {
    for (let auth of checkArray) {
      for (let authority of userData.authorities) {
        if (authority.endsWith(auth)) {
          return true;
        }
      }
    }

  } else {
    return false;
  }
}


export function clearAccessToken(){
  removeLocalStorageItem(ACCESS_TOKEN);
  removeLocalStorageItem(EXPIRATION_TOKEN_DATE);
  clearCurrentArea();
}

export function logout(){
  clearAccessToken();
}

export function login(username,password) {
  //const formData = new FormData();
  const headers = new Headers();
  //formData.append("username",username);
  //formData.append("password",password);
  let params = {username:username,password:password};

  //headers.set('Authorization', 'Basic ' + btoa("user" + ":" + "user"));
 return new Promise(
   (resolve, reject) => { // fat arrow
    pubRequest({
      url: API_BASE_URL + "auth/user/login",
      method: 'POST',
      headers: headers,
      data: params
    })
      .then(function(response){
        let token =setAccessToken(response.data.access_token,response.data.refresh_token);
        initCurrentArea().then(() =>
          resolve(token)
        )
       //return setAccessToken(response.data.access_token,response.data.refresh_token)
    }).catch(function(errorData){
      reject(errorData);
      console.log(errorData);
    })
   })
}
