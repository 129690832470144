import React, {Component} from "react";
import {Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown} from "reactstrap";

import {getCurrentArea, setCurrentArea} from "~/external-modules/dlv-web-common/util/AreaUtils";
import {
  getStatusInfoActiveEventsByArea,
  getStatusInfoPendingAckEventsByArea
} from "~/external-modules/dlv-web-common/util/EventUtils";
import {withTranslation} from "react-i18next";
import {APP_WEBSERVICE_PATH} from "~/external-modules/dlv-web-common/util/LoginUtils";


class EventsBell extends Component {

  constructor(props) {
    super(props);
    let actualArea = getCurrentArea();
    this.state = {
      refreshTime: 60000,
      refreshInterval:null,
      currentAlarmColor: "success",
      totalEventCount: "",
      veryHighPriorityEventCount: "N/A",
      highPriorityEventCount: "N/A",
      mediumPriorityEventCount: "N/A",
      lowPriorityEventCount: "N/A",
      nonePriorityEventCount: "N/A",
      actualArea: actualArea,
      dropdownOpen: false,
    };
    this.toggle = this.toggle.bind(this);
    this.setEvents = this.setEvents.bind(this);
    this.changeCurrentArea = this.changeCurrentArea.bind(this);
    this.goToEvents = this.goToEvents.bind(this);
    this.getAlarmColor = this.getAlarmColor.bind(this);
    this.configureRefreshInterval = this.configureRefreshInterval.bind(this);

    /* this.getCurrentAreaName = this.getCurrentAreaName.bind(this);
     this.setAreasAvailable = this.setAreasAvailable.bind(this);*/


  }


  componentDidMount() {
    if (this.props.eventList == null) {
      this.setEvents();
      this.configureRefreshInterval();
    }else{
      this.setState(this.props.eventList);
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.eventList !== this.props.eventList){
      this.setState(this.props.eventList);
    }
  }

  componentWillUnmount() {
    if (this.state.refreshInterval != null){
      clearInterval(this.state.refreshInterval);
    }
  }

  configureRefreshInterval(){
      this.setState({ refreshInterval:setInterval(this.setEvents,this.state.refreshTime)});
  }

  setEvents() {
    let that = this;
    getStatusInfoActiveEventsByArea(this.state.actualArea).then(function (eventInfo) {
      let totalVeryHigh = eventInfo.totalVeryHigh;
      let totalHigh =eventInfo.totalHigh;
      let totalMedium =eventInfo.totalMedium;
      let totalLow = eventInfo.totalLow;
      let totalNone = eventInfo.totalNone;
      let totalEvents = eventInfo.totalEvents;
      that.getAlarmColor(totalVeryHigh, totalHigh, totalMedium, totalLow, totalNone);
      that.setState(
        {
          totalEventCount: totalEvents,
          veryHighPriorityEventCount: totalVeryHigh,
          highPriorityEventCount: totalHigh,
          mediumPriorityEventCount: totalMedium,
          lowPriorityEventCount: totalLow,
          nonePriorityEventCount: totalNone
        });
      if (that.props.receiveEventList != null){
          that.props.receiveEventList({
          totalEventCount: totalEvents,
          veryHighPriorityEventCount: totalVeryHigh,
          highPriorityEventCount: totalHigh,
          mediumPriorityEventCount: totalMedium,
          lowPriorityEventCount: totalLow,
          nonePriorityEventCount: totalNone
        });
      }
      return null;
    }).catch(function (errorData) {
      console.log(errorData);
      throw errorData;
    });
  }

  getAlarmColor(veryHighPriorityEventCount, highPriorityEventCount
    , mediumPriorityEventCount, lowPriorityEventCount, nonePriorityEventCount) {
    let currentAlarmColor = null;
    if (veryHighPriorityEventCount > 0)
      currentAlarmColor = "dark";
    else if (highPriorityEventCount > 0)
      currentAlarmColor = "danger";
    else if (mediumPriorityEventCount > 0)
      currentAlarmColor = "warning";
    else if (lowPriorityEventCount > 0)
      currentAlarmColor = "info";
    else if (nonePriorityEventCount > 0)
      currentAlarmColor = "success";

    this.setState({currentAlarmColor: currentAlarmColor})
  }


  /*getCurrentAreaName(){
    let actualName='';
    this.state.areasAvailable.forEach(function(area) {
      if(area.id===this.state.currentArea)
        actualName = area.name;
    });
    return actualName;
  }


  setAreasAvailable(){
    let that = this;
    getAreasAvailable().then(function (areas) {
      that.setState({areasAvailable:areas});
    }).catch(function (errorData) {
      console.log(errorData);
      throw errorData;
    });
  }*/


  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  goToEvents =(e) => {
    window.location.href = APP_WEBSERVICE_PATH +'/private/events';
  }

  changeCurrentArea(newAreaId) {
    setCurrentArea(newAreaId);
    this.setAreasAvailableAndAreasName();
  }


  render() {
    const {t} = this.props;
    if (this.state.areasAvailable !== null) {
      return (
        <React.Fragment>
          <Nav className="ml-auto position-relative d-inline-block" navbar>
              <UncontrolledDropdown inNavbar={true} className={this.props.className} size='lg' nav direction="down">
                  <DropdownToggle className={"header-text"} style={{border:"none"}} outline color="dark" caret size="md">
                    <i className="header-text-color icon-bell"/>
                    <Badge pill color={this.state.currentAlarmColor}>{this.state.totalEventCount}</Badge>
                </DropdownToggle>
                <DropdownMenu style={{right:0,left:"auto"}} end >
                  <DropdownItem header tag="div" className="text-center"><strong>{t("events.title")}</strong></DropdownItem>
                  <DropdownItem disabled style={{cursor:'auto', color:'#23282c'}}>
                    <i className="fa-regular fa-bell"/> {t("events.priority.very_high")}
                    <Badge pill color="dark">{this.state.veryHighPriorityEventCount}</Badge>
                  </DropdownItem>
                  <DropdownItem disabled style={{cursor:'auto', color:'#23282c'}}>
                    <i className="fa-regular fa-envelope"/> {t("events.priority.high")}
                  <Badge  pill color="danger">{this.state.highPriorityEventCount}</Badge>
                  </DropdownItem>
                  <DropdownItem disabled style={{cursor:'auto', color:'#23282c'}}>
                    <i className="fa fa-tasks"/> {t("events.priority.medium")}
                    <Badge pill color="warning">{this.state.mediumPriorityEventCount}
                    </Badge>
                  </DropdownItem>
                  <DropdownItem disabled style={{cursor:'auto', color:'#23282c'}}>
                    <i className="fa fa-comments"/> {t("events.priority.low")}
                    <Badge pill color="info">{this.state.lowPriorityEventCount}</Badge>
                  </DropdownItem>
                  <DropdownItem disabled style={{cursor:'auto', color:'#23282c'}}>
                    <i className="fa fa-comments"/> {t("events.priority.none")}
                    <Badge pill color="success">{this.state.nonePriorityEventCount}</Badge>
                  </DropdownItem>
                  <DropdownItem className="text-center" onClick={ this.goToEvents}
                  ><strong>{t("events.view_all")}</strong></DropdownItem>

                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
        </React.Fragment>
      )
    }
  }
}
export default withTranslation('common')(EventsBell);
