import {API_BASE_URL} from "./LoginUtils";
import {privRequest} from "./RequestUtils";


export function getStatusInfoActiveEventsByArea(areaId) {
  return privRequest({
    url: API_BASE_URL + "eventstate/event/status/active/"+areaId,
    method: 'GET',
    //data: formData,
    params: null
  }).then( (response)=>{
    return response.data;
  }).catch(function(error){
    if (error != null && error.response != null) {
      console.log(error.response);
      throw error.response;
    }else{
      console.log(error);
      throw error;
    }
  });
}
export function getStatusInfoAllEventsByArea(areaId) {
  return privRequest({
    url: API_BASE_URL + "eventstate/event/status/all/"+areaId,
    method: 'GET',
    //data: formData,
    params: null
  }).then( (response)=>{
    return response.data;
  }).catch(function(error){
    if (error != null && error.response != null) {
      console.log(error.response);
      throw error.response;
    }else{
      console.log(error);
      throw error;
    }
  });
}

export function getStatusInfoPendingAckEventsByArea(areaId) {
  return privRequest({
    url: API_BASE_URL + "eventstate/event/status/pendingAck/"+areaId,
    method: 'GET',
    //data: formData,
    params: null
  }).then( (response)=>{
    return response.data;
  }).catch(function(error){
    if (error != null && error.response != null) {
      console.log(error.response);
      throw error.response;
    }else{
      console.log(error);
      throw error;
    }
  });
}

export function getStatusInfoHistoricalEventsByArea(areaId) {
  return privRequest({
    url: API_BASE_URL + "eventstate/event/status/historical/"+areaId,
    method: 'GET',
    //data: formData,
    params: null
  }).then( (response)=>{
    return response.data;
  }).catch(function(error){
    if (error != null && error.response != null) {
      console.log(error.response);
      throw error.response;
    }else{
      console.log(error);
      throw error;
    }
  });
}

export function getActiveEventsByArea(areaId) {
  return privRequest({
    url: API_BASE_URL + "eventstate/event/active/"+areaId,
    method: 'GET',
    //data: formData,
    params: null
  }).then( (response)=>{
    return response.data;
  }).catch(function(error){
    if (error != null && error.response != null) {
      console.log(error.response);
      throw error.response;
    }else{
      console.log(error);
      throw error;
    }
  });
}
export function getAllEventsByArea(areaId) {
  return privRequest({
    url: API_BASE_URL + "eventstate/event/all/"+areaId,
    method: 'GET',
    //data: formData,
    params: null
  }).then( (response)=>{
    return response.data;
  }).catch(function(error){
    if (error != null && error.response != null) {
      console.log(error.response);
      throw error.response;
    }else{
      console.log(error);
      throw error;
    }
  });
}

export function getPendingAckEventsByArea(areaId) {
  return privRequest({
    url: API_BASE_URL + "eventstate/event/pendingAck/"+areaId,
    method: 'GET',
    //data: formData,
    params: null
  }).then( (response)=>{
    return response.data;
  }).catch(function(error){
    if (error != null && error.response != null) {
      console.log(error.response);
      throw error.response;
    }else{
      console.log(error);
      throw error;
    }
  });
}

export function getHistoricalEventsByArea(areaId) {
  return privRequest({
    url: API_BASE_URL + "eventstate/event/historical/"+areaId,
    method: 'GET',
    //data: formData,
    params: null
  }).then( (response)=>{
    return response.data;
  }).catch(function(error){
    if (error != null && error.response != null) {
      console.log(error.response);
      throw error.response;
    }else{
      console.log(error);
      throw error;
    }
  });
}

export function setSingleEventAck(eventId) {
  let eventIds=[];
  eventIds.push(eventId);
  return privRequest({
    url: API_BASE_URL + "eventstate/event/ackList",
    method: 'PUT',
    data: eventIds,
    params: null
  }).then( (response)=>{
    return response.data;
  }).catch(function(error){
    if (error != null && error.response != null) {
      console.log(error.response);
      throw error.response;
    }else{
      console.log(error);
      throw error;
    }
  });
}
