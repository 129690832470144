import React, {Component} from 'react';

import {newRequestPage} from "../../util/RequestUtils";
import withRouter from "~/external-modules/dlv-web-common/component/Route/WithRouter";

class LocationListener extends Component {


  // componentDidUpdate(prevProps) {
  //   if (this.props.location.pathname !== prevProps.location.pathname) {
  //     newRequestPage();
  //   }
  // }
  shouldComponentUpdate(nextProps, nextState){
    if (this.props.location.pathname !== nextProps.location.pathname) {
      newRequestPage();
    }
    return true;
  }

  constructor(props) {
    super(props);
    newRequestPage();
  }

  render() {
    return this.props.children;
  }
}
export default withRouter(props => <LocationListener {...props} />);
