import React, {Component} from 'react';
import {Button, Col, Input, InputGroup,  InputGroupText, Row} from 'reactstrap';
import {login} from "../../../external-modules/dlv-web-common/util/LoginUtils"

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      errorText: ''
    };

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }


  handleKeyPress(e){
    if (e.key === 'Enter')
      this.handleClick();
  }

  handleClick() {
    //login()
    let that = this;
    login(this.state.username, this.state.password).then(function (token) {
      if (token != null) {
        if (that.props.loggedChanged != null)
          that.props.loggedChanged();
       // that.props.history.push('/');
      } else {
        that.setState({
          errorText: "Error al realizar login"
        });
      }
    }).catch(function (errorData) {
      /*
      const errorText = errorData.error_description ? errorData.error_description : errorData.message;
      const errorType = errorData.error ? errorData.error : errorData.stack;
*/
      that.setState({
        errorText: "Usuario o contraseña incorrectos"/* errorType + ":" + errorText*/
      });
    });

  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }


  render() {



    const errorParagraph =
      this.state.errorText ?
        <p className="test-muted" style={{color:"red"}}>{this.state.errorText}</p>
        : null;

    return (<div className="px-1 py-2 loginPopup">
        <div className="text-center">
        <span className="py-1">Inicio de sesión</span>
        </div>
        {errorParagraph}
        <InputGroup className="mb-3 pt-2">

            <InputGroupText>
              <i className="icon-user"/>
            </InputGroupText>

          <Input type="text" placeholder="Nombre de usuario" name="username" value={this.state.username}
                 onChange={this.handleInputChange}/>
        </InputGroup>
        <InputGroup className="mb-2">

            <InputGroupText>
              <i className="icon-lock"/>
            </InputGroupText>

          <Input name="password" type="password" placeholder="Contraseña" value={this.state.password}
                 onChange={this.handleInputChange} onKeyPress={this.handleKeyPress}/>
        </InputGroup>
        <Row className="py-1">
          <Col xs={{size: 6, offset: 3}} className="text-center">
            <Button onClick={this.handleClick} color="primary" /*className="px-4"*/>Login</Button>
          </Col>
        </Row>
        <div className="pl-1">
          <a href="/register">Regístrate</a><br/>
          <a href="/reset-password">Recupera tu contraseña</a>
        </div>
      </div>
    );
  }
}

export default Login;
