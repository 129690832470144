import React, {Component, Suspense} from 'react';
import {Outlet, Route, Routes} from 'react-router-dom';
import {Container, Spinner} from 'reactstrap';
import "../../custom/PrivateLayout.css"
import "roboto-fontface/css/roboto/roboto-fontface.css"
// sidebar nav config
import sidebar from './sidebar';
// routes config
import privateRoutes from '~/private/route/privateRoutes';
import PrivateHeader from './PrivateHeader';
import {WithTranslation, withTranslation} from 'react-i18next';
import SidebarNav from "~/external-modules/dlv-web-common/component/Layout/SidebarNav.jsx";
import LayoutEvents from "~/private/events/LayoutEvents";
import i18n from "~/config/i18n.jsx";
import queryString from "query-string";
import {layoutApi, videowallApi} from "~/external-modules/dlv-ai-common/util/mediasignaler/Apis";
import {excludeI18n} from '~/external-modules/dlv-web-common/util/Utils';
import "./icon.css"
import {appDefaultLang, appHasDisableFlags, appHasFullAi} from "~/common/checks/app-checks";
import {API_BASE_URL, APP_WEBSERVICE_PATH} from "~/external-modules/dlv-web-common/util/LoginUtils";
import {externalBoardsApi, vehicleApi} from "~/external-modules/dlv-ai-common/util/data/Apis";
import {privRequest} from "~/external-modules/dlv-web-common/util/RequestUtils";
import DlvAppHeader from "~/external-modules/dlv-web-common/component/Nav/DlvAppHeader";
import DlvAppSidebar from "~/external-modules/dlv-web-common/component/Nav/DlvAppSidebar";
import DlvAppSidebarHeader from "~/external-modules/dlv-web-common/component/Nav/DlvAppSidebarHeader";
import DlvAppSidebarForm from "~/external-modules/dlv-web-common/component/Nav/DlvAppSidebarForm";
import withRouter from "~/external-modules/dlv-web-common/component/Route/WithRouter";
import DlvAppBreadcrumb from "~/external-modules/dlv-web-common/component/Nav/DlvAppBreadcrumb";

interface SidebarItem {
  name: string;
  icon: string;
  url: string;
}

interface IProps {
  location: Location;
}

type Props = IProps & WithTranslation;

interface State {
  fixedMaxHeight: null,
  layouts: SidebarItem[],
  dashboardList: SidebarItem[],
  jasperReportList: SidebarItem[],
  disableFlags: boolean,
  defaultLang: string,
  disableBorders: boolean,
  hasVideowall: boolean
}


class PrivateLayout extends Component<Props, State> {
  //static whyDidYouRender = true;
  private parentContainer: React.RefObject<HTMLDivElement>;
  private currentListener: () => void;

  constructor(props) {
    super(props);
    this.parentContainer = React.createRef();
    this.state = {
      fixedMaxHeight: null,
      layouts: [],
      dashboardList: [],
      jasperReportList: [],
      disableFlags: false,
      defaultLang: 'ES',
      disableBorders: false,
      hasVideowall: false
    };
    this.refreshLayoutList = this.refreshLayoutList.bind(this);
    this.handleHeightCallback = this.handleHeightCallback.bind(this);
    this.updateFlags = this.updateFlags.bind(this);
    this.renderMainDiv = this.renderMainDiv.bind(this);
    this.renderWithBorders = this.renderWithBorders.bind(this);
    this.renderWithoutBorders = this.renderWithoutBorders.bind(this);
  }

  /**
   * Fuerza el tamaño de la ventana general al especificado.
   * IMPORTANTE: Si se usa, se debe de usar con null en el componentWillUnmount()
   * @param newHeight
   */
  handleHeightCallback(newHeight) {

    this.setState({fixedMaxHeight: newHeight});
  }


  componentWillUnmount() {
    LayoutEvents.removeListener(this.currentListener);
  }

  updateFlags() {
    const disableFlags = appHasDisableFlags();
    const defaultLang = appDefaultLang();
    if (disableFlags === true) {

      i18n.changeLanguage(defaultLang.toLowerCase());
    }
    this.setState({disableFlags: disableFlags, defaultLang: defaultLang})
  };

  refreshVideowalls = () => {
    let that = this;
    videowallApi.findVideowalls().then(x => {
      that.setState({hasVideowall: x.data.length > 0});
    });
  };

  componentDidMount() {
    this.refreshLayoutList();
    if (appHasFullAi())
      this.refreshDashboardList();
    this.refreshVideowalls();
    let that = this;
    this.currentListener = () => {
      that.refreshLayoutList();
    };
    LayoutEvents.addListener(this.currentListener);
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 1000);
    this.updateFlags();
    const values = queryString.parse(this.props.location.search);

    if (values.disableborders === "true") {
      this.setState({disableBorders: true});
    }


  }

  refreshDashboardList = () => {
    const that = this;
    const {t} = this.props;
    externalBoardsApi.getAllExternalBoards().then(function (response) {
      // console.log("Obtenida lista dashboard: " + response.data);
      let dashboardList = response.data;

      let newMetabaseDashboards: SidebarItem[] = [];
      let newJasperReports: SidebarItem[] = [];

      if (dashboardList.length > 0) {


        for (let dashboard of dashboardList) {
          if (dashboard.type == "METABASE") {
            newMetabaseDashboards.push({
              name: dashboard.name == 'default' ? t('dashboard.title') : dashboard.name,
              icon: 'nav-icon icon-speedometer',
              url: APP_WEBSERVICE_PATH + '/private/edashboard?id=' + dashboard.id,
            })
          } else {
            newJasperReports.push({
              name: dashboard.name,
              icon: 'nav-icon fa-regular fa-file-text',
              url: APP_WEBSERVICE_PATH + '/private/edashboard?id=' + dashboard.id,
            })
          }
        }

      }
      that.setState({dashboardList: newMetabaseDashboards, jasperReportList: newJasperReports});
    }).catch(function (errorData) {
      console.log("No se ha obtenido lista " + errorData);
      throw errorData;
    });
    /*
    vehicleApi.getLayouts().then(response => {
      let layoutList = response.data;

      let newLayouts: SidebarItem[] = [];

      if (layoutList.length > 0) {


        for (let layout of layoutList) {
          newLayouts.push({
            name: layout.name,
            icon: 'fa fa-window-maximize sidebaricon-small',
            url: APP_WEBSERVICE_PATH+'/private/layout?id=' + layout.id,
          })
        }

      } else {
        //TODO Crear nuevo layout
        newLayouts.push({
          name: 'Crear layout',
          icon: 'fa fa-plus sidebaricon-small',
          url: APP_WEBSERVICE_PATH+'/private/layout',
        })
      }


      this.setState({layouts: newLayouts});

    });*/
  };

  refreshLayoutList() {
    layoutApi.getLayouts().then(response => {
      let layoutList = response.data;

      let newLayouts: SidebarItem[] = [];

      if (layoutList.length > 0) {


        for (let layout of layoutList) {
          newLayouts.push({
            name: layout.name,
            icon: 'fa fa-window-maximize sidebaricon-small',
            url: APP_WEBSERVICE_PATH + '/private/layout?id=' + layout.id,
          })
        }

      } else {
        //TODO Crear nuevo layout
        newLayouts.push({
          name: 'Crear layout',
          icon: 'fa fa-plus sidebaricon-small',
          url: APP_WEBSERVICE_PATH + '/private/layout',
        })
      }


      this.setState({layouts: newLayouts});

    });
  };

  getSidebarList = () => {
    const {t} = this.props;
    const layouts = this.state.layouts;
    const dashboardList = this.state.dashboardList;
    const reportList = this.state.jasperReportList;
    let sidebarTemp = sidebar(t, this.state.hasVideowall);
    for (let item of sidebarTemp.items) {
      if (item.id === 'layouts') {
        item.children = layouts;
        continue;
      }
      if (item.id === 'dashboardList') {

        item.children = dashboardList;
      }
      if (item.id === 'reportList') {

        item.children = reportList;
      }
    }
    return sidebarTemp;
  }

  renderMainDiv() {
    const {t} = this.props;
    return <Container style={{"height": "100%", maxHeight: this.state.fixedMaxHeight}} className="fullContainer" fluid>
      <div ref={this.parentContainer} style={{"height": "100%", "width": "100%"}}>
        <Suspense fallback={<div style={{"width":"100%","textAlign":"center"}}><Spinner color="dark" /></div>}>
          <Outlet context={{parentContainer: this.parentContainer, heightCallback: this.handleHeightCallback}}/>
        </Suspense>
      </div>
    </Container>;
  }

  renderWithBorders() {
    const {t} = this.props;
    const cleanProps = excludeI18n(this.props);
    return <React.Fragment>
      <DlvAppHeader className="header-nav header-text" fixed>
        <PrivateHeader defaultLanguage={this.state.defaultLang} disableFlags={this.state.disableFlags}/>
      </DlvAppHeader>
      <div className="app-body">
        <DlvAppSidebar fixed display="lg">
          <DlvAppSidebarHeader/>
          <DlvAppSidebarForm/>
          <SidebarNav isOpen={false}
                      navConfig={this.getSidebarList()} {...cleanProps} />
          {/*<img src={require('./logosgv/GVLogo.png')} className="b-t-2 border-secondary"/>*/}
        </DlvAppSidebar>
        <main className="main" style={{overflowY: "hidden"}}>
          <DlvAppBreadcrumb className="header-breadcrumb" appRoutes={privateRoutes(t)} {...cleanProps} />
          {this.renderMainDiv()}

        </main>
      </div>
    </React.Fragment>
  }

  renderWithoutBorders() {
    return this.renderMainDiv();

  }


  render() {
    return (
      <div className="app">
        {this.state.disableBorders ? this.renderWithoutBorders() :
          this.renderWithBorders()
        }
      </div>

    );
  }
}

export default withRouter(withTranslation('common')(PrivateLayout));
