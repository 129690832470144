import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import "./Imports"
import {APP_WEBSERVICE_PATH, isLogged} from "./external-modules/dlv-web-common/util/LoginUtils";
import React, {Suspense} from "react";
import Login from "./public/page/Login/Login";


import privateRoutes from "~/private/route/privateRoutes";
import {useTranslation} from "react-i18next";
import PrivateLayout from "~/private/layout/PrivateLayout";
import LocationListener from "~/external-modules/dlv-web-common/component/Redirect/LocationListener";

function getCurrentLoginLocation() {
  return APP_WEBSERVICE_PATH + "/login?location=" + btoa(window.location.href);
}


function RequireAuth({children}: any) {
  if (!isLogged()) {
    window.location.href = getCurrentLoginLocation();
    return null;
  }
  return children;
  //return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

/*
               <RequireAuth>
<PrivateLayout />
</RequireAuth>
// parentContainer={this.parentContainer}
                              // heightCallback={this.handleHeightCallback}
 */
function App() {
  const [t, i18n] = useTranslation('common');
  return (
    <BrowserRouter>


          <Routes>
            <Route key={'a0'} path={APP_WEBSERVICE_PATH + "/private"} element={
              <RequireAuth>
                <LocationListener>
                <PrivateLayout/>
                </LocationListener>
              </RequireAuth>
            }>
              {privateRoutes(t).map((route, idx) => {
                const PrivateRouteComponent = route.component as any;
                  // @ts-ignore
                  return route.component && (
                    <Route key={idx} path={route.path} element={
                      <PrivateRouteComponent/>
                    }/>);
                },
              )}
            </Route>
            {/* Si se redirige a login, redirigimos a login a la otra url*/}
            <Route key={'a1'} path={APP_WEBSERVICE_PATH + "/login"} element={<Login/>}/>
            <Route key={'a2'} path={APP_WEBSERVICE_PATH + "/"} element={<Login/>}/>


          </Routes>


    </BrowserRouter>

  );
}

export default App
