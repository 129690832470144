import React, {Component} from 'react';
import {Col, Nav, NavItem, NavLink, Row} from 'reactstrap';
import PropTypes from 'prop-types';

import {withTranslation} from "react-i18next";
import EventsBell from "~/common/view/EventsBell/EventsBell";
import LoginDropdown from "~/common/view/LoginDropdown/LoginDropdown";
import {logo, logo_min} from "../../custom/logo";
import FlagLanguageSelector from "./FlagLanguageSelector";
import {userHasAccessOrDefaultSidebar} from "~/external-modules/dlv-web-common/util/LoginUtils";
import {appHasNewPermission} from "~/common/checks/app-checks";
import DlvAppSidebarToggler from "~/external-modules/dlv-web-common/component/Nav/DlvAppSidebarToggler";
import DlvAppNavbarBrand from "~/external-modules/dlv-web-common/component/Nav/DlvAppNavbarBrand";
import {Popover,Button} from "antd";

const propTypes = {
  children: PropTypes.node,
  disableFlags: PropTypes.bool
};

const defaultProps = {};

class PrivateHeader extends Component {

  constructor(props) {
    super(props);
    this.state={
      eventList:[]
    }
  }

  render() {

    // eslint-disable-next-line
    const {children, ...attributes} = this.props;
    const {t} = this.props;
    const that = this;
    const content = (
      <>
        <Row>
          <Col >
            { this.props.disableFlags !== true ?
              <FlagLanguageSelector class="text-secondary" defaultLanguage={this.props.defaultLanguage}/>
              : "" }
            <LoginDropdown prefixLink={"/private"}/>
          </Col>
        </Row>
      </>
    )
    return (
      <React.Fragment>

        <div className={"header-sidebar-nav-logo"} style={{display:'flex',alignItems:'center'}}>
          <div style={{display:'flex',flexDirection:'column'}}>
            <DlvAppSidebarToggler key={"1"} className="d-lg-none shadow-none" display="md" mobile/>
            <DlvAppSidebarToggler key={"2"} className="d-compact-none shadow-none btn-disable-mob" display="lg"/>
            </div>
          <div>
            <a href="/private/dashboard"  className={"mt-3"} >
              <DlvAppNavbarBrand
                full={{src: logo,
                  width: "auto",height: "auto",alt: 'Logo',
                  marginLeft:'30' }}
                minimized={{src: logo_min, width: "auto", height: "auto", alt: 'Logo Minimized'}}
                className={"logo"}
              />
            </a>
          </div>
        </div>


        <Nav key="n2" className="ml-auto header-text menuD" navbar>
          {userHasAccessOrDefaultSidebar("view_event", appHasNewPermission(), [
            <EventsBell key={""} receiveEventList={(eventList)=>that.setState({eventList:eventList})}/>],[])}
          { this.props.disableFlags !== true ?
            <FlagLanguageSelector defaultLanguage={this.props.defaultLanguage}/>
            : "" }
          {/*<AreaDropdown/>*/}
          <LoginDropdown prefixLink={"/private"}/>
        </Nav>

        <div className="menuT">
          <Nav className="ml-auto header-text" navbar>
            <div style={{marginRight:"0.5em"}}>
              {userHasAccessOrDefaultSidebar("view_event", appHasNewPermission(), [
                <EventsBell key={""} eventList={that.state.eventList}/>],[])}
            </div>
            <Popover trigger={"click"} content={content} placement={"bottomLeft"} color={"#dee0e0"} style={{width:"auto",height:"auto"}}>
              <Button type={"ghost"}><i className={"fas fa-ellipsis-v"} style={{color: "#cccccc"}}/></Button>
            </Popover>
          </Nav>
        </div>

        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
      </React.Fragment>
    );
  }
}

PrivateHeader.propTypes = propTypes;
PrivateHeader.defaultProps = defaultProps;

export default React.memo(withTranslation('common')(PrivateHeader));


/*
<AppAsideToggler className="d-md-down-none"/>
            <div right style={{right: 'auto'}}>
              <div header tag="div" className="text-center"><strong>Account</strong></div>
              <div><i className="fa fa-bell-o"></i> Updates<Badge color="info">42</Badge></div>
              <div><i className="fa fa-envelope-o"></i> Messages<Badge
                color="success">42</Badge></div>
              <div><i className="fa fa-tasks"></i> Tasks<Badge color="danger">42</Badge></div>
              <div><i className="fa fa-comments"></i> Comments<Badge color="warning">42</Badge></div>
              <div header tag="div" className="text-center"><strong>Settings</strong></div>
              <div><i className="fa fa-user"></i> Profile</div>
              <div><i className="fa fa-wrench"></i> Settings</div>
              <div><i className="fa fa-usd"></i> Payments<Badge color="secondary">42</Badge></div>
              <div><i className="fa fa-file"></i> Projects<Badge color="primary">42</Badge></div>
              <div divider/>
              <div><i className="fa fa-shield"></i> Lock Account</div>
              <div><i className="fa fa-lock"></i> Logout</div>
            </div>
          */
