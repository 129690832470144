import {
  CameraControllerApi,
  CameraSequenceControllerApi,
  JoystickControllerApi,
  LayoutControllerApi,
  MultiLayoutControllerApi,
  CameraBrandControllerApi,
  OverlayImageApi,
  ScenarioApi,
  VideowallApi,
  DetectionCameraControllerApi,
  VideoEventControllerApi,
  DetectorApi,
  DetectionAreaControllerApi,
  CameraPresetControllerApi,
  DetectionCameraScheduleControllerApi,
  DetectionCameraScheduleDateControllerApi,
  DetectionCameraScheduleHistControllerApi,
  AiModuleApi,
  CameraTourControllerApi, GeoDataControllerApi, CameraImagingSettingsControllerApi,
  CameraPtzControllerApi
} from "../../openapi/mediasignaler/api";
import {API_BASE_URL, getAccessTokenRenewIfNeeded} from "../../../dlv-web-common/util/LoginUtils";
import {Configuration} from "~/external-modules/dlv-ai-common/openapi/mediasignaler";
const commonConfig = new Configuration( {
  accessToken: getAccessTokenRenewIfNeeded,
  basePath: API_BASE_URL + "mediasignaler"
});

export const videowallApi = new VideowallApi(commonConfig);
export const cameraApi = new CameraControllerApi(commonConfig);
export const layoutApi = new LayoutControllerApi(commonConfig);
export const cameraSequenceApi = new CameraSequenceControllerApi(commonConfig);
export const joystickApi = new JoystickControllerApi(commonConfig);
export const layoutScenarioApi = new ScenarioApi(commonConfig);
export const multiLayoutApi = new MultiLayoutControllerApi(commonConfig);
export const cameraBrandApi = new CameraBrandControllerApi(commonConfig);
export const cameraPresetControllerApi = new CameraPresetControllerApi(commonConfig);
export const overlayImagesApi = new OverlayImageApi(commonConfig);
export const detectionApi = new DetectionCameraControllerApi(commonConfig);
export const videoEventApi = new VideoEventControllerApi(commonConfig);
export const detectorApi = new DetectorApi(commonConfig);
export const detectionAreaApi = new DetectionAreaControllerApi(commonConfig);
export const detectionCameraScheduleApi = new  DetectionCameraScheduleControllerApi(commonConfig);
export const detectionCameraScheduleDateApi = new  DetectionCameraScheduleDateControllerApi(commonConfig);
export const cameraTourApi = new CameraTourControllerApi(commonConfig);
export const aiModulesApi = new AiModuleApi(commonConfig);
export const geoDataApi = new GeoDataControllerApi(commonConfig);
export const imagingSettingsApi = new CameraImagingSettingsControllerApi(commonConfig);
export const cameraPtzApi = new CameraPtzControllerApi(commonConfig);
/*
export {videowallApi, cameraApi, layoutApi, cameraSequenceApi, joystickApi,layoutScenarioApi,multiLayoutApi,cameraBrandApi, overlayImagesApi
,detectionApi,videoEventApi, alprBlacklistGroupApi,detectorApi};
*/
